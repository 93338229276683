import React from 'react';
import {trans} from "../../config/helper";
import CircularProgress from "@mui/material/CircularProgress";

const SubmitButton = ({className, isSubmitLoading = false, btn_name = trans('save')}) => {
    return (
        <button type="submit" className={className}>
            {isSubmitLoading && <i className="loader-btn"></i>}
            <span>{btn_name}</span>
        </button>
    );
};

export default SubmitButton;
