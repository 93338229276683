/* payments */
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {SetLocalStatePaymentsAction} from "../../../store/invoices/InvoiceOperations";

export default () => {

    let dispatch = useDispatch();

    let InvoiceOperations = useRtkState('InvoiceOperations');

    let [payment_types, setPaymentTypes] = useState([]);

    let payment_skeleton = {payment_type_id: 1, amount: "", payment_serial_number: ""};

    useEffect(() => {
        AxiosInstance.get('payment_types?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setPaymentTypes(data.data);
        }).catch(function ({response}) {
            //
        });

        let old_payments = [];
        old_payments.push(payment_skeleton);
        dispatch(SetLocalStatePaymentsAction(old_payments, {}));
    }, []);

    let localStatePayments = InvoiceOperations?.localStatePayments;

    let handlePaymentsChange = (index, key, value) => {
        let payments = [];
        localStatePayments.forEach(function (localStatePayment, loop_index) {
            if (index == loop_index) {
                let payment = {...localStatePayment};
                payment[key] = value;
                payments.push(payment);
            } else {
                payments.push(localStatePayment);
            }
        })
        dispatch(SetLocalStatePaymentsAction(payments, {}));
    };

    let CreateNewPaymentHandler = () => {
        let payments = [...localStatePayments, payment_skeleton];
        dispatch(SetLocalStatePaymentsAction(payments, {}));
    };

    let DeletePaymentHandler = (index) => {
        let updatedRows = localStatePayments.filter((_, i) => i !== index);
        dispatch(SetLocalStatePaymentsAction(updatedRows, {}));
    };

    return {
        payment_types,
        localStatePayments,
        handlePaymentsChange,
        CreateNewPaymentHandler,
        DeletePaymentHandler,
        payment_skeleton,
    };
};