import React, {useState} from 'react';
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {trans} from "../../config/helper";

const PasswordInput = ({formik, name, error, label = trans('Password') , placeholder = trans('Enter_password') }) => {

    const [showHidePassword, setShowHidePassword] = useState(false);

    const showHidePasswordHandler = () => {
        setShowHidePassword(!showHidePassword);
    }

    const server_error = error;
    const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;
    const error_text = front_error || server_error;


    return (
        <div className="form-login">
            <label>{label}</label>
            <div className="pass-group">
                <input name={name}
                       type={showHidePassword ? 'text' : 'password'}
                       className={`pass-input form-control ${error_text ? "border border-danger" : ""}`}
                       placeholder={placeholder}
                       onChange={formik && formik.handleChange}
                    //  onBlur={formik && formik.handleBlur}
                />

                <span onClick={showHidePasswordHandler}
                      className={`bi toggle-password ${showHidePassword ? 'bi-eye' : 'bi-eye-slash'}`}>
                </span>
            </div>
            {formik && formik.touched.password && formik.errors.password ? (
                <ErrorValidateComponent error={formik.errors.password}/>) : null}
            {error && <ErrorValidateComponent error={error}/>}
        </div>
    );
};

export default PasswordInput;
