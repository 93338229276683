import React from 'react';
import NormalInput from "../../../shared/inputs/NormalInput";

const TransferStocksProducts = ({products, errors, deleteProductFromTableAction}) => {
    return (
        <table className="table table-custom">
            <thead>
            <tr>
                <th>اسم الصنف</th>
                <th>الكمية</th>
                <th>التحكم</th>
            </tr>
            </thead>
            <tbody>
            {
                products.map(function (product, index) {
                    return (
                        <tr key={product?.id}>

                            <td>
                                <div className="productimgname w-100 mw-100">
                                    <img src={product?.image_url} alt={product?.name}/>
                                    <p>
                                        {product?.name}
                                    </p>
                                </div>
                            </td>

                            {/* product_id */}
                            <td className="d-none">
                                <input name={`stocks[${index}][product_id]`}
                                       value={product?.id}
                                       type="number"/>
                            </td>
                            {/* ==================================== */}

                            <td>
                                    <NormalInput
                                        type={'number'}
                                        min={1}
                                        name={`stocks[${index}][quantity]`}
                                        className={'input-table-sm'}
                                        placeholder="0"
                                        step={1}
                                        error={errors?.[`stocks.${index}.quantity`]?.[0] ?? ''}/>
                            </td>

                            <td>
                                <div className="flex-btn-table">
                                    <a onClick={event => deleteProductFromTableAction(product)} href="#!"
                                       className="btn-danger">
                                        <i className="bi bi-trash">
                                        </i>
                                    </a>
                                </div>
                            </td>
                        </tr>);
                })
            }
            </tbody>
        </table>
    );
};

export default TransferStocksProducts;