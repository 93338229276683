import React from "react";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import { errorValidation } from "../../config/images-path";
import CustomTooltip from "../material-ui/CustomTooltip";

const DateInput = ({
    className,
    label,
    placeholder,
    formik,
    name,
    error,
    allow_formik_value = false,
    value = ""
}) => {
    const server_error = error;
    const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;

    return (
        <div className={className}>
            <div className="form-group">

                <div className="flex-label">
                    {label && <label>{label}</label>}
                    <span className="flex-error">
                        <CustomTooltip title={front_error || server_error}>
                            <em>
                                {front_error && (
                                    <img
                                        src={errorValidation}
                                        className={"warning-error"}
                                        alt={"errorValidation"}
                                    />
                                )}
                            </em>
                        </CustomTooltip>
                    </span>
                </div>

                {allow_formik_value ? (
                    <input
                        name={name}
                        className={`form-control ${front_error ? "border border-danger" : ""}`}
                        autoComplete={"off"}
                        type={"date"}
                        value={formik.values[name]} /* allow_formik_value */
                        placeholder={placeholder}
                        onChange={formik && formik.handleChange}
                        onBlur={formik && formik.handleBlur}
                    />
                ) : (
                    <input
                        name={name}
                        className={`form-control ${front_error ? "border border-danger" : ""}`}
                        type={"date"}
                        placeholder={placeholder}
                        onChange={formik && formik.handleChange}
                        onBlur={formik && formik.handleBlur}
                        value={value}
                    />
                )}
            </div>
        </div>
    );
};
export default DateInput;
