import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState
} from "../../../config/helper";
import React, {useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";


import {setLocale} from 'yup';
import {useDispatch} from "react-redux";
import {AppendNewCustomerToCustomersAction} from "../../../store/pos/Pos";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    const module_endpoint = 'customers';
    const module_name = 'العملاء';

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const [isPopUpOpened, setIsPopUpOpened] = useState(false);

    const handleClickOpen = () => {
        setIsPopUpOpened(true);
    };

    const state = useRtkState('Pos');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            country: '',
            city: '',
            address: '',
        },
        validationSchema: Yup.object({

            name: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            email: Yup.string().email(trans('The_email_format_is_invalid'))
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            phone: Yup.number().required(trans('This_field_is_required')),

            country: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            city: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            address: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            // dob: Yup.date()
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                let customer = data.data;
                setErrors({});
                setIsSubmitLoading(false);
                setIsPopUpOpened(false);
                dispatch(AppendNewCustomerToCustomersAction(customer, {}));
                successSweetAlert();
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    return {
        module_name,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        isPopUpOpened,
        setIsPopUpOpened,
        handleClickOpen,
    };
}