import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'roles';
    const route_endpoint = (prefix ? (prefix + '/') : '') + 'roles-permissions';
    const module_endpoint = 'roles';
    const module_name = 'الادوار والصلاحيات';

    const slug = 'تعديل بيانات ' + (oldItems?.name ?? '----');

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "roles-permissions");

        if (permissions.length > 0 && permissions.includes("roles-permissions")) {
            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                const old_item = data.data;
                await setOldItems(old_item);

                /* set inputs values */
                await formik.setValues({
                    name: old_item?.name,
                });

                setIsOldItemsLoading(false);
            }).catch(function ({response}) {
                if (response.data.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                }
            });
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });


    const resetForm = () => {
        setErrors({});
    }

    return {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    };
}