import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import {useDispatch} from "react-redux";
import {
    fetchProducts,
    AppendParamsValues,
    ShowInvoiceWithProducts,
    ResetAllInitialValues, SetInvoiceType, SetIsReturnInvoice, ShowInvoiceWithProductsAndInvoiceProducts
} from "../../../store/invoices/InvoiceOperations";
import {PRODUCT_PER_PAGE} from "../../../config/constants";
import SearchHooks from "../../system/invoice/SearchHooks";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'purchases-module';
    const module_endpoint = 'purchases';
    const module_name = 'المشتريات';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'purchases';
    const slug = 'تعديل بيانات ' + (oldItems?.reference_code ?? '----');

    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const dispatch = useDispatch();

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "purchases");

        if (permissions.length > 0 && permissions.includes("purchases")) {
            dispatch(SetInvoiceType('purchase', {}));
            dispatch(SetIsReturnInvoice(false, {}));

            AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setWarehouses(data.data);
            }).catch(function ({response}) {
            });

            AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setSuppliers(data.data);
            }).catch(function ({response}) {
            });

            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                const old_item = data.data;

                await setOldItems(old_item);
                /* set inputs values */
                await formik.setValues({
                    warehouse_id: old_item?.warehouse?.id,
                    supplier_id: old_item?.supplier?.id,
                });

                await dispatch(ShowInvoiceWithProductsAndInvoiceProducts({old_item}, {}));

                setIsOldItemsLoading(false);
            }).catch(function (response) {
                if (response?.data?.data?.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                }
            });

            return () => {
                dispatch(ResetAllInitialValues({}, {}));
            }
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {}, /* it will be server side validate */
        validationSchema: Yup.object({}), /* it will be server side validate */

        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);

                if (errors && typeof errors == "string") {
                    warningSweetAlert(errors)
                }
            });
        },

        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }


    /* start product list operations */
    const InvoiceOperations = useRtkState('InvoiceOperations');
    const invoice_products = InvoiceOperations.invoice_products;

    const products_list_ref = useRef();
    const search_loading = useRtkState('InvoiceOperations').search_loading;


    const searchProductsHandler = () => {
        const search_key = products_list_ref?.current?.value
        dispatch(AppendParamsValues({search_key: search_key, per_page: PRODUCT_PER_PAGE}, {}));
        dispatch(fetchProducts());
    }
    /* start product list operations */

    const onChangeHandler = (event) => {
        console.log({val: event.target.value});
    }

    /* ========================== search ============================ */
    const {handleOnFormChange} = SearchHooks(dispatch);
    /* ========================== search ============================ */


    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        suppliers,
        isOldItemsLoading,
        oldItems,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,
        /* start product list operations */
        onChangeHandler,
        handleOnFormChange,
    };
}