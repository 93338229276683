import AxiosInstance from "../../../config/AxiosInstance";
import {AppendProductToInvoiceAction} from "../../../store/pos/Pos";
import {trans, warningSweetAlert} from "../../../config/helper";
import {useDispatch} from "react-redux";
import GetCurrentProductInvoiceQty from "./useGetCurrentProductInvoiceQty";

const GetProductByCodeAndAppendToInvoice = async (value, state, dispatch, callback) => {
    const url = `products/product_by_scan_code?warehouse_id=${state?.warehouse_selected?.id}`;
    await AxiosInstance.get(url, {
        params: {code: value}
    }).then(async function (res) {
        const product = res?.data?.data;
        console.log({url});
        if (product && product != null) {
            if (product['warehouse_manage_stock_quantity'] <= GetCurrentProductInvoiceQty(state, product)) {
                warningSweetAlert(trans("This Quantity isn't available in stock") + " " + state?.warehouse_selected?.name);
            } else {
                dispatch(AppendProductToInvoiceAction(product, {}));
            }
        } else {
            warningSweetAlert(trans("This Code Doesn't Exist ."));
        }
        callback();
    }).catch(function (response) {
        //
    });
}

export default GetProductByCodeAndAppendToInvoice;