import {configureStore} from '@reduxjs/toolkit'
import tableSlice from "./table/slices/tableSlice";
import InvoiceOperations from "./invoices/InvoiceOperations";
import SettingsSlice from "./settings/SettingsSlice";
import Pos from "./pos/Pos";

export const store = configureStore({
    reducer: {
        Settings: SettingsSlice,
        table: tableSlice,
        InvoiceOperations: InvoiceOperations,
        Pos: Pos,
    },
});