const useGetCurrentProductInvoiceQty = (state, product) => {
    const invoice_products = state.invoice_products;
    let qty = 0;
    if (invoice_products.length > 0) {
        invoice_products.forEach(function (invoice_product) {
            if (invoice_product.id === product.id) {
                qty += invoice_product.quantity;
            }
        })
    }
    return qty;
}


export default useGetCurrentProductInvoiceQty;