import {
    FormattingTime,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'work-shifts';
    const module_endpoint = 'work-shifts';
    const module_name = 'الورديات';

    const slug = 'تعديل وردية ' + ((oldItems?.user?.firstname ?? "--") + " " + (oldItems?.user?.lastname ?? "--"));

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {

        PermissionMiddleware(permissions, "work-shifts");

        if (permissions.length > 0) {
            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                const old_item = data.data;
                await setOldItems(old_item);

                /* set inputs values */
                await formik.setValues({
                    user_id: old_item?.user_id,
                    start_date: old_item?.start_date,
                    // start_time: FormattingTime(old_item?.start_time ?? new Date()),
                });

                setIsOldItemsLoading(false);
            }).catch(function ({response}) {
                if (response.data.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                }
            });
        }

    }, [permissions]);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });


    const resetForm = () => {
        setErrors({});
    }

    const [users, setUsers] = useState([]);

    useEffect(() => {
        AxiosInstance.get('users?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setUsers(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    return {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        users,
    };
}