import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../../config/constants";

/* components & shared */
import PageHeader from "../../../../components/layout/PageHeader";
import CardComponent from "../../../../components/cards/CardComponent";
import LoadingComponent from "../../../../components/global/LoadingComponent";
import TableComponent from "../../../../components/table/TableComponent";
import TableHeaderComponent from "../../../../components/table/TableHeaderComponent";
import TdSelectItemComponent from "../../../../components/table/TableTbody/TdSelectItemComponent";
import {selectedOrNo} from "../../../../config/helper";

/* hook */
import IndexHook from "../../../../hooks/system/reports/reports/ReturnSalesReportHook";
import NormalSelectInput from "../../../../shared/inputs/NormalSelectInput";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        warehouses,
        customers,
        OnChangeWarehouse,
        OnChangeSupplier,
        OnChangeDuration,
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>

                    <NormalSelectInput className={'col-md-3 col-sm-6 col-12'} onChangeHandler={OnChangeWarehouse}>
                        <option value="" selected>جميع المخازن</option>
                        {
                            warehouses && warehouses.map(function (warehouse) {
                                return (
                                    <option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.name}
                                    </option>
                                );
                            })
                        }
                    </NormalSelectInput>

                    <NormalSelectInput className={'col-md-3 col-sm-6 col-12'} onChangeHandler={OnChangeSupplier}>
                        <option value="" selected>جميع العملاء</option>
                        {
                            customers && customers.map(function (customer) {
                                return (
                                    <option key={customer.id} value={customer.id}>
                                        {customer.name}
                                    </option>
                                );
                            })
                        }
                    </NormalSelectInput>

                    <NormalSelectInput className={'col-md-3 col-sm-6 col-10'} onChangeHandler={OnChangeDuration}>
                        <option value="" selected>كل الاوقات</option>
                        <option value="day">اليوم</option>
                        <option value="week">خلال اسبوع</option>
                        <option value="month">خلال شهر</option>
                        <option value="year">خلال سنة</option>
                    </NormalSelectInput>

                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                {items.map(function (item) {
                        return (
                            <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} key={item.id}>
                                <td className="prevent-print">
                                    <TdSelectItemComponent is_exportable={false} item={item}/>
                                </td>

                                <td><span className='text-green'>{(item?.reference_code ?? null)}</span></td>
                                <td>{(item?.warehouse?.name ?? null)}</td>
                                <td>{(item?.customer?.name ?? null)}</td>
                                <td>{(item?.grand_total ?? null)} {currency} </td>
                                <td>{(item?.date ?? null)}</td>
                            </tr>
                        );
                    })}
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
