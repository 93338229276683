import {
    getLocale,
    getYupLocaleObject,
    successSweetAlert,
    trans, useRtkState,
} from "../../../config/helper";

import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import {useDispatch} from "react-redux";
import {fetchSettings} from "../../../store/settings/SettingsSlice";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {


    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const prefix = 'settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'general';
    const module_endpoint = 'settings';
    const module_name = 'الاعدادات';

    const slug = 'الاعدادات العامة';


    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "settings");

        if (permissions.length > 0 && permissions.includes("settings")) {
            AxiosInstance.get('currencies', {}).then(async function ({data}) {
                const currencies = data.data;
                await setCurrencies(currencies);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get('warehouses?sort_attribute=id&sort_type=asc', {}).then(async function ({data}) {
                const warehouses = data.data;
                await setWarehouses(warehouses);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(module_endpoint, {}).then(async function ({data}) {
                const old_item = data.data;
                await setPreviewImage(old_item?.logo ?? '');
                await setOldItems(old_item);

                /* set inputs values */
                await formik.setValues({
                    system_name: old_item?.system_name,
                    time_zone: old_item?.time_zone,
                    currency: old_item?.currency,
                    email: old_item?.email,
                    phone: old_item?.phone,
                    address: old_item?.address,
                    default_warehouse_id: old_item?.default_warehouse_id,
                    default_safe_id: old_item?.default_safe_id,
                });

                setIsOldItemsLoading(false);
            }).catch(function (response) {
                // console.log(response)
            });
        }

    }, [permissions]);


    const dispatch = useDispatch();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            system_name: '',
            timezone: '',
            currency: '',
            email: '',
            phone: '',
            address: '',
        },
        validationSchema: Yup.object({
            system_name: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            time_zone: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            currency: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            email: Yup.string().email(trans('The_email_format_is_invalid'))
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            phone: Yup.number().required(trans('This_field_is_required')),

            address: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(200, trans('The_name_must_not_be_greater_than_200_characters'))
                .required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()));
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                dispatch(fetchSettings());
                successSweetAlert();
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const [previewImage, setPreviewImage] = useState('');

    const resetForm = () => {
        setPreviewImage('');
        setErrors({});
    }


    const [safes, setSafes] = useState([]);
    useEffect(() => {
        AxiosInstance.get('safes?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSafes(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);


    return {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        currencies,
        warehouses,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        previewImage,
        setPreviewImage,
        safes
    };
}