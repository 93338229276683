import React from 'react';

const FooterComponent = () => {
    return (
            <footer className="footer">
                <p>جميع الحقوق محفوظة © 2023 لدى شركة كود لوب</p>
            </footer>
    );
};

export default FooterComponent;
