import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {
    const params = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState(null);

    const prefix = 'sales-module';
    const module_endpoint = 'return_sales';
    const module_name = 'عرض بيانات فاتورة رقم ' + (invoice?.reference_code ?? '----');
    const route_endpoint = (prefix ? prefix + '/' : '') + 'return_sales';

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "return_sales");

        if (permissions.length > 0 && permissions.includes("return_sales")) {
            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                setInvoice(data.data);
            }).catch(function (response) {
                if (response?.data?.data?.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                }
            });
        }

    }, [permissions]);


    return {
        module_name,
        invoice
    };
}