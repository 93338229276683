import DefaultLayout from "../../layouts/DefaultLayout";
import {getBearToken, prefixRouterPath} from "../../config/helper";
import Page404 from "../../pages/errors/Page404";
import HomePage from "../../pages/HomePage";
import module_routes from "./cruds/routes";
import LoginPage from "../../pages/Auth/LoginPage";
import {Navigate} from "react-router-dom";
import system from "./systems/system";


export default [
    {
        path: "/",
        element: <Navigate replace to={getBearToken() ? prefixRouterPath('') : prefixRouterPath('login')}/>,
    },
    {
        path: "/",
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        // errorElement: <Page404/>, /* Page Not found */
        children: [
            {
                index: true,
                path: prefixRouterPath(''), /* replace "path" with "system/path" */
                element: <HomePage/>, /* render instead of <Outlet/> */
            }
        ]
    },
    {
        path: prefixRouterPath("login"),
        element: <LoginPage/>,
    },
    ...module_routes,
    ...system,
    {
        path: "*", /* any other routes */
        element: <Navigate replace to={getBearToken() ? prefixRouterPath('') : prefixRouterPath('login')}/>,
    },
];
