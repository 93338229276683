/* helper functions */
export const UpdatingTotalTax = (state) => {
    state.tax_amount = (state.tax_rate * state.sub_grand_total / 100).toFixed(2) ?? 0;
}

export const UpdatingDiscountTypeTax = (state) => {
    if (state.discount_type === 1) {
        state.discount_amount = parseFloat("0.00");
        state.discount_amount = parseFloat((state.discount_rate * state.sub_grand_total / 100).toFixed(2) ?? 0);
    } else {
        state.discount_rate = parseFloat("0.00");
        state.discount_amount = parseFloat(state.discount_amount ?? 0);
    }
}

export const UpdatingTotalDiscount = (state) => {
    if (state.discount_type === 1) { /* rate */
        const discount_rate = state.discount_rate;
        state.discount_amount = (discount_rate * state.sub_grand_total / 100).toFixed(2) ?? 0;
    } else { /* amount */
        state.discount_amount = state.discount_amount ?? 0;
    }
}

export let update_invoice_product_sub_grand_total = (invoice_product , invoice_type) => {

    let price = 0.00;
    if (invoice_type === "sale") {
        price = parseFloat(invoice_product?.product_price ?? 0);
    } else {
        price = parseFloat(invoice_product?.product_cost ?? 0);
    }

    let quantity = parseFloat(invoice_product?.quantity ?? 0);
    let tax_value = parseFloat(invoice_product?.tax_value ?? 0);
    let discount_type = parseInt(invoice_product?.discount_type ?? 0);
    let discount_value = parseFloat(invoice_product?.discount_value ?? 0);

    let sub_total = (price * quantity);
    let tax_amount = ((sub_total * tax_value) / 100);

    if (discount_type === 1) { // 0 => value, 1 => per
        discount_value = ((sub_total * discount_value) / 100);
    }

    sub_total = sub_total + tax_amount - discount_value;
    return sub_total.toFixed(2);
}

export let update_invoice_sub_grand_total = (state) => {
    let invoice_sub_grand_total = 0;
    state.invoice_products.forEach(function (invoice_product) {
        if (!isNaN(invoice_product?.sub_total)) {
            invoice_sub_grand_total += parseFloat(invoice_product?.sub_total);
        }
    });
    state.sub_grand_total = invoice_sub_grand_total.toFixed(2);
    update_invoice_grand_total(state);
}

export let update_invoice_grand_total = (state) => {
    const sub_grand_total = parseFloat(state.sub_grand_total);
    const tax_amount = parseFloat(state.tax_amount);
    const discount_amount = parseFloat(state.discount_amount);
    const shipping = parseFloat(state.shipping);

    const grand_total = sub_grand_total + tax_amount + shipping - discount_amount;
    state.grand_total = grand_total.toFixed(2);
}
/* helper functions */

