import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../store/invoices/InvoiceOperations";
import {useParams} from "react-router-dom";


export default () => {

    const params = useParams();
    const account_number = params?.account_number;

    /* table */
    const prefix = "accounts-balances";
    const module_endpoint = "financial-transactions/account-statement/customers" + `?account_number=${account_number}`;
    const module_name = ' كشف حساب عميل رقم ' + (account_number ?? '----');
    const create_redirect = "";


    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;

    const headers = [
        {
            label: 'تاريخ العملية',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'نوع الحركة',
            key: 'type',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'combined_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'combined_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'balanced_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'balanced_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'البيان',
            key: 'statement',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'المستخدم',
            key: 'transaction_username',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        // {
        //     label: 'التفاصيل',
        //     key: 'transaction_info',
        //     printable: true,
        //     sortable: false,
        //     can_export_excel: true,
        // }
    ];

    const [sales, setSales] = useState(0);

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(reRenderItems())

        AxiosInstance.get(`sales?account_number=${account_number}`, {}).then(async function ({data}) {
            await setSales(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);


    /* =============================== searches =============================== */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }

    const searchDateHandler = (event) => {
        dispatch(appendFilterParams({...table.params, date: event.target.value}, {}));
    }

    const OnChangeSale = (event) => {
        dispatch(appendFilterParams({...table.params, sale_id: event.target.value}, {}));
    }

    const OnMonthChange = (event) => {
        dispatch(appendFilterParams({...table.params, month: event.target.value}, {}));
    }
    /* =============================== searches =============================== */


    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        sales,
        searchKeyHandler,
        searchDateHandler,
        OnChangeSale,
        OnMonthChange,
    };
}