import React, {useRef} from 'react';
import PageHeader from "../../layout/PageHeader";
import CardComponent from "../../cards/CardComponent";
import ReactToPrint from "react-to-print";

export default ({transfer_stock}) => {

    const PrintRef = useRef();

    return (
        <>
            <PageHeader title="تفاصيل التحويل"/>
            <div ref={PrintRef}>


                <CardComponent>
                    <div className="transfer-stock">
                        <p>التاريخ :<span>{transfer_stock?.date}</span></p>
                        <p>من مخزن :<span>{transfer_stock?.from_warehouse?.name}</span></p>
                        <p>الي مخزن :<span>{transfer_stock?.to_warehouse?.name}</span></p>
                    </div>
                    <hr/>

                    {
                        transfer_stock?.manage_stock_transfer_details
                            ?
                            <table className="table table-custom">
                                <thead>
                                <tr>
                                    <th>المنتج</th>
                                    <th>الكمية</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    transfer_stock?.manage_stock_transfer_details?.map(function (ms) {
                                        return (
                                            <tr>
                                                <td>{ms?.product_name}</td>
                                                <td>{ms?.quantity}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                            :
                            ""
                    }


                </CardComponent>

            </div>


            <ReactToPrint
                bodyClass="print-agreement"
                content={() => PrintRef.current}
                trigger={() => (
                    <button className="btn btn-primary print-bill">
                        <i className="bi bi-printer"> </i> طباعة
                    </button>
                )}
            />

        </>

    );
};
