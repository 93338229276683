import React, {useState} from "react";
import PopUp from "../../../../shared/material-ui/PopUp";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AxiosInstance from "../../../../config/AxiosInstance";
import {confirmDelete, errorSweetAlert, GeneralConfirmation, trans, useRtkState} from "../../../../config/helper";
import EmptyContentComponent from "../../../global/EmptyContentComponent";
import {useDispatch} from "react-redux";
import {fetchAllProducts, RestoreInvoiceHoldsAction} from "../../../../store/pos/Pos";
import LoadingComponent from "../../../global/LoadingComponent";

const HoldComponent = () => {

    const settings = useRtkState('Settings');
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [isHoldsLoading, setIsHoldsLoading] = useState(false);

    /* Show Holds */
    const FetchInvoiceHolds = () => {
        setIsHoldsLoading(true);
        AxiosInstance.get('holds', {}).then(async function ({data}) {
            setHolds(data?.data ?? []);
            setIsHoldsLoading(false);
        }).catch(function ({response}) {
            //
        });
    }

    const [showHolds, setShowHolds] = useState(false);
    const [holds, setHolds] = useState([]);

    const ShowHoldsAction = () => {
        setShowHolds(true);
        FetchInvoiceHolds();
    };

    const DeleteInvoiceHoldsAxios = (invoice_hold_id) => {
        AxiosInstance.delete(`holds/${invoice_hold_id}`, {}).then(async function ({data}) {
            FetchInvoiceHolds();
            setShowHolds(false);
        }).catch(function ({response}) {
            errorSweetAlert();
        });
    }

    const DeleteInvoiceHolds = (invoice_hold_id) => {
        confirmDelete(async function () {
            DeleteInvoiceHoldsAxios(invoice_hold_id);
        });
    }

    const RestoreInvoiceHolds = (invoice_hold) => {
        GeneralConfirmation(trans("Do you want to return invoice"), async function () {
                await dispatch(RestoreInvoiceHoldsAction(invoice_hold, {}));
                await DeleteInvoiceHoldsAxios(invoice_hold?.id);
                await dispatch(fetchAllProducts());
            }
        );
    }

    return (
        <>
            <button onClick={ShowHoldsAction} type="button" className="btn-header holds-invoices">
                <i className="bi bi-clock-history"> </i>
                الطلبات المعلقة
            </button>

            {/* Holds Popup */}
            <PopUp other_modal_class="holds-popup"
                   isOpen={showHolds} setIsOpen={setShowHolds}
                   title="عرض الفواتير المعلقة">
                {
                    holds.length > 0
                        ?
                        holds.map(function (hold) {
                            return (
                                <Accordion key={hold?.id} expanded={expanded === `panel${hold?.id}`}
                                           onChange={handleChange(`panel${hold?.id}`)}>

                                    {/* invoice hold */}
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                      aria-controls="panel1bh-content"
                                                      id="panel1bh-header">
                                        <Typography>
                                            <span className="text-green">#{hold?.reference_code}</span>
                                        </Typography>
                                        <Typography>
                                            <span>{hold?.customer?.name}</span>
                                        </Typography>
                                        <Typography>
                                            <span>{hold?.warehouse?.name}</span>
                                        </Typography>
                                        <Typography>
                                        <span className="text-red">{hold?.grand_total}
                                            <em>{settings?.settings?.currency}</em></span>
                                        </Typography>
                                    </AccordionSummary>

                                    {/* invoice hold items */}
                                    <AccordionDetails>

                                        <div className="btns-acc">
                                            <button onClick={e => RestoreInvoiceHolds(hold)} type="button"
                                                    className="edit-invoise">
                                                <i className="bi bi-pencil"> </i>
                                            </button>
                                            <button onClick={e => DeleteInvoiceHolds(hold?.id)} type="button"
                                                    className="delete-invoise">
                                                <i className="bi bi-trash"> </i>
                                            </button>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table table-custom">
                                                <thead>
                                                <tr>
                                                    <th>الصنف</th>
                                                    <th>السعر</th>
                                                    <th>الكمية</th>
                                                    <th>الاجمالي (ر.س)</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    hold?.sale_items?.length > 0 && hold.sale_items.map(function (sale_item) {
                                                        return (
                                                            <tr key={sale_item?.id}>
                                                                <td>{sale_item?.product?.name}</td>
                                                                <td>{sale_item?.product_price}</td>
                                                                <td>{sale_item?.quantity}</td>
                                                                <td>{sale_item?.sub_total}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>

                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        : (isHoldsLoading ? <LoadingComponent/> : <EmptyContentComponent/>)
                }
            </PopUp>
        </>
    );
};

export default HoldComponent;
