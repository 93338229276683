import React, {useEffect, useRef, useState} from 'react';
import AxiosInstance from "../../../config/AxiosInstance";
import {successSweetAlert, useRtkState, warningSweetAlert} from "../../../config/helper";
import {useDispatch} from "react-redux";
import PaymentHook from "../../../hooks/system/invoice/PaymentHook";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SetLocalStatePaymentsAction} from "../../../store/invoices/InvoiceOperations";

export default () => {

    const currency = useRtkState('Settings').settings?.currency;
    let dispatch = useDispatch();
    let [isSubmitLoading, setIsSubmitLoading] = useState(false);
    let [errors, setErrors] = useState({});
    const submitForm = useRef();
    const module_endpoint = "bonds/PayingSaleInvoice";
    const {payment_skeleton} = PaymentHook();

    const [customers, setCustomers] = useState([]);
    const [sales, setSales] = useState([]);
    const [sale, setSale] = useState(null);
    const [grand_total, setGrandTotal] = useState(null);
    const [total_paid_payments, setTotal_paid_payments] = useState(null);
    const [total_rest_payments, setTotal_rest_payments] = useState(null);


    let [natureOfAccountChecked, setNatureOfAccountChecked] = useState("nature_of_account_2");
    const ChangeNatureOfAccount = (nature_of_account) => {
        setNatureOfAccountChecked(nature_of_account);
    };

    const onChangeCustomerGetTheirOwnSales = (event) => {
        setSale(null);
        let customer_id = event?.target?.value;
        AxiosInstance.get(`sales?sort_type=asc&sort_attribute=id&customer_id=${customer_id}`, {}).then(async function ({data}) {
            let sales = data.data;
            if (sales.length > 0) {
                await setSales(data.data);
            } else {
                setSales([]);
            }
        }).catch(function ({response}) {
            //
        });
    }

    const setSaleByReferenceCode = (reference_code) => {
        AxiosInstance.get(`sales/slug/${reference_code}`, {}).then(async function ({data}) {
            let sale = data.data;
            setSale(sale);
        }).catch(function ({response}) {
            //
        });
    }

    const onChangeSale = (event) => {
        let selectElement = document.getElementById("saleID");
        let selectedOption = selectElement.options[selectElement.selectedIndex];
        let reference_code = selectedOption.text;
        setSale(null);
        setSaleByReferenceCode(reference_code);
    }

    const handleReset = () => {
        if (submitForm.current) {
            submitForm.current.reset();
        }
    };

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                let payments = [payment_skeleton];
                dispatch(SetLocalStatePaymentsAction(payments, {}));
                successSweetAlert();

                /* update amounts */
                setSaleByReferenceCode(sale?.reference_code);
                setSale(sale);

                setTimeout(() => {
                    setSale(null);
                    handleReset();
                }, 1500);
            }).catch(function ({response}) {
                let errors = response.data;
                setIsSubmitLoading(false);
                if (errors.status === 420) {
                    warningSweetAlert(errors?.message);
                    return false;
                }
                setErrors(errors.data);
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    useEffect(() => {
        AxiosInstance.get('customers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setCustomers(data.data);
        }).catch(function ({response}) {
            //
        });

    }, []);

    useEffect(() => {
        setGrandTotal(sale?.grand_total);
        setTotal_paid_payments(sale?.total_paid_payments);
        setTotal_rest_payments(sale?.total_rest_payments);
    }, [sale])


    return {
        currency,
        onChangeCustomerGetTheirOwnSales,
        customers,
        onChangeSale,
        sales,
        sale,
        isSubmitLoading,
        grand_total,
        total_paid_payments,
        total_rest_payments,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    }
};