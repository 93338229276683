import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/cruds/products/EditHook";
import SelectInput from "../../../shared/inputs/SelectInput";
import NumberInput from "../../../shared/inputs/NumberInput";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import OpeningBalance from "../../../components/cruds/products/OpeningBalance";

const Edit = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        isOldItemsLoading,
        resetForm,
        previewImage,
        setPreviewImage,
        productCategories,
        brands,
        units,
        handleOnFormChange,
        purchase_unit_name,
        sale_unit_name,

        warehouses,
        stocks,
        addStock,
        deleteStock,
        handleInputStockChange,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form onChange={handleOnFormChange} ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>
                    {isOldItemsLoading ? <LoadingComponent/> : ""}

                    <input name="type" type="hidden" value={1}/>

                    <div className="row">
                        <ImageInput
                            label={"الصورة"}
                            name={"image"}
                            formik={formik}
                            previewImage={previewImage}
                            setPreviewImage={setPreviewImage}
                            className={"col-lg-12 col-sm-6 col-12"}
                            error={errors?.["image"]?.[0] ?? ""}/>
                    </div>


                    <div className="row">
                        <TextInput
                            label={"الاسم"}
                            name={"name"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["name"]?.[0] ?? ""}/>

                        <TextInput
                            label={"الكود (رمز الباركود)"}
                            name={"code"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["code"]?.[0] ?? ""}/>

                        <TextInput
                            label={"الـ SKU"}
                            name={"sku"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["sku"]?.[0] ?? ""}/>

                        <SelectInput
                            label={"القسم"}
                            name={"product_category_id"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["product_category_id"]?.[0] ?? ""}>
                            <option value="" disabled selected>اختر</option>
                            {productCategories &&
                            productCategories.map(function (productCategory) {
                                return (
                                    <optgroup label={productCategory.name}>
                                        {productCategory?.sub_categories &&
                                        productCategory?.sub_categories.map(function (
                                            sub_category
                                        ) {
                                            return (
                                                <option key={sub_category.id} value={sub_category.id}>
                                                    {sub_category.name}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            })}
                        </SelectInput>

                        <SelectInput
                            label={"الماركة"}
                            name={"brand_id"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["brand_id"]?.[0] ?? ""}>
                            <option value="" disabled selected>اختر</option>
                            {brands && brands.map(function (brand) {
                                return (
                                    <option key={brand.id} value={brand.id}>
                                        {brand.name}
                                    </option>
                                );
                            })}
                        </SelectInput>

                        <SelectInput
                            label={"وحدة الشراء"}
                            name={"purchase_unit_id"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["purchase_unit_id"]?.[0] ?? ""}>
                            <option value="" disabled selected>اختر</option>
                            {units &&
                            units.map(function (unit) {
                                return (
                                    <option key={unit.id} value={unit.id}>
                                        {unit.name}
                                    </option>
                                );
                            })}
                        </SelectInput>

                        <SelectInput
                            label={"وحدة البيع"}
                            name={"sale_unit_id"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["sale_unit_id"]?.[0] ?? ""}>
                            <option value="" disabled selected>اختر</option>
                            {units &&
                            units.map(function (unit) {
                                return (
                                    <option key={unit.id} value={unit.id}>
                                        {unit.name}
                                    </option>
                                );
                            })}
                        </SelectInput>

                        {
                            (purchase_unit_name && sale_unit_name)
                            &&
                            (
                                <NumberInput
                                    label={`${purchase_unit_name ?? "---"} يساوي كام ${sale_unit_name ?? "---"} `}
                                    name={'equivalent_unit_value'}
                                    formik={formik}
                                    allow_formik_value={true}
                                    className={'col-lg-3 col-sm-6 col-12'}
                                    error={errors?.['stock_alert']?.[0] ?? ''}/>
                            )
                        }

                        <NumberInput label="أقل سعر بيع"
                                     name={'min_product_price'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['min_product_price']?.[0] ?? ''}/>

                        <NumberInput label="أعلى سعر بيع"
                                     name={'max_product_price'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['max_product_price']?.[0] ?? ''}/>

                        <NumberInput
                            label="سعر الصنف (سعر البيع)"
                            name={"product_price"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["product_price"]?.[0] ?? ""}/>

                        <NumberInput
                            label={"تنبيه المخزن عند الكمية"}
                            name={"stock_alert"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["stock_alert"]?.[0] ?? ""}/>

                        <TextInput label={'ملاحظات'}
                                   name={'notes'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['notes']?.[0] ?? ''}/>
                    </div>

                    <OpeningBalance addStock={addStock}
                                    stocks={stocks}
                                    deleteStock={deleteStock}
                                    formik={formik}
                                    errors={errors}
                                    warehouses={warehouses}
                                    handleInputStockChange={handleInputStockChange}/>

                    <div className="card-footer">
                        <SubmitButton
                            isSubmitLoading={isSubmitLoading}
                            className={"btn btn-primary"}
                        />
                    </div>
                </form>
            </CardComponent>
        </>
    );
};

export default Edit;
