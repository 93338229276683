import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";

/* create hook */
import CreateHook from "../../../hooks/cruds/safes/CreateHook";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalInput from "../../../shared/inputs/NormalInput";

const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        mainSafes,
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <TextInput
                            label={"الاسم"}
                            name={"title"}
                            formik={formik}
                            className={"col-lg-3 col-sm-6 col-6"}
                            error={errors?.["title"]?.[0] ?? ""}/>


                        <SelectInput
                            label={"القسم الرئيسي الخاص بالخزنة"}
                            name={"parent_id"}
                            formik={formik}
                            className={"col-lg-3 col-sm-6 col-6"}
                            error={errors?.["parent_id"]?.[0] ?? ""}>
                            {
                                mainSafes && mainSafes.map(function (safe) {
                                    return (<option key={safe.id} value={safe.id}>{safe.name}</option>);
                                })
                            }
                        </SelectInput>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                        <RestButton className={"btn btn-danger"}/>
                    </div>

                </form>
            </CardComponent>
        </>
    );
};

export default Create;
