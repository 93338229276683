import React, {useState} from 'react';

import CaptchaComponent from "../components/global/CaptchaComponent";

const ImageCaptcha = () => {

    const captchaCallback = (isCorrect) => {
        console.log({isCorrect});
    }

    return (
        <>
            <CaptchaComponent captchaCallback={captchaCallback}/>
        </>
    );
};

export default ImageCaptcha;
