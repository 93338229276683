import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import AxiosInstance from "../../config/AxiosInstance";

import {
    UpdatingTotalTax,
    UpdatingDiscountTypeTax,
    UpdatingTotalDiscount,
    update_invoice_product_sub_grand_total,
    update_invoice_sub_grand_total,
    update_invoice_grand_total
} from "../../config/InvoiceHelper";
import {preparing_invoice_product, trans, warningSweetAlert} from "../../config/helper";

export const fetchAllUnits = createAsyncThunk('invoices/fetchUnits', async (paylaod, action) => {
    try {
        const response = await AxiosInstance.get(`units?sort_type=asc&sort_attribute=id`, {params: {}});
        return response.data
    } catch (err) {
        // custom error
    }
});

export const fetchProducts = createAsyncThunk('invoices/fetchProducts', async (paylaod, action) => {
    try {
        const {products_params} = action.getState()?.InvoiceOperations ?? {};
        const response = await AxiosInstance.get(`products?sort_type=asc&sort_attribute=id`, {params: products_params});
        return response.data
    } catch (err) {
        // custom error
    }
});

const initialState = {
    invoice_type: '',
    is_return_invoice: false,
    search_loading: false,
    products_params: {},
    products: [],
    invoice_products: [],
    units: [],

    /* total */
    tax_type: 1,
    tax_rate: 0.00,
    tax_amount: 0.00, // calc

    discount_type: 0,
    discount_rate: 0.00,
    discount_amount: 0.00, // calc

    shipping: 0.00,
    is_damage: 0,

    date: new Date().toJSON().slice(0, 10),
    notes: '',
    safe_id: '',
    payments: [],
    localStatePayments: [],

    sub_grand_total: 0.00,
    grand_total: 0.00,

    payment_status: 0, // 0 => cash, 1 => doubted
    paid_amount: 0, // when payment_status is cash => (0)
    rest_amount: 0,
};

const RefreshInvoice = (state) => {
    /* refresh table */
    let invoice_products = [];
    state.invoice_products.forEach(function (invoice_product) {
        invoice_product['sub_total'] = update_invoice_product_sub_grand_total(invoice_product, state.invoice_type);
        invoice_products.push(invoice_product);
    });
    state.invoice_products = invoice_products;

    /* refresh invoice settings */
    update_invoice_sub_grand_total(state);
}

export const InvoiceOperations = createSlice({
    name: 'invoices',
    initialState,
    reducers: {

        SetInvoiceType: (state, {type, payload}) => {
            state.invoice_type = payload;
            return state;
        },

        SetIsReturnInvoice: (state, {type, payload}) => {
            state.is_return_invoice = payload;
            return state;
        },

        ChangeSearchLoading: (state, {type, payload}) => {
            state.search_loading = payload;
            return state;
        },

        AppendParamsValues: (state, {type, payload}) => {
            state.search_loading = true;
            state.products_params = payload;
            return state;
        },

        UpdateInvoiceProducts: (state, {type, payload}) => {
            state.invoice_products = payload;
            update_invoice_sub_grand_total(state);
            return state;
        },

        RemoveProductFromProductsListAction: (state, {type, payload}) => {
            let invoice_product = payload;
            let products = state?.products;
            state.products = products.filter(product => product?.id !== invoice_product?.id);
            return state;
        },

        AppendProductToProductsListAction: (state, {type, payload}) => {
            state.products.push(payload);
            return state;
        },

        UpdateInvoiceProductSetting: (state, {type, payload}) => {
            const product_id = payload?.product?.id;
            const attribute = payload?.attribute;
            const value = payload?.value;

            /* update invoice products list */
            const invoice_products = [];
            let has_negative_value = false;

            state.invoice_products.forEach(function (invoice_product) {
                if (invoice_product.id === product_id) {
                    invoice_product[attribute] = value;
                    let sub_total = update_invoice_product_sub_grand_total(invoice_product, state.invoice_type);
                    invoice_product['sub_total'] = sub_total;

                    if (sub_total <= 0) {
                        if (attribute === "discount_value") {
                            invoice_product[attribute] = 0;
                            invoice_product['sub_total'] = update_invoice_product_sub_grand_total(invoice_product, state.invoice_type);
                        } else {
                            has_negative_value = true;
                            state.sub_grand_total = 0;
                            state.grand_total = 0;
                        }
                        warningSweetAlert(trans("The total cannot be zero or less ."));
                    }
                }
                invoice_products.push(invoice_product);
            });

            /* update invoice products list */

            state.invoice_products = invoice_products;
            if (!has_negative_value) {
                update_invoice_sub_grand_total(state);
            }

            return state;
        },

        /* ===================== calc ===================== */
        /* Tax */
        UpdateTotalTax: (state, {type, payload}) => {
            state.tax_rate = !isNaN(payload) && payload !== '' ? payload : 0;
            UpdatingTotalTax(state);
            update_invoice_grand_total(state);
            return state;
        },

        /* discount */
        UpdateTotalDiscountType: (state, {type, payload}) => {
            state.discount_type = parseInt(payload);
            UpdatingDiscountTypeTax(state);
            update_invoice_grand_total(state);
            return state;
        },

        UpdateTotalDiscountRate: (state, {type, payload}) => {
            state.discount_rate = parseFloat(!isNaN(payload) && payload !== '' ? payload : 0);
            UpdatingTotalDiscount(state);
            update_invoice_grand_total(state);
            return state;
        },

        UpdateTotalDiscountAmount: (state, {type, payload}) => {
            state.discount_amount = parseFloat(!isNaN(payload) && payload !== '' ? payload : 0);
            UpdatingTotalDiscount(state);
            update_invoice_grand_total(state);
            return state;
        },

        /* Shipping */
        UpdateTotalShipping: (state, {type, payload}) => {
            state.shipping = !isNaN(payload) && payload !== '' ? payload : 0;
            update_invoice_grand_total(state);
            return state;
        },

        /* InvoiceTotalGrand */
        UpdateInvoiceTotalGrand: (state, {type, payload}) => {
            state.sub_grand_total = !isNaN(payload) && payload !== '' ? payload : 0;
            UpdateTotalTax(state);
            UpdatingTotalDiscount(state);
            update_invoice_grand_total(state);
            return state;
        },
        /* ===================== calc ===================== */

        SetDateHandler: (state, {type, payload}) => {
            state.date = payload;
            return state;
        },

        SetNotesHandler: (state, {type, payload}) => {
            state.notes = payload;
            return state;
        },

        ResetAllInitialValues: (state, {type, payload}) => {
            state.search_loading = false;
            state.products_params = {};
            state.products = [];
            state.invoice_products = [];
            state.tax_type = 1;
            state.tax_rate = 0.00;
            state.tax_amount = 0.00;
            state.discount_type = 0;
            state.discount_rate = 0.00;
            state.discount_amount = 0.00;
            state.shipping = 0.00;
            state.is_damage = 0;
            state.date = new Date().toJSON().slice(0, 10);
            state.notes = '';
            state.safe_id = '';
            state.sub_grand_total = 0.00;
            state.grand_total = 0.00;

            state.payment_status = 0;
            state.paid_amount = 0;
            state.rest_amount = 0;
            state.payments = [];

            return state;
        },

        ShowInvoiceWithProducts: (state, {type, payload}) => {

            state.products = [];
            state.invoice_products = [];

            let products = [];
            const invoice_items = payload?.old_item[state?.invoice_type + '_items'] ?? [];
            invoice_items.forEach(function (invoice_item) {
                let product = invoice_item?.product;
                product["product_price"] = parseFloat(invoice_item?.product_price ?? "0");
                product["product_cost"] = parseFloat(invoice_item?.product_cost ?? "0");
                products.push(invoice_item?.product);
            });

            console.log({invoice_items: invoice_items})
            state.products = products;

            // const old_item = payload?.old_item;
            // const invoice_items = payload?.old_item[state?.invoice_type + '_items'] ?? [];
            //
            // /* invoice products */
            // let invoice_products = [];
            // invoice_items.forEach(function (invoice_item) {
            //     invoice_products.push(
            //         preparing_invoice_product(
            //             invoice_item?.product?.id,
            //             invoice_item?.product?.name,
            //             invoice_item?.product?.image_url,
            //             invoice_item?.product_price,
            //             invoice_item?.product_cost,
            //             invoice_item[`${state?.invoice_type}_unit`]?.id,
            //             Math.abs(invoice_item?.quantity),
            //             invoice_item?.tax_value,
            //             invoice_item?.discount_type,
            //             invoice_item?.discount_value,
            //             Math.abs(invoice_item?.sub_total),
            //             invoice_item?.product?.sale_unit,
            //             invoice_item?.product?.purchase_unit
            //         ));
            // });
            // state.invoice_products = invoice_products;
            //
            // /* invoice */
            // state.tax_type = old_item?.tax_type ?? 0.00;
            // state.tax_rate = old_item?.tax_rate ?? 0.00;
            // state.tax_amount = old_item?.tax_amount ?? 0.00;
            // state.discount_type = old_item?.discount_type ?? 0;
            // state.discount_rate = old_item?.discount_rate ?? 0.00;
            // state.discount_amount = old_item?.discount_amount;
            // state.shipping = old_item?.shipping ?? 0.00;
            // state.sub_grand_total = Math.abs(old_item?.sub_grand_total ?? 0.00);
            // state.grand_total = Math.abs(old_item?.grand_total ?? 0.00);
            // state.date = old_item?.date ?? new Date().toJSON().slice(0, 10);
            // state.notes = old_item?.notes ?? '';
            //
            // /* use in return sales or purchases when qty is converted to 1 */
            // RefreshInvoice(state)
            // /* use in return sales or purchases when qty is converted to 1 */
            //
            //
            // let paid_amount = state?.is_return_invoice ? 0 : !isNaN(old_item?.paid_amount) ? old_item?.paid_amount : '0';
            // let rest_amount = state?.is_return_invoice ? 0 : parseFloat(((state.grand_total - state.paid_amount) ?? "0").toFixed(2));
            //
            // state.payment_status = old_item?.payment_status ?? 0;
            // state.paid_amount = paid_amount;
            // state.rest_amount = rest_amount;

            return state;
        },

        ShowInvoiceWithProductsAndInvoiceProducts: (state, {type, payload}) => {
            const old_item = payload?.old_item;
            const invoice_items = payload?.old_item[state?.invoice_type + '_items'] ?? [];

            /* invoice products */
            let invoice_products = [];
            invoice_items.forEach(function (invoice_item) {
                invoice_products.push(
                    preparing_invoice_product(
                        invoice_item?.product?.id,
                        invoice_item?.product?.name,
                        invoice_item?.product?.image_url,
                        invoice_item?.product_price,
                        invoice_item?.product_cost,
                        invoice_item[`${state?.invoice_type}_unit`]?.id,
                        Math.abs(invoice_item?.quantity),
                        invoice_item?.tax_value,
                        invoice_item?.discount_type,
                        invoice_item?.discount_value,
                        Math.abs(invoice_item?.sub_total),
                        invoice_item?.product?.sale_unit,
                        invoice_item?.product?.purchase_unit
                    ));
            });
            state.invoice_products = invoice_products;

            /* invoice */
            state.tax_type = old_item?.tax_type ?? 0.00;
            state.tax_rate = old_item?.tax_rate ?? 0.00;
            state.tax_amount = old_item?.tax_amount ?? 0.00;
            state.discount_type = old_item?.discount_type ?? 0;
            state.discount_rate = old_item?.discount_rate ?? 0.00;
            state.discount_amount = old_item?.discount_amount;
            state.shipping = old_item?.shipping ?? 0.00;
            state.sub_grand_total = Math.abs(old_item?.sub_grand_total ?? 0.00);
            state.grand_total = Math.abs(old_item?.grand_total ?? 0.00);
            state.date = old_item?.date ?? new Date().toJSON().slice(0, 10);
            state.notes = old_item?.notes ?? '';
            state.payments = old_item?.payments;

            /* use in return sales or purchases when qty is converted to 1 */
            RefreshInvoice(state)
            /* use in return sales or purchases when qty is converted to 1 */


            // let paid_amount = state?.is_return_invoice ? 0 : !isNaN(old_item?.paid_amount) ? old_item?.paid_amount : '0';
            // let rest_amount = state?.is_return_invoice ? 0 : parseFloat(((state.grand_total - state.paid_amount) ?? "0").toFixed(2));

            let paid_amount = !isNaN(old_item?.paid_amount) ? old_item?.paid_amount : '0';
            let rest_amount = parseFloat(((state.grand_total - paid_amount) ?? "0").toFixed(2));

            state.payment_status = old_item?.payment_status ?? 0;
            state.paid_amount = paid_amount;
            state.rest_amount = rest_amount;

            return state;
        },

        SetInvoicePaymentStatusAction: (state, {type, payload}) => {
            state.payment_status = payload;
            return state;
        },


        ChangeIsDamageAction: (state, {type, payload}) => {
            state.is_damage = payload;
            return state;
        },

        SetInvoiceSafeIdAction: (state, {type, payload}) => {
            state.safe_id = payload;
            return state;
        },

        SetInvoicePaymentsAction: (state, {type, payload}) => {
            state.payments = payload;
            return state;
        },

        SetLocalStatePaymentsAction: (state, {type, payload}) => {
            state.localStatePayments = payload;
            return state;
        },

        SetInvoicePaidAmountAction: (state, {type, payload}) => {
            let paid_amount = parseFloat(payload ?? "0");
            state.paid_amount = parseFloat((paid_amount).toFixed(2));
            state.rest_amount = parseFloat((state.grand_total - paid_amount).toFixed(2));
            if (state.rest_amount < 0) {
                warningSweetAlert(trans("The amount paid is more than the total bill"));
                state.paid_amount = 0;
                state.rest_amount = 0;
            }
            return state;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllUnits.pending, (state, action) => {
            // state.isLoading = true;
        })
        builder.addCase(fetchAllUnits.fulfilled, (state, action) => {
            state.units = action?.payload?.data ?? [];
        })
        builder.addCase(fetchProducts.pending, (state, action) => {
            // state.isLoading = true;
        })
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products = action?.payload?.data ?? [];
            state.search_loading = false;
        })
    }
});


export const {
    SetInvoiceType,
    SetIsReturnInvoice,
    AppendParamsValues,
    UpdateInvoiceProducts,
    RemoveProductFromProductsListAction,
    AppendProductToProductsListAction,
    UpdateInvoiceProductSetting,

    /* ===================== calc ===================== */
    /* Tax */
    UpdateTotalTax,

    /* discount */
    UpdateTotalDiscountType,
    UpdateTotalDiscountRate,
    UpdateTotalDiscountAmount,

    /* Shipping */
    UpdateTotalShipping,

    /* InvoiceTotalGrand */
    UpdateInvoiceTotalGrand,
    /* ===================== calc ===================== */

    SetDateHandler,
    SetNotesHandler,
    SetInvoiceSafeIdAction,
    SetInvoicePaymentsAction,

    ResetAllInitialValues,
    ShowInvoiceWithProducts,
    ShowInvoiceWithProductsAndInvoiceProducts,

    SetInvoicePaymentStatusAction,
    SetInvoicePaidAmountAction,
    ChangeIsDamageAction,
    SetLocalStatePaymentsAction,
    ChangeSearchLoading,
} = InvoiceOperations.actions

export default InvoiceOperations.reducer