import React, {useEffect, useRef, useState} from 'react';
import ReactECharts from "echarts-for-react";
import {useRtkState} from "../../config/helper";
import AxiosInstance from "../../config/AxiosInstance";
import LoadingComponent from "../global/LoadingComponent";
import BarSkeleton from '../../shared/Skeleton/Charts/BarSkeleton';

const MostSalesAndPurchasesDuringWeek = ({weeklySalesStatistics, isLoading = true}) => {
    const settings = useRtkState("Settings");
    let chartColor = settings?.systemMode === "dark" ? "#caccdb" : "#212b36";
    let lineColor = settings?.systemMode === "dark" ? "#193156" : "#e5e5e5";

    const chartRef = useRef(null);
    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.getEchartsInstance) {
                chartRef.current.getEchartsInstance().resize();
            }
        };
        const resizeTimeout = setTimeout(resizeChart, 100);
        window.addEventListener("resize", resizeChart);
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", resizeChart);
        };
    }, []);


    const line = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            textStyle: {
                fontFamily: "regular",
            },
        },
        grid: {
            top: "3%",
            left: "1%",
            right: "1%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [
            {
                data: [
                    "السبت",
                    "الأحد",
                    "الإثنين",
                    "الثلاثاء",
                    "الأربعاء",
                    "الخميس",
                    "الجمعة",
                ],
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        fontFamily: "regular",
                        color: chartColor,
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: lineColor,
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: chartColor,
                    },
                },
            },
        ],
        series: [
            {
                name: "المبيعات",
                type: "line",
                smooth: true,
                data: weeklySalesStatistics?.sales ?? [],
                color: "#B05338",
            },
            {
                name: "المشتريات",
                type: "line",
                smooth: true,
                data: weeklySalesStatistics?.purchases ?? [],
                color: "#1587b0",
            },
        ],
    };


    return (
        <div className="position-relative">
            <h3 className="title-chart">
                <i className="bi bi-graph-up"> </i>
                إحصائيات المبيعات والمشتريات خلال الأسبوع
            </h3>
            {
                isLoading
                    ?
                    (<BarSkeleton/>)
                    :
                    <ReactECharts ref={chartRef} option={line}> </ReactECharts>
            }
        </div>
    );
};

export default MostSalesAndPurchasesDuringWeek;