export default () => {

    /* move main table to print table */
    let print_table =   document.getElementById('print-table');
    let main_table = document.getElementById('main-table');

    print_table.innerHTML = (main_table.innerHTML);

    if(print_table.classList.contains("custom-print"))
    {
      print_table.classList.remove("custom-print");
    }


    if(main_table.hasAttribute("fancy-print"))
    {
        let custom_class_val = main_table.getAttribute("fancy-print");
        print_table.classList.add(custom_class_val);
    }








    // /* start printing .... */
    // let stylesHtml = '';
    // // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    // //     stylesHtml += node.outerHTML;
    // // }
    //
    // let prtHtml = document.getElementById("print-table").outerHTML;
    //
    // stylesHtml = '<link rel="stylesheet" href="../../../src/assets/dashboard/css/bootstrap.rtl.min.css">';
    //
    // let html = TableHtml(stylesHtml, prtHtml); /* html table */
    //
    // const WinPrint = window.open('', 'PRINT', '');
    // WinPrint.document.write(html);
    // WinPrint.document.close();
    // WinPrint.focus();
    // WinPrint.print();
    // WinPrint.close();

}

// const TableHtml = (stylesHtml, prtHtml) => {
//     return `<!DOCTYPE html>
//                 <html>
//                 <head>
//                 ${stylesHtml}
//                 </head>
//                 <body>
//                 ${prtHtml}
//                 </body>
//             </html>`
//
// }