import {useEffect, useState} from "react";

export default () => {

    const [activeTab, setActiveTab] = useState("document");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [translation, setTranslation] = useState({x: 0, y: 30});

    const calculateZoom = () => {
        if (screenWidth < 500) {
            return 0.4;
        } else if (screenWidth < 800) {
            return 0.5;
        } else if (screenWidth < 1200) {
            return 0.6;
        } else {
            return 1;
        }
    };

    const [zoom, setZoom] = useState(calculateZoom());

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
            setZoom(calculateZoom());
            centerSvgElement();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        centerSvgElement();
    }, [zoom]);

    const centerSvgElement = () => {
        const svgElement = document.querySelector(".rd3t-g");
        const wrapperElement = document.querySelector("#treeWrapper");
        if (svgElement) {
            const svgWidth = svgElement.clientWidth;
            const wrapperWidth = wrapperElement.clientWidth;
            const offsetX = (wrapperWidth - svgWidth) / 2;
            setTranslation({x: offsetX, y: 30});
        }
    };

    return {
        translation,
        zoom,
        activeTab,
        handleTabClick,
        setActiveTab,
    }
};