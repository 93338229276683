import {getBearToken, prefixRouterPath} from "../../config/helper";
import LoginPage from "../../pages/Auth/LoginPage";
import {Navigate} from "react-router-dom";


export default [
    {
        path: "/",
        element: <Navigate replace to={getBearToken() ? prefixRouterPath('') : prefixRouterPath('login')}/>,
    },
    {
        path: prefixRouterPath("login"),
        element: <LoginPage/>,
    },
    {
        path: "*", /* any other routes */
        element: <Navigate replace to={getBearToken() ? prefixRouterPath('') : prefixRouterPath('login')}/>,
    },
];
