import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import SelectInput from "../../../shared/inputs/SelectInput";

/* create hook */
import ShowHook from "../../../hooks/cruds/transfer_stocks/ShowHook";
import ShowTransferStockDetails from "../../../components/cruds/transfer_stocks/ShowTransferStockDetails";
import LoadingComponent from "../../../components/global/LoadingComponent";


const Create = () => {
    const {
        module_name,
        transfer_stock,
        is_loading,
    } = ShowHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {SYSTEM_TITLE} | {module_name}
                </title>
            </Helmet>

            {
                is_loading && <LoadingComponent/>
            }

            <ShowTransferStockDetails transfer_stock={transfer_stock}/>
        </>
    );
};

export default Create;
