import {
    confirmDelete,
    GeneralConfirmation,
    getLocale,
    getYupLocaleObject,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";

import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";

import {setLocale} from 'yup';
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    ChangeConfirmPaymentPopUpAction, ChangeIsAllowPrintInInvoice, ChangeIsShowEndedProductsList,
    ChangeIsShowProductsList,
    ChangePosSubmitLoaderAction,
    ChangeWarehouseAction,
    fetchAllCategories,
    fetchAllCustomers,
    fetchAllProducts,
    fetchAllWarehouses,
    RestInitialInvoiceStatesAction,
    SetPosSubmitErrorsAction,
    SetWarehouseSelectedAction,
} from "../../../store/pos/Pos";
import {ChangeSystemModeAction, fetchSettings} from "../../../store/settings/SettingsSlice";
import {getCookie} from "../../global/useCookie";


if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    let module_endpoint = 'sales';
    const module_name = 'نقطة البيع';

    const posState = useRtkState('Pos');
    const {settings} = useRtkState('Settings');
    const dispatch = useDispatch();
    const [isPosLoading, setIsPosLoading] = useState(true);

    useEffect(() => {

        /** add pos style */
        import(`../../../assets/dashboard/css/pos-style.css`);

        /** adjust Pos System Mode */
        let is_show_products_list = getCookie('is_show_products_list');
        let is_show_ended_products_list = getCookie('is_show_ended_products_list');
        let is_allow_print_in_invoice = getCookie('is_allow_print_in_invoice');

        dispatch(ChangeIsShowProductsList((is_show_products_list === null ? (posState?.is_show_products_list) : is_show_products_list), {}));
        dispatch(ChangeIsShowEndedProductsList((is_show_ended_products_list === null ? (posState?.is_show_ended_products_list) : is_show_ended_products_list), {}));
        dispatch(ChangeIsAllowPrintInInvoice((is_allow_print_in_invoice === null ? (posState?.is_allow_print_in_invoice) : is_allow_print_in_invoice), {}));

        /** settings (store safe_id) */
        dispatch(fetchAllCustomers());

        /** get main categories */
        dispatch(fetchAllCategories());

        /** customers */
        dispatch(fetchAllCustomers());

        /** warehouses */
        const FetchPosData = async () => {
            await dispatch(fetchAllWarehouses());
        }

        /** fetch products after load warehouses load */
        FetchPosData().then(res => {
            dispatch(appendFilterParams({...posState?.params, per_page: 44}, {}));
            dispatch(fetchAllProducts());
        });

        /** after "1s" set post loading false */
        setTimeout(function () {
            setIsPosLoading(false)
        }, 1000);


        return () => {
            window.location.reload();
        }
    }, []);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();

    const formik = useFormik({
        initialValues: {}, /* it will be server side validate */
        validationSchema: Yup.object({}), /* it will be server side validate */

        onSubmit: async (values) => {

            dispatch(ChangePosSubmitLoaderAction(true, {}))

            // if (posState?.invoice_paid === "" || parseFloat(posState?.invoice_paid) <= 0) {
            //     dispatch(ChangePosSubmitLoaderAction(false, {}))
            //     warningSweetAlert(trans("Please Enter The Paid Amount"));
            //     return false;
            // }

            if (posState?.invoice_grand_total === "" || parseFloat(posState?.invoice_grand_total) <= 0) {
                dispatch(ChangePosSubmitLoaderAction(false, {}))
                warningSweetAlert(trans("The Grand invoice price is less than or equal zero"));
                return false;
            }

            if (parseFloat(posState?.invoice_paid) < parseFloat(posState?.invoice_grand_total)) {
                dispatch(ChangePosSubmitLoaderAction(false, {}))
                warningSweetAlert(trans("The Paid is less than grand total"));
                return false;
            }

            const formData = new FormData(submitForm.current);

            const formDataEntries = Object.fromEntries(formData.entries());

            if (formDataEntries.sale_action_type === 'hold') {
                GeneralConfirmation(`${trans('Do you really want to hold this invoice')} !`, async () => {
                    await HoldOrPaidPaymentAxios('holds', formData);
                });
            } else {
                await HoldOrPaidPaymentAxios('sales', formData);
            }

            dispatch(ChangePosSubmitLoaderAction(false, {}))
        },
        /* https://www.npmjs.com/package/yup */
    });

    const HoldOrPaidPaymentAxios = async (module_endpoint, formData) => {
        await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
            setErrors({});
            dispatch(ChangePosSubmitLoaderAction(false, {}))
            dispatch(RestInitialInvoiceStatesAction({}, {}))
            dispatch(fetchAllProducts());
            dispatch(ChangeConfirmPaymentPopUpAction(false, {}))
            successSweetAlert();

        }).catch(function ({response}) {
            const errors = response.data.data;
            console.log({errors});
            setErrors(errors);
            dispatch(ChangePosSubmitLoaderAction(false, {}))
            dispatch(SetPosSubmitErrorsAction(errors, {}))
            if (errors && typeof errors == "string") {
                warningSweetAlert(errors)
            }
        });
    }

    const ChangeWarehouse = (event) => {
        const warehouses = posState?.warehouses;
        if (warehouses.length > 0) {
            warehouses.forEach(function (warehouse) {
                if (warehouse?.id == event?.target?.value) {
                    dispatch(ChangeWarehouseAction(warehouse, {}));
                    dispatch(fetchAllProducts());
                }
            })
        }
    }

    const handleOnFormChange = (event) => {

        /* if warehouse select changed */
        if (event?.target?.name === 'warehouse_id') {
            if (posState?.invoice_products?.length > 0) {
                GeneralConfirmation(trans('If Your warehouse changed you current invoice data will remove'), () => {
                    ChangeWarehouse(event);
                });
            } else {
                ChangeWarehouse(event);
            }
        }

    };

    return {
        module_name,
        formik,
        handleOnFormChange,
        submitForm,
        isSubmitLoading,
        errors,
        posState,
        isPosLoading,
    };
}