import React from 'react';
import {login} from "../../../config/images-path";

const ImagePreview = () => {
    return (
        <div className="login-img">
            <img src={login} alt="img"/>
        </div>
    );
};

export default ImagePreview;
