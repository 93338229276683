import React, {useRef, useState} from 'react';
import {avatar_1, uploadFile} from "../../config/images-path";
import {trans} from "../../config/helper";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";

const ImageInput = ({className, label, formik, name, previewImage, setPreviewImage, error, accept = "image/*"}) => {


    const onChangeImageHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setPreviewImage("");
        }
    }

    return (
        <>
            <div className={className}>
                <div className="form-group">
                    {label && <label>{label}</label>}
                    <div className="image-upload">
                        <input name={name} onChange={onChangeImageHandler} type="file" accept={accept}/>
                        <div className="image-uploads">
                            {/* if preview image */}
                            {previewImage && <img className={'imagePreview'} src={previewImage} alt="img"/>}

                            {/* before preview image */}
                            {!previewImage && <i className="bi bi-cloud-arrow-up"></i>}
                            {!previewImage && <h4>{trans('click_to_upload_file')}</h4>}
                        </div>
                    </div>

                    {formik && formik.touched[name] && formik.errors[name] ?
                        (<ErrorValidateComponent error={formik.errors[name]}/>) : null}

                    {error && <ErrorValidateComponent error={error}/>}
                </div>
            </div>
        </>
    );
};

export default ImageInput;
