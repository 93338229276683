import React from 'react';
import {preparing_invoice_product, trans, useRtkState, warningSweetAlert} from "../../config/helper";
import {useDispatch} from "react-redux";
import {
    RemoveProductFromProductsListAction,
    UpdateInvoiceProducts,
    UpdateInvoiceProductSetting
} from "../../store/invoices/InvoiceOperations";
import EmptyContentComponent from "../global/EmptyContentComponent";
import LoadingComponent from "../global/LoadingComponent";


const ProductListComponent = () => {

    /* {products, addProductToTableAction} */
    const InvoiceOperations = useRtkState('InvoiceOperations');
    const products = InvoiceOperations?.products;
    const invoice_products = InvoiceOperations?.invoice_products;
    const dispatch = useDispatch();

    const IsProductExistInTable = (product) => {
        return invoice_products.find(function (invoice_product) {
            return invoice_product.id === product.id;
        })
    }

    const addProductToTableAction = (product) => {
        if (IsProductExistInTable(product)) {
            warningSweetAlert(trans('This product has already exist .'));
            return false;
        }
        const invoice_product = preparing_invoice_product(
            product?.id,
            product?.name,
            product?.image_url,
            product?.product_price,
            product?.product_cost > 0 ? product?.product_cost : 0,
            '',
            1,
            0,
            0,
            0,
            '---',
            product?.sale_unit,
            product?.purchase_unit
        );
        dispatch(RemoveProductFromProductsListAction(invoice_product, {}));
        dispatch(UpdateInvoiceProducts([...invoice_products, invoice_product], {}));

        /* update sub_grand_total and grand total when add new item to sale */
        if (InvoiceOperations?.invoice_type === "sale") {
            dispatch(UpdateInvoiceProductSetting({
                value: product?.product_price,
                product,
                attribute: "product_price"
            }, {}))
        }

        /* update sub_grand_total and grand total when add new item to purchase */
        if (InvoiceOperations?.invoice_type === "purchase" && InvoiceOperations?.is_return_invoice) {
            dispatch(UpdateInvoiceProductSetting({
                value: product?.product_cost,
                product,
                attribute: "product_cost"
            }, {}))
        }
    }

    return (

        <>

            {InvoiceOperations?.search_loading ? <LoadingComponent/> : ""}

            <ul className={`products-list ${products && products.length > 0 ? "" : "empty-content"}`}>
                {
                    (products && products.length > 0) ?
                        products.map(function (product) {
                            return (<li key={product?.id}>
                        <span>
                            <img src={product?.image_url} alt="image_url"/>
                        </span>
                                <p>{product?.name}</p>
                                <button className="btn btn-info" type="button"
                                        onClick={event => addProductToTableAction(product)}>
                                    <i className="bi bi-plus-lg"> </i>
                                </button>
                            </li>);
                        })
                        :
                        (<EmptyContentComponent/>)
                }
            </ul>
        </>
    );
};

export default ProductListComponent;