import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AxiosInstance from "../../../config/AxiosInstance";

export default () => {

    const params = useParams();
    const safe_account_number = params?.safe_account_number;

    /* table */
    const prefix = "accounts-balances";
    const module_endpoint = "financial-transactions/account-statement/safes" + `?account_number=${safe_account_number}`;
    const module_name = ' كشف حساب خزنة ' + (safe_account_number ?? '----');
    const create_redirect = "";


    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;

    const headers = [
        {
            label: 'تاريخ العملية',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'نوع الحركة',
            key: 'financial_transaction_type',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'combined_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'combined_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'balanced_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'balanced_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'البيان',
            key: 'statement',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'المستخدم',
            key: 'transaction_username',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'التفاصيل',
            key: 'transaction_info',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }
    ];

    const [financialTransactionTypes, setFinancialTransactionTypes] = useState([]);

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(reRenderItems())

        AxiosInstance.get('get-financial-transaction-types', {}).then(async function ({data}) {
            await setFinancialTransactionTypes(data.data);
        }).catch(function ({response}) {
            //
        });

    }, []);


    /* ---------------- searches ------------------ */
    const searchDateHandler = (event) => {
        dispatch(appendFilterParams({...table.params, date: event.target.value}, {}));
    }

    const OnStartAtChange = (event) => {
        dispatch(appendFilterParams({...table.params, start_at: event.target.value}, {}));
    }

    const OnEndAtChange = (event) => {
        dispatch(appendFilterParams({...table.params, end_at: event.target.value}, {}));
    }

    const OnFinancialTransactionType = (event) => {
        dispatch(appendFilterParams({...table.params, financial_transaction_type: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchDateHandler,
        OnStartAtChange,
        OnEndAtChange,
        OnFinancialTransactionType,
        financialTransactionTypes,
    };
}