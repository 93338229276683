import React from 'react';
import {printer} from "../../../config/images-path";
import {trans} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {printTable} from "../../../store/table/slices/tableSlice";

const PrinterComponent = () => {

    const dispatch = useDispatch();

    const printTableHandler = () => {
        dispatch(printTable());
        window.print();
    }

    return (
        <li>
            <a onClick={printTableHandler} data-bs-toggle="tooltip" data-bs-placement="top"
               title={trans('printAllSelected')}>
                <img src={printer} alt="img"/>
            </a>
        </li>
    );
};

export default PrinterComponent;
