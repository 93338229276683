import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import IndexHook from "../../../hooks/cruds/expense_categories/IndexHook";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        deleteHandler,
        expense_categories,
        OnChangeExpenseCategoriesHandler,
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>
                    <NormalSelectInput className={'col-md-3 col-sm-6 col-12'} onChangeHandler={OnChangeExpenseCategoriesHandler}>
                        <option value="" selected={true}>عرض كل اقسام المصروفات</option>
                        {expense_categories && expense_categories.map(function (expense_category) {
                            return (
                                <option key={expense_category.id} value={expense_category.id}>
                                    {expense_category.name}
                                </option>
                            );
                        })}
                    </NormalSelectInput>
                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {items.map(function (item) {
                        return (
                            <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} key={item.id}>
                                <td className="prevent-print">
                                    <TdSelectItemComponent is_exportable={false} item={item}/>
                                </td>

                                <td>
                                    <img className="table-img" src={(item?.image_url ?? null)}
                                         alt={(item?.name ?? "----")}/>
                                </td>
                                <td>{(item?.name ?? "----")}</td>
                                <td>{(item?.expense_category ?? "----")}</td>
                                <td>{(item?.creation_date ?? "----")}</td>

                                <td className="prevent-print">
                                    <TableButtonsComponent
                                        redirect_to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/edit/' + (item?.slug ?? null))}
                                        item={item}
                                        deleteHandler={deleteHandler}/>
                                </td>
                            </tr>
                        );
                    })}
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
