import React from "react";
import PaymentMethodsComponent from "../components/PaymentMethodsComponent";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import PayAnExpenseHook from "../../../hooks/system/financial_accounts/PayAnExpenseHook";

export default ({safes, nodeSelected}) => {

    const {
        currency,
        isSubmitLoading,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    } = PayAnExpenseHook();

    return (
        <>
            <form ref={submitForm} onSubmit={formik.handleSubmit}>
                <div className="flex-radio-box">
                    <input
                        type="radio"
                        id="nature_of_account_1"
                        name="nature_of_account"
                        value="1"
                        checked={natureOfAccountChecked === "nature_of_account_1"}
                    />

                    <label htmlFor="nature_of_account_1" onClick={e => ChangeNatureOfAccount("nature_of_account_1")}>
                        {"سند صرف"} {nodeSelected?.name}
                    </label>

                    <input
                        type="radio"
                        id="nature_of_account_2"
                        name="nature_of_account"
                        value="2"
                        checked={natureOfAccountChecked === "nature_of_account_2"}
                    />
                    <label htmlFor="nature_of_account_2" onClick={e => ChangeNatureOfAccount("nature_of_account_2")}>
                        {"سند قبض"} {nodeSelected?.name}
                    </label>
                </div>

                <input type="hidden" name="expense_id" value={nodeSelected?.id}/>

                <NormalSelectInput label={"الخزنة"}
                                   name="safe_id"
                                   formik={formik}
                                   className={"col-12"}
                                   error={errors?.["safe_id"]?.[0] ?? ""}>
                    {
                        safes &&
                        safes.map(function (safe) {
                            return (
                                <optgroup key={safe.id} label={safe.name}>
                                    {safe.accounts.map(function (safe) {
                                        return (
                                            <option key={safe.id} value={safe.id}>
                                                {safe.title}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                            );
                        })
                    }
                </NormalSelectInput>

                <PaymentMethodsComponent errors={errors}/>

                <NormalTextAreaInput className={"col-12"} label={"ملاحظات"}/>

                <div className="card-footer">
                    <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                </div>
            </form>
        </>
    );
};
