import React from "react";
import PageHeader from "../../../components/layout/PageHeader";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import NormalInput from "../../../shared/inputs/NormalInput";
import CardComponent from "../../../components/cards/CardComponent";
import {search_whites} from "../../../config/images-path";
import CreateHook from "../../../hooks/cruds/products/ProductListHook";
import SelectInput from "../../../shared/inputs/SelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import EditBtnTableComponent from "../../../components/table/table_btns/EditBtnTableComponent";
import DeleteBtnTableComponent from "../../../components/table/table_btns/DeleteBtnTableComponent";
import EmptyContentComponent from "../../../components/global/EmptyContentComponent";
import {Link} from "react-router-dom";
import {prefixRouterPath} from "../../../config/helper";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import LoadingComponent from "../../../components/global/LoadingComponent";

const ProductPricesList = () => {

    const {
        module_name,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        productCategories,
        products,
        selectedProducts,
        isSearchLoading,
        OnChangeCategory,
        OnChangeSearchKeyword,
        SearchButtonHandler,
        onSelectProduct,
        onDeleteSelectProduct,
    } = CreateHook();

    return (
        <>

            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>


            <PageHeader title={module_name}/>

            <CardComponent custom_class="mbody-0">
                {/* -------------------- Table Header -------------------- */}
                <div className="row">
                    <NormalSelectInput onChangeHandler={OnChangeCategory} className={'col-lg-3 col-md-4 col-sm-5 col-12'}>
                        <option value="" selected>اختر القسم</option>
                        {
                            productCategories && productCategories.map(function (productCategory) {
                                return (
                                    <optgroup label={productCategory.name}>
                                        {
                                            productCategory?.sub_categories && productCategory?.sub_categories.map(function (sub_category) {
                                                return (
                                                    <option key={sub_category.id}
                                                            value={sub_category.id}>
                                                        {sub_category.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </optgroup>
                                );
                            })
                        }
                    </NormalSelectInput>

                    <NormalInput onKeyUpHandler={OnChangeSearchKeyword} name="search_key"
                                 className="col-lg-3 col-md-4 col-sm-6 col-10"
                                 placeholder="ابحث ..."/>

                    <div className="col-sm-1 col-2 pd-0">
                        <button onClick={SearchButtonHandler} className="btn btn-filters btn-success">
                            <img src={search_whites} alt="img"/>
                        </button>
                    </div>
                </div>
                {/* -------------------- Table Header -------------------- */}
            </CardComponent>

            {
                products.length > 0 ? (
                    <CardComponent custom_class="card-custom" card_title="اختر الاصناف المراد اضافتها">
                        <ul className="products-list">
                            {
                                products.map(function (product, index) {
                                    return (
                                        <li key={product?.id}>
                                        <span>
                                          <img src={product?.image_url} alt={product?.name}/>
                                        </span>
                                            <p>{product?.name}</p>
                                            <button onClick={e => onSelectProduct(e, product)} className="btn btn-info"
                                                    type="button">
                                                <i className="bi bi-plus-lg"> </i>
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </CardComponent>
                ) : (isSearchLoading ? <LoadingComponent/> : <EmptyContentComponent text="لا يوجد منتجات لعرضها"/>)
            }

            {
                selectedProducts.length > 0 ? (
                    <CardComponent>
                        <form ref={submitForm} onSubmit={formik.handleSubmit}>
                            {/* -------------------- Table -------------------- */}
                            <div className="table-responsive">

                                <table className="table table-custom">
                                    <thead>
                                    <tr>
                                        <th>الصورة</th>
                                        <th>الاسم</th>
                                        <th>وحدة البيع</th>
                                        <th>السعر القديم</th>
                                        <th>السعر الجديد</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        selectedProducts.map(function (selectedProduct, index) {
                                            return (
                                                <tr key={selectedProduct?.id}>
                                                    <td>
                                                        <img className="table-img" src={selectedProduct?.image_url}
                                                             alt=""/>
                                                    </td>
                                                    <td>
                                                        {selectedProduct?.name}
                                                    </td>
                                                    <td>{selectedProduct?.sale_unit?.name}</td>
                                                    <td>{selectedProduct?.product_price}</td>
                                                    <td>
                                                        {/* id */}
                                                        <input type="hidden"
                                                               name={`products[${selectedProduct?.id}][id]`}
                                                               value={selectedProduct?.id}/>

                                                        {/* price */}
                                                        <NormalInput
                                                            name={`products[${selectedProduct?.id}][product_price]`}
                                                            type={"number"}
                                                            min={0}
                                                            className={"input-table-sm input-size"}
                                                            placeholder="0.00"
                                                            error={errors?.[`products.${selectedProduct?.id}.product_price`]?.[0] ?? ''}/>
                                                    </td>
                                                    <td>
                                                        <div className="flex-btn-table">
                                                            <a onClick={e => onDeleteSelectProduct(e, selectedProduct)}
                                                               href="#!"
                                                               className="btn-danger">
                                                                <i className="bi bi-trash"> </i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    </tbody>
                                </table>

                            </div>
                            {/* -------------------- Table -------------------- */}

                            <div className="card-footer">
                                <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                            </div>


                        </form>
                    </CardComponent>
                ) : ""
            }
        </>
    );
}
;

export default ProductPricesList;
