import React, {useEffect, useRef, useState} from 'react';
import ReactECharts from "echarts-for-react";
import AxiosInstance from "../../config/AxiosInstance";
import {useRtkState} from "../../config/helper";
import LoadingComponent from "../global/LoadingComponent";
import BarSkeleton from '../../shared/Skeleton/Charts/BarSkeleton';


const MostMonthProductSalesDuringTwelveMonth = ({mostMonthlyProductSalesStatistics, isLoading = true}) => {

    const settings = useRtkState("Settings");
    let chartColor = settings?.systemMode === "dark" ? "#caccdb" : "#212b36";
    let lineColor = settings?.systemMode === "dark" ? "#193156" : "#e5e5e5";

    const chartRef = useRef(null);
    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.getEchartsInstance) {
                chartRef.current.getEchartsInstance().resize();
            }
        };
        const resizeTimeout = setTimeout(resizeChart, 100);
        window.addEventListener("resize", resizeChart);
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", resizeChart);
        };
    }, []);

    const line2 = {
        grid: {
            top: "3%",
            left: "1%",
            right: "1%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [
            {
                data: [
                    "يناير",
                    "فبراير",
                    "مارس",
                    "ابريل",
                    "مايو",
                    "يونيو",
                    "يوليو",
                    "اغسطس",
                    "سبتمبر",
                    "اكتوبر",
                    "نوفمبر",
                    "ديسمبر",
                ],
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 13,
                        fontFamily: "regular",
                        color: chartColor,
                    },
                    // interval: 0
                },
            },
        ],
        yAxis: {
            type: "value",
            splitLine: {
                lineStyle: {
                    type: "dashed",
                    color: lineColor,
                },
            },
            axisLabel: {
                textStyle: {
                    color: chartColor,
                },
            },
        },
        tooltip: {
            trigger: "item",
            textStyle: {
                fontFamily: "regular",
            },
            axisPointer: {
                type: "shadow",
            },
        },
        series: [
            {
                type: "bar",
                barWidth: '40%',
                itemStyle: {
                    borderRadius: [50, 50, 0, 0],
                },
                color: "#1587b0",
                data: mostMonthlyProductSalesStatistics,
            },
        ],
    };

    return (
        <div className="col-md-12 position-relative">
            <h3 className="title-chart">
                <i className="bi bi-graph-up"> </i>
                أكثر صنف مبيعاً خلال آخر 12 أشهر
            </h3>
            { !isLoading ? <ReactECharts ref={chartRef} option={line2}> </ReactECharts> : (<BarSkeleton/>) }
        </div>
    );
};

export default MostMonthProductSalesDuringTwelveMonth;