import axios from "axios";
import {getBearToken} from "./helper";
import {APP_URL} from "./constants";

const AxiosInstance = axios.create({
    baseURL: APP_URL,
    timeout: 10000,
    headers: {
        Accept: "application/json",
        Authorization: getBearToken(),
    },
});

export default AxiosInstance;