import React, {useState, useRef, useEffect} from 'react';
import TextInput from "../../shared/inputs/TextInput";

export default ({captchaCallback}) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [userInput, setUserInput] = useState('');
    const [captchaError, setCaptchaError] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        generateCaptcha();
    }, []);

    const generateCaptcha = () => {
        const num1 = Math.floor(Math.random() * 10);
        const num2 = Math.floor(Math.random() * 10);
        const correctAnswer = num1 + num2;
        const questionText = `${num1} + ${num2} = ؟`;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Clear previous content
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set background
        ctx.fillStyle = '#3498db';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw noisy background
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
        for (let i = 0; i < 100; i++) {
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.stroke();
        }

        // Draw the question text with distorted style
        ctx.font = 'bold 40px Impact, Charcoal, sans-serif';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((Math.random() - 0.5) * Math.PI / 6); // Random rotation
        ctx.fillText(questionText, 0, 0);
        ctx.restore();

        setQuestion(questionText);
        setAnswer(correctAnswer.toString());
    };

    const handleChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!userInput) {
            setCaptchaError(true);
            return;
        }
        if (userInput === answer) {
            captchaCallback(true);
        } else {
            captchaCallback(false);
            reGenerateCaptcha();
        }
    };

    const reGenerateCaptcha = () => {
        setUserInput('');
        generateCaptcha();
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <div style={{border: '1px solid #ccc', width: '36%'}} className="p-2">
            <p className="color-red">من فضلك اكتب ناتج الجمع للدخول على صفحة تسجيل الدخول.</p>

            <canvas ref={canvasRef} width="200" height="80"
                    style={{border: '1px solid #ccc', borderRadius: '5px', margin: '5px 0'}}>
            </canvas>

            <input
                className={`form-control ${captchaError ? "border border-danger" : ""}`}
                autoComplete="off"
                value={userInput}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                type={"number"}
                placeholder="اكتب ناتج الجمع ثم تاكيد"
                step="any"/>

            <form onSubmit={handleSubmit}>
                <button type="submit" className="btn btn-info m-2">
                    تاكيد
                </button>
                <button type="button" className="btn btn-danger" onClick={reGenerateCaptcha}>
                    <i className="bi bi-repeat"> </i>
                </button>
            </form>
        </div>
    );
};
