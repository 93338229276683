import {trans, useRtkState, warningSweetAlert} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {useRef, useState} from "react";
import {ChangeConfirmPaymentPopUpAction} from "../../../store/pos/Pos";

export default () => {
    const posState = useRtkState("Pos");
    const dispatch = useDispatch();

    // const [isPrintPopUpOpened, setIsPrintPopUpOpened] = useState(false);
    const printInvoice = () => {
        if (posState?.invoice_products?.length <= 0) {
            warningSweetAlert(trans("There no products in invoice ."));
            return false;
        }
        window.print();
    };


    /* Choose Sale Action Type */
    const [saleActionType, setSaleActionType] = useState("paid");
    const ChooseSaleActionType = (type) => {
        setSaleActionType(type);
    };

    const OpenConfirmPaymentPopUp = async () => {
        await dispatch(ChangeConfirmPaymentPopUpAction(true, {}))
        // document.getElementById("invoice-notes").focus();
    };

    const CloseConfirmPaymentPopUp = () => {
        dispatch(ChangeConfirmPaymentPopUpAction(false, {}))
    };

    const ConfirmPaidBtn = useRef("");

    /* Popup Confirm Payment */
    const PopupConfirmPaidAction = () => {
        // setIsConfirmPaymentUpOpened(false);
        if (posState?.is_allow_print_in_invoice) {
            printInvoice();
        }
        ConfirmPaidBtn.current.click();
    };

    const payRef = useRef("");
    const printRef = useRef("");
    const holdRef = useRef("");
    const confirmPayRef = useRef("");

    return {
        printInvoice,
        saleActionType,
        ChooseSaleActionType,
        OpenConfirmPaymentPopUp,
        CloseConfirmPaymentPopUp,
        PopupConfirmPaidAction,
        posState,
        ConfirmPaidBtn,
        payRef,
        printRef,
        holdRef,
        confirmPayRef,
    };

}