import React from 'react';
import {errorValidation} from "../../config/images-path";

const ErrorValidateComponent = ({error}) => {
    return (
        <small className={'text-red d-flex'}>
            {/*<img src={errorValidation} className={'warning-error'} alt={'errorValidation'}/>*/}
            {error ? (<span>{error}</span>) : ''}
        </small>
    );
};

export default ErrorValidateComponent;
