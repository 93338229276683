import React from 'react';
import {mail} from "../../config/images-path";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {trans} from "../../config/helper";

const EmailWithImageInput = ({formik, name, error}) => {

    const server_error = error;
    const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;
    const error_text = front_error || server_error;

    return (
        <div className="form-login">
            <label>{trans('Email')}</label>
            <div className="form-addons">
                <img src={mail} alt="img"/>
                <input name={name}
                       className={`form-control ${error_text ? "border border-danger" : ""}`}
                       placeholder={trans('Enter_email')}
                       onChange={formik && formik.handleChange}
                    //  onBlur={formik && formik.handleBlur}
                />
            </div>
            {formik && formik.touched.email && formik.errors.email ? (
                <ErrorValidateComponent error={formik.errors.email}/>) : null}
            {error && <ErrorValidateComponent error={error}/>}
        </div>
    );
};

export default EmailWithImageInput;
