import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TableComponent from "../../../components/table/TableComponent";
import {selectedOrNo, trans} from "../../../config/helper";

import AccountingTrialBalanceHooks from "../../../hooks/system/accounts-balances/AccountingTrialBalanceHooks";
import {excel} from "../../../config/images-path";

export default () => {

    const {
        module_name,
        create_redirect,
        items,
        items_selected,
        setTrSpecialColor,
        drawThSpace,
        maxLevel,
        exportAccountTrialBalanceAction,
    } = AccountingTrialBalanceHooks();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <a href={"#!"} className={"btn btn-outline-success export-btn-excel"} onClick={exportAccountTrialBalanceAction}>
                    <img src={excel} alt="img" width={30} height={30}/>تصدير على هيئة اكسيل
                </a>
                {/* -------------------- Table Header -------------------- */}


                {/* -------------------- Table -------------------- */}
                <TableComponent custom_class={"accounting-trial-balance-table"}>
                    <thead>
                    <tr className={'prevent-print'}>
                        <th></th>
                        {drawThSpace(maxLevel)}
                        <th className='text-red' colSpan={2}>
                            <span className='custom-two-col'><em>المجمع</em></span>
                        </th>
                        <th className='text-red' colSpan={2}>
                            <span className='custom-two-col'><em>الرصيد</em></span>
                        </th>
                    </tr>
                    <tr>
                        {drawThSpace(maxLevel, true)}
                        <th>الحساب الرئيسي</th>
                        <th>مدين</th>
                        <th>دائن</th>
                        <th>مدين</th>
                        <th>دائن</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        items.map(function (item, index) {
                            return (
                                <>
                                    {item.level == 1 && index > 0 ? <tr className='tr-table-space'></tr> : ""}

                                    <tr className={`${selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} ${setTrSpecialColor(item.level)}`}
                                        key={item.id}>

                                        <td>{++index}</td>

                                        <td>{(item?.column1 ?? "----")}</td>
                                        <td>{(item?.column2 ?? "----")}</td>
                                        <td>{(item?.column3 ?? "----")}</td>
                                        <td>{(item?.column4 ?? "----")}</td>
                                        <td>{(item?.column5 ?? "----")}</td>

                                        <td>{(item?.parent_account_title ?? "----")}</td>
                                        <td>{item?.combined_debit_amount}</td>
                                        <td>{item?.combined_credit_amount}</td>
                                        <td>{item?.balanced_debit_amount}</td>
                                        <td>{item?.balanced_credit_amount}</td>
                                    </tr>
                                </>
                            );
                        })
                    }
                    </tbody>

                {/*    <tfoot>
                    {
                        !table.params.parent_id
                        &&
                        (
                            <tr>
                                <td colSpan={3}><span className='tfoot-span'></span></td>
                                <td><span
                                    className='tfoot-span'>{accounting_trial_balance_total_amount?.combined_debit_amount}</span>
                                </td>
                                <td><span
                                    className='tfoot-span'>{accounting_trial_balance_total_amount?.combined_credit_amount}</span>
                                </td>
                                <td><span
                                    className='tfoot-span'>{accounting_trial_balance_total_amount?.balanced_debit_amount}</span>
                                </td>
                                <td><span
                                    className='tfoot-span'>{accounting_trial_balance_total_amount?.balanced_credit_amount}</span>
                                </td>
                            </tr>
                        )
                    }
                    </tfoot>*/}
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};