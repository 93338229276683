import React from 'react';
import ContentLoader from 'react-content-loader'
import {useRtkState} from "../../../config/helper";

const BarSkeleton = ({props}) => {

    const settings = useRtkState("Settings");
    let tableSkeletonColor1 = settings?.systemMode === "dark" ? "#182a46" : "#f3f3f3";
    let tableSkeletonColor2 = settings?.systemMode === "dark" ? "#233b60" : "#ecebeb";

    return (
        <>
            <ContentLoader
                backgroundColor={tableSkeletonColor1}
                foregroundColor={tableSkeletonColor2}
                className='svg-custom' width={780} height={220} viewBox="0 0 590 220"
                {...props}>
                <rect x="34" y="77" rx="0" ry="0" width="30" height="193"/>
                <rect x="98" y="51" rx="0" ry="0" width="30" height="181"/>
                <rect x="161" y="115" rx="0" ry="0" width="30" height="145"/>
                <rect x="6" y="217" rx="0" ry="0" width="780" height="3"/>
                <rect x="224" y="14" rx="0" ry="0" width="30" height="214"/>
                <rect x="282" y="75" rx="0" ry="0" width="30" height="175"/>
                <rect x="343" y="55" rx="0" ry="0" width="30" height="177"/>
                <rect x="404" y="130" rx="0" ry="0" width="30" height="105"/>
                <rect x="464" y="95" rx="0" ry="0" width="30" height="140"/>
                <rect x="524" y="76" rx="0" ry="0" width="30" height="171"/>
            </ContentLoader>
        </>
    );
}

export default BarSkeleton;
