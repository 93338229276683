import React from 'react';
import '../../assets/dashboard/css/receipt.css';
import {useRtkState} from "../../config/helper";
import {useDispatch} from "react-redux";
import SimpleButton from "../../shared/buttons/SimpleButton";
import usePrintInvoiceHook from "../../hooks/global/usePrintInvoiceHook";

const InvoicePrintComponent = () => {

    const state = useRtkState('Pos');
    const dispatch = useDispatch();
    const settings = useRtkState('Settings')?.settings;
    const auth = useRtkState('Settings')?.auth;
    let date = new Date();

    const PrintInvoiceAction = () => {
        window.print();
    }

    return (
        <>
            <div id="printInvoice" className="receipt">
                <img src="https://codlop.sa/dashboard/assets/img/logo.svg" alt=""/>
                <h3>فاتورة مبيعات</h3>
                <div className="line-rcpt">
                    <span>
                    </span>
                </div>

                <ul className="flex-rcpt">
                    <li>{auth?.firstname + " " + auth?.lastname}</li>
                    <li>#809</li>
                    <li>{auth?.id}</li>
                    <li>
                        <span>{date.toJSON().slice(0, 10)}</span><span>{date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()}</span>
                    </li>
                </ul>

                <table className='table-rcpt'>
                    <thead>
                    <tr>
                        <th>الصنف</th>
                        <th>السعر</th>
                        <th>الكمية</th>
                        <th>الاجمالي</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (state?.invoice_products && state?.invoice_products.length > 0) ?
                            (state?.invoice_products.map(function (product) {
                                return (
                                    <tr key={product.id}>
                                        <td>{product?.name}</td>
                                        <td>{product?.product_price}</td>
                                        <td>x {product?.quantity}</td>
                                        <td>{product?.sub_total}</td>
                                    </tr>
                                );
                            })) : (<tr className="py-4">
                                <td colSpan="5">لا يتوفر بيانات</td>
                            </tr>)
                    }
                    </tbody>
                </table>

                <ul className="subtotal">
                    <li><p>المجموع الفرعي </p><em> </em><span>{state?.invoice_grand_sub_total} <em>{settings?.currency}</em></span>
                    </li>
                    <li><p>الضريبة</p><em> </em><span>{state?.invoice_tax}<em>%</em></span></li>
                    <li><p>الخصم</p><em> </em><span>{state?.invoice_discount} <em>{settings?.currency}</em></span></li>
                    <li className='total'><p>الإجمالي</p>
                        <em> </em><span>{state?.invoice_grand_total} <em>{settings?.currency}</em></span></li>
                </ul>

                <p className='text-btm'>نشكركم على ثقتكم الغالية</p>
                <div className="addrs-phone">
                    <p><i className="bi bi-geo-alt"> </i> {settings?.address}</p>
                    <p><i className="bi bi-telephone"> </i> {settings?.phone}</p>
                </div>
                {/* <p className='text-btm'>يشرفنا حضوركم</p> */}
                {/*<img className='barcode-receipt' src="https://barcodegenerator.seagullscientific.com/Content/Images/BarCodes/524d00b4-2f54-4eb3-bf54-0abf99f899a7.png" alt="" />*/}
                <p className='dev-by'> برمجة كود لوب </p>

                {/*<SimpleButton className="btn btn-success text-center"*/}
                {/*              btn_name="طباعة الفاتورة"*/}
                {/*              clickAction={PrintInvoiceAction}/>*/}

            </div>
        </>
    );
}

export default InvoicePrintComponent;
