import {
    confirmDelete,
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";

import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {setLocale} from 'yup';
import {
    fetchProducts,
    ResetAllInitialValues,
    AppendParamsValues,
    SetInvoiceType, SetIsReturnInvoice, SetDateHandler, UpdateInvoiceProducts, AppendProductToProductsListAction,
} from "../../../store/invoices/InvoiceOperations";

import {useDispatch} from "react-redux";
import {PRODUCT_PER_PAGE} from "../../../config/constants";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {
    const prefix = 'stocks';
    const module_endpoint = 'transfer_stocks';
    const module_name = 'التحويل بين المخازن';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'transfer_stocks';
    const slug = 'اضافة تحويل بين المخازن جديدة';
    const dispatch = useDispatch();

    const [from_warehouses, setFromWarehouses] = useState([]);
    const [to_warehouses, setToWarehouses] = useState([]);


    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "transfer_stocks");

        if (permissions.length > 0) {
            dispatch(SetInvoiceType('transfer_stocks', {}));
            dispatch(SetIsReturnInvoice(false, {}));
            AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setFromWarehouses(data.data);
                await setToWarehouses(data.data);
            }).catch(function ({response}) {
                //
            });

            return () => {
                dispatch(ResetAllInitialValues({}, {}));
            }
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {}, /* it will be server side validate */
        validationSchema: Yup.object({}), /* it will be server side validate */

        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()));
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
                dispatch(ResetAllInitialValues({}, {}))
            }).catch(function ({response}) {
                const errors = response.data.data;
                setErrors(errors);
                setIsSubmitLoading(false);

                if (errors && typeof errors == "string") {
                    warningSweetAlert(errors)
                }
            });
        },

        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    /* start product list operations */
    const InvoiceOperations = useRtkState('InvoiceOperations');
    const invoice_products = InvoiceOperations.invoice_products;

    const products_list_ref = useRef();
    const search_loading = useRtkState('InvoiceOperations').search_loading;

    const searchProductsHandler = () => {
        const search_key = products_list_ref?.current?.value
        dispatch(AppendParamsValues({search_key: search_key, per_page: PRODUCT_PER_PAGE}, {}));
        dispatch(fetchProducts());
    }
    /* start product list operations */

    const ChangeDateHandler = (event) => {
        setErrors([]);
        let value = event.target.value;
        dispatch(SetDateHandler(value, {}));
    };

    const deleteProductFromTableAction = (product) => {
        setErrors([]);
        confirmDelete(async function () {
            const new_invoice_products = invoice_products.filter(function (invoice_product) {
                return invoice_product.id !== product.id;
            });
            await dispatch(UpdateInvoiceProducts(new_invoice_products, {}));
            await dispatch(AppendProductToProductsListAction(product, {}));
        });
    }

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeDateHandler,
        deleteProductFromTableAction,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,

        from_warehouses,
        to_warehouses,
        /* start product list operations */
    };
}