import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    /* table */
    const prefix = 'warehouses-products';
    const module_endpoint = 'products';
    const module_name = 'الاصناف';
    const create_redirect = prefixRouterPath((prefix ? prefix + '/' : '') + 'products/create');

    const headers = [
        {
            label: 'الصورة',
            key: 'image',
            printable: true,
            sortable: false,
            can_export_excel: false,
        },
        {
            label: 'الاسم',
            key: 'name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'القسم',
            key: 'product_category.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الماركة',
            key: 'brand.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'وحدة الشراء',
            key: 'purchase_unit.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'وحدة البيع',
            key: 'sale_unit.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'سعر بيع الصنف',
            key: 'product_price',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الباركود',
            key: 'code',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'طباعة الباركود',
            key: 'print-barcode',
            printable: false,
            sortable: false,
            can_export_excel: false,
        },
        {
            label: 'الاجراء',
            key: 'action',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const [productCategories, setProductCategories] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "products");

        if (permissions.length > 0 && permissions.includes("products")) {
            dispatch(endpoint(module_endpoint, {}));
            dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
            dispatch(reRenderItems())
            AxiosInstance.get('product_categories/categories-with-its-subcategories', {}).then(async function ({data}) {
                await setProductCategories(data.data);
            }).catch(function ({response}) {
                //
            });
        }

    }, [permissions]);


    /* ---------------- searches ------------------ */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }
    const CategoryChangeHandler = (event) => {
        dispatch(appendFilterParams({...table.params, product_category_id: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */


    /* ---------------- delete ------------------ */
    const deleteHandler = (item) => {
        confirmDelete(async () => {
            await AxiosInstance.delete(`${module_endpoint}/${item.id}`, {}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }
    /* ---------------- delete ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
        productCategories,
        CategoryChangeHandler,
    };
}