import Cookies from "js-cookie";

/* https://www.npmjs.com/package/js-cookie */

/**
 * @param name
 * @param value
 */
export const setCookie = (name, value) => {
    Cookies.set(name, value, {expires: 365});
}

/**
 * @param name
 * @returns {*|null}
 */
export const getCookie = (name) => {
    return Cookies.get(name) ?? null;
}

/**
 * @param name
 */
export const removeCookie = (name) => {
    Cookies.remove(name);
}