import React from 'react';
import {appendFilterParams, reRenderItems} from "../../store/table/slices/tableSlice";
import {useDispatch} from "react-redux";
import {useRtkState} from "../../config/helper";

const PerPageComponent = () => {

    const table = useRtkState('table');
    const dispatch = useDispatch();

    const perPageHandler = async (event) => {
        await dispatch(appendFilterParams({...table.params, per_page: event.target.value}, {}));
        await dispatch(reRenderItems())
    }

    return (
        <div className="flex-select-page">
            <label>إظهار</label>
            <select onChange={perPageHandler}
                    className="custom-select custom-select-sm form-control form-control-sm">
                <option value="7">الافتراضي</option>
                <option value="10"> 10 عناصر</option>
                <option value="25"> 25 عنصر</option>
                <option value="50"> 50 عنصر</option>
                <option value="100"> 100 عنصر</option>
            </select>
            <label>بالصفحة</label>
        </div>
    );
};

export default PerPageComponent;
