export default () => {

    const UpdateRepeatedProductQuantityAndReturnAllList = (state, payload) => {
        const invoice_product = {
            id: payload?.id,
            code: payload?.code,
            name: payload?.name,
            warehouse_manage_stock_quantity: payload?.warehouse_manage_stock_quantity,
            product_price: payload?.product_price,
            sale_unit: payload?.sale_unit,
            quantity: 1,
            sub_total: (parseFloat(payload?.product_price ?? 0).toFixed(2) * 1).toFixed(2),
        };

        let old_invoice_products = state.invoice_products;
        let is_product_found = false;
        let new_invoice_products = [];

        if (old_invoice_products && old_invoice_products.length > 0) {
            old_invoice_products.forEach(function (product, index) {
                if (payload?.id === product?.id) {
                    is_product_found = true;
                    product['quantity'] = product['quantity'] + 1;
                    product['sub_total'] = (parseFloat(product['product_price'] ?? 0).toFixed(2) * parseInt(product['quantity'])).toFixed(2);
                }
                new_invoice_products[index] = product;
            });
        }

        if (!is_product_found) {
            new_invoice_products.push(invoice_product);
        }
        return new_invoice_products;
    }

    const UpdateInvoiceGrandSubTotal = (state) => {
        let invoice_products = state.invoice_products;

        let invoice_tax = state.invoice_tax;
        let invoice_discount = state.invoice_discount;
        let invoice_shipping = state.shipping;

        let invoice_grand_sub_total = 0;

        if (invoice_products && invoice_products.length > 0) {
            invoice_products.forEach(function (product) {
                invoice_grand_sub_total += parseFloat(product.sub_total);
            });
        }

        state.invoice_paid = '';
        state.invoice_rest_of_paid = '';
        state.invoice_grand_sub_total = parseFloat((invoice_grand_sub_total).toFixed(2));
        let invoice_grand_total = ((invoice_grand_sub_total ?? 0) + (invoice_shipping ?? 0) + ((invoice_tax * invoice_grand_sub_total) / 100) - (invoice_discount ?? 0)).toFixed(2);
        state.invoice_grand_total = parseFloat(invoice_grand_total);
    }

    const PlaySound = () => {
        const audioElement = document.getElementById('audioElementId');
        audioElement.play(); // Play the sound
    }

    const UpdateProductQtyAndReturnAllList = (state, payload) => {
        const {product, rate} = payload;
        const invoice_products = state.invoice_products;
        if (invoice_products && invoice_products.length > 0) {
            invoice_products.forEach(function (invoice_product, index) {
                if (invoice_product?.id === product?.id) {
                    let new_qty = invoice_product['quantity'] + rate;
                    new_qty = (new_qty > 0 ? new_qty : 1);
                    invoice_product['quantity'] = new_qty
                    invoice_product['sub_total'] = parseFloat(invoice_product['product_price'] * parseInt(invoice_product['quantity'])).toFixed(2);
                }
                invoice_products[index] = invoice_product;
            });
        }
        return invoice_products;
    }

    const DeleteProductAndReturnAllList = (state, payload) => {
        const {product} = payload;
        const invoice_products = state.invoice_products;
        let new_invoice_products = [];
        if (invoice_products && invoice_products.length > 0) {
            invoice_products.forEach(function (invoice_product, index) {
                if (invoice_product?.id !== product?.id) {
                    new_invoice_products.push(invoice_product);
                }
            });
        }
        return new_invoice_products;
    }

    const RestInitialInvoiceStates = (state) => {
        state.invoice_products = [];
        state.invoice_grand_sub_total = 0;
        state.invoice_tax = 0;
        state.invoice_discount = 0;
        state.shipping = 0;
        state.invoice_grand_total = 0;
        state.invoice_paid = '';
        state.invoice_rest_of_paid = '';

        state.payment_status = 0;
        state.paid_amount = 0;
        state.rest_amount = 0;
        state.pos_submit_errors = {};
    }

    return {
        UpdateRepeatedProductQuantityAndReturnAllList,
        UpdateInvoiceGrandSubTotal,
        PlaySound,
        UpdateProductQtyAndReturnAllList,
        DeleteProductAndReturnAllList,
        RestInitialInvoiceStates,
    }
}