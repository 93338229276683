import React, {useState} from "react";
import LogoComponent from "./header/LogoComponent";
import ProfileComponent from "./header/ProfileComponent";
import {allow_vertical_sidebar_helper} from "../../config/helper";
import MenuComponent from "./MenuComponent";
import ThemeModeComponent from "../pos/components/PosHeader/ThemeModeComponent";
import SystemSetting from "../pos/components/PosHeader/SystemSetting";
import PosLink from "./header/PosLink";

const HeaderComponent = () => {
    const allow_vertical_sidebar = allow_vertical_sidebar_helper();

    const [isOpen, setIsopen] = useState(false);
    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    };


    /* ============================ */
    const [menu, setMenu] = useState({menu: '', status: false});

    const submenuHandler = (submenu, status) => {
        if (menu?.menu !== submenu) {
            setMenu({menu: submenu, status: true});
        } else {
            setMenu({menu: submenu, status: !status});
        }
    }

    const closeSubmenuHandler = (SideMenuType) => {
        if (SideMenuType === "horizontal") {
            setMenu({menu: '', status: false});
        }
    }


    return (
        <>
            <div className={`header ${allow_vertical_sidebar ? "" : "header-one"}`}>
                {/*mobile btn*/}
                <div className="mobile_btn" onClick={ToggleSidebar}>
                  <span className="bar-icon">
                    <span> </span>
                    <span> </span>
                    <span> </span>
                  </span>
                </div>

                {/*Logo*/}
                <LogoComponent/>

                {/*menu*/}
                <MenuComponent isMenuOpen={isOpen}
                               menu={menu}
                               submenuHandler={submenuHandler}
                               closeSubmenuHandler={closeSubmenuHandler}/>

                {/* Header Menu */}
                <ul className="nav user-menu">
                    <PosLink/>
                    <SystemSetting submenuHandler={submenuHandler}/>
                    <ProfileComponent submenuHandler={submenuHandler}/>
                </ul>

                {/*sidebar overlay*/}
                <div className={`sidebar-overlay ${isOpen === true ? "active" : ""}`} onClick={ToggleSidebar}>
                </div>
            </div>

            {/*menu overlay*/}
            <div className={`menu-overlay ${!menu?.status || (menu?.menu === "" && menu?.status) ? '' : 'active'}`}
                 onClick={event => submenuHandler("", false)}>
            </div>
        </>
    );
};

export default HeaderComponent;
