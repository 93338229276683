import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import PosCoreComponent from "../../../components/pos/PosCoreComponent";
import PosHeaderComponent from "../../../components/pos/PosHeaderComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import PosHook from "../../../hooks/system/pos/PosHook";
import InvoicePrintComponent from "../../../components/invoice/InvoicePrintComponent";


const PosPage = () => {

        const {
            module_name,
            formik,
            handleOnFormChange,
            submitForm,
            isSubmitLoading,
            errors,
            posState,
            isPosLoading,
        } = PosHook();

        return (
            <>
                <Helmet>
                    <meta name="description" content=""/>
                    <meta name="keywords" content=""/>
                    <title> {SYSTEM_TITLE} | {module_name} </title>
                </Helmet>

                {isPosLoading && <LoadingComponent otherClasses={'page-loader'}/>}

                <div className={`main-wrapper ${!posState?.is_show_products_list ? "hide-products-list" : ""}`}>
                    <div className="header">
                        <PosHeaderComponent/>
                    </div>

                    <div className="page-wrapper">
                        <PosCoreComponent formik={formik}
                                          handleOnFormChange={handleOnFormChange}
                                          submitForm={submitForm}
                                          isSubmitLoading={isSubmitLoading}
                                          errors={errors}
                                          isPosLoading={isPosLoading}/>
                    </div>
                </div>


                <InvoicePrintComponent/>
            </>
        );
    }
;

export default PosPage;
