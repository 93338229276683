import React, {useState} from 'react';
import FilterComponent from "./FilterComponent";
import ExportsAndDeleteAllComponent from "./ExportsAndDeleteAllComponent";
import FilterContentComponent from "./filters/FilterContentComponent";
import {trans, useRtkState} from "../../config/helper";
import PerPageComponent from "./PerPageComponent";

const TableHeaderComponent = ({
                                  children,
                                  is_open = false,
                                  allow_delete = true,
                                  custom_class_search = "",
                                  allow_export = true
                              }) => {

    const [isFilterShow, setIsFilterShow] = useState(false);

    const showFilterHandler = () => {
        setIsFilterShow(!isFilterShow);
    }

    const table = useRtkState('table');
    const items_selected = table?.items_selected;
    const params = table?.params;

    return (
        (
            (table?.items?.length < 1) && (Object.keys(params)?.length < 1)) || is_open
            ?
            ""
            :
            (<>
                <div className="table-top">

                    <div className="flex-right">
                        {/* filters */}
                        {children &&
                            <FilterComponent isFilterShow={isFilterShow} showFilterHandler={showFilterHandler}/>}

                        {/* exports */}
                        {items_selected.length > 0
                            ?
                            (<ExportsAndDeleteAllComponent allow_delete={allow_delete}/>)
                            :
                            (allow_export ? <span
                                className="select-some">{trans('Select_some_items_to_show_the_buttons')}</span> : "")
                        }
                    </div>

                    {/* per page */}
                    <PerPageComponent/>
                </div>

                {/* filters content */}
                {children && <FilterContentComponent custom_class_search={custom_class_search}
                                                     isFilterShow={isFilterShow}>{children}</FilterContentComponent>}
            </>)
    );
};

export default TableHeaderComponent;
