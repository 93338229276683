import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const permissions = useRtkState('Settings')?.permissions;
    useEffect(() => {
        PermissionMiddleware(permissions, "currencies");
    }, [permissions]);

    const prefix = 'product-settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'currencies';
    const module_endpoint = 'currencies';
    const module_name = 'العملات';
    const slug = 'اضافة عملة جديدة';

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            code: '',
            symbol: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            code: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            symbol: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    return {module_name, slug, formik, submitForm, isSubmitLoading, errors, resetForm};
}