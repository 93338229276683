import React from 'react';
import {useRtkState} from "../../../config/helper";

const InvoiceFooterComponent = () => {

    const settings = useRtkState("Settings")?.settings;


    return (
        <>
            <div className="footer-bill">
                <h4>
                    <i className="bi bi-geo-alt"> </i>
                    {settings?.address}
                </h4>
                <ul className="contact-bill">
                    <li>
                        <i className="bi bi-telephone"> </i>
                        <span>{settings?.phone}</span>
                    </li>
                    <li>
                        <i className="bi bi-envelope"> </i>
                        <span>{settings?.email}</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default InvoiceFooterComponent;