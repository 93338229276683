import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";

/* create hook */
import CreateHook from "../../../hooks/cruds/warehouses/CreateHook";


const Create = () => {

    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>
            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <TextInput label={'الاسم'}
                                   name={'name'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'البريد الالكتروني'}
                                   name={'email'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['email']?.[0] ?? ''}/>


                        <TextInput label={'الهاتف'}
                                   name={'phone'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['phone']?.[0] ?? ''}/>


                        <TextInput label={'الدولة'}
                                   name={'country'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['country']?.[0] ?? ''}/>


                        <TextInput label={'المدينة'}
                                   name={'city'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['city']?.[0] ?? ''}/>


                        <TextInput label={'الرمز البريدي'}
                                   name={'zip_code'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['zip_code']?.[0] ?? ''}/>


                        <TextAreaInput label={'العنوان'}
                                   name={'address'}
                                   formik={formik}
                                   className={'col-lg-12 col-sm-12 col-12'}
                                   error={errors?.['address']?.[0] ?? ''}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                        <RestButton className={'btn btn-danger'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Create;