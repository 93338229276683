import AxiosInstance from "../../config/AxiosInstance";
import {removeCookie} from "../../hooks/global/useCookie";
import {prefixRouterPath} from "../../config/helper";

export default async (navigate) => {
  await AxiosInstance.post('auth/logout', {}).then(async function () {
      removeCookie('token');
      removeCookie('permissions_by_group');
      removeCookie('permissions');
      navigate(prefixRouterPath('login'));
  }).catch(function ({response}) {
      // console.log(response);
  });
}
