import React, {useEffect, useState} from "react";
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import ShowBarcode from "../../../components/cruds/products/ShowBarcode";
import usePrintContentById from "../../../hooks/global/usePrintContentById";
import InfoPage from "../../../components/global/InfoPage";
import {Link} from "react-router-dom";
import {prefixRouterPath, useRtkState} from "../../../config/helper";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

const PrintBarcode = () => {

        const permissions = useRtkState('Settings')?.permissions;
        useEffect(() => {
            PermissionMiddleware(permissions, "print-barcode");
        }, [permissions]);


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const product_barcode = urlParams.get('product_barcode')

        const [code, setCode] = useState(product_barcode ?? "");
        const [qty, setQty] = useState(1);

        const OnChangeCode = (e) => {
            setCode(e?.target?.value);
        };

        const OnChangeQty = (e) => {
            setQty(e?.target?.value);
        };

        const PrintBarcode = (e) => {
            const id = "barcode-id";
            usePrintContentById(id);
        };

        return (
            <>
                {
                    (permissions.length > 0 && permissions.includes("print-barcode")) &&
                    <>
                        <PageHeader title="طباعة الباركود" route={prefixRouterPath('product-settings/products')}
                                    redirect_name={'الذهاب للاصناف'} allow_icon={false}/>

                        <InfoPage
                            page_description="قم بكتابة الكود الخاص بالصنف المراد طباعة له الباركود (barcode)  , او قم بالذهاب الى الاصناف لاختيار صنف معين وسيتم تحويلك هنا مرة اخر مع الكود الخاص بالصنف المراد طباعة الباركود (barcode) الخاص به ."/>

                        <CardComponent>

                            <div className="row">

                                <div className="col-xl-3 col-lg-4 col-sm-6 form-group">
                                    <div className="flex-label">
                                        <label>
                                            اكتب الكود
                                        </label>
                                    </div>

                                    <input onChange={OnChangeCode} type="text" className="form-control" value={code}/>


                                </div>

                                {
                                    code
                                    &&
                                    <>
                                        <div className="col-xl-3 col-lg-4 col-sm-6 form-group">
                                            <div className="flex-label">
                                                <label>عدد مرات الطباعة</label>
                                            </div>
                                            <input onChange={OnChangeQty} type="number" className="form-control" min="1"
                                                   max="30"
                                                   value={qty}/>
                                        </div>
                                        <div className="card-footer pr-10">
                                            <SimpleButton clickAction={PrintBarcode} className="btn btn-primary"
                                                          btn_name="طباعة"/>
                                        </div>
                                    </>
                                }

                            </div>

                        </CardComponent>
                    </>

                }
                {qty > 0 && code !== '' && <CardComponent><ShowBarcode code={code} qty={qty}/></CardComponent>}
            </>
        );
    }
;
export default PrintBarcode;
