import React, {useState} from 'react';
import CustomSwiper from "../../../../shared/swiper/CustomSwiper";
import {SwiperSlide} from "swiper/react";
import {useRtkState, warningSweetAlert} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import LoadingComponent from "../../../global/LoadingComponent";
import AxiosInstance from "../../../../config/AxiosInstance";
import {
    appendFilterParams,
    ConvertFiltrationTypeAction, fetchAllProducts,
    UpdateFiltrationListAction,
    UpdateProductsListAction
} from "../../../../store/pos/Pos";
import CustomTooltip from '../../../../shared/material-ui/CustomTooltip';

const CategoriesAndBrandsComponent = () => {
    const SwapBaseFiltration = async () => {
        let endpoint;
        let filtration_type;

        RemoveActivationItem();
        if (state.filtration_type === "categories") {
            endpoint = "brands";
            filtration_type = "brands";
        } else {
            endpoint = "product_categories";
            filtration_type = "categories"
        }

        setIsLoading(true);
        await AxiosInstance.get(endpoint, {params: {}}).then(async function ({data}) {
            dispatch(ConvertFiltrationTypeAction(filtration_type, {}))
            dispatch(UpdateFiltrationListAction(data.data, {}))
            setIsLoading(false);
        }).catch(function (response) {
            warningSweetAlert();
        });

        dispatch(appendFilterParams({...state?.params, product_category_id: '', brand_id: ''}, {}));
        dispatch(fetchAllProducts());
    }

    const [isLoading, setIsLoading] = useState(false);
    const state = useRtkState('Pos');
    const dispatch = useDispatch();


    const ActiveItem = (event) => {
        RemoveActivationItem();
        event.target.closest('.ctg-item').classList.add("active");
    }

    const RemoveActivationItem = () => {
        let ctg_items = document.getElementsByClassName('ctg-item');
        for (let i = 0; i < ctg_items.length; i++) {
            ctg_items[i].classList.remove("active")
        }
    }

    const FiltrationListAction = async (event, item) => {
        const filtration_type = state?.filtration_type;

        if (filtration_type === "categories") {
            setIsLoading(true);
            await AxiosInstance.get("product_categories", {params: {category_id: item?.id}}).then(async function ({data}) {
                dispatch(ConvertFiltrationTypeAction('sub_categories', {}))
                dispatch(UpdateFiltrationListAction(data.data, {}))
                setIsLoading(false);
            }).catch(function (response) {
                setIsLoading(false);
                warningSweetAlert();
            });
        } else {

            if (filtration_type === "sub_categories") {
                dispatch(appendFilterParams({...state?.params, product_category_id: item?.id}, {}));
                ActiveItem(event);
            } else if (filtration_type === "brands") {
                dispatch(appendFilterParams({...state?.params, brand_id: item?.id}, {}));
                ActiveItem(event);
            } else {
                dispatch(appendFilterParams({...state?.params, product_category_id: '', brand_id: ''}, {}));
                // ActiveItem(event);
            }

            dispatch(fetchAllProducts());
        }
    }

    return (
        <div className="categoreis position-relative">

            {state?.filtration_type === "categories" ?
                <CustomTooltip title="اضغط لعرض الماركات">
                    <button onClick={SwapBaseFiltration} className="catg-brands btn-primary">
                        <i className={`bi bi-grid`}> </i>
                        الماركات
                    </button>
                </CustomTooltip>
                :
                <CustomTooltip title="رجوع">
                    <button onClick={SwapBaseFiltration} className="back-step">
                        <i className={`bi bi-arrow-right`}> </i>
                    </button>
                </CustomTooltip>
            }


            {/*<CustomTooltip title="رجوع">
                <button className="back-step">
                    <i className={`bi ${isLoading ? 'loader-btn' : 'bi-arrow-right'}`}> </i>
                </button>
            </CustomTooltip>*/}

            {isLoading && <LoadingComponent/>}

            <CustomSwiper>
                {
                    (state?.filtration_list && state?.filtration_list.length > 0) &&
                    (
                        state?.filtration_list.map(function (item) {
                            return (
                                <SwiperSlide>
                                    <div onClick={event => FiltrationListAction(event, item)} className="ctg-item">
                                        {/*<img src={item?.image_url} alt={item?.name}/>*/}
                                        <p>{item?.name}</p>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    )
                }
            </CustomSwiper>
        </div>
    );
};

export default CategoriesAndBrandsComponent;