import React, {useState} from 'react';
import NormalInput from "../../../../shared/inputs/NormalInput";
import SelectInput from "../../../../shared/inputs/SelectInput";
import NormalTextAreaInput from "../../../../shared/inputs/NormalTextAreaInput";
import {useDispatch} from "react-redux";
import {
    ChangeInvoiceDiscountAction,
    ChangeInvoiceTaxAction,
    SetPosNotesAction,
    SetPosPaidAmountAction,
    SetPosPaymentStatusAction,
    SetPosPaymentTypeAction,
    SetPosShippingAction
} from "../../../../store/pos/Pos";
import NormalSelectInput from "../../../../shared/inputs/NormalSelectInput";
import {useRtkState} from "../../../../config/helper";
import PaymentHook from "../../../../hooks/system/invoice/PaymentHook";
import SimpleButton from "../../../../shared/buttons/SimpleButton";

const PaymentSettingComponent = ({confirmPayRef, NotesRef, errors}) => {
    const posState = useRtkState("Pos");
    const dispatch = useDispatch();
    const currency = useRtkState("Settings").settings?.currency;

    const PaymentStatusHandler = () => {
        dispatch(SetPosPaymentStatusAction(posState?.payment_status === 1 ? 0 : 1, {}));
    }

    const SetNotesHandler = (event) => {
        let value = event?.target?.value;
        dispatch(SetPosNotesAction(value, {}));
        if (event.key === 'Enter') {
            if (confirmPayRef?.current) {
                confirmPayRef.current.click();
            }
        }
    }

    const SetPaymentTypeHandler = (event) => {
        let value = event?.target?.value;
        dispatch(SetPosPaymentTypeAction(value, {}));
    }

    const ChangeInvoiceTax = (event) => {
        const value = event?.target?.value;
        dispatch(ChangeInvoiceTaxAction(value === '' ? "0" : value, {}));
    };

    const ChangeInvoiceDiscount = (event) => {
        const value = event?.target?.value;
        dispatch(ChangeInvoiceDiscountAction((value === '' ? "0" : value), {}));
    };

    const ChangeShippingDiscount = (event) => {
        const value = event?.target?.value;
        dispatch(SetPosShippingAction((value === '' ? "0" : value), {}));
    };

    const SelectInputValue = (obj) => {
        obj.target.select();
    };

    /* payments */
    const {
        CreateNewPaymentHandler,
        localStatePayments,
        handlePaymentsChange,
        payment_types,
        DeletePaymentHandler
    } = PaymentHook();
    /* payments */


    return (
        <div className="right-paid-card">


            <div
                className={`switch-holder ${!posState?.customer_id_selected || posState?.customer_id_selected == null || posState?.customer_id_selected == "1" ? "d-none" : ""}`}>
                <div className="switch-label">
                    <span>حالة الدفع</span>
                </div>
                <div className="switch-toggle">
                    <input onChange={PaymentStatusHandler}
                           type="checkbox"
                           id="payment-status"
                        // name={`payment_status`}
                           checked={posState?.payment_status === 1}/>
                    <label htmlFor="payment-status"> </label>
                </div>
            </div>


            <div className="tax-discount">
                <h3>
                    الضريبة
                    <input readOnly={posState?.invoice_grand_sub_total <= 0}
                           onChange={ChangeInvoiceTax}
                           type="number"
                           onClick={e => SelectInputValue(e)} min={0} step=".01"
                           name={`tax_rate`}
                           value={posState?.invoice_tax}
                           placeholder="0.00"/>
                    <span>%</span>
                </h3>
                <h3>
                    الخصم
                    <input readOnly={posState?.invoice_grand_sub_total <= 0}
                           onChange={ChangeInvoiceDiscount}
                           onClick={e => SelectInputValue(e)}
                           type="number" min={0}
                           step=".01"
                           name={`discount_amount`}
                           value={posState?.invoice_discount}
                           placeholder="0.00"/>
                    <span>{currency}</span>
                </h3>
                <h3>
                    الشحن
                    <input readOnly={posState?.invoice_grand_sub_total <= 0}
                           onChange={ChangeShippingDiscount}
                           onClick={e => SelectInputValue(e)}
                           type="number" min={0} step=".01"
                           name={`shipping`}
                           value={posState?.shipping}
                           placeholder="0.00"/>
                    <span>{currency}</span>
                </h3>
            </div>

            {
                (localStatePayments && localStatePayments.length > 0)
                &&
                (
                    localStatePayments.map(function (payment, index) {
                        return (
                            <>
                                <div className="row custom-row">
                                    <NormalSelectInput
                                        label={"نوع طريقة الدفع"}
                                        name={`payments[${index}][payment_type_id]`}
                                        // value={payment?.payment_type_id ?? 0}
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'payment_type_id', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.payment_type_id`]?.[0] ?? ""}>
                                        {
                                            payment_types && payment_types.map(function (payment_type) {
                                                return (
                                                    <option
                                                        selected={payment?.payment_type_id && payment?.payment_type_id == payment_type.id}
                                                        key={payment_type.id} value={payment_type.id}>
                                                        {payment_type.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </NormalSelectInput>

                                    <NormalInput
                                        label={"المبلغ المدفوع"}
                                        type="number"
                                        min={0}
                                        name={`payments[${index}][amount]`}
                                        value={payment?.amount ?? 0}
                                        // placeholder={InvoiceOperations?.paid_amounts ?? 0}
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'amount', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.amount`]?.[0] ?? ""}/>

                                    <NormalInput
                                        label={"رقم فاتورة الدفع"}
                                        type="number"
                                        min={0}
                                        name={`payments[${index}][payment_serial_number]`}
                                        value={payment?.payment_serial_number ?? 0}
                                        placeholder="(اختياري)"
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'payment_serial_number', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.payment_serial_number`]?.[0] ?? ""}/>

                                    {
                                        (index > 0)
                                        &&
                                        (
                                            <div className="abs-x">
                                                <SimpleButton clickAction={() => DeletePaymentHandler(index)}
                                                              className="btn btn-danger btn-sm"
                                                              btn_name="حذف"/>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    })
                )
            }
            <div className="mb-3">
                <SimpleButton clickAction={CreateNewPaymentHandler} className="btn btn-info btn-sm"
                              btn_name="اضافة طريقة دفع اخرى"/>
            </div>
            {/*<NormalSelectInput onChangeHandler={SetPaymentTypeHandler} label={"نوع الدفع"} name={"payment_type"}>*/}
            {/*    <option value={0} selected={true}>كاش</option>*/}
            {/*</NormalSelectInput>*/}

            <NormalTextAreaInput name={`notes`}
                                 id={"invoice-notes"}
                                 label={'ملاحظات'}
                                 onKeyupHandler={SetNotesHandler}/>
        </div>
    );
};

export default PaymentSettingComponent;