import React, {useRef, useState} from 'react';
import CardComponent from '../../../components/cards/CardComponent';
import PageHeader from '../../../components/layout/PageHeader';
import PasswordInput from '../../../shared/inputs/PasswordInput';
import SubmitButton from '../../../shared/buttons/SubmitButton';
import {
    prefixRouterPath,
    successSweetAlert,
    successSweetAlert2,
    trans,
    useRtkState,
    warningSweetAlert
} from '../../../config/helper';
import {useFormik} from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../config/AxiosInstance";
import {setCookie} from "../../../hooks/global/useCookie";

const Profile = () => {

    const auth = useRtkState('Settings')?.auth;
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const restForm = useRef();

    const formik = useFormik({
        initialValues: {
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        },
        validationSchema: Yup.object({
            current_password: Yup.string().required(trans('Enter_password')),
            new_password: Yup.string().required(trans('Enter_password')),
            new_password_confirmation: Yup.string().required(trans('Enter_password')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            await AxiosInstance.post('auth/change_password', values).then(async function ({data}) {
                setIsSubmitLoading(false);
                const user = data?.data;
                const token = user?.token;
                setCookie('token', token);
                setErrors({});
                restForm.current.reset();
                successSweetAlert();
            }).catch(function ({response}) {
                setIsSubmitLoading(false);
                setErrors(response.data.data);
                warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    return (
        <>
            <PageHeader title="حسابي"/>

            <CardComponent custom_class='bg-card'>
                <div className="profile-box">
                    <i className='bi bi-person-circle'> </i>
                    <div className="profile-dts">
                        <h3>{(auth?.firstname ?? "---") + " " + (auth?.lastname ?? "---")}</h3>
                        <p>{auth?.user_type == "1" ? "مشرف" : "بائع" }</p>
                        <ul>
                            <li>
                                <h4>البريد الالكتروني</h4>
                                <span>{auth?.email ?? "---"}</span>
                            </li>
                            <li>
                                <h4>رقم الهاتف</h4>
                                <span>{auth?.phone ?? "---"}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </CardComponent>


            <CardComponent card_title="تغيير كلمة المرور">
                <form ref={restForm} onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-3">
                            <PasswordInput label="كلمة المرور الحالية"
                                           formik={formik}
                                           name={'current_password'}
                                           error={errors?.["current_password"]?.[0] ?? ""}/>
                        </div>
                        <div className="col-md-3">
                            <PasswordInput label="كلمة المرور الجديدة"
                                           formik={formik}
                                           name={'new_password'}
                                           error={errors?.["new_password"]?.[0] ?? ""}/>
                        </div>
                        <div className="col-md-3">
                            <PasswordInput label="تأكيد كلمة المرور الجديدة"
                                           formik={formik}
                                           name={'new_password_confirmation'} placeholder=""
                                           error={errors?.["new_password_confirmation"]?.[0] ?? ""}/>
                        </div>
                    </div>
                    <div className="card-footer mt-4">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>

        </>
    );
}

export default Profile;
