import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {Link} from "react-router-dom";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import AccountsBalancesForSuppliersHook
    from "../../../hooks/system/accounts-balances/AccountsBalancesForSuppliersHooks";
import {useDispatch} from "react-redux";
import {selectAll} from "../../../store/table/slices/tableSlice";

export default () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchKeyHandler,
        totalBalance,
    } = AccountsBalancesForSuppliersHook();

    const dispatch = useDispatch();
    const selectAllItemsHandler = () => {
        dispatch(selectAll());
    }

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>
                    {/*<NormalInput onChangeHandler={searchKeyHandler}*/}
                    {/*             name={'search_key'}*/}
                    {/*             className={'col-md-4 col-sm-6 col-10'}*/}
                    {/*             placeholder={'ابحث ...'}/>*/}
                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent>

                    <thead>
                    <tr className={'prevent-print'}>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className='text-red' colSpan={2}><span className='custom-two-col'><em>المجمع</em></span></th>
                        <th className='text-red' colSpan={2}><span className='custom-two-col'><em>الرصيد</em></span></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th className={'prevent-print'}>
                            <label className="checkboxs">
                                <input onChange={selectAllItemsHandler}
                                       checked={items_selected.length === items.length && items.length > 0}
                                       type="checkbox" id="select-all"/>
                                <span className="checkmarks"> </span>
                            </label>
                        </th>
                        {
                            headers.length > 0 && headers.map(function (header, index) {
                                return (
                                    <th key={index}
                                        className={!header?.printable ? 'prevent-print' : ''}>
                                        {header?.label}
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {
                        items.map(function (item) {
                            return (
                                <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'}
                                    key={item.id}>
                                    <td className="prevent-print">
                                        <TdSelectItemComponent is_exportable={false} item={item}/>
                                    </td>
                                    <td>{(item?.supplier_account_number ?? "----")}</td>
                                    <td>{(item?.supplier_name ?? "----")}</td>

                                    <td className='w-75px'>{item?.combined_debit_amount}</td>
                                    <td className='w-75px'>{item?.combined_credit_amount}</td>

                                    <td className='w-75px'>{item?.balanced_debit_amount}</td>
                                    <td className='w-75px'>{item?.balanced_credit_amount}</td>

                                    <td className="prevent-print">
                                        <div className="flex-btn-table">
                                            <Link
                                                to={prefixRouterPath((prefix ? prefix + '/' : '') + `show-supplier-account-details/${item?.supplier_account_number}`)}
                                                className="btn-info"><i className="bi bi-eye"> </i></Link>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>

                    {/*<tr>*/}
                    {/*    <td colSpan={4}><span className='tfoot-span'>المجموع</span></td>*/}
                    {/*    <td><span className='tfoot-span'>{totalBalance}</span></td>*/}
                    {/*    <td></td>*/}
                    {/*</tr>*/}

                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};