import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import {ResetAllInitialValues, SetInvoiceType, SetIsReturnInvoice} from "../../../store/invoices/InvoiceOperations";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const submitForm = useRef();
    const navigate = useNavigate();
    const prefix = 'accounts-balances';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'exchange_bonds';
    const module_endpoint = 'exchange_bonds';
    const module_name = 'سند الصرف';
    const slug = 'اضافة سند صرف جديد';

    const currency = useRtkState('Settings').settings?.currency;
    const [purchase_id, setPurchaseId] = useState(null);
    const [sale_id, setSaleId] = useState(null);
    const [bond_type, setExchangeBondsType] = useState("1");
    const [safes, setSafes] = useState([]);
    const [expense_categories, setExpenseCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [date, setDate] = useState(new Date().toJSON().slice(0, 10));
    const [purchases, setPurchases] = useState([]);
    const [purchase, setPurchase] = useState(null);
    const [sales, setSales] = useState([]);
    const [sale, setSale] = useState(null);
    const [supplier_account_transactions, setSupplierAccountTransactions] = useState([]);
    const [customer_account_transactions, setCustomerAccountTransactions] = useState([]);
    const [supplier_balance, setSupplierBalance] = useState(0);
    const [rest_purchase_amount, setRestPurchaseAmount] = useState(0);
    const [rest_sale_amount, setRestSaleAmount] = useState(0);
    const [supplier_balance_type, setSupplierBalanceType] = useState("");
    const [operation_type, setOperationType] = useState(1);
    const [expenses_accounts, setExpensesAccounts] = useState([]);

    const formik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: Yup.object({
            // amount: Yup.number().required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                // navigate(prefixRouterPath(route_endpoint));
                if (purchase_id) {
                    SupplierBalanceAccountTransactionsAxios(purchase_id)
                }
                successSweetAlert();
                setErrors({});
                setIsSubmitLoading(false);
                document.getElementById("SubmitFormID").reset();
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    const ChangeInitialDateHandler = (event) => {
        let value = event?.target?.value;
        setDate(value);
    };

    useEffect(() => {
        AxiosInstance.get('safes?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSafes(data.data);
        }).catch(function ({response}) {
            //
        });
        AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSuppliers(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('customers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setCustomers(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('exchange_bonds/get-expenses-accounts', {}).then(async function ({data}) {
            await setExpensesAccounts(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    const SupplierBalanceAccountTransactionsAxios = (purchase_id) => {

        setPurchaseId(purchase_id);

        if (purchase_id) {
            AxiosInstance.get(`exchange_bonds/get-supplier-balance-account-transactions?purchase_id=${purchase_id}`, {}).then(async function ({data}) {
                await setSupplierAccountTransactions(data?.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(`accounts-balances/total-balances-for-suppliers?purchase_id=${purchase_id}`, {}).then(async function ({data}) {
                let transactions_amount_for_purchase = data?.data?.transactions_amount_for_purchase;
                await setRestPurchaseAmount(transactions_amount_for_purchase);
            }).catch(function ({response}) {
                //
            });
        }
    }

    const CustomerSaleInvoiceDetailsTransactionsAxios = (sale_id) => {
        setSaleId(sale_id);

        if (sale_id) {
            AxiosInstance.get(`receipt_vouchers/get-customer-balance-account-transactions?sale_id=${sale_id}`, {}).then(async function ({data}) {
                await setCustomerAccountTransactions(data?.data);
                console.log({customer_account_transactions});
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(`accounts-balances/total-balances-for-customers?sale_id=${sale_id}`, {}).then(async function ({data}) {
                let transactions_amount_for_sale = data?.data?.transactions_amount_for_sale;
                await setRestSaleAmount(transactions_amount_for_sale);
            }).catch(function ({response}) {
                //
            });
        }
    }

    const handleOnFormChange = (event) => {
        if (event?.target?.name === 'supplier_id') {
            let supplier_id = event?.target?.value;
            setPurchase(null);
            AxiosInstance.get(`purchases?sort_type=asc&sort_attribute=id&supplier_id=${supplier_id}`, {}).then(async function ({data}) {
                let purchases = data.data;
                if (purchases.length > 0) {
                    await setPurchases(data.data);
                } else {
                    setPurchases([]);
                }
            }).catch(function ({response}) {
                //
            });
        }

        if (event?.target?.name === 'purchase_id') {
            let purchase_id = event?.target?.value;
            setPurchase(null);
            if (purchase_id) {
                purchases.forEach(function (purchase) {
                    if (purchase.id == purchase_id) {
                        setPurchase(purchase);
                        return false;
                    }
                });
                SupplierBalanceAccountTransactionsAxios(purchase_id);
            } else {
                setPurchase(null);
            }
        }

        if (event?.target?.name === 'customer_id') {
            let customer_id = event?.target?.value;
            setSale(null);
            AxiosInstance.get(`sales?sort_type=asc&sort_attribute=id&customer_id=${customer_id}`, {}).then(async function ({data}) {
                let sales = data.data;
                if (sales.length > 0) {
                    console.log({data})
                    await setSales(data.data);
                } else {
                    setSales([]);
                }
            }).catch(function ({response}) {
                //
            });
        }

        if (event?.target?.name === 'sale_id') {
            let sale_id = event?.target?.value;
            setSale(null);
            if (sale_id) {
                sales.forEach(function (sale) {
                    if (sale.id == sale_id) {
                        setSale(sale);
                        return false;
                    }
                });
                CustomerSaleInvoiceDetailsTransactionsAxios(sale_id);
            } else {
                setSale(null);
            }
        }

        if (event?.target?.name === 'operation_type') {
            let operation_type = event?.target?.value;
            setOperationType(operation_type);
        }

    };

    const CleanUp = (e) => {
        setPurchases([]);
        setPurchase(null);
        setSale(null);
        setSales([]);
        setRestPurchaseAmount(0);
        setRestSaleAmount(0);
        setExchangeBondsType("1");
    }

    const ExchangeBondsTypeAction = (e) => {
        CleanUp();
        setExchangeBondsType(e?.target?.value)
    }

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeInitialDateHandler,
        date,
        currency,
        bond_type,
        ExchangeBondsTypeAction,
        safes,
        expense_categories,
        suppliers,
        customers,
        sales,
        sale,
        handleOnFormChange,
        purchases,
        purchase,
        supplier_account_transactions,
        customer_account_transactions,
        supplier_balance,
        supplier_balance_type,
        expenses_accounts,
        rest_purchase_amount,
        rest_sale_amount,
        operation_type,
    };
}