import React from 'react';
import {useRtkState} from "../../../config/helper";

const InvoiceItemsTableComponent = ({items}) => {

    const settings = useRtkState("Settings")?.settings;

    return (
        <>
            {
                (items?.length > 0)
                &&
                <table className="table-prd">
                    <thead>
                    <tr>
                        <th>اسم الصنف</th>
                        <th>السعر</th>
                        <th>الكمية</th>
                        <th>الخصم</th>
                        <th>الضريبة</th>
                        <th>الاجمالي ({settings?.currency})</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(function (item) {
                            return (
                                <tr>
                                    <td>{item?.product?.name}</td>
                                    <td>{item?.product_price}</td>
                                    <td>
                                        <p>{item?.quantity} <small>{item?.purchase_unit?.name}</small></p>
                                    </td>
                                    <td>
                                        {
                                            item?.discount_type === 1 ?
                                                (<p>{item?.discount_value}<small>%</small></p>)
                                                :
                                                (<p>{item?.discount_amount} {settings?.currency}</p>)
                                        }
                                    </td>
                                    <td>
                                        {
                                            item?.tax_type === 1 ?
                                                (<p>{item?.tax_value}<small>%</small></p>)
                                                :
                                                (<p>{item?.tax_amount}</p>)
                                        }
                                    </td>
                                    <td>{item?.sub_total}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            }

        </>
    );
};

export default InvoiceItemsTableComponent;