import {getBearToken} from "./helper";
import {APP_URL} from "./constants";

export default (endpoint, params = {}) => {

    const queryString = new URLSearchParams(params).toString();
    let operator = endpoint.includes("?") ? "&" : "?";
    const url = APP_URL + endpoint + operator + queryString;

    console.log(url);

    return fetch(
        url,
        {
            method: 'GET',
            headers: {
                'Authorization': getBearToken(),
                'Content-Type': 'application/json'
            }
        });
};