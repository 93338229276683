import React, {useEffect, useRef, useState} from 'react';
import CustomTooltip from "../../../../shared/material-ui/CustomTooltip";
import AxiosInstance from "../../../../config/AxiosInstance";
import {useRtkState, warningSweetAlert} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams, ChangeIsShowEndedProductsList,
    ConvertFiltrationTypeAction, fetchAllProducts,
    UpdateFiltrationListAction
} from "../../../../store/pos/Pos";
import ShowProductsListComponent from './ShowProductsListComponent';

const SearchComponent = () => {
  const [inputValue, setInputValue] = useState('');
  const [isActive, setIsActive] = useState(false);
  const handleInputDivClick = () => {
    setIsActive(true);
  };
  const handleCloseSearchClick = () => {
    setIsActive(false);
    SearchKeyRef.current.value = "";
  };


    const [isLoading, setIsLoading] = useState(false);

    const posState = useRtkState('Pos');
    const dispatch = useDispatch();

    /** search */
    const [SearchKey, setSearchKey] = useState('');
    const SearchKeyRef = useRef();
    useEffect(() => {
        const delayTimer = setTimeout(() => {
            if (SearchKey.length <= 0) {
                dispatch(ChangeIsShowEndedProductsList(posState?.previous_is_show_ended_products_list, {}));
            } else {
                dispatch(ChangeIsShowEndedProductsList(true, {}));
            }
            dispatch(appendFilterParams({...posState?.params, search_key: SearchKey}, {}));
            dispatch(fetchAllProducts());
        }, 500); // Wait for 500 ms of inactivity before triggering search
        return () => clearTimeout(delayTimer);
    }, [SearchKey]);

    const SearchKeyAction = (event) => {
        const search_val = event?.target?.value;
        setSearchKey(search_val);
        setInputValue(search_val);
    }
    /** search */


    return (
        <div className={`search-scan-product ${isActive ? 'active' : ''}`}>
          <i className="bi bi-x close-search" onClick={handleCloseSearchClick}></i>
            <div className="input-div" onClick={handleInputDivClick}>
                <input ref={SearchKeyRef} onKeyUp={SearchKeyAction} type="text" className="form-control"
                       placeholder="ابحث عن الاصناف ..."/>
                <button className="search-btn">
                    <i className="bi bi-search"> </i>
                </button>
            </div>
            {inputValue.trim() !== '' && (
            <ShowProductsListComponent/>
            )}
        </div>
    );
};

export default SearchComponent;