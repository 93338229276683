import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import permissionsList from "../../../config/permissionsList";
import PageHeader from "../../../components/layout/PageHeader";
import ModulePermission from "../../../components/cruds/roles_permissions/ModulePermission";
import OnePermission from "../../../components/cruds/roles_permissions/OnePermission";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import AssignPermissionToRoleHook from "../../../hooks/cruds/roles_permissions/AssignPermissionToRoleHook";
import LoadingComponent from "../../../components/global/LoadingComponent";

const AssignPermissionToRole = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        permissions,
        ChangePermissionStatus,
        CheckPermissionOnOrOff,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    } = AssignPermissionToRoleHook();

    let count = 0;

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {SYSTEM_TITLE} | {'الادوار والصلاحيات'}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                {isOldItemsLoading ? <LoadingComponent/> : ""}

                <ul className="list-permissions">
                    {
                        permissionsList.length > 0 && (
                            permissionsList.map(function (module_permissions, index) {
                                return (
                                    <ModulePermission key={index} module_name={module_permissions?.menu_name}>
                                        {
                                            module_permissions.sub_menus.length > 0 && (
                                                module_permissions.sub_menus.map(function (permission) {
                                                    return (
                                                        <OnePermission
                                                            key={count++}
                                                            name={`permissions[${count++}]`}
                                                            value={permission?.permission?.id}
                                                            permission_name={permission?.menu_name}
                                                            checked={CheckPermissionOnOrOff(permission?.permission?.name)}
                                                            onChange={e => ChangePermissionStatus(permission?.permission?.name)}/>
                                                    )
                                                })
                                            )
                                        }
                                    </ModulePermission>
                                );
                            })
                        )
                    }
                </ul>

                <div className="card-footer">
                    <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                </div>
            </form>
        </>
    );
};

export default AssignPermissionToRole;