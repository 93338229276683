import React from 'react';
import {excel} from "../../../config/images-path";
import {CSVLink} from "react-csv";
import {trans, useRtkState} from "../../../config/helper";

const ExcelComponent = () => {

    const table = useRtkState('table');

    const headers = table.export_headers;
    const items_selected = table.items_selected;
    const filename = table.endpoint;

    console.log({items_selected});

    return (
        <li>
            <CSVLink headers={headers}  data={items_selected} filename={filename}>
                <span data-bs-toggle="tooltip" data-bs-placement="top" title={trans('DownloadAllSelectedAsExcel')}>
                    <img src={excel} alt="img"/>
                </span>
            </CSVLink>
        </li>
    );
};

export default ExcelComponent;
