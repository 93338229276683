import React, {useRef, useState} from "react";
import CustomTooltip from "../../../../shared/material-ui/CustomTooltip";
import {setCookie} from "../../../../hooks/global/useCookie";
import {useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {ChangeSystemModeAction} from "../../../../store/settings/SettingsSlice";
import {dark_mode, light_mode} from "../../../../config/images-path";

const ThemeModeComponent = () => {
    const settings = useRtkState("Settings");
    const dispatch = useDispatch();

    const ChangeSystemMode = (mode) => {
        dispatch(ChangeSystemModeAction(mode, {}));
    };

    return (
        <>
            <div className="flex-content">

                <div className="toggle-mode">
                    <input type="radio" id="light-mode" name="system-mode" checked={settings?.systemMode === "light"}/>
                    <label htmlFor="light-mode" onClick={(e) => ChangeSystemMode("light")}>
                        <img src={light_mode} alt=""/>
                        <span>الوضع الفاتح</span>
                    </label>
                </div>

                <div className="toggle-mode">
                    <input type="radio" id="dark-mode" name="system-mode" checked={settings?.systemMode === "dark"}/>
                    <label htmlFor="dark-mode" onClick={(e) => ChangeSystemMode("dark")}>
                        <img src={dark_mode} alt=""/>
                        <span>الوضع المظلم</span>
                    </label>
                </div>

            </div>
        </>
    );
};

export default ThemeModeComponent;
