import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";

/* create hook */
import CreateHook from "../../../hooks/cruds/receipt_vouchers/CreateHook";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalInput from "../../../shared/inputs/NormalInput";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";

const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeInitialDateHandler,
        date,
        currency,
        bond_type,
        setExchangeBondsType,
        safes,
        customers,
        sales,
        sale,
        handleOnFormChange,
        customer_account_transactions,
        rest_sale_amount,
        operation_type,
        suppliers,
        purchases,
        rest_purchase_amount,
        supplier_account_transactions,
        purchase,
        SetBondsTypeAction,
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <form id="SubmitFormID" onChange={handleOnFormChange} ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>
                <CardComponent>
                    <div className="row">
                        {
                            (sale && rest_sale_amount == 0 && operation_type == 2) ? "" :
                                <>
                                    <TextInput
                                        type={"number"}
                                        max={sale && rest_sale_amount && operation_type == 1 ? Math.abs(rest_sale_amount) : 10000000}
                                        label={"المبلغ"}
                                        name={"amount"}
                                        formik={formik}
                                        className={"col-lg-3 col-sm-6 col-12"}
                                        error={errors?.["amount"]?.[0] ?? ""}
                                        placeholder={"0"}/>

                                    <SelectInput
                                        label={"الخزنة"}
                                        name={"safe_id"}
                                        formik={formik}
                                        className={"col-lg-3 col-sm-6 col-12"}
                                        error={errors?.["safe_id"]?.[0] ?? ""}>
                                        {safes &&
                                        safes.map(function (safe) {
                                            return (
                                                <optgroup key={safe.id} label={safe.name}>
                                                    {
                                                        safe.accounts.map(function (safe) {
                                                            return (<option key={safe.id} value={safe.id}>{safe.title}</option>);
                                                        })
                                                    }
                                                </optgroup>
                                            );
                                        })}
                                    </SelectInput>
                                </>
                        }

                        <SelectInput
                            label="اختر (مدين او دائن)"
                            name="operation_type"
                            formik={formik}
                            allow_formik_value={true}
                            className={'col-lg-3 col-sm-6 col-12'}
                            error={errors?.['operation_type']?.[0] ?? ''}>
                            <option value="2" selected={operation_type == 2}>مدين</option>
                            <option value="1" selected={operation_type == 1}>دائن</option>
                        </SelectInput>

                        <NormalSelectInput
                            onChangeHandler={SetBondsTypeAction}
                            label="نوع سند القبض"
                            name="bond_type"
                            formik={formik}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["bond_type"]?.[0] ?? ""}>
                            <option value="3">دفعة مقدمة</option>
                            <option value="2">تحصيل مبيعات</option>
                            <option value="4">مرتجع مشتريات</option>
                        </NormalSelectInput>

                        {
                            (bond_type === "2" || bond_type === "3")
                            &&
                            <>
                                <SelectInput
                                    label={"العميل"}
                                    name={"customer_id"}
                                    formik={formik}
                                    className={"col-lg-3 col-sm-6 col-12"}
                                    error={errors?.["customer_id"]?.[0] ?? ""}>
                                    <option selected={true} disabled={true}>
                                        اختر العميل
                                    </option>
                                    {customers &&
                                    customers.map(function (customer) {
                                        return (
                                            <option key={customer.id} value={customer.id}>
                                                {customer.name}
                                            </option>
                                        );
                                    })}
                                </SelectInput>
                            </>
                        }


                        {bond_type === "2" && (
                            <>
                                <SelectInput
                                    label={"فاتورة المبيعات"}
                                    name={"sale_id"}
                                    formik={formik}
                                    className={"col-lg-3 col-sm-6 col-12"}
                                    error={errors?.["sale_id"]?.[0] ?? ""}>
                                    <option selected={true} value={""}>
                                        اختر الفاتورة
                                    </option>
                                    {sales && sales.length > 0 ? (
                                        sales.map(function (sale) {
                                            return (
                                                <option key={sale.id} value={sale.id}>
                                                    {sale.reference_code}
                                                </option>
                                            );
                                        })
                                    ) : (
                                        <option selected={true} disabled={true}>
                                            لايوجد فواتير ,اختر العميل
                                        </option>
                                    )}
                                </SelectInput>
                            </>
                        )}

                        {bond_type === "4" && (
                            <>
                                <SelectInput
                                    label={"المورد"}
                                    name={"supplier_id"}
                                    formik={formik}
                                    className={"col-lg-3 col-sm-6 col-12"}
                                    error={errors?.["supplier_id"]?.[0] ?? ""}>
                                    <option selected={true} disabled={true}>
                                        اختر المورد
                                    </option>
                                    {suppliers &&
                                    suppliers.map(function (supplier) {
                                        return (
                                            <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </option>
                                        );
                                    })}
                                </SelectInput>

                                <SelectInput
                                    label={"فاتورة المشتريات"}
                                    name={"purchase_id"}
                                    formik={formik}
                                    className={"col-lg-3 col-sm-6 col-12"}
                                    error={errors?.["purchase_id"]?.[0] ?? ""}>
                                    <option selected={true} value={""}>
                                        اختر الفاتورة
                                    </option>
                                    {purchases && purchases.length > 0 ? (
                                        purchases.map(function (purchase) {
                                            return (
                                                <option key={purchase.id} value={purchase.id}>
                                                    {purchase.reference_code}
                                                </option>
                                            );
                                        })
                                    ) : (
                                        <option selected={true} disabled={true}>
                                            لايوجد فواتير ,اختر المورد
                                        </option>
                                    )}
                                </SelectInput>
                            </>
                        )}

                        <NormalInput
                            type="date"
                            label={"التاريخ"}
                            name={"date"}
                            value={date}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["date"]?.[0] ?? ""}
                            onChangeHandler={(event) => ChangeInitialDateHandler(event)}/>

                        <NormalTextAreaInput
                            className={"col-12"}
                            name={"reason"}
                            id={"reason"}
                            label={"ملاحظات"}
                            error={errors?.["reason"]?.[0] ?? ""}/>
                    </div>
                    {
                        (sale && rest_sale_amount == 0 && operation_type == 2) ? "" :
                            (<div className="card-footer">
                                <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                                <RestButton className={"btn btn-danger"}/>
                            </div>)
                    }
                </CardComponent>

                {sale && (
                    <>
                        <CardComponent>
                            <div className="table-responsive">
                                <table id="main-table" className="table table-custom">
                                    <thead>
                                    <tr>
                                        <th>تاريخ العملية</th>
                                        <th>مدين</th>
                                        <th>دائن</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (customer_account_transactions && customer_account_transactions.length > 0)
                                        &&
                                        customer_account_transactions.map(function (customer_account_transaction) {
                                            return (
                                                <tr>
                                                    <td>{customer_account_transaction?.date}</td>
                                                    <td>{customer_account_transaction?.combined_debit_amount}</td>
                                                    <td>{customer_account_transaction?.combined_credit_amount}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"flex-total-bonds"}>
                                <h3>اجمالي الفاتورة:<span>{sale?.grand_total} <em>{currency}</em></span></h3>
                                {
                                    (sale && rest_sale_amount == 0) ?
                                        (<h3><span> تم سداد الفاتورة</span></h3>)
                                        :
                                        (<h3>
                                            باقي رصيد الفاتورة :
                                            <span>{Math.abs(rest_sale_amount)}<em>{currency}</em></span>
                                            |
                                            <em> {rest_sale_amount < 0 ? "دائن" : "مدين"} </em>
                                        </h3>)
                                }

                            </div>
                        </CardComponent>
                    </>
                )}

                {purchase && (
                    <>
                        <CardComponent>
                            <div className="table-responsive">
                                <table id="main-table" className="table table-custom">
                                    <thead>
                                    <tr>
                                        <th>تاريخ العملية</th>
                                        <th>مدين</th>
                                        <th>دائن</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (supplier_account_transactions && supplier_account_transactions.length > 0)
                                        &&
                                        supplier_account_transactions.map(function (supplier_account_transaction) {
                                            return (
                                                <tr>
                                                    <td>{supplier_account_transaction?.date}</td>
                                                    <td>{supplier_account_transaction?.combined_debit_amount}</td>
                                                    <td>{supplier_account_transaction?.combined_credit_amount}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"flex-total-bonds"}>
                                <h3>
                                    اجمالي الفاتورة:
                                    <span>{purchase?.grand_total} <em>{currency}</em></span>
                                </h3>

                                {/*{*/}
                                {/*    (purchase && rest_purchase_amount == 0) */}
                                {/*        ? (<h3><span> تم سداد الفاتورة</span></h3>) :*/}
                                {/*        (<h3>باقي رصيد الفاتورة :*/}
                                {/*            <span>{Math.abs(rest_purchase_amount)}<em>{currency}</em></span>*/}
                                {/*            |*/}
                                {/*            <em> {rest_purchase_amount < 0 ? "دائن" : "مدين"} </em>*/}
                                {/*        </h3>)*/}
                                {/*}*/}

                            </div>
                        </CardComponent>
                    </>
                )}

            </form>
        </>
    );
};

export default Create;
