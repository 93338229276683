import React from 'react';
import {trans} from "../../config/helper";
import CircularProgress from "@mui/material/CircularProgress";

const SimpleButton = ({clickAction, className, btn_name, isLoading = false}) => {
    return (
        <button onClick={clickAction} type="button" className={className}>
            {isLoading && <i className="loader-btn"></i>}
            <span>{btn_name}</span>
        </button>
    );
};

export default SimpleButton;
