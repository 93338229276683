import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    successSweetAlert,
    trans, useRtkState,
    warningSweetAlert,
} from "../../../config/helper";
import TreeSettingHook from "./TreeSettingHook";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    const prefix = "/";
    const module_endpoint = "financial-accounts";
    const module_name = "شجرة الحسابات";
    const create_redirect = "";
    const [orgChart, setOrgChart] = useState({});
    const [initialDepth, setInitialDepth] = useState(1);
    const [selectedDepth, setSelectedDepth] = useState(1);
    const [showTreeSide, setShowTreeSide] = useState(false);
    const [allowAddBillOfExchange, setAllowAddBillOfExchange] = useState(false);
    const [nodeSelected, setNodeSelected] = useState(null);
    const [totalBalance, setTotalBalance] = useState(null);
    const [openAccountSmallDetails, setOpenAccountSmallDetails] = useState(false);
    const [account_type, setAccount_type] = useState(null);

    const {
        translation,
        zoom,
        activeTab,
        handleTabClick,
        setActiveTab,
    } = TreeSettingHook();

    const removeNodeClassActive = () => {
        const tabs = document.querySelectorAll(".rd3t-leaf-node, .rd3t-node");
        tabs.forEach((tab) => {
            tab.addEventListener("click", () => {
                tabs.forEach((otherTab) => {
                    otherTab.classList.remove("active");
                });

                tab.classList.add("active");
            });
        });
    };

    const renderTreeData = () => {
        AxiosInstance.get(module_endpoint, {})
            .then(async function (data) {
                setOrgChart(data?.data?.data ?? {});
            })
            .catch(function ({response}) {
                //
            });
    };


    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "financial_accounts");
        if (permissions.length > 0 && permissions.includes("financial_accounts")) {

            renderTreeData();

        }
    }, [permissions]);


    const openBondSideBar = (type) => {
        setAccount_type(type);
        setShowTreeSide(true);
        setOpenAccountSmallDetails(false);
    };

    const OpenTreeSideAction = (type) => {
        setActiveTab(type);
        setAccount_type(nodeSelected?.attributes?.account_type)
        setShowTreeSide(true);
    };

    function roundToDecimal(number, decimalPlaces) {
        const multiplier = 10 ** decimalPlaces;
        return Math.round(number * multiplier) / multiplier;
    }

    const OnclickNodeAction = (e) => {
        setOpenAccountSmallDetails(true);
        setActiveTab("document");
        let node = e?.data;
        setNodeSelected(node);
        setAccount_type(node?.attributes?.account_type)

        let combined_debit_amount = node?.attributes?.DebitAmount;
        let combined_credit_amount = node?.attributes?.CreditAmount;
        let balanced_debit_amount = node?.attributes?.BalancedDebitAmount;
        let balanced_credit_amount = node?.attributes?.BalancedCreditAmount;

        setTotalBalance({
            combined_debit_amount: combined_debit_amount,
            combined_credit_amount: combined_credit_amount,
            balanced_debit_amount: balanced_debit_amount,
            balanced_credit_amount: balanced_credit_amount
        });

        let childrens = node?.children;
        if (childrens?.length < 1) {
            setAllowAddBillOfExchange(true);
        } else {
            setAllowAddBillOfExchange(false);
        }

        removeNodeClassActive();
        let g_id = e?.data?.__rd3t?.id;
        document.getElementById(g_id).classList.add("active");
        let depth = e?.data?.__rd3t?.depth;
        setInitialDepth(depth + 1);
        setSelectedDepth(depth);
    };

    const CloseTreeSideAction = () => {
        setOpenAccountSmallDetails(true);
        setShowTreeSide(false);
        setOpenAccountSmallDetails(false);
        // ResetStoreNewNodeForm();
    };

    // ==============


    /* Http requests */

    /**
     * Add or Create New Node
     */
    const StoreNewNodeFormikURLEndpoint = "create-financial-account";
    const [isStoreNewNodeFormSubmitLoading, setIsStoreNewNodeFormSubmitLoading] =
        useState(false);
    const [storeNewNodeErrors, setStoreNewNodeErrors] = useState({});
    const StoreNewNodeRef = useRef();

    const ResetStoreNewNodeForm = () => {
        setStoreNewNodeErrors({});
        setIsStoreNewNodeFormSubmitLoading(false);
        document.getElementById("StoreNewNodeFormikID").reset();
    };

    const StoreNewNodeFormik = useFormik({
        initialValues: {
            title: Yup.string()
                .min(2, trans("This_field_must_be_at_least_2_characters"))
                .max(50, trans("The_name_must_not_be_greater_than_50_characters"))
                .required(trans("This_field_is_required")),
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsStoreNewNodeFormSubmitLoading(true);
            const formData = new FormData(StoreNewNodeRef.current);
            const parent_id = nodeSelected?.attributes?.id;
            formData.append("parent_id", parent_id);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(StoreNewNodeFormikURLEndpoint, formData)
                .then(async function ({data}) {
                    successSweetAlert();
                    ResetStoreNewNodeForm();
                    renderTreeData();
                    CloseTreeSideAction();
                })
                .catch(function ({response}) {
                    setStoreNewNodeErrors(response.data.data);
                    setIsStoreNewNodeFormSubmitLoading(false);
                    warningSweetAlert("من فضلك تاكد من صحة البيانات");
                });
        },
        /* https://www.npmjs.com/package/yup this is yup validate */
    });

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        orgChart,
        initialDepth,
        OnclickNodeAction,
        showTreeSide,
        OpenTreeSideAction,
        CloseTreeSideAction,
        translation,
        zoom,
        activeTab,
        handleTabClick,
        allowAddBillOfExchange,
        nodeSelected,
        totalBalance,
        openAccountSmallDetails,

        StoreNewNodeFormik,
        ResetStoreNewNodeForm,
        isStoreNewNodeFormSubmitLoading,
        storeNewNodeErrors,
        StoreNewNodeRef,
        selectedDepth,
        openBondSideBar,
        account_type,
    };
};
