import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";

import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {setLocale} from 'yup';
import {
    fetchProducts,
    ResetAllInitialValues,
    AppendParamsValues,
    SetInvoiceType, ShowInvoiceWithProducts, SetIsReturnInvoice,
} from "../../../store/invoices/InvoiceOperations";

import {useDispatch} from "react-redux";
import {PRODUCT_PER_PAGE} from "../../../config/constants";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {

    const prefix = 'purchases-module';
    const module_endpoint = 'return_purchases';
    const module_name = ' مرتجعات المشتريات';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'return_purchases';
    const slug = 'اضافة مرتجع مشتريات جديدة';

    const dispatch = useDispatch();

    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "return_purchases");

        if (permissions.length > 0 && permissions.includes("return_purchases")) {
            dispatch(SetInvoiceType('purchase', {}));
            dispatch(SetIsReturnInvoice(true, {}));

            AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setWarehouses(data.data);
            }).catch(function ({response}) {
                //
            });
            AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setSuppliers(data.data);
            }).catch(function ({response}) {
                //
            });
            return () => {
                dispatch(ResetAllInitialValues({}, {}));
            }
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {}, /* it will be server side validate */
        validationSchema: Yup.object({}), /* it will be server side validate */

        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
                dispatch(ResetAllInitialValues({}, {}))
            }).catch(function ({response}) {
                const errors = response.data.data;
                setErrors(errors);
                setIsSubmitLoading(false);

                if (errors && typeof errors == "string") {
                    warningSweetAlert(errors)
                }
            });
        },

        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    /* start product list operations */
    const InvoiceOperations = useRtkState('InvoiceOperations');

    const invoice_products = InvoiceOperations.invoice_products;

    const search_loading = useRtkState('InvoiceOperations').search_loading;

    /* start product list operations */

    const reference_code_ref = useRef();
    const [is_reference_code_loading, setIsReferenceCodeLoading] = useState(false);

    const SearchInvoiceReferenceCodeHandler = () => {
        const reference_code = reference_code_ref?.current?.value;
        setIsReferenceCodeLoading(true);
        AxiosInstance.get(`return_purchases/show_by_reference_code/${reference_code}`, {}).then(async function ({data}) {
            let old_item = data.data;

            /* convert quantity --> 1x */
            // let purchase_items = old_item?.purchase_items;
            // purchase_items.forEach(function (purchase_item, index) {
            //     purchase_item['quantity'] = 1;
            //     purchase_item['sub_total'] = purchase_item['product_cost'];
            //     purchase_item[index] = purchase_item;
            // })
            // old_item['purchase_items'] = purchase_items;
            /* convert quantity --> 1x */

            await dispatch(ShowInvoiceWithProducts({old_item}, {}));
            setIsReferenceCodeLoading(false);
        }).catch(function (response) {
            if (response?.response?.status === 404) {
                warningSweetAlert(trans("This Invoice Doesn't exist"));
            }
            setIsReferenceCodeLoading(false);
        });
    }


    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        suppliers,

        /* start product list operations */
        search_loading,
        reference_code_ref,
        is_reference_code_loading,
        SearchInvoiceReferenceCodeHandler,
        InvoiceOperations,
        invoice_products,
        /* start product list operations */
    };
}