import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath, preparingCanExportExcel,
    successSweetAlert,
    trans, useRtkState
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const prefix = 'settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'work-shifts';
    const module_endpoint = 'work-shifts';
    const module_name = 'الورديات';

    const slug = 'اضافة وردية جديدة';

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    const [users, setUsers] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "work-shifts");
        if (permissions.length > 0 && permissions.includes("work-shifts")) {

            AxiosInstance.get('users?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setUsers(data.data);
            }).catch(function ({response}) {
                //
            });

        }
    }, [permissions]);


    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        users,
    };
}