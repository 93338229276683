import {useEffect, useCallback, useRef, useState} from "react";

const useAutoClose = (submenuHandler) => {

    const menu = useRef('');
    const [isOpen, setIsOpen] = useState(false);

    const handleClosure = useCallback((event) => {
        if (!menu.current.contains(event.target)) {
            setIsOpen(false)
        }
    }, []);

    useEffect(() => {
        window.addEventListener('mousedown', handleClosure)
        return function cleanup() {
            window.removeEventListener("mousedown", handleClosure, false);
        };
    }, []);

    const openHandle = (event) => {
        event.preventDefault();
        setIsOpen(isOpen => !isOpen)
        console.log("submenuHandler", submenuHandler)
        if (submenuHandler) {
            submenuHandler("", false)
        }
    }


    const closeSubmenuHandler = () => {
        setIsOpen(false)
    }


    return {menu, isOpen, openHandle, closeSubmenuHandler};
}

export default useAutoClose;