import {prefixRouterPath, preparingCanExportExcel, useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../../store/invoices/InvoiceOperations";

export default () => {
    /* table */
    const prefix = 'reports';
    const module_endpoint = 'shortage-report';
    const module_name = 'تقارير النواقص';
    const create_redirect = "";

    const headers = [
        {
            label: 'صورة الصنف',
            key: 'image_url',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }, {
            label: 'اسم الصنف',
            key: 'name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'حد التنبية',
            key: 'stock_alert',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'العدد الحالي بالمخزن',
            key: 'warehouse_manage_stock_quantity',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الحالة',
            key: 'status',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const params = table.params;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(SetInvoiceType('storage_reports', {}));
        dispatch(reRenderItems())

        AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setWarehouses(data.data);
        }).catch(function ({response}) {
            //
        });
        dispatch(appendFilterParams({...table.params, warehouse_id: null}, {}));
    }, []);


    const OnChangeWarehouse = (event) => {
        dispatch(appendFilterParams({...table.params, warehouse_id: event.target.value}, {}));
        dispatch(reRenderItems())
    }

    return {
        table,
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        warehouses,
        OnChangeWarehouse,
        params,
    };
}