import React, { useRef, useState } from "react";
import CustomTooltip from "../../../../shared/material-ui/CustomTooltip";
import { calculator } from "../../../../config/images-path";
import PopUp from "../../../../shared/material-ui/PopUp";
import Calculator from "./components/Calculator";


const CalculatorComponent = () => {

  const hiddenCalcRef = useRef("");

  const [isPopUpOpened, setIsPopUpOpened] = useState(false);
  const handleClickOpen = () => {
      setIsPopUpOpened(true);
  };

  return (
    <>
      <CustomTooltip title="الآلة الحاسبة">
        <div className="calculator-div" onClick={handleClickOpen}>
          <div className="calc-btn">
            <img src={calculator} alt="" />
          </div>
        </div>
      </CustomTooltip>

      <PopUp other_modal_class="calc-modal" isOpen={isPopUpOpened}  setIsOpen={setIsPopUpOpened}>
          <Calculator/>
      </PopUp>

    </>
  );
};

export default CalculatorComponent;
