import React from 'react';
import {trans} from "../../config/helper";

const SubmitButton = ({className}) => {
    return (
        <button type="reset" className={className}>{trans('reset')}</button>
    );
};

export default SubmitButton;
