import React, {useEffect, useRef, useState} from 'react';
import ReactECharts from "echarts-for-react";
import {useRtkState} from "../../config/helper";
import AxiosInstance from "../../config/AxiosInstance";
import LoadingComponent from "../global/LoadingComponent";
import PieSkeleton from '../../shared/Skeleton/Charts/PieSkeleton';

const MostCustomersBySales = ({mostSuppliersByPurchases, isLoading = true}) => {

    const settings = useRtkState("Settings");
    let chartColor = settings?.systemMode === "dark" ? "#caccdb" : "#212b36";


    const chartRef = useRef(null);
    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.getEchartsInstance) {
                chartRef.current.getEchartsInstance().resize();
            }
        };
        const resizeTimeout = setTimeout(resizeChart, 100);
        window.addEventListener("resize", resizeChart);
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", resizeChart);
        };
    }, []);

    const pei = {
        grid: {
            top: "3%",
            left: "1%",
            right: "1%",
            bottom: "3%",
        },
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontFamily: "regular",
            },
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                itemStyle: {
                    normal: {
                        borderRadius: 10,
                        label: {
                            fontSize: 14,
                            color: chartColor,
                            formatter: function (params) {
                                return params.value + '\n'
                            },
                        }
                    },
                },
                data: mostSuppliersByPurchases
            }
        ]
    };

    return (
        <div className="position-relative">
            <h3 className="title-chart">
                <i className="bi bi-pie-chart"> </i>
                أكثر الموردين من حيث المشتريات
            </h3>
            {
                isLoading
                    ?
                    (<PieSkeleton/>)
                    :
                    <ReactECharts className="h-custom" ref={chartRef} option={pei}> </ReactECharts>
            }
        </div>
    );
};

export default MostCustomersBySales;