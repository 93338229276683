import React from 'react';
import PageHeader from '../../../components/layout/PageHeader';
import CardComponent from '../../../components/cards/CardComponent';
import {Link} from "react-router-dom";
import {prefixRouterPath} from "../../../config/helper";
import reports_sub_menus from "../../../config/reports_sub_menus";
import MostSalesAndPurchasesDuringWeek from "../../../components/Statistics/MostSalesAndPurchasesDuringWeek";
import MostProductsDuringNumberOfSales from "../../../components/Statistics/MostProductsDuringNumberOfSales";
import MostCustomersBySales from "../../../components/Statistics/MostCustomersBySales";
import MostSuppliersByPurchases from "../../../components/Statistics/MostSuppliersByPurchases";
import MostMonthProductSalesDuringTwelveMonth
    from "../../../components/Statistics/MostMonthProductSalesDuringTwelveMonth";
import SystemStatistic from "../../../components/HomePage/SystemStatistic";

const Reports = () => {

    const module_name = "التقارير";

    const reports = [...reports_sub_menus()];

    return (
        <>
            <PageHeader title={module_name}/>

            <CardComponent>
                <ul className="reports">
                    {
                        (reports && reports.length > 0)
                        &&
                        reports.map(function (report) {
                            return (
                                (report?.route !== 'index')
                                &&
                                (<li>
                                    <Link to={report?.route}>
                                        <i className="bi bi-file-earmark-text">
                                        </i>
                                        {report?.menu_name}
                                    </Link>
                                </li>)
                            )
                        })
                    }
                </ul>
            </CardComponent>


            <SystemStatistic/>
        </>
    );

}

export default Reports;
