import React from 'react';
import DefaultLayout from "../../../layouts/DefaultLayout";
import {prefixRouterPath} from "../../../config/helper";

import Brands from "../../../pages/cruds/brands/Index";
import CreateBrand from "../../../pages/cruds/brands/Create";
import EditBrand from "../../../pages/cruds/brands/Edit";

import Warehouses from "../../../pages/cruds/warehouses/Index";
import CreateWarehouses from "../../../pages/cruds/warehouses/Create";
import EditWarehouses from "../../../pages/cruds/warehouses/Edit";

import Base_units from "../../../pages/cruds/base_units/Index";
import CreateBase_units from "../../../pages/cruds/base_units/Create";
import EditBase_units from "../../../pages/cruds/base_units/Edit";

import Units from "../../../pages/cruds/units/Index";
import CreateUnits from "../../../pages/cruds/units/Create";
import EditUnits from "../../../pages/cruds/units/Edit";

import Currencies from "../../../pages/cruds/currencies/Index";
import CreateCurrencies from "../../../pages/cruds/currencies/Create";
import EditCurrencies from "../../../pages/cruds/currencies/Edit";

import ProductCategories from "../../../pages/cruds/product_categories/Index";
import CreateProductCategories from "../../../pages/cruds/product_categories/Create";
import EditProductCategories from "../../../pages/cruds/product_categories/Edit";

import Suppliers from "../../../pages/cruds/suppliers/Index";
import CreateSuppliers from "../../../pages/cruds/suppliers/Create";
import EditSuppliers from "../../../pages/cruds/suppliers/Edit";

import Customers from "../../../pages/cruds/customers/Index";
import CreateCustomers from "../../../pages/cruds/customers/Create";
import EditCustomers from "../../../pages/cruds/customers/Edit";

import Products from "../../../pages/cruds/products/Index";
import CreateProducts from "../../../pages/cruds/products/Create";
import EditProducts from "../../../pages/cruds/products/Edit";
import ShowProduct from "../../../pages/cruds/products/Show";
import ProductPricesList from '../../../pages/cruds/products/ProductPricesList';
import PrintBarcode from "../../../pages/cruds/products/PrintBarcode";

import Users from "../../../pages/cruds/users/Index";
import CreateUsers from "../../../pages/cruds/users/Create";
import EditUsers from "../../../pages/cruds/users/Edit";

import Purchases from "../../../pages/cruds/purchases/Index";
import CreatePurchases from "../../../pages/cruds/purchases/Create";
import EditPurchases from "../../../pages/cruds/purchases/Edit";
import ShowPurchases from "../../../pages/cruds/purchases/Show";

import ReturnPurchases from "../../../pages/cruds/return_purchases/Index";
import CreateReturnPurchases from "../../../pages/cruds/return_purchases/Create";
import EditReturnPurchases from "../../../pages/cruds/return_purchases/Edit";
import ShowReturnPurchases from "../../../pages/cruds/return_purchases/Show";

import Sales from "../../../pages/cruds/sales/Index";
import CreateSales from "../../../pages/cruds/sales/Create";
import EditSales from "../../../pages/cruds/sales/Edit";
import ShowSales from "../../../pages/cruds/sales/Show";

import ReturnSales from "../../../pages/cruds/return_sales/Index";
import CreateReturnSales from "../../../pages/cruds/return_sales/Create";
import EditReturnSales from "../../../pages/cruds/return_sales/Edit";
import ShowReturnSales from "../../../pages/cruds/return_sales/Show";

import Expense_categories from "../../../pages/cruds/expense_categories/Index";
import CreateExpense_categories from "../../../pages/cruds/expense_categories/Create";
import EditExpense_categories from "../../../pages/cruds/expense_categories/Edit";

import Expenses from "../../../pages/cruds/expenses/Index";
import CreateExpenses from "../../../pages/cruds/expenses/Create";
import EditExpenses from "../../../pages/cruds/expenses/Edit";

import RolesPermissions from '../../../pages/cruds/roles_permissions/Index';
import CreateRolesPermissions from '../../../pages/cruds/roles_permissions/Create';
import EditRolesPermissions from '../../../pages/cruds/roles_permissions/Edit';
import AssignPermissionToRole from "../../../pages/cruds/roles_permissions/AssignPermissionToRole";
import ImportProducts from "../../../pages/cruds/products/ImportProducts";

import Safes from "../../../pages/cruds/safes/Index";
import CreateSafes from "../../../pages/cruds/safes/Create";
import EditSafes from "../../../pages/cruds/safes/Edit";

import PaymentTypes from "../../../pages/cruds/payment_types/Index";
import CreatePaymentTypes from "../../../pages/cruds/payment_types/Create";
import EditPaymentTypes from "../../../pages/cruds/payment_types/Edit";

import TransferStocks from "../../../pages/cruds/transfer_stocks/Index";
import CreateTransferStocks from "../../../pages/cruds/transfer_stocks/Create";
import ShowTransferStocks from "../../../pages/cruds/transfer_stocks/Show";

export default [
    {
        path: prefixRouterPath('roles'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* roles & permissions */
            {
                path: 'roles-permissions', /* replace "path" with "system/path" */
                element: <RolesPermissions/>, /* render instead of <Outlet/> */
            }, {
                path: 'roles-permissions/create', /* replace "path" with "system/path" */
                element: <CreateRolesPermissions/>, /* render instead of <Outlet/> */
            }, {
                path: 'roles-permissions/edit/:slug', /* replace "path" with "system/path" */
                element: <EditRolesPermissions/>, /* render instead of <Outlet/> */
            }, {
                path: 'assign-permissions-to-role/:slug', /* replace "path" with "system/path" */
                element: <AssignPermissionToRole/>, /* render instead of <Outlet/> */
            },
        ]
    },
    {
        path: prefixRouterPath('admins'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* admins */
            {
                path: 'admins', /* replace "path" with "system/path" */
                element: <Users/>, /* render instead of <Outlet/> */
            }, {
                path: 'admins/create', /* replace "path" with "system/path" */
                element: <CreateUsers/>, /* render instead of <Outlet/> */
            }, {
                path: 'admins/edit/:slug', /* replace "path" with "system/path" */
                element: <EditUsers/>, /* render instead of <Outlet/> */
            },
        ]
    },


    {
        path: prefixRouterPath('product-settings'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* warehouses */
            {
                path: 'warehouses', /* replace "path" with "system/path" */
                element: <Warehouses/>, /* render instead of <Outlet/> */
            }, {
                path: 'warehouses/create', /* replace "path" with "system/path" */
                element: <CreateWarehouses/>, /* render instead of <Outlet/> */
            }, {
                path: 'warehouses/edit/:slug', /* replace "path" with "system/path" */
                element: <EditWarehouses/>, /* render instead of <Outlet/> */
            },

            /* safes */
            {
                path: 'safes', /* replace "path" with "system/path" */
                element: <Safes/>, /* render instead of <Outlet/> */
            }, {
                path: 'safes/create', /* replace "path" with "system/path" */
                element: <CreateSafes/>, /* render instead of <Outlet/> */
            }, {
                path: 'safes/edit/:slug', /* replace "path" with "system/path" */
                element: <EditSafes/>, /* render instead of <Outlet/> */
            },

            /* base_units */
            {
                path: 'base_units', /* replace "path" with "system/path" */
                element: <Base_units/>, /* render instead of <Outlet/> */
            }, {
                path: 'base_units/create', /* replace "path" with "system/path" */
                element: <CreateBase_units/>, /* render instead of <Outlet/> */
            }, {
                path: 'base_units/edit/:slug', /* replace "path" with "system/path" */
                element: <EditBase_units/>, /* render instead of <Outlet/> */
            },

            /* units */
            {
                path: 'units', /* replace "path" with "system/path" */
                element: <Units/>, /* render instead of <Outlet/> */
            }, {
                path: 'units/create', /* replace "path" with "system/path" */
                element: <CreateUnits/>, /* render instead of <Outlet/> */
            }, {
                path: 'units/edit/:slug', /* replace "path" with "system/path" */
                element: <EditUnits/>, /* render instead of <Outlet/> */
            },

            /* currencies */
            {
                path: 'currencies', /* replace "path" with "system/path" */
                element: <Currencies/>, /* render instead of <Outlet/> */
            }, {
                path: 'currencies/create', /* replace "path" with "system/path" */
                element: <CreateCurrencies/>, /* render instead of <Outlet/> */
            }, {
                path: 'currencies/edit/:slug', /* replace "path" with "system/path" */
                element: <EditCurrencies/>, /* render instead of <Outlet/> */
            },

            /* brands */
            {
                path: 'brands', /* replace "path" with "system/path" */
                element: <Brands/>, /* render instead of <Outlet/> */
            }, {
                path: 'brands/create', /* replace "path" with "system/path" */
                element: <CreateBrand/>, /* render instead of <Outlet/> */
            }, {
                path: 'brands/edit/:slug', /* replace "path" with "system/path" */
                element: <EditBrand/>, /* render instead of <Outlet/> */
            },

            /* product_categories */
            {
                path: 'product_categories', /* replace "path" with "system/path" */
                element: <ProductCategories/>, /* render instead of <Outlet/> */
            }, {
                path: 'product_categories/create', /* replace "path" with "system/path" */
                element: <CreateProductCategories/>, /* render instead of <Outlet/> */
            }, {
                path: 'product_categories/edit/:slug', /* replace "path" with "system/path" */
                element: <EditProductCategories/>, /* render instead of <Outlet/> */
            },

            /* payment_types */
            {
                path: 'payment_types', /* replace "path" with "system/path" */
                element: <PaymentTypes/>, /* render instead of <Outlet/> */
            }, {
                path: 'payment_types/create', /* replace "path" with "system/path" */
                element: <CreatePaymentTypes/>, /* render instead of <Outlet/> */
            }, {
                path: 'payment_types/edit/:slug', /* replace "path" with "system/path" */
                element: <EditPaymentTypes/>, /* render instead of <Outlet/> */
            },

            /* products */
            {
                path: 'products', /* replace "path" with "system/path" */
                element: <Products/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/create', /* replace "path" with "system/path" */
                element: <CreateProducts/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/edit/:slug', /* replace "path" with "system/path" */
                element: <EditProducts/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/show/:slug/:name', /* replace "path" with "system/path" */
                element: <ShowProduct/>, /* render instead of <Outlet/> */
            }, {
                path: 'product-prices-list', /* replace "path" with "system/path" */
                element: <ProductPricesList/>, /* render instead of <Outlet/> */
            }, {
                path: 'import-products', /* replace "path" with "system/path" */
                element: <ImportProducts/>, /* render instead of <Outlet/> */
            }
            , {
                path: 'print-barcode', /* replace "path" with "system/path" */
                element: <PrintBarcode/>, /* render instead of <Outlet/> */
            }

        ]
    },

    {
        path: prefixRouterPath('warehouses-products'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* warehouses */
            {
                path: 'warehouses', /* replace "path" with "system/path" */
                element: <Warehouses/>, /* render instead of <Outlet/> */
            }, {
                path: 'warehouses/create', /* replace "path" with "system/path" */
                element: <CreateWarehouses/>, /* render instead of <Outlet/> */
            }, {
                path: 'warehouses/edit/:slug', /* replace "path" with "system/path" */
                element: <EditWarehouses/>, /* render instead of <Outlet/> */
            },

            /* products */
            {
                path: 'products', /* replace "path" with "system/path" */
                element: <Products/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/create', /* replace "path" with "system/path" */
                element: <CreateProducts/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/edit/:slug', /* replace "path" with "system/path" */
                element: <EditProducts/>, /* render instead of <Outlet/> */
            }, {
                path: 'products/show/:slug/:name', /* replace "path" with "system/path" */
                element: <ShowProduct/>, /* render instead of <Outlet/> */
            }, {
                path: 'product-prices-list', /* replace "path" with "system/path" */
                element: <ProductPricesList/>, /* render instead of <Outlet/> */
            }, {
                path: 'import-products', /* replace "path" with "system/path" */
                element: <ImportProducts/>, /* render instead of <Outlet/> */
            }
            , {
                path: 'print-barcode', /* replace "path" with "system/path" */
                element: <PrintBarcode/>, /* render instead of <Outlet/> */
            }

        ]
    },


    {
        path: prefixRouterPath('users'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* suppliers */
            {
                path: 'suppliers', /* replace "path" with "system/path" */
                element: <Suppliers/>, /* render instead of <Outlet/> */
            }, {
                path: 'suppliers/create', /* replace "path" with "system/path" */
                element: <CreateSuppliers/>, /* render instead of <Outlet/> */
            }, {
                path: 'suppliers/edit/:slug', /* replace "path" with "system/path" */
                element: <EditSuppliers/>, /* render instead of <Outlet/> */
            },

            /* customers */
            {
                path: 'customers', /* replace "path" with "system/path" */
                element: <Customers/>, /* render instead of <Outlet/> */
            }, {
                path: 'customers/create', /* replace "path" with "system/path" */
                element: <CreateCustomers/>, /* render instead of <Outlet/> */
            }, {
                path: 'customers/edit/:slug', /* replace "path" with "system/path" */
                element: <EditCustomers/>, /* render instead of <Outlet/> */
            },

        ]
    },
    {
        path: prefixRouterPath('purchases-module'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* purchases */
            {
                path: 'purchases', /* replace "path" with "system/path" */
                element: <Purchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'purchases/create', /* replace "path" with "system/path" */
                element: <CreatePurchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'purchases/edit/:slug', /* replace "path" with "system/path" */
                element: <EditPurchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'purchases/show/:slug', /* replace "path" with "system/path" */
                element: <ShowPurchases/>, /* render instead of <Outlet/> */
            },

            /* return_purchases */
            {
                path: 'return_purchases', /* replace "path" with "system/path" */
                element: <ReturnPurchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_purchases/create', /* replace "path" with "system/path" */
                element: <CreateReturnPurchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_purchases/edit/:slug', /* replace "path" with "system/path" */
                element: <EditReturnPurchases/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_purchases/show/:slug', /* replace "path" with "system/path" */
                element: <ShowReturnPurchases/>, /* render instead of <Outlet/> */
            },
        ]
    },
    {
        path: prefixRouterPath('sales-module'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* sales */
            {
                path: 'sales', /* replace "path" with "system/path" */
                element: <Sales/>, /* render instead of <Outlet/> */
            }, {
                path: 'sales/create', /* replace "path" with "system/path" */
                element: <CreateSales/>, /* render instead of <Outlet/> */
            }, {
                path: 'sales/edit/:slug', /* replace "path" with "system/path" */
                element: <EditSales/>, /* render instead of <Outlet/> */
            },
            {
                path: 'sales/show/:slug', /* replace "path" with "system/path" */
                element: <ShowSales/>, /* render instead of <Outlet/> */
            },

            /* return_sales */
            {
                path: 'return_sales', /* replace "path" with "system/path" */
                element: <ReturnSales/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_sales/create', /* replace "path" with "system/path" */
                element: <CreateReturnSales/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_sales/edit/:slug', /* replace "path" with "system/path" */
                element: <EditReturnSales/>, /* render instead of <Outlet/> */
            }, {
                path: 'return_sales/show/:slug', /* replace "path" with "system/path" */
                element: <ShowReturnSales/>, /* render instead of <Outlet/> */
            },

        ]
    },
    {
        path: prefixRouterPath('expenses-module'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [

            /* expense_categories */
            {
                path: 'expense_categories', /* replace "path" with "system/path" */
                element: <Expense_categories/>, /* render instead of <Outlet/> */
            },
            {
                path: 'expense_categories/create', /* replace "path" with "system/path" */
                element: <CreateExpense_categories/>, /* render instead of <Outlet/> */
            },
            {
                path: 'expense_categories/edit/:slug', /* replace "path" with "system/path" */
                element: <EditExpense_categories/>, /* render instead of <Outlet/> */
            },

            /* expenses */
            {
                path: 'expenses', /* replace "path" with "system/path" */
                element: <Expenses/>, /* render instead of <Outlet/> */
            },
            {
                path: 'expenses/create', /* replace "path" with "system/path" */
                element: <CreateExpenses/>, /* render instead of <Outlet/> */
            },
            {
                path: 'expenses/edit/:slug', /* replace "path" with "system/path" */
                element: <EditExpenses/>, /* render instead of <Outlet/> */
            },

        ]
    },
    {
        path: prefixRouterPath('stocks'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* stocks */
            {
                path: 'transfer_stocks', /* replace "path" with "system/path" */
                element: <TransferStocks/>, /* render instead of <Outlet/> */
            },
            {
                path: 'transfer_stocks/create', /* replace "path" with "system/path" */
                element: <CreateTransferStocks/>, /* render instead of <Outlet/> */
            },
            {
                path: 'transfer_stocks/show/:slug', /* replace "path" with "system/path" */
                element: <ShowTransferStocks/>, /* render instead of <Outlet/> */
            },
        ]
    },
];
