import {useRtkState} from "../../../config/helper";

const InvoicePricingDetailComponent = ({invoice, invoice_types, invoice_type, totalPaidAmount}) => {

    const settings = useRtkState("Settings")?.settings;

    return (
        <>
            {
                (invoice?.payments && invoice?.payments.length > 0)
                &&
                (
                    <>
                    <table className="table-prd">
                        <thead>
                        <tr>
                            <th>نوع الدفع</th>
                            <th>
                                مدين ({settings?.currency})
                            </th>
                            <th>
                                دائن ({settings?.currency})
                            </th>
                            <th>التاريخ</th>
                            <th>رقم فاتورة الدفع</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            invoice?.payments.map(function (payment) {
                                return (
                                    <tr>
                                        <td>{payment?.payment_type}</td>
                                        <td>{payment?.debit_amount}</td>
                                        <td>{payment?.credit_amount}</td>
                                        <td>{payment?.date}</td>
                                        <td>{payment?.payment_serial_number}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                        
                    </table>
                    <h4>اجمالي {invoice_types[invoice_type] ?? ""} <span>{invoice?.total_paid_payments} {settings?.currency}</span></h4>
                    </>
                )
            }
        </>
    );
};

export default InvoicePricingDetailComponent;