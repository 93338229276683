import React from 'react';
import {tree_img} from "../../config/images-path";
import TextInput from "../../shared/inputs/TextInput";
import SubmitButton from "../../shared/buttons/SubmitButton";

const AddNewNodeComponent = ({formik, resetForm, isSubmitLoading, errors, StoreNewNodeRef}) => {
    return (
        <>
            <img className="tree-img" src={tree_img} alt=""/>

            <form id="StoreNewNodeFormikID" ref={StoreNewNodeRef} onReset={resetForm} onSubmit={formik.handleSubmit}>
                <div className="row">

                    <TextInput
                        type={"text"}
                        label={"اسم الحساب"}
                        name={"title"}
                        formik={formik}
                        className={"col-12"}
                        error={errors?.["title"]?.[0] ?? ""}
                    />

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                    </div>

                </div>
            </form>
        </>
    );
};

export default AddNewNodeComponent;