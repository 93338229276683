import React from 'react';
import SelectItemComponent from "../SelectItemComponent";
import {selectItem} from "../../../store/table/slices/tableSlice";
import {useDispatch} from "react-redux";

const TdSelectItemComponent = ({item}) => {
    const dispatch = useDispatch();

    const selectItemHandler = (item) => {
        dispatch(selectItem(item, {}));
    }

    return (
        <SelectItemComponent item={item} selectItemHandler={e => selectItemHandler(item)}/>
    );
};

export default TdSelectItemComponent;
