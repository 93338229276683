import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import AxiosInstance from "../../config/AxiosInstance";
import {da} from "yup-locales";
import {getCookie, setCookie} from "../../hooks/global/useCookie";


export const fetchSettings = createAsyncThunk('settings/fetchSettings', async (paylaod, action) => {
    try {
        const {settings} = action.getState()?.settings ?? {};
        if (settings) {
            return settings;
        }
        const response = await AxiosInstance.get(`settings`, {params: {}});
        return response?.data?.data
    } catch (err) {
        // custom error
    }
});

export const fetchAuth = createAsyncThunk('settings/fetchAuth', async (paylaod, action) => {
    try {
        const {auth} = action.getState()?.auth ?? {};
        if (auth) {
            return auth;
        }
        const response = await AxiosInstance.get(`auth/profile`, {params: {}});
        return response?.data?.data
    } catch (err) {
        // custom error
    }
});

const initialState = {
    settings: {},
    auth: {},
    permissions_by_group: {},
    permissions: [],
    systemMode: "dark",
    SideMenuType: "horizontal",
};

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        ChangeSystemModeAction: (state, {type, payload}) => {
            state.systemMode = payload;
            document.getElementById("change-mode").setAttribute("data-theme", payload);
            setCookie('systemMode', payload);
            return state;
        },
        ChangeSideMenuTypeAction: (state, {type, payload}) => {
            state.SideMenuType = payload;
            document.getElementById("change-mode").setAttribute("data-side", payload);
            setCookie('SideMenuType', payload);
            return state;
        },
        SetUserPermissions: (state, {type, payload}) => {
            let permissions_by_group = payload?.permissions_by_group
            let permissions = payload?.permissions

            state.permissions_by_group = permissions_by_group;
            state.permissions = permissions;

            setCookie('permissions_by_group', JSON.stringify(permissions_by_group ?? []));
            setCookie('permissions', JSON.stringify(permissions ?? []));
            // console.log(JSON.parse(getCookie("permissions")))
            return state;
        },
    },
    extraReducers: (builder) => {
        /* settings */
        builder.addCase(fetchSettings.pending, (state, action) => {
            // state.isLoading = true;
        })
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            state.settings = action?.payload ?? {};
            // console.log(state.settings);
        })

        /* auth */
        builder.addCase(fetchAuth.pending, (state, action) => {
            // state.isLoading = true;
        })
        builder.addCase(fetchAuth.fulfilled, (state, action) => {
            state.auth = action?.payload ?? {};
            // console.log(state.settings);
        })
    }
});

export const {ChangeSystemModeAction, ChangeSideMenuTypeAction, SetUserPermissions} = SettingsSlice.actions

export default SettingsSlice.reducer