import React, {useEffect} from 'react';
import {Pagination} from "@mui/material";
import {useDispatch} from "react-redux";
import {useRtkState} from "../../config/helper";
import {appendFilterParams, pageNumber, reRenderItems, selectAll} from "../../store/table/slices/tableSlice";
import EmptyContentComponent from "../global/EmptyContentComponent";
import LoadingComponent from "../global/LoadingComponent";
import TableSkeleton from '../../shared/Skeleton/Tables/TableSkeleton';

const TableComponent = ({headers, children,custom_class = "", fancy_print = "normal-table"}) => {

    const dispatch = useDispatch();
    const table = useRtkState('table');
    const pagination = table.pagination;
    const items_selected = table.items_selected;
    const items = table.items;
    const isLoading = table.isLoading;


    const selectAllItemsHandler = () => {
        dispatch(selectAll());
    }

    /* filter */
    const paginationPageHandler = (e, page) => {
        dispatch(appendFilterParams({...table.params, page: page}, {}));
        dispatch(reRenderItems());
    }

    return (
        <>
            {/* ------------------------ Table ------------------------ */}

            {
                (
                    items.length > 0 && (!isLoading) ? (
                            <div className="table-responsive">
                                <table fancy-print={fancy_print} id="main-table" className={`table table-custom ${custom_class}`}>
                                    {
                                        headers && headers.length ?
                                            (
                                                <>
                                                    <thead>
                                                    <tr>
                                                        <th className={'prevent-print'}>
                                                            <label className="checkboxs">
                                                                <input onChange={selectAllItemsHandler}
                                                                       checked={items_selected.length === items.length && items.length > 0}
                                                                       type="checkbox" id="select-all"/>
                                                                <span className="checkmarks"> </span>
                                                            </label>
                                                        </th>
                                                        {
                                                            headers.length > 0 && headers.map(function (header, index) {
                                                                return (
                                                                    <th key={index}
                                                                        className={!header?.printable ? 'prevent-print' : ''}>
                                                                        {header?.label}
                                                                    </th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {children}
                                                    </tbody>
                                                </>
                                            ) : children
                                    }
                                </table>
                            </div>
                        ) :
                        ((isLoading) ? <TableSkeleton/> : "")
                )

            }

            <>
                {
                    (pagination && pagination.last_page > 1)
                    &&
                    <Pagination
                        shape="rounded"
                        count={pagination.last_page}
                        page={pagination.current_page}
                        showFirstButton
                        showLastButton
                        onChange={paginationPageHandler}/>
                }

                {!isLoading && items.length < 1 ?
                    (<EmptyContentComponent/>) : (items.length > 0 ? '' : <LoadingComponent/>)}
            </>

            {/* ------------------------ Table ------------------------ */}
        </>
    );
};

export default TableComponent;
