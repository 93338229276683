import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {

    const prefix = 'product-settings';
    const module_endpoint = 'currencies';
    const module_name = 'العملات';
    const create_redirect = prefixRouterPath((prefix ? prefix + '/' : '') + 'currencies/create');

    const headers = [
        {
            label: 'الاسم',
            key: 'name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }, {
            label: 'الكود',
            key: 'code',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }, {
            label: 'رمز العملة',
            key: 'symbol',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'وقت الانشاء',
            key: 'creation_date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجراء',
            key: 'action',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;


    const permissions = useRtkState('Settings')?.permissions;
    useEffect(() => {
        PermissionMiddleware(permissions, "currencies");
        if (permissions.length > 0) {
            dispatch(endpoint(module_endpoint, {}));
            dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
            dispatch(reRenderItems())
        }
    }, [permissions]);


    /* ---------------- searches ------------------ */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */


    /* ---------------- delete ------------------ */
    const deleteHandler = (item) => {
        confirmDelete(async () => {
            await AxiosInstance.delete(`${module_endpoint}/${item.id}`, {}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }
    /* ---------------- delete ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
    };
}