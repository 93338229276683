import {
    continueSweetAlert, GeneralConfirmation,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";

import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {setLocale} from 'yup';
import {
    fetchProducts,
    ResetAllInitialValues,
    AppendParamsValues,
    SetInvoiceType, SetIsReturnInvoice, ChangeSearchLoading,
} from "../../../store/invoices/InvoiceOperations";

import {useDispatch} from "react-redux";
import {PRODUCT_PER_PAGE} from "../../../config/constants";
import SearchHooks from "../../system/invoice/SearchHooks";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {

    const prefix = 'purchases-module';
    const module_endpoint = 'purchases';
    const module_name = 'المشتريات';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'purchases';
    const slug = 'اضافة مشتريات جديدة';

    const dispatch = useDispatch();

    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "purchases");

        if (permissions.length > 0 && permissions.includes("purchases")) {

            dispatch(SetInvoiceType('purchase', {}));
            dispatch(SetIsReturnInvoice(false, {}));

            AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setWarehouses(data.data);
            }).catch(function ({response}) {
                //
            });
            AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setSuppliers(data.data);
            }).catch(function ({response}) {
                //
            });
            return () => {
                dispatch(ResetAllInitialValues({}, {}));
            }
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {}, /* it will be server side validate */
        validationSchema: Yup.object({}), /* it will be server side validate */

        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()));
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
                dispatch(ResetAllInitialValues({}, {}))
            }).catch(function ({response}) {
                const errors = response.data.data;
                setErrors(errors);
                setIsSubmitLoading(false);

                if (errors && typeof errors == "string") {
                    warningSweetAlert(errors)
                }
            });
        },

        /* https://www.npmjs.com/package/yup */
    });


    const resetForm = () => {
        setErrors({});
    }

    /* start product list operations */
    const InvoiceOperations = useRtkState('InvoiceOperations');
    const invoice_products = InvoiceOperations.invoice_products;

    const products_list_ref = useRef();
    const search_loading = useRtkState('InvoiceOperations').search_loading;

    const searchProductsHandler = () => {
        const search_key = products_list_ref?.current?.value
        dispatch(AppendParamsValues({search_key: search_key, per_page: PRODUCT_PER_PAGE}, {}));
        dispatch(fetchProducts());
    }
    /* start product list operations */

    /* ========================== search ============================ */
    const {handleOnFormChange} = SearchHooks(dispatch);
    /* ========================== search ============================ */

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        suppliers,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,
        handleOnFormChange,
        /* start product list operations */
    };
}