import React from 'react';
import ReactDOM from 'react-dom/client';

/* styles & scripts */

/* styles */
import './assets/dashboard/css/bootstrap.rtl.min.css';
import './assets/dashboard/css/bootstrap-icons.css';
import './assets/dashboard/css/style.css';

/* scripts */
import './assets/dashboard/script';
/* styles & scripts */

/* Redux toolkit */
import {Provider} from 'react-redux'
import {store} from './store'
/* Redux toolkit */

import App from './App';

/* material ui*/
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";

const theme = createTheme({
    direction: 'rtl', // or ltr
});
/* material ui*/


ReactDOM.createRoot(document.getElementById('root'))
    /**
     * Don't forget use "htaccess" to redirect all client side to "index.html"
     * .htaccess-must-used-inside-build
     */
    .render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Provider>
    );
