import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import SelectInput from "../../../shared/inputs/SelectInput";

/* create hook */
import CreateHook from "../../../hooks/cruds/sales/CreateHook";

/* others */
import ProductListComponent from "../../../components/ProductList/ProductListComponent";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import InfoPage from "../../../components/global/InfoPage";
import InvoiceSettingComponent from "../../../components/invoice/InvoiceSettingComponent";
import ShowInvoiceComponent from "../../../components/invoice/ShowInvoiceComponent";
import InvoiceProductsListComponent from "../../../components/invoice/InvoiceProductsListComponent";


const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        customers,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,
        /* start product list operations */
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {SYSTEM_TITLE} | {module_name}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <InfoPage/>

            <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                <div className="row">
                  <div className="col-lg-5">
                    <CardComponent>
                      <div className="row">
                        <SelectInput
                            label={"المخزن"}
                            name={"warehouse_id"}
                            formik={formik}
                            className={"col-lg-12 col-sm-6 col-12"}
                            error={errors?.["warehouse_id"]?.[0] ?? ""}>
                            {warehouses &&
                            warehouses.map(function (warehouse) {
                                return (
                                    <option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.name}
                                    </option>
                                );
                            })}
                        </SelectInput>
                        <SelectInput
                            label={"العميل"}
                            name={"customer_id"}
                            formik={formik}
                            className={"col-lg-12 col-sm-6 col-12"}
                            error={errors?.["customer_id"]?.[0] ?? ""}>
                            {customers &&
                            customers.map(function (customer) {
                                return (
                                    <option key={customer.id} value={customer.id}>
                                        {customer.name}
                                    </option>
                                );
                            })}
                        </SelectInput>
                        
                      </div>

                      <div className="card-footer custom-footer">
                        {/* search product input */}
                        <TextInput
                            className={"col-12"}
                            input_ref={products_list_ref}
                            label={"ابحث عن الاصناف المراد اضافتها للفاتورة"}
                            placeholder={"ابحث عن الصنف ..."}
                            error={errors?.["purchase_items"]?.[0] ?? ""}/>

                        {/* button to search Action */}
                        <SimpleButton
                            clickAction={searchProductsHandler}
                            className={"btn btn-primary custom-btn-search"}
                            btn_name={"بحث"}
                            isLoading={search_loading}/>
                      </div>
                    </CardComponent>
                  </div>
                  <div className="col-lg-7">
                {/* show products after search */}
                <CardComponent custom_class="card-custom"
                               card_title="اختر الاصناف المراد اضافتها"
                               is_show={InvoiceOperations?.products?.length > 0}>
                    <ProductListComponent/>
                </CardComponent>
                  </div>
                </div>
                


                {/* show products after select products */}
                <CardComponent custom_class="mt-3" is_show={invoice_products?.length > 0}>
                    <InvoiceProductsListComponent errors={errors}/>
                </CardComponent>

                {/* show invoice & invoice settings */}
                <CardComponent is_show={invoice_products?.length && (InvoiceOperations?.sub_grand_total ?? 0) > 0}>
                    <div className="flex-total-order">
                        <InvoiceSettingComponent errors={errors}/>
                        <ShowInvoiceComponent/>
                    </div>

                    {invoice_products?.length && (Math.abs(InvoiceOperations?.sub_grand_total) ?? 0) > 0 ? (
                        <div className="card-footer">
                            <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                            <RestButton className={"btn btn-danger"}/>
                        </div>
                    ) : ""}
                </CardComponent>

            </form>
        </>
    );
};

export default Create;
