import React, {useCallback, useEffect, useState} from 'react';
import {useRtkState} from "../../config/helper";
import AxiosInstance from "../../config/AxiosInstance";
import CardComponent from "../cards/CardComponent";
import MostSalesAndPurchasesDuringWeek from "../Statistics/MostSalesAndPurchasesDuringWeek";
import MostProductsDuringNumberOfSales from "../Statistics/MostProductsDuringNumberOfSales";
import MostCustomersBySales from "../Statistics/MostCustomersBySales";
import MostSuppliersByPurchases from "../Statistics/MostSuppliersByPurchases";
import MostMonthProductSalesDuringTwelveMonth from "../Statistics/MostMonthProductSalesDuringTwelveMonth";
import axios from "axios";
import AuthorizationFetch from "../../config/AuthorizationFetch";
import {APP_URL} from "../../config/constants";

const SystemStatistic = () => {

    const auth = useRtkState("Settings")?.auth;
    const itemStylesColors = ["#699ecb", "#91CC75", "#FAC858", "#EE6666", "#73C0DE", "#3BA272", "#FC8452", "#9A60B4", "#EF9DD9", "#697acb"];

    /* (1) Prepare WeeklySalesStatistics */
    const [weeklySalesStatistics, setWeeklySalesStatistics] = useState([]);
    const [isWeeklySalesStatisticsLoading, setWeeklySalesStatisticsLoading] = useState(true);

    const PrepareWeeklySalesStatistics = (data) => {
        setWeeklySalesStatistics(data.data);
        setWeeklySalesStatisticsLoading(false);
    }

    /* (2) Prepare ProductsDuringNumberOfSale */
    const [dataOfProductsDuringNumberOfSale, setDataOfProductsDuringNumberOfSale] = useState([]);
    const [valuesOfProductDuringNumberOfSale, setValuesOfProductDuringNumberOfSale] = useState([]);
    const [isDataOfProductsDuringNumberOfSaleLoading, setDataOfProductsDuringNumberOfSaleLoading] = useState(true);

    const PrepareProductsDuringNumberOfSale = (data) => {
        let _data = data?.data?.data && data?.data?.data.length > 0 ? data?.data?.data : [""];
        let values = data?.data?.values && data?.data?.values.length > 0 ? data?.data?.values : [0];
        let seriesData = [];

        values.forEach(function (value, index) {
            seriesData.push({
                value: value,
                itemStyle: {color: (index < itemStylesColors.length) ? itemStylesColors[index] : "#699ecb"},
            });
        })

        setDataOfProductsDuringNumberOfSale(_data);
        setValuesOfProductDuringNumberOfSale(seriesData);
        setDataOfProductsDuringNumberOfSaleLoading(false);
    }

    /* (3) Prepare MostCustomersBySales */
    const [mostCustomersBySales, setMostCustomersBySales] = useState([]);
    const [isMostCustomersBySalesLoading, setMostCustomersBySalesLoading] = useState(true);

    const PrepareMostCustomersBySales = (data) => {
        let most_customers_by_sales = data?.data ?? [];
        most_customers_by_sales = most_customers_by_sales.filter(function (most_customers_by_sale) {
            return most_customers_by_sale?.value !== 0;
        })
        setMostCustomersBySales(most_customers_by_sales);
        setMostCustomersBySalesLoading(false);
    }

    /* (4) Prepare MostSuppliersByPurchases */
    const [mostSuppliersByPurchases, setMostSuppliersByPurchases] = useState([]);
    const [isMostSuppliersByPurchasesLoading, setMostSuppliersByPurchasesLoading] = useState(true);

    const PrepareMostSuppliersByPurchases = (data) => {
        let most_suppliers_by_purchases = data?.data ?? [];
        most_suppliers_by_purchases = most_suppliers_by_purchases.filter(function (most_customers_by_sale) {
            return most_customers_by_sale?.value !== 0;
        })
        setMostSuppliersByPurchases(most_suppliers_by_purchases);
        setMostSuppliersByPurchasesLoading(false);
    }

    /* (5) Prepare MostMonthlyProductSalesStatistics */
    const [mostMonthlyProductSalesStatistics, setMostMonthlyProductSalesStatistics] = useState([]);
    const [isMostMonthlyProductSalesStatisticsLoading, setMostMonthlyProductSalesStatisticsLoading] = useState(true);
    const PrepareMostMonthlyProductSalesStatistics = (data) => {
        setMostMonthlyProductSalesStatistics(data.data);
        setMostMonthlyProductSalesStatisticsLoading(false);
    }


    useEffect(() => {
        async function FetchStatistics() {
            let ProductsDuringNumberOfSale = await (await AuthorizationFetch(`most-products-with-sales?price_sort=desc&limit=5`)).json();
            PrepareProductsDuringNumberOfSale(ProductsDuringNumberOfSale);

            let MostSuppliersByPurchases = await (await AuthorizationFetch(`most-suppliers-by-purchases?purchase_sort=desc&limit=5`)).json();
            PrepareMostSuppliersByPurchases(MostSuppliersByPurchases);

            let MostMonthlyProductSalesStatistics = await (await AuthorizationFetch(`most-monthly-product-sales-statistics`)).json();
            PrepareMostMonthlyProductSalesStatistics(MostMonthlyProductSalesStatistics);


            let WeeklySalesStatistics = await (await AuthorizationFetch(`weekly-sales-statistics`)).json();
            PrepareWeeklySalesStatistics(WeeklySalesStatistics);

            let MostCustomersBySales = await (await AuthorizationFetch(`most-customers-by-sales?sales_sort=asc&limit=5`)).json();
            PrepareMostCustomersBySales(MostCustomersBySales);
        }

        FetchStatistics().finally(() => {
            console.log("loaded")
        });
    }, []);


    return (
        <div>
            <CardComponent>
                <div className="row">
                    <div className="col-md-6">
                        <MostMonthProductSalesDuringTwelveMonth
                            mostMonthlyProductSalesStatistics={mostMonthlyProductSalesStatistics}
                            isLoading={isMostMonthlyProductSalesStatisticsLoading}/>

                    </div>
                    <div className="col-md-6">
                        <MostProductsDuringNumberOfSales data={dataOfProductsDuringNumberOfSale}
                                                         values={valuesOfProductDuringNumberOfSale}
                                                         isLoading={isDataOfProductsDuringNumberOfSaleLoading}/>
                    </div>
                </div>
            </CardComponent>

            <CardComponent>
                <div className="row">
                    <div className="col-md-6">
                        <MostCustomersBySales mostCustomersBySales={mostCustomersBySales}
                                              isLoading={isMostCustomersBySalesLoading}/>
                    </div>
                    <div className="col-md-6">
                        <MostSuppliersByPurchases mostSuppliersByPurchases={mostSuppliersByPurchases}
                                                  isLoading={isMostSuppliersByPurchasesLoading}/>
                    </div>
                </div>
            </CardComponent>


            <div className="row mt-2">
                <div className="col-md-12">
                    <CardComponent>
                        <MostSalesAndPurchasesDuringWeek weeklySalesStatistics={weeklySalesStatistics}
                                                         isLoading={isWeeklySalesStatisticsLoading}/>
                    </CardComponent>
                </div>
            </div>

        </div>
    );
};

export default SystemStatistic;