import {prefixRouterPath} from "./helper";

export default () => {
    return [
        {
            route: prefixRouterPath('reports/purchases-report'),
            menu_name: 'تقارير المشتريات',
            permission: {id: 24, name: "purchases-report"},
        }, {
            route: prefixRouterPath('reports/purchases-return-report'),
            menu_name: 'تقارير مرتجعات المشتريات',
            permission: {id: 25, name: "purchases-return-report"},
        }, {
            route: prefixRouterPath('reports/sales-report'),
            menu_name: 'تقارير المبيعات',
            permission: {id: 26, name: "sales-report"},
        }, {
            route: prefixRouterPath('reports/sales-return-report'),
            menu_name: 'تقارير مرتجعات المبيعات',
            permission: {id: 27, name: "sales-return-report"},
        },
        {
            route: prefixRouterPath('reports/shortage-report'),
            menu_name: 'تقارير الاصناف الناقصة',
            permission: {id: 28, name: "shortage-report"},
        },
        {
            route: prefixRouterPath('reports/get-store-inventory'),
            menu_name: 'تقارير جرد المخازن',
            permission: {id: 35, name: "get-store-inventory"},
        }
    ]
}