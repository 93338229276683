import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const prefix = 'product-settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'products';
    const module_endpoint = 'products/update-prices';
    const module_name = 'قائمة الاسعار للاصناف';
    const slug = '';

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const [params, setParams] = useState({"sort_type": "asc"});
    const [productCategories, setProductCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "product-prices-list");

        if (permissions.length > 0) {
            AxiosInstance.get('product_categories/categories-with-its-subcategories', {}).then(async function ({data}) {
                await setProductCategories(data.data);
            }).catch(function ({response}) {
                //
            });
            /** get products */
            // GetProductFiltration();
        }

    }, [permissions]);

    let GetProductFiltration = () => {
        setIsSearchLoading(true);
        AxiosInstance.get('products', {
            params: params
        }).then(async function ({data}) {
            await setProducts(data.data);
            setIsSearchLoading(false);
        }).catch(function ({response}) {
            //
            setIsSearchLoading(false);
        });
    }

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            console.log(Object.fromEntries(formData.entries()));
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
                successSweetAlert();
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    /* on Change Filtration */
    let OnChangeCategory = (e) => {
        setParams({...params, product_category_id: e?.target?.value});
    }

    let OnChangeSearchKeyword = (e) => {
        if (e.key === "Enter") {
            GetProductFiltration();
        } else {
            setParams({...params, search_key: e?.target?.value});
        }
    }

    let SearchButtonHandler = (e) => {
        console.log({params});
        /** get products */
        GetProductFiltration();
    }
    /* on Change Filtration */

    let CheckIfThisProductSelectedBefore = (product) => {
        let exist = false;
        selectedProducts.forEach(function (selectedProduct) {
            if (selectedProduct.id === product.id) {
                exist = true;
            }
        });
        return exist;
    }

    let onSelectProduct = (e, product) => {
        if (CheckIfThisProductSelectedBefore(product)) {
            warningSweetAlert(trans("This product has already exist ."));
            return false;
        }
        setSelectedProducts([...selectedProducts, product]);
    }

    let onDeleteSelectProduct = (e, product) => {
        e.preventDefault();
        if (!CheckIfThisProductSelectedBefore(product)) {
            warningSweetAlert(trans("this is product not found"));
            return false;
        }
        let new_selectedProducts = selectedProducts.filter(selectedProduct => selectedProduct.id !== product.id);
        setSelectedProducts(new_selectedProducts);
    }

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        productCategories,
        products,
        selectedProducts,
        isSearchLoading,
        OnChangeCategory,
        OnChangeSearchKeyword,
        SearchButtonHandler,
        onSelectProduct,
        onDeleteSelectProduct,
    };
}