import React from "react";
import {pos} from "../../../config/images-path";
import {Link} from "react-router-dom";
import {prefixRouterPath, useRtkState} from "../../../config/helper";

const PosLink = () => {

    const permissions_by_group_keys = Object.keys(useRtkState('Settings')?.permissions_by_group ?? null);


    return (

        (permissions_by_group_keys.includes("pos"))
        &&
        (<Link to={prefixRouterPath("pos/start-session")} className="point-of-sale">
          <span>
            <img src={pos} alt=""/>
            نقطة البيع
          </span>
        </Link>)

    );
};

export default PosLink;
