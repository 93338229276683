import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../../config/constants";

/* components & shared */
import PageHeader from "../../../../components/layout/PageHeader";
import CardComponent from "../../../../components/cards/CardComponent";
import LoadingComponent from "../../../../components/global/LoadingComponent";
import TableComponent from "../../../../components/table/TableComponent";
import TableHeaderComponent from "../../../../components/table/TableHeaderComponent";
import TdSelectItemComponent from "../../../../components/table/TableTbody/TdSelectItemComponent";
import {selectedOrNo} from "../../../../config/helper";

/* hook */
import IndexHook from "../../../../hooks/system/reports/reports/ShortageReportHook";
import NormalSelectInput from "../../../../shared/inputs/NormalSelectInput";
import {search_whites} from "../../../../config/images-path";


const Index = () => {

    const {
        table,
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        warehouses,
        OnChangeWarehouse,
        params,
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>


                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}


                <NormalSelectInput className={'col-md-3 col-sm-6 col-12'} onChangeHandler={OnChangeWarehouse}>
                    <option value="" selected>جميع المخازن</option>
                    {
                        warehouses && warehouses.map(function (warehouse) {
                            return (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            );
                        })
                    }
                </NormalSelectInput>

                {/* {table?.params?.warehouse_id === null && <h3 className="text-center">من فضلك قم باختيار مخزن معين</h3>} */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {
                        params?.warehouse_id ?
                            items.map(function (item) {
                                return (
                                    <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'}
                                        key={item.id}>
                                        <td className="prevent-print">
                                            <TdSelectItemComponent is_exportable={false} item={item}/>
                                        </td>
                                        <td>
                                            <img className="table-img" src={(item?.image_url ?? null)}
                                                 alt={(item?.name ?? null)}/>
                                        </td>
                                        <td>{(item?.name ?? null)}</td>
                                        <td><span className='text-red'>{(item?.stock_alert ?? null)}</span></td>
                                        <td>{(item?.warehouse_manage_stock_quantity >= 0 ? item?.warehouse_manage_stock_quantity : "----")}</td>

                                        <td>
                                            {
                                                item?.stock_alert > item?.warehouse_manage_stock_quantity
                                                    ?
                                                    <span className='text-red'> اقل من حد التنبية </span> : "----"
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                            :
                            (
                                <tr>
                                    <td colSpan={6}>من فضلك اختر المخزن المراد معرفة النواقص بداخله</td>
                                </tr>
                            )
                    }
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
