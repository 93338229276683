import React, {useRef, useState} from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";

/* hook */
import IndexHook from "../../../hooks/cruds/purchases/IndexHook";
import ShowPurchaseDetailsComponent from "../../../components/invoice/ShowPurchaseDetailsComponent";
import ShowHook from "../../../hooks/cruds/purchases/ShowHook";


const Index = () => {

    const {
        module_name,
        invoice
    } = ShowHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={""}/>

            <ShowPurchaseDetailsComponent invoice={invoice} invoice_type={"purchase"}/>
        </>
    );
};

export default Index;
