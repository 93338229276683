import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});
    const [permissions, setPermissions] = useState([]);

    const prefix = 'roles';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'roles-permissions';
    const module_endpoint = 'roles';
    const module_name = 'الادوار والصلاحيات';

    const slug = 'تعديل صلاحيات ' + (oldItems?.name ?? '----');

    useEffect(() => {
        AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
            const old_item = data.data;
            await setOldItems(old_item);
            setIsOldItemsLoading(false);
        }).catch(function (res) {
            if (res.response.data.status === 404) {
                navigate(prefixRouterPath(route_endpoint));
                warningSweetAlert(trans('This_page_no_longer_exists'));
            }
        });

        AxiosInstance.get(module_endpoint + '/permissions-by-role/' + params?.slug, {}).then(async function ({data}) {
            const permissions = data.data;
            let permissions_names = [];
            await permissions.forEach(function (permission) {
                permissions_names.push(permission?.name);
            })
            await setPermissions(permissions_names);
            setIsOldItemsLoading(false);
        }).catch(function (res) {
            if (res.response.data.status === 404) {
                navigate(prefixRouterPath(route_endpoint));
                warningSweetAlert(trans('This_page_no_longer_exists'));
            }
        });
    }, []);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT');
            console.log(Object.fromEntries(formData.entries()));
            await AxiosInstance.post("roles/assign-permissions-to-role" + '/' + oldItems?.slug, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                // navigate(prefixRouterPath(route_endpoint));
                window.location.href = prefixRouterPath(route_endpoint);
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    const ChangePermissionStatus = (permission) => {
        if (CheckPermissionOnOrOff(permission)) {
            let new_permissions = permissions.filter(stored_permission => stored_permission !== permission);
            setPermissions(new_permissions);
            return false;
        }
        setPermissions([...permissions, permission]);
    }

    const CheckPermissionOnOrOff = (permission) => {
        return permissions.includes(permission); // return true or false
    }

    return {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        permissions,
        ChangePermissionStatus,
        CheckPermissionOnOrOff,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    };
}