import React from "react";
import { prefixRouterPath, useRtkState } from "../../../config/helper";
import useAutoClose from "../../../hooks/global/useAutoClose";
import { logout } from "../../../config/images-path";
import { Link, useNavigate } from "react-router-dom";
import LogoutHook from "../../../hooks/system/LogoutHook";

const PosProfileComponent = () => {
  const auth = useRtkState("Settings")?.auth;
  const { menu, isOpen, openHandle, closeSubmenuHandler } = useAutoClose();
  const navigate = useNavigate();
  const logoutHandler = async (e) => {
    e.preventDefault();
      LogoutHook(navigate);
  }
  return (
    <>
      <ul className="nav user-menu">
        <li className="nav-item dropdown has-arrow main-drop">
          <a
            onClick={openHandle}
            className={`${isOpen ? "show" : ""} nav-link userset`}
            href="#!"
          >
            <span className="user-img">
              <i className="bi bi-person-circle"> </i>
            </span>
          </a>

          <div
            ref={menu}
            className={`${
              isOpen ? "show" : ""
            } dropdown-menu menu-drop-user profileHeader`}
          >
            <div className="profilename">
              <div className="profileset">
                <div className="profilesets">
                  <h6>
                    {(auth?.firstname ?? "---") +
                      " " +
                      (auth?.lastname ?? "---")}
                  </h6>
                  <span>{auth?.user_type == "1" ? "مشرف" : "بائع"}</span>
                </div>
              </div>
              <hr className="m-0" />
              <Link onClick={closeSubmenuHandler} to={prefixRouterPath('settings/profile')} className="dropdown-item">
                        <i className="bi bi-person-circle"> </i>
                        حسابي
              </Link>
              <hr className="m-0" />
              <a onClick={logoutHandler} className="dropdown-item logout pb-0" href="#!">
                <img src={logout} className="" alt="img" />
                انهاء وردية
              </a>
            </div>
          </div>
        </li>
      </ul>

      {/* <div className="pos-profile">
                <div className="user-name-icon">
                    <i className="bi bi-person-circle"> </i>
                    <div className="name-time">
                        <p>{(auth?.firstname ?? "---") + " " + (auth?.lastname ?? "---")}</p>
                        <span>{auth?.user_type == "1" ? "مشرف" : "بائع"}</span>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default PosProfileComponent;
