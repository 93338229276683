import React, {useEffect, useRef, useState} from 'react';
import AxiosInstance from "../../../config/AxiosInstance";
import {successSweetAlert, useRtkState, warningSweetAlert} from "../../../config/helper";
import {useDispatch} from "react-redux";
import PaymentHook from "../../../hooks/system/invoice/PaymentHook";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SetLocalStatePaymentsAction} from "../../../store/invoices/InvoiceOperations";

export default () => {

    const currency = useRtkState('Settings').settings?.currency;
    let dispatch = useDispatch();
    let [isSubmitLoading, setIsSubmitLoading] = useState(false);
    let [errors, setErrors] = useState({});
    const submitForm = useRef();
    const module_endpoint = "bonds/PayCapital";
    const {payment_skeleton} = PaymentHook();

    const [assets, setAssets] = useState([]);

    const handleReset = () => {
        if (submitForm.current) {
            submitForm.current.reset();
        }
    };

    let [capitalType, setCapitalType] = useState("money");
    const ChangeCapitalType = (capitalType) => {
        setCapitalType(capitalType);
    };

    let [assetID, setAssetID] = useState("");
    let [assetName, setAssetName] = useState("");
    const ChangeAssetID = (e, nodeSelected) => {
        let asset_id = nodeSelected?.id
        let name = nodeSelected?.name
        let hasChildren = nodeSelected?.children?.length;

        if (hasChildren > 0) {
            setAssetID("");
            setAssetName("");
        } else {
            setAssetID(asset_id);
            setAssetName(name);
        }
    };

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()));

            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                let payments = [payment_skeleton];
                dispatch(SetLocalStatePaymentsAction(payments, {}));
                successSweetAlert();
                handleReset();
            }).catch(function ({response}) {
                let errors = response.data;
                setIsSubmitLoading(false);
                if (errors.status === 420) {
                    warningSweetAlert(errors?.message);
                    return false;
                }
                setErrors(errors.data);
            });

        },
        /* https://www.npmjs.com/package/yup */
    });

    useEffect(() => {

        AxiosInstance.get('get-financial-assets?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setAssets(data.data);
        }).catch(function ({response}) {
            //
        });

    }, []);

    return {
        currency,
        assets,
        isSubmitLoading,
        formik,
        errors,
        submitForm,
        capitalType,
        ChangeCapitalType,
        assetID,
        ChangeAssetID,
        assetName,
    }
};