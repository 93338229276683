import React from "react";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {errorValidation} from "../../config/images-path";
import {Autocomplete, TextField} from "@mui/material";
import CustomTooltip from "../material-ui/CustomTooltip";

const SelectInput = ({
                         id,
                         children,
                         className,
                         label,
                         placeholder,
                         formik,
                         name,
                         error,
                         allow_formik_value = false,
                         is_disabled = false
                     }) => {

    const server_error = error;
    const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;
    const error_text = front_error || server_error;

    return (
        <div className={className}>
            <div className="bt-select form-group">
                <div className="flex-label">
                    {label && <label>{label}</label>}
                    <span className="flex-error">
            <CustomTooltip title={error_text}>
              <em>
                {error_text && (
                    <img
                        src={errorValidation}
                        className={"warning-error"}
                        alt={"errorValidation"}
                    />
                )}
              </em>
            </CustomTooltip>
          </span>
                </div>

                {allow_formik_value ? (
                    <select
                        disabled={is_disabled}
                        id={id}
                        value={allow_formik_value ? formik.values[name] : ""}
                        name={name}
                        className={`ct-select form-select ${front_error ? "border border-danger" : ""}`}
                        aria-label="Default select example"
                        onChange={formik && formik.handleChange}>
                        {children}
                    </select>
                ) : (
                    <select
                        disabled={is_disabled}
                        id={id}
                        name={name}
                        className={`ct-select form-select ${front_error ? "border border-danger" : ""}`}
                        aria-label="Default select example"
                        onChange={formik && formik.handleChange}>
                        {children}
                    </select>
                )}
            </div>
        </div>
    );
};
export default SelectInput;
