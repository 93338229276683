import SimpleButton from "../../../shared/buttons/SimpleButton";
import SelectInput from "../../../shared/inputs/SelectInput";
import NumberInput from "../../../shared/inputs/NumberInput";
import TextInput from "../../../shared/inputs/TextInput";
import NormalInput from "../../../shared/inputs/NormalInput";


const OpeningBalance = ({addStock, stocks, deleteStock, formik, errors, warehouses, handleInputStockChange}) => {

    return (
        <div className="row">
          <h3 className="title-h3">رصيد اول مدة للمخازن</h3>
            <div className="col-md-12">
                <SimpleButton clickAction={addStock} className={'btn btn-info btn-sm'} btn_name={"اضافة مخزن"}/>
            </div>

            <div className="col-md-12 table-responsive mt-2">
                {
                    (stocks.length > 0)
                    &&
                    (
                        <table id="main-table" className="table table-custom">
                            <thead>
                            <tr>
                                <th>المخزن</th>
                                <th>سعر التكلفة</th>
                                <th>الكمية المتاحة</th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {stocks.map((stock, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="select-table-sm">
                                          <SelectInput
                                              name={`stocks[${index}][warehouse_id]`}
                                              formik={formik}
                                              error={errors?.[`stocks.${index}.warehouse_id`]?.[0] ?? ""}>
                                              {warehouses &&
                                              warehouses.map(function (warehouse) {
                                                  return (
                                                      <option key={warehouse.id}
                                                              value={warehouse.id}
                                                              selected={stock.warehouse_id == warehouse.id}>
                                                          {warehouse.name}
                                                      </option>
                                                  );
                                              })}
                                          </SelectInput>
                                        </div>
                                    </td>
                                    <td>
                                      <div className="input-table-sm">
                                        <NormalInput name={`stocks[${index}][product_cost]`}
                                                     placeholder={0}
                                                     formik={formik}
                                                     value={stock.product_cost}
                                                     onChangeHandler={(e) => handleInputStockChange(e, index)}
                                                     error={errors?.[`stocks.${index}.product_cost`]?.[0] ?? ""}/>
                                      </div>
                                    </td>
                                    <td>
                                        <div className="input-table-sm">
                                          <NormalInput name={`stocks[${index}][qty]`}
                                                      placeholder={0}
                                                      formik={formik}
                                                      value={stock.qty}
                                                      onChangeHandler={(e) => handleInputStockChange(e, index)}
                                                      error={errors?.[`stocks.${index}.qty`]?.[0] ?? ""}/>
                                        </div>
                                    </td>
                                    <td>
                                      
                                        {/* <SimpleButton clickAction={() => deleteStock(index)}
                                                      className={'btn btn-danger'} btn_name={"حذف"}/> */}
                                        <div className="flex-btn-table">
                                            <a onClick={() => deleteStock(index)} href="#!" className="btn-danger">
                                                <i className="bi bi-trash"> </i>
                                            </a>
                                        </div>
                                        
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )
                }
            </div>
        </div>
    );
}

export default OpeningBalance;