import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../store/invoices/InvoiceOperations";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    /* table */
    const prefix = "accounts-balances";
    const module_endpoint = "financial-general-ledger/safes";
    const module_name = 'استاذ عام خزن';
    const create_redirect = "";


    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;

    const headers = [
        {
            label: 'الخزنة',
            key: 'safe_name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }, {
            label: 'رقم الحساب',
            key: 'safe_account_number',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'combined_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'combined_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'balanced_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'balanced_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'عرض كشف حساب الخزن',
            key: 'show_account_details',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    const [totalBalance, setTotalBalance] = useState(0);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "accounts-balances-for-safes");
        if (permissions.length > 0 && permissions.includes("accounts-balances-for-safes")) {

            dispatch(endpoint(module_endpoint, {}));
            dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
            dispatch(reRenderItems())

            AxiosInstance.get('accounts-balances/total-balances-for-safes', {}).then(async function ({data}) {
                await setTotalBalance(data.data);
            }).catch(function ({response}) {
                //
            });

        }
    }, [permissions]);


    /* ---------------- searches ------------------ */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */


    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchKeyHandler,
        totalBalance,
    };
}