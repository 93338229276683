import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";
import {useParams} from "react-router-dom";

export default () => {
    const params = useParams();

    const prefix = 'warehouses-products';
    const product_endpoint = `products/slug/${params?.slug}`;
    const product_stocks_endpoint = `products/product-stocks/slug/${params?.slug}`;
    const module_name = ` عرض تفاصل المنتج ${params?.name}`;


    const [product, setProduct] = useState(null);
    const [product_stocks, setProductStocks] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "products");
        setIsLoading(true);

        if (permissions.length > 0 && permissions.includes("products")) {
            setIsLoading(true);


            AxiosInstance.get(product_endpoint, {}).then(async function ({data}) {
                await setProduct(data.data);
                setIsLoading(false);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(product_stocks_endpoint, {}).then(async function ({data}) {
                await setProductStocks(data.data);
                setIsLoading(false);
            }).catch(function ({response}) {
                //
            });

        }

    }, [permissions]);

    return {
        prefix,
        module_name,
        isLoading,
        product,
        product_stocks,
    };
}