import React, { useState, useEffect, useRef } from "react";

const Calculator = () => {
  /* eslint no-eval: 0 */
  const [result, setResult] = useState("");
  const printCalc = useRef("");


  const handleClick = (event) => {
    setResult(result + event.target.value);
  };

  const handleBackspace = () => {
    setResult(result.slice(0, -1));
  };

  const handleClear =  () => {
   setResult("");
    printCalc.current.focus();
    printCalc.current.blur();
  };

  const handleKeyPress = (event) => {
    const { key } = event;
    if (/^[0-9()+\-*/.]$/.test(key)) {
      setResult(result.concat(key));
    } else if (key === "Enter") {
      calculate();
    } else if (key === "Backspace") {
      handleBackspace();
    } else if (key === "Escape") {
      handleClear();
    }
  };

  const calculate = () => {
    try {

      let res = String(eval(result)).length > 3 && String(eval(result)).includes(".")
      ? 
      String(eval(result).toFixed(2))
      :
       String(eval(result));

      setResult(res);

    } catch (error) {
      setResult("error");
    }

  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <div className="wrapper-calculator">
      <input className="show-input" ref={printCalc} type="text" value={result} />
      <div className="digits flex">
      <button value="9" onClick={handleClick}>
          9
        </button>
        <button value="8" onClick={handleClick}>
          8
        </button>
        <button value="7" onClick={handleClick}>
          7
        </button>
        <button value="6" onClick={handleClick}>
          6
        </button>
        <button value="5" onClick={handleClick}>
          5
        </button>
        <button value="4" onClick={handleClick}>
          4
        </button>
        <button value="3" onClick={handleClick}>
          3
        </button>
        <button value="2" onClick={handleClick}>
          2
        </button>
        <button value="1" onClick={handleClick}>
          1
        </button>
        <button value="0" onClick={handleClick}>
          0
        </button>
        <button value="." onClick={handleClick}>
          .
        </button>
      </div>
      <div className="modifiers subgrid">
         <button className="text-red" value="" onClick={handleClear}>
         AC
        </button>
        <button onClick={handleBackspace}>
        <i className="bi bi-backspace"></i>
        </button>
       </div>
       <div className="operations subgrid">
       <button value="+" onClick={handleClick}>
          +
        </button>
        <button value="-" onClick={handleClick}>
          -
        </button>
        <button value="*" onClick={handleClick}>
          *
        </button>
        <button value="/" onClick={handleClick}>
          /
        </button>
        <button className="equals" value="=" onClick={calculate}>
          =
        </button>
      </div>
    </div>
  );
}

export default Calculator;
