import React from "react";
import GetProductByCodeAndAppendToInvoice from "../../../../hooks/system/pos/GetProductByCodeAndAppendToInvoice";
import {useRtkState } from "../../../../config/helper";
import { useDispatch } from "react-redux";
import BarcodeReader from "react-barcode-reader";
import { ChangeConfirmPaymentPopUpAction } from "../../../../store/pos/Pos";

const ScanCodeComponent = () => {
  const state = useRtkState("Pos");
  const dispatch = useDispatch();

/*  const scan_ref = useRef(null);
  const [is_scanning, setIsScanning] = useState(false);

  const ScanQrCodeClick = (event) => {
      scan_ref?.current?.focus();
  }

  //  scan "Qr Code" and append product to invoice 
  let time = null;
  const ScanQrCodeAction = (event) => {
      const code = event?.target?.value;
      clearTimeout(time);
      time = setTimeout(async function () {
          if (code) {
              setIsScanning(true);
              await GetProductByCodeAndAppendToInvoice(code, state, dispatch, function () {
                  scan_ref.current.value = '';
                  setIsScanning(false);
                  // successSweetAlert2(`تم اضافة العنصر بنجاح`);
              });
          }
      }, 200);
  }
  */

  const handleBarcodeScan = async (code) => {
    if (code) {
      dispatch(ChangeConfirmPaymentPopUpAction(false, {})) // close popup after scan
      await GetProductByCodeAndAppendToInvoice(code, state, dispatch,  function () {});
    }
  };

  return (
    <>

      <BarcodeReader onScan={handleBarcodeScan} />

      {/* <div className="abs-div">
                <input onChange={ScanQrCodeAction} ref={scan_ref} name="code" type="text" autoComplete="off"
                       className="form-control"
                       placeholder="امسح الباركود ..."/>
            </div>

            <button onClick={ScanQrCodeClick} type="button" id='scan-qr-btn' className="btn-header scan-qr">
                <i className={is_scanning ? 'loader-btn' : 'bi bi-qr-code-scan'}>
                </i>
                اضغط لمسح الباركود
            </button> */}

    </>
  );
};

export default ScanCodeComponent;
