import React, {useRef} from 'react';
import {ConvertNumberToArabicWords, CurrencyFormatStyle} from "../../config/helper";
import ReactToPrint from "react-to-print";

export default ({voucherTransaction, currency}) => {

    const PrintRef = useRef();

    return (
        <>
            {/*<button className="btn btn-primary print-bill"><i className="bi bi-printer"> </i> طباعة الايصال</button>*/}


            <ReactToPrint
                bodyClass="print-agreement"
                content={() => PrintRef.current}
                trigger={() => (
                    <button className="btn btn-primary print-bill">
                        <i className="bi bi-printer"> </i> طباعة الايصال
                    </button>
                )}
            />


            <div className="cash-receipt" ref={PrintRef}>
                {/*{*/}
                {/*    voucherTransaction?.voucher_type === "pay"*/}
                {/*        ?*/}
                {/*        (<h3>ايصال دفع نقدية</h3>)*/}
                {/*        :*/}
                {/*        (<h3>ايصال استلام نقدية</h3>)*/}
                {/*}*/}

                <h3> ايصال {voucherTransaction?.financial_transaction_type ?? "نقدي"}</h3>

                <ul>
                    <li>
                        <h5> السيد /</h5>
                        <h6>{voucherTransaction?.transaction_username ?? "النظام"}</h6>
                    </li>
                    <li>
                        <h5>مــبــلــغ وقـــدره /</h5>
                        <h6 className="w-h6">{CurrencyFormatStyle(voucherTransaction?.amount, true, "ar", currency)}</h6>
                        <h5>فقط /</h5>
                        <h6 className="custom-size-font">{ConvertNumberToArabicWords(voucherTransaction?.amount, currency)}</h6>
                    </li>
                    <li>
                        <h5>وذلـك نظير /</h5>
                        <h6>{voucherTransaction?.transaction_info}</h6>
                    </li>
                </ul>
                <div className="flex">
                    <div className="date-receipt">
                        <h5>تحريرا في</h5>
                        <p>{voucherTransaction?.date}</p>
                    </div>
                    <div className="receiver-name">
                        <h5>المستلم</h5>
                        <h6></h6>
                    </div>
                </div>
            </div>
        </>
    );
};