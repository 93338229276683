import React from 'react';
import {selectedOrNo, useRtkState} from "../../config/helper";
import {selectItem} from "../../store/table/slices/tableSlice";
import {useDispatch} from "react-redux";

const SelectItemComponent = ({item, selectItemHandler}) => {

    /* table state */
    const table = useRtkState('table');
    const items_selected = table.items_selected;
    /* table state */

    return (
        <label className="checkboxs">
            <input type="checkbox" checked={selectedOrNo(item.id, items_selected)} onChange={e => selectItemHandler(item)}/>
            <span className="checkmarks"></span>
        </label>
    );
};

export default SelectItemComponent;
