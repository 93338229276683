import * as React from 'react';

const LoadingComponent = ({otherClasses}) => {
    return (
        <>
            <div style={{'zIndex':'9'}} className={`loader-div ${otherClasses}`}>
                <div className="loader"> </div>
            </div>
        </>
    );
};

export default LoadingComponent;