import React from 'react';
import {useRtkState} from "../../../../config/helper";

const DisplayPaymentComponent = () => {

    const posState = useRtkState("Pos");

    return (
        <ul className="total-order">
            <li>
                <h4>عدد الاصناف</h4>
                <h5>
                    <span className="items-count">{posState?.invoice_products?.length}</span>
                </h5>
            </li>
            <li>
                <h4>الاجمالي الفرعي</h4>
                <h5>
                    <span> {posState?.invoice_grand_sub_total} </span>ر.س
                </h5>
            </li>
            <li className="discount">
                <h4>الخصم </h4>
                <h5>
                    <span> {posState?.invoice_discount} </span> ر.س
                </h5>
            </li>
            <li>
                <h4>الضريبة %</h4>
                <h5><span> {posState?.invoice_tax} </span> %</h5>
            </li>
            <li>
                <h4>الشحن</h4>
                <h5><span> {posState?.shipping} </span>ر.س</h5>
            </li>
            <li className="total">
                <h4>اجمالي الفاتورة</h4>
                <h5>
                    <span> {posState?.invoice_grand_total} </span>ر.س
                </h5>
            </li>
        </ul>
    );
};

export default DisplayPaymentComponent;