import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {SetInvoiceType, SetIsReturnInvoice} from "../../../store/invoices/InvoiceOperations";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    /* table */
    const prefix = 'sales-module';
    const module_endpoint = 'sales';
    const module_name = 'المبيعات';
    const create_redirect = prefixRouterPath((prefix ? prefix + '/' : '') + 'sales/create');

    const headers = [
        {
            label: 'الرقم المرجعي',
            key: 'reference_code',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'المخزن',
            key: 'warehouse.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'العميل',
            key: 'customer.name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجمالي',
            key: 'grand_total',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'التاريخ',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجراء',
            key: 'action',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "sales");

        if (permissions.length > 0 && permissions.includes("sales")) {
            dispatch(endpoint(module_endpoint, {}));
            dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
            dispatch(SetInvoiceType('sale', {}));
            dispatch(SetIsReturnInvoice(false, {}));
            dispatch(reRenderItems())
        }

    }, [permissions]);


    /* ---------------- searches ------------------ */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */


    /* ---------------- delete ------------------ */
    const deleteHandler = (item) => {
        confirmDelete(async () => {
            await AxiosInstance.delete(`${module_endpoint}/${item.id}`, {}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }
    /* ---------------- delete ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
    };
}