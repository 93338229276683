import React from 'react';

const InvoiceNotesComponent = ({invoice}) => {
    return (
        <>
            {
                invoice?.notes
                &&
                <div className="invoice-notes">
                    <i className="bi bi-file-earmark-text"> </i>
                    <p>
                        <span>ملاحظات :</span>
                        {invoice?.notes}
                    </p>
                </div>
            }
        </>
    );
};

export default InvoiceNotesComponent;