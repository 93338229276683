import React from 'react';
import ContentLoader from 'react-content-loader'
import {useRtkState} from "../../../config/helper";

const PieSkeleton = ({props}) => {

    const settings = useRtkState("Settings");
    let tableSkeletonColor1 = settings?.systemMode === "dark" ? "#182a46" : "#f3f3f3";
    let tableSkeletonColor2 = settings?.systemMode === "dark" ? "#233b60" : "#ecebeb";

    return (
        <>
            <ContentLoader
                backgroundColor={tableSkeletonColor1}
                foregroundColor={tableSkeletonColor2}
                viewBox="0 0 400 200"
                height={325}
                width={780}
                {...props}>
                <rect x="100" y="5" rx="0" ry="0" width="200" height="15"/>
                <circle cx="140" cy="110" r="70"/>
                <rect x="230" y="50" rx="0" ry="0" width="7" height="7"/>
                <rect x="250" y="50" rx="0" ry="0" width="30" height="7"/>
                <rect x="230" y="64" rx="0" ry="0" width="7" height="7"/>
                <rect x="250" y="64" rx="0" ry="0" width="30" height="7"/>
                <rect x="230" y="78" rx="0" ry="0" width="7" height="7"/>
                <rect x="250" y="78" rx="0" ry="0" width="30" height="7"/>
                <rect x="230" y="92" rx="0" ry="0" width="7" height="7"/>
                <rect x="250" y="92" rx="0" ry="0" width="30" height="7"/>
            </ContentLoader>
        </>
    );
}


export default PieSkeleton;
