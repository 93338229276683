import React from 'react';
import PdfComponent from "./exports/PdfComponent";
import ExcelComponent from "./exports/ExcelComponent";
import PrinterComponent from "./exports/PrinterComponent";
import DeleteAllComponent from "./table_btns/DeleteAllComponent";

const ExportsAndDeleteAllComponent = ({allow_delete}) => {
    return (
        <div className="wordset">
            <ul>
                {allow_delete && <DeleteAllComponent/>}
                {/*<PdfComponent/>*/}
                <ExcelComponent/>
                <PrinterComponent/>
            </ul>
        </div>
    );
};

export default ExportsAndDeleteAllComponent;
