import React from 'react';

const DeleteBtnTableComponent = ({item, deleteHandler}) => {
    return (
        <>
            <a onClick={(e) => {
                e.preventDefault();
                deleteHandler(item);
            }} href="#!" className="btn-danger">
                <i className="bi bi-trash"> </i>
            </a>
        </>
    );
};

export default DeleteBtnTableComponent;
