import React from "react";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {errorValidation} from "../../config/images-path";
import {Autocomplete, TextField} from "@mui/material";
import CustomTooltip from "../material-ui/CustomTooltip";

const NormalSelectInput = ({id, children, className, label, name, onChangeHandler, error}) => {

    return (
        <div className={className}>
            <div className="bt-select form-group">

                <div className="flex-label">
                    {label && <label>{label}</label>}
                    <span className="flex-error">

            <CustomTooltip title={error}>
              <em>
                {label && error && (
                    <img
                        src={errorValidation}
                        className={"warning-error"}
                        alt={"errorValidation"}
                    />
                )}
              </em>
            </CustomTooltip>
            
          </span>
                </div>

                <select id={id} name={name} className="ct-select form-select" onChange={onChangeHandler}>
                    {children}
                </select>

            </div>
        </div>
    );
};

export default NormalSelectInput;
