import React from "react";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import { errorValidation } from "../../config/images-path";
import CustomTooltip from "../material-ui/CustomTooltip";

const NumberInput = ({
  readOnly,
  className,
  label,
  placeholder,
  formik,
  name,
  error,
  max,
  allow_formik_value = false,
}) => {
  const server_error = error;
  const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;
  const error_text = front_error || server_error;

  return (
    <div className={className}>
      <div className="form-group">
        <div className="flex-label">
          {label && <label>{label}</label>}
          <span className="flex-error">
            <CustomTooltip title={error_text}>
              <em>
                {error_text && (
                  <img
                    src={errorValidation}
                    className={"warning-error"}
                    alt={"errorValidation"}
                  />
                )}
              </em>
            </CustomTooltip>
          </span>
        </div>
        {allow_formik_value ? (
          <input
            name={name}
            readOnly={readOnly}
            className={`form-control ${
              front_error ? "border border-danger" : ""
            }`}
            autoComplete={"off"}
            type="number"
            max={max}
            step=".01"
            value={formik.values[name]} /* allow_formik_value */
            placeholder={placeholder}
            onChange={formik && formik.handleChange}
            onBlur={formik && formik.handleBlur}
          />
        ) : (
          <input
            name={name}
            readOnly={readOnly}
            className={`form-control ${
              front_error ? "border border-danger" : ""
            }`}
            type="number"
            max={max}
            step=".01"
            placeholder={placeholder}
            onChange={formik && formik.handleChange}
            onBlur={formik && formik.handleBlur}
          />
        )}
      </div>
    </div>
  );
};
export default NumberInput;
