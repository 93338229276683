import React from 'react';
import {useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import AddClientComponent from "../PosHeader/AddClientComponent";
import SelectInput from "../../../../shared/inputs/SelectInput";
import NormalSelectInput from "../../../../shared/inputs/NormalSelectInput";
import {ChangeConfirmPaymentPopUpAction, OnChangeCustomerAction} from "../../../../store/pos/Pos";

const ShowWarehouseAndClientsListComponent = ({formik, errors}) => {

    const posState = useRtkState('Pos');
    const settingStates = useRtkState('Settings')?.settings;
    const dispatch = useDispatch();

    const OnCustomerChange = (e) => {
        let customer_id = e?.target?.value;
        dispatch(OnChangeCustomerAction(customer_id, {}));
    }

    return (
        <div className='flex-select'>
            <div className="select-user">
                <i className="bi bi-boxes"> </i>

                <SelectInput
                    name={"warehouse_id"}
                    formik={formik}
                    error={errors?.["warehouse_id"]?.[0] ?? ""}>
                    <option disabled selected={true}>اختر المخزن</option>
                    {
                        posState?.warehouses.map(function (warehouse, index) {
                            return <option key={warehouse?.id}
                                           selected={settingStates?.default_warehouse_id ? (posState.warehouse_selected?.id === warehouse?.id ? 'selected' : '') : (index === 0 ? 'selected' : '')}
                                           value={warehouse?.id}>
                                {warehouse?.name}
                            </option>;
                        })
                    }
                </SelectInput>
            </div>

            <div className="select-user">
                <i className="bi bi-person"> </i>

                <NormalSelectInput
                    name="customer_id"
                    formik={formik}
                    error={errors?.["customer_id"]?.[0] ?? ""}
                    onChangeHandler={OnCustomerChange}>
                    <option disabled selected={true}>اختر العميل</option>
                    {
                        posState?.customers.map(function (customer, index) {
                            return <option key={customer?.id}
                                           selected={index === 0 ? 'selected' : ''}
                                           value={customer?.id}>
                                {customer?.name}
                            </option>;
                        })
                    }
                </NormalSelectInput>
            </div>

            <AddClientComponent/>
        </div>
    );
};

export default ShowWarehouseAndClientsListComponent;