import React from "react";

import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {errorValidation} from "../../config/images-path";
import CustomTooltip from "../material-ui/CustomTooltip";

const TextInput = ({
                       type = "text",
                       max,
                       input_ref,
                       className,
                       label,
                       placeholder,
                       formik,
                       name,
                       error,
                       allow_formik_value = false,
                       onkeypress
                   }) => {

    const server_error = error;
    const front_error = formik && formik?.touched[name] && formik?.errors[name];
    const error_text = front_error || server_error;

    return (
        <div className={className}>
            <div className="form-group">

                <div className="flex-label">
                    {label && <label>{label}</label>}

                    <span className="flex-error">
                        <CustomTooltip title={error_text}>
                          <em>
                            {error_text && (
                                <img
                                    src={errorValidation}
                                    className={"warning-error"}
                                    alt={"errorValidation"}
                                />
                            )}
                          </em>
                        </CustomTooltip>
                    </span>

                </div>

                {
                    allow_formik_value ? (
                        <input
                            name={name}
                            className={`form-control ${
                                front_error || server_error ? "border border-danger" : ""
                            }`}
                            autoComplete={"off"}
                            ref={input_ref}
                            type={type}
                            value={formik.values[name]} /* allow_formik_value */
                            placeholder={placeholder}
                            onChange={formik && formik.handleChange}
                            onKeyPress={onkeypress}
                            // onBlur={formik && formik.handleBlur}
                            max={max}
                            step="any"
                        />
                    ) : (
                        <input
                            name={name}
                            className={`form-control ${
                                front_error || server_error ? "border border-danger" : ""
                            }`}
                            autoComplete={"off"}
                            ref={input_ref}
                            type={type}
                            placeholder={placeholder}
                            onChange={formik && formik.handleChange}
                            onKeyPress={onkeypress}
                            // onBlur={formik && formik.handleBlur}
                            max={max}
                            step="any"
                        />
                    )
                }
            </div>
        </div>
    );
};
export default TextInput;
