import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import IndexHook from "../../../hooks/cruds/brands/IndexHook";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>
                    <NormalInput onChangeHandler={searchKeyHandler}
                                 name={'search_key'}
                                 className={'col-md-4 col-sm-6 col-10'}
                                 placeholder={'ابحث ...'}/>
                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {
                        items.map(function (item) {
                            return (
                                <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'}
                                    key={item.id}>
                                    <td className="prevent-print">
                                        <TdSelectItemComponent is_exportable={false} item={item}/>
                                    </td>

                                    {/*<td>*/}
                                    {/*    <img className="table-img" src={(item?.image_url ?? null)}*/}
                                    {/*         alt={(item?.name ?? null)}/>*/}
                                    {/*</td>*/}
                                    <td>{(item?.name ?? null)}</td>
                                    <td>{(item?.creation_date ?? null)}</td>

                                    <td className="prevent-print">
                                        <TableButtonsComponent
                                            redirect_to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/edit/' + (item?.slug ?? null))}
                                            item={item}
                                            deleteHandler={deleteHandler}/>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
