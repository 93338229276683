import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/cruds/units/EditHook";
import SelectInput from "../../../shared/inputs/SelectInput";


const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        baseUnits,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">
                        <TextInput label={'الاسم'}
                                   allow_formik_value={true}
                                   name={'name'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'اسم مختصر للوحدة'}
                                   allow_formik_value={true}
                                   name={'short_name'}
                                   formik={formik}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['short_name']?.[0] ?? ''}/>

                        <SelectInput label={'السمة الاساسية'} name={'base_unit_id'} formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-4 col-sm-6 col-12'}
                                     error={errors?.['base_unit_id']?.[0] ?? ''}>
                            <option value="" defaultValue>- اختر -</option>
                            {
                                baseUnits && baseUnits.map(function (baseUnit) {
                                    return (<option key={baseUnit.id} value={baseUnit.id}>{baseUnit.name}</option>);
                                })
                            }
                        </SelectInput>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Edit;