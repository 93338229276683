import React from 'react';
import {filter, filter_closes} from "../../config/images-path";
import CustomTooltip from '../../shared/material-ui/CustomTooltip';

const FilterComponent = ({isFilterShow, showFilterHandler}) => {
    return (
      <CustomTooltip title="تصنيفات البحث">
        <div className="search-set">
            <div className="search-path">
                <a onClick={showFilterHandler} className="btn btn-filter" id="filter_search">
                    <img src={isFilterShow ? filter_closes : filter} alt="img"/>
                </a>
            </div>
        </div>
      </CustomTooltip>
    );
};

export default FilterComponent;
