import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import IndexHook from "../../../hooks/cruds/work-shifts/IndexHook";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
        users,
        OnChangeUsers,
        OnChangeDateHandler,
        table,
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>
                    <NormalSelectInput className={'col-md-3 col-sm-6 col-12'} onChangeHandler={OnChangeUsers}>
                        <option value="" selected>جميع الموظفين</option>
                        {
                            users && users.map(function (user) {
                                return (
                                    <option key={user.id} value={user.id}>
                                        {((user?.firstname ?? "--") + " " + (user?.lastname ?? "--"))}
                                    </option>
                                );
                            })
                        }
                    </NormalSelectInput>


                    <NormalInput
                        type="date"
                        name={"date"}
                        value={table?.params?.date}
                        className={"col-md-3 col-sm-6 col-12"}
                        onChangeHandler={event => OnChangeDateHandler(event)}/>
                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {items.map(function (item) {
                        return (
                            <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} key={item.id}>
                                <td className="prevent-print">
                                    <TdSelectItemComponent is_exportable={false} item={item}/>
                                </td>

                                <td>{((item?.user?.firstname ?? "--") + " " + (item?.user?.lastname ?? "--"))}</td>
                                <td>{(item.user?.phone ?? "----")}</td>
                                <td>{(item.user?.role?.name ?? "----")}</td>
                                <td>{(item?.start_date ?? "----")}</td>
                                <td>{(item?.start_time ?? "----")}</td>
                                <td>{(item?.end_time ?? "----")}</td>

                                <td className="prevent-print">
                                    <TableButtonsComponent
                                        redirect_to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/edit/' + (item?.slug ?? null))}
                                        item={item}
                                        deleteHandler={deleteHandler}/>
                                </td>
                            </tr>
                        );
                    })}
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
