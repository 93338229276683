import React, {useState} from "react";
import CustomTooltip from "../../../../shared/material-ui/CustomTooltip";
import ThemeModeComponent from "./ThemeModeComponent";
import {useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {
    ChangeIsShowProductsList,
    ChangeIsShowEndedProductsList,
    ChangeIsAllowPrintInInvoice,
} from "../../../../store/pos/Pos";
import HoldComponent from "./HoldComponent";
import InstallmentsComponent from "./InstallmentsComponent";
import SideMenuTypeComponent from "../../../layout/setting-side/SideMenuTypeComponent";

const SystemSetting = ({submenuHandler}) => {
    const [isOpen, setIsOpen] = useState(false);

    const ToggleSettingSidebar = () => {
        setIsOpen(!isOpen);
        if (submenuHandler) {
            submenuHandler("", false)
        }
    };

    const posState = useRtkState("Pos");
    const dispatch = useDispatch();

    return (
        <>
            <CustomTooltip title="اعدادات النظام">
                <div className="setting-icon" onClick={ToggleSettingSidebar}>
                    <i className="bi bi-gear"> </i>
                </div>
            </CustomTooltip>

            <div
                className={`system-setting-sidebar ${isOpen === true ? "active" : ""}`}
            >
                <h3 className="header-sidebar">
                    <i className="bi bi-gear"> </i>
                    اعدادات النظام
                    <i className="bi bi-x" onClick={ToggleSettingSidebar}>
                        {" "}
                    </i>
                </h3>
                <ul className="content-sidebar">
                    <li>
                        <h4>
                            <i className="bi bi-palette"> </i>
                            مظهر النظام
                        </h4>
                        <p className="p">اختر الوضع الفاتح او المظلم</p>
                        <ThemeModeComponent/>
                    </li>

                    <li className="dashboard-only">
                        <h4>
                            <i className="bi bi-grid"> </i>
                            شكل القائمة
                        </h4>
                        <p className="p">اختر الشكل الذي يناسبك</p>
                        <SideMenuTypeComponent/>
                    </li>

                    {/* <li className="pos-only">
                        <label className="switch">
                            <p>اظهار قائمة الاصناف</p>
                            <input type="checkbox"
                                   onChange={e => dispatch(ChangeIsShowProductsList(!posState?.is_show_products_list, {}))}
                                   checked={posState?.is_show_products_list}/>
                        </label>
                    </li> */}

                    <li className="pos-only">
                        <label className="switch">
                            <p>اظهار الاصناف المنتهية</p>
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    dispatch(
                                        ChangeIsShowEndedProductsList(
                                            !posState?.is_show_ended_products_list,
                                            {}
                                        )
                                    )
                                }
                                checked={posState?.is_show_ended_products_list}
                            />
                        </label>
                    </li>

                    <li className="pos-only">
                        <label className="switch">
                            <p>طباعة الفاتورة مع الدفع</p>
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    dispatch(
                                        ChangeIsAllowPrintInInvoice(
                                            !posState?.is_allow_print_in_invoice,
                                            {}
                                        )
                                    )
                                }
                                checked={posState?.is_allow_print_in_invoice}
                            />
                        </label>
                    </li>
                    <li className="pos-only btns-holds-inst">
                        <HoldComponent/>
                    </li>
                </ul>
            </div>

            <div className={`setting-sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSettingSidebar}></div>
        </>
    );
};

export default SystemSetting;
