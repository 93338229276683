import React, {useEffect, useState} from "react";
import PaymentMethodsComponent from "../components/PaymentMethodsComponent";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import PayAnAdvancePaymentFromCustomerHook
    from "../../../hooks/system/financial_accounts/PayAnAdvancePaymentFromCustomerHook";
import CreateCapitalPartnerHook from "../../../hooks/system/financial_accounts/CreateCapitalPartnerHook";
import Tree from "react-d3-tree";
import TreeSettingHook from "../../../hooks/system/financial_accounts/TreeSettingHook";
import AxiosInstance from "../../../config/AxiosInstance";


import {createTheme, ThemeProvider, alpha, styled} from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import {prefixer} from "stylis";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import Box from "@mui/material/Box";
import {useSpring, animated} from "@react-spring/web";
import SvgIcon from "@mui/material/SvgIcon";
import Collapse from "@mui/material/Collapse";
import {TreeView} from "@mui/x-tree-view/TreeView";
import {TreeItem, treeItemClasses} from "@mui/x-tree-view/TreeItem";
import TextInput from "../../../shared/inputs/TextInput";


export default ({safes, nodeSelected}) => {

    const {
        currency,
        assets,
        isSubmitLoading,
        formik,
        errors,
        submitForm,
        capitalType,
        ChangeCapitalType,
        assetID,
        ChangeAssetID,
        assetName,
    } = CreateCapitalPartnerHook();

    const [initialDepth, setInitialDepth] = useState(1);
    const [orgChart, setOrgChart] = useState({});
    const {translation, zoom, activeTab, handleTabClick, setActiveTab} = TreeSettingHook();

    const renderTreeData = () => {
        AxiosInstance.get("get-financial-assets", {})
            .then(async function (data) {
                setOrgChart(data?.data?.data ?? {});
            })
            .catch(function ({response}) {
                //
            });
    };

    useEffect(() => {
        renderTreeData();
    }, []);


    /**
     * ******************
     * ****** @MUI ******
     * ******************
     */

    const theme = createTheme({
        direction: "rtl"
    });

    const cacheRtl = createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin]
    });

    function MinusSquare(props) {
        return (
            <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
                {/* tslint:disable-next-line: max-line-length */}
                <path
                    d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
            </SvgIcon>
        );
    }

    function PlusSquare(props) {
        return (
            <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
                {/* tslint:disable-next-line: max-line-length */}
                <path
                    d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
            </SvgIcon>
        );
    }

    function CloseSquare(props) {
        return (
            <SvgIcon className="close" fontSize="inherit" viewBox="0 0 60 60" style={{width: 14, height: 14}}{...props}>
                {/* tslint:disable-next-line: max-line-length */}
                <path d="M0,0v60h60V0H0z M58,58H2V2h56V58z"/>
            </SvgIcon>
        );
    }

    function TransitionComponent(props) {
        const style = useSpring({
            to: {
                opacity: props.in ? 1 : 0,
                transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
            }
        });

        return (
            <animated.div style={style}>
                <Collapse {...props} />
            </animated.div>
        );
    }

    const renderTree = (nodes) => (
        <TreeItem id={`node-id-${nodes.id}`}
                  TransitionComponent={TransitionComponent}
                  onClick={e => ChangeAssetID(e, nodes)} key={nodes.id}
                  nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );
    /**
     * ******************
     * ****** @MUI ******
     * ******************
     */


    return (
        <>
            <form ref={submitForm} onSubmit={formik.handleSubmit}>

                <input type="hidden" name="capital_partner_id" value={nodeSelected?.id}/>

                <div className="flex-radio-box">
                    <input type="radio" id="money" name="capital_type" value="money" checked={capitalType === "money"}/>
                    <label htmlFor="money" onClick={e => ChangeCapitalType("money")}>{"رصيد"}</label>

                    <input type="radio" id="asset" name="capital_type" value="asset" checked={capitalType === "asset"}/>
                    <label htmlFor="asset" onClick={e => ChangeCapitalType("asset")}>{"آصل"}</label>
                </div>

                <h5 className="name-financial"> أضافة راس
                    مال <span>{nodeSelected?.name}</span> {capitalType === "money" ? "(رصيد)" : "(اصول)"}</h5>

                {
                    capitalType === "money" ?
                        (
                            <div className="row">
                                <NormalSelectInput label={"الخزنة"} name="financial_account_id" formik={formik}
                                                   className={"col-12"}
                                                   error={errors?.["financial_account_id"]?.[0] ?? ""}>
                                    {
                                        safes &&
                                        safes.map(function (safe) {
                                            return (
                                                <optgroup key={safe.id} label={safe.name}>
                                                    {safe.accounts.map(function (safe) {
                                                        return (
                                                            <option key={safe.id} value={safe.id}>
                                                                {safe.title}
                                                            </option>
                                                        );
                                                    })}
                                                </optgroup>
                                            );
                                        })
                                    }
                                </NormalSelectInput>

                                <PaymentMethodsComponent errors={errors}/>
                            </div>
                        )
                        :
                        (
                            <>
                                <input type="hidden" name="financial_account_id" value={assetID}/>

                                <div className="tree-view-side" dir="rtl">
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={theme}>
                                            <TreeView
                                                aria-label="customized"
                                                defaultExpanded={[2]}
                                                defaultCollapseIcon={<MinusSquare/>}
                                                defaultExpandIcon={<PlusSquare/>}
                                                defaultEndIcon={<CloseSquare/>}>

                                                {renderTree(orgChart[0])}

                                            </TreeView>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </div>

                                {
                                    assetName &&
                                    <h5 className="name-financial">
                                        <i className="bi bi-check2-circle"> </i>
                                        تم اختيار <span>{assetName}</span> كـآصل راس مال
                                    </h5>
                                }

                                <TextInput
                                    type={"number"}
                                    label={"قيمة الآصل في الوقت الحالي"}
                                    name={`payments[${0}][amount]`}
                                    formik={formik}
                                    className={"col-12"}
                                    error={errors?.[`payments.0.payment_type_id`]?.[0] ?? ""}
                                />
                            </>
                        )
                }

                <NormalTextAreaInput className={"col-12"} label={"ملاحظات"}/>

                <div className="card-footer">
                    <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                </div>

            </form>
        </>
    );
};