import React, { useRef, useState } from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import IndexHook from "../../../hooks/cruds/sales/IndexHook";
import CustomTooltip from '../../../shared/material-ui/CustomTooltip';
import {Link} from "react-router-dom";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler
    } = IndexHook();

    const textRefs = useRef([]);
    const [copiedIndex, setCopiedIndex] = useState(-1);
  
    const handleClick = (index) => {
      const textToCopy = textRefs.current[index].innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopiedIndex(index);
  
      setTimeout(() => {
        setCopiedIndex(-1);
      }, 4000);
    };

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>
                    <NormalInput onChangeHandler={searchKeyHandler}
                                 name={'search_key'}
                                 className={'col-md-4 col-sm-6 col-10'}
                                 placeholder={'ابحث ...'}/>
                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {items.map(function (item,index) {
                        return (
                            <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} key={item.id}>
                                <td className="prevent-print">
                                    <TdSelectItemComponent is_exportable={false} item={item}/>
                                </td>

                                <td>
                                  <CustomTooltip title={index === copiedIndex ? 'تم النسخ' : 'اضغط للنسخ'}>
                                  <span ref={(ref) => (textRefs.current[index] = ref)} onClick={() => handleClick(index)} className={`text-green flex-sp ${index === copiedIndex ? 'copied' : ''}`}>
                                    <i className={`bi ${index === copiedIndex ? 'bi-check-circle' : 'bi-files'}`}></i>
                                    {(item?.reference_code ?? null)}
                                    </span>
                                    </CustomTooltip>
                                  </td>
                                <td>{(item?.warehouse?.name ?? null)}</td>
                                <td>{(item?.customer?.name ?? null)}</td>
                                <td>{(item?.grand_total ?? null)} {currency}</td>
                                <td>{(item?.date ?? null)}</td>

                                <td className="prevent-print">

                                    <div className="flex-btn-table">
                                        <Link
                                            to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/show/' + (item?.slug ?? null))}
                                            className="btn-info"><i className="bi bi-eye"> </i></Link>
                                    </div>

                                    {/*<TableButtonsComponent*/}
                                    {/*    redirect_to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/edit/' + (item?.slug ?? null))}*/}
                                    {/*    item={item}*/}
                                    {/*    deleteHandler={deleteHandler}/>*/}
                                </td>
                            </tr>
                        );
                    }) }
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
