import React, {useEffect, useState} from 'react';
import HeaderComponent from "../components/layout/HeaderComponent";
import MenuComponent from "../components/layout/MenuComponent";
import SidebarComponent from "../components/layout/SidebarComponent";
import {Outlet} from "react-router-dom";
import TablePrint from "../components/table/TablePrint";
import {allow_vertical_sidebar_helper, prefixRouterPath, useRtkState, warningSweetAlert} from "../config/helper";
import FooterComponent from '../components/layout/header/FooterComponent';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AxiosInstance from "../config/AxiosInstance";
import {setCookie} from "../hooks/global/useCookie";
import Page404 from "../pages/errors/Page404";
import LoadingComponent from "../components/global/LoadingComponent";

import {useLocation} from 'react-router-dom';


const DefaultLayout = () => {

    const allow_vertical_sidebar = allow_vertical_sidebar_helper();

    const [isShowSideBar, setIsShowSideBar] = useState(false);

    const [isLayoutLoading, setLayoutLoading] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setLayoutLoading(false)
        }, 500);
    }, []);

    return (
        <>
            {/* Page Loader */}
            {isLayoutLoading && <LoadingComponent otherClasses={'page-loader'}/>}
            {/* Page Loader */}

            {/* Main Wrapper */}
            <div className="main-wrapper">

                {/* Header */}
                <HeaderComponent/>
                {/* Header */}

                {/* {
                    !allow_vertical_sidebar ?
                        <MenuComponent/>
                        :
                        <SidebarComponent/>
                } */}

                {/* Content */}
                <div className={`page-wrapper ${allow_vertical_sidebar ? 'sidebarMinHeight' : 'page-wrapper-one'}`}>
                    <div className="content">
                        {/*{permissions.includes("suppliers") ? <Outlet/> : <Page404/>}*/}
                        <Outlet/>
                    </div>
                    <FooterComponent/>
                </div>
                {/* Content */}

            </div>
            {/* Main Wrapper */}

            <TablePrint/>

            <ToastContainer/>
        </>
    );
};

export default DefaultLayout;
