import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'warehouses-products';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'products';
    const module_endpoint = 'products';
    const module_name = 'الاصناف';

    const slug = 'تعديل بيانات ' + (oldItems?.name ?? '----');


    const [productCategories, setProductCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "products");

        if (permissions.length > 0 && permissions.includes("products")) {
            AxiosInstance.get('product_categories/categories-with-its-subcategories', {}).then(async function ({data}) {
                await setProductCategories(data.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get('brands', {}).then(async function ({data}) {
                await setBrands(data.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get('units', {}).then(async function ({data}) {
                await setUnits(data.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setWarehouses(data.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                const old_item = data.data;
                await setPreviewImage(old_item?.image_url ?? '');
                await setOldItems(old_item);

                let opening_balance_purchases_items = old_item?.opening_balance_purchases_items ?? [];
                await setStocks(opening_balance_purchases_items);

                /* set inputs values */
                await formik.setValues({
                    name: old_item?.name,
                    code: old_item?.code,
                    sku: old_item?.sku,
                    barcode_symbol: old_item?.barcode_symbol,

                    product_category_id: old_item?.product_category?.id,
                    brand_id: old_item?.brand?.id,
                    default_unit_id: old_item?.default_unit?.id,
                    purchase_unit_id: old_item?.purchase_unit?.id,
                    sale_unit_id: old_item?.sale_unit?.id,

                    stock_alert: old_item?.stock_alert,

                    min_product_price: old_item?.min_product_price,
                    max_product_price: old_item?.max_product_price,
                    product_price: old_item?.product_price,

                    tax_type: old_item?.tax_type,
                    first_tax: old_item?.first_tax,
                    second_tax: old_item?.second_tax,

                    notes: old_item?.notes,
                    equivalent_unit_value: old_item?.equivalent_unit_value,
                });

                setPurchaseUnitName(old_item?.purchase_unit?.name);
                setSaleUnitName(old_item?.sale_unit?.name);
                setIsOldItemsLoading(false);
            }).catch(function (response) {
                if (response?.data?.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                } else {
                    // console.log(response);
                }
            });
        }

    }, [permissions]);


    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            type: '',
            image: '',

            name: '', /* string */
            code: '', /* string */
            sku: '', /* string */
            barcode_symbol: '', /* string */

            product_category_id: '', /* fk */
            brand_id: '', /* fk */
            default_unit_id: '', /* fk */
            purchase_unit_id: '', /* fk */
            sale_unit_id: '', /* fk */

            product_price: '', /* integer */
            stock_alert: '', /* integer */
            tax_type: '', /* integer */
            first_tax: '', /* integer */
            second_tax: '', /* integer */
            notes: '', /* string */

            equivalent_unit_value: '', /* integer */
        },
        validationSchema: Yup.object({

            name: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            code: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            sku: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            // barcode_symbol: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
            //     .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
            //     .required(trans('This_field_is_required')),

            product_category_id: Yup.string().required(trans('This_field_is_required')),
            // brand_id: Yup.string().required(trans('This_field_is_required')),
            // default_unit_id: Yup.string().required(trans('This_field_is_required')),
            purchase_unit_id: Yup.string().required(trans('This_field_is_required')),
            sale_unit_id: Yup.string().required(trans('This_field_is_required')),

            product_price: Yup.number().required(trans('This_field_is_required')),
            stock_alert: Yup.number().required(trans('This_field_is_required')),
            equivalent_unit_value: Yup.number().required(trans('This_field_is_required')),

            // tax_type: Yup.number().required(trans('This_field_is_required')),
            // first_tax: Yup.number().required(trans('This_field_is_required')),
            // second_tax: Yup.number().required(trans('This_field_is_required')),

            // notes: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
            //     .max(200, trans('The_name_must_not_be_greater_than_200_characters'))
            //     .required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const [previewImage, setPreviewImage] = useState('');

    const resetForm = () => {
        setPreviewImage('');
        setErrors({});
    }

    const [purchase_unit_name, setPurchaseUnitName] = useState("");
    const [sale_unit_name, setSaleUnitName] = useState("");

    const handleOnFormChange = (event) => {
        /* if purchase unit select changed */
        if (event?.target?.name === 'purchase_unit_id') {
            const purchase_unit = event?.target?.options[event?.target?.selectedIndex].text;
            setPurchaseUnitName(purchase_unit);
        }

        /* if sale unit select changed */
        if (event?.target?.name === 'sale_unit_id') {
            const sale_unit = event?.target?.options[event?.target?.selectedIndex].text;
            setSaleUnitName(sale_unit);
        }
    };

    const [stocks, setStocks] = useState([]);

    const addStock = () => {
        let stock = {warehouse_id: "", product_cost: "", qty: ""};
        setStocks([...stocks, stock]);
    };

    const deleteStock = (id) => {
        const updatedStocks = stocks.filter((warehouse, index) => index !== id);
        setStocks(updatedStocks);
    };

    const handleInputStockChange = (e, index) => {
        const {value} = e.target;
        let new_stocks = [];
        stocks.forEach(function (stock, loop_index) {
            if (loop_index == index) {
                new_stocks[index] = value;
            } else {
                new_stocks.push(stock);
            }
        });
        setStocks(new_stocks);
    };

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        isOldItemsLoading,
        previewImage,
        setPreviewImage,
        productCategories,
        brands,
        units,
        handleOnFormChange,
        purchase_unit_name,
        sale_unit_name,

        warehouses,
        stocks,
        addStock,
        deleteStock,
        handleInputStockChange,
    };
}