import {prefixRouterPath} from "./helper";
import menus from "./menus";

/**
 * (Hint) Before add new permission
 * 1- make sure this "permission_id" returned in permissions (backend api)
 * 2- add route here with ( permission: {id: "permission_id", name: "permission_name"} ),
 */


export default [
    {
        route: '',
        menu_name: 'الصفحة الرئيسية',
        icon: "",
        sub_menus: [
            {
                route: prefixRouterPath("/"),
                menu_name: 'الصفحة الرئيسية',
                permission: {id: 44, name: "home_page"},
            }
        ]
    },

    /** get all menus list */
    ...menus,

    /** other permissions */
    {
        route: 'pos',
        menu_name: 'نقطة البيع',
        icon: "",
        sub_menus: [
            {
                route: prefixRouterPath("pos/start-session"),
                menu_name: 'نقطة البيع',
                permission: {id: 23, name: "pos"},
            }
        ]
    },
]