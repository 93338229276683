import React from 'react';
import {trans, useRtkState, warningSweetAlert} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {DeleteInvoiceProductAction, UpdateInvoiceProductQtyAction} from "../../../../store/pos/Pos";
import useGetCurrentProductInvoiceQty from "../../../../hooks/system/pos/useGetCurrentProductInvoiceQty";

const InvoiceProductsComponent = ({formik, errors}) => {

    const state = useRtkState('Pos');
    const dispatch = useDispatch();
    const currency = useRtkState('Settings').settings?.currency;

    /* UpdateInvoiceProductQtyAction */
    const UpdateInvoiceProductQty = (product, rate) => {
        if (product['warehouse_manage_stock_quantity'] <= useGetCurrentProductInvoiceQty(state, product) && rate > 0) {
            warningSweetAlert(trans("This Quantity isn't available in stock") + " " + state?.warehouse_selected?.name);
            return false;
        }
        dispatch(UpdateInvoiceProductQtyAction({product, rate}, {}))
    }

    /* DeleteInvoiceProductAction */
    const DeleteInvoiceProduct = (product) => {
        dispatch(DeleteInvoiceProductAction({product}, {}))
    }

    return (
        <div className="list-prd-choose">
            <table className="table table-custom">
                <thead>
                <tr>
                    <th>الصنف</th>
                    <th>السعر</th>
                    <th>الكمية</th>
                    <th>الاجمالي ({currency})</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>
                {
                    (state?.invoice_products && state?.invoice_products.length > 0) ?
                        (
                            state?.invoice_products.map(function (product, index) {
                                return (
                                    <>
                                        {/* hidden form submit "Not better way must be in main submit method action" */}
                                        <input type="hidden" name={`sale_items[${index}][product_id]`}
                                               value={product?.id}/>
                                        <input type="hidden" name={`sale_items[${index}][product_price]`}
                                               value={product?.product_price}/>
                                        <input type="hidden" name={`sale_items[${index}][tax_value]`}
                                               value={0}/>
                                        <input type="hidden" name={`sale_items[${index}][discount_type]`}
                                               value={0}/>
                                        <input type="hidden" name={`sale_items[${index}][discount_value]`}
                                               value={0}/>
                                        <input type="hidden" name={`sale_items[${index}][sale_unit_id]`}
                                               value={product?.sale_unit?.id}/>
                                        <input type="hidden" name={`sale_items[${index}][quantity]`}
                                               value={product?.quantity}/>
                                        {/* hidden form submit "Not better way must be in main submit method action" */}

                                        <tr>
                                            <td>{product?.name}</td>

                                            <td><p>{product?.product_price}</p></td>

                                            <td className="quentity-td">
                                                <div className="p-quentity">
                                                    <button onClick={e => UpdateInvoiceProductQty(product, 1)}
                                                            className="qty-btn qty-btn-plus" type="button"
                                                            aria-label="btn">
                                                        <i className="bi bi-plus">
                                                        </i>
                                                    </button>

                                                    <input type="number"
                                                           name="quantity[]"
                                                           value={product?.quantity}
                                                           className="input-qty"/>

                                                    <button onClick={e => UpdateInvoiceProductQty(product, -1)}
                                                            className="qty-btn qty-btn-minus" type="button">
                                                        <i className="bi bi-dash">
                                                        </i>
                                                    </button>
                                                </div>
                                            </td>

                                            <td><p>{product?.sub_total}</p></td>

                                            <td>
                                                <div className="flex-btn-table">
                                                    <a onClick={e => DeleteInvoiceProduct(product)} href="#!"
                                                       className="btn-remove">
                                                        <i className="bi bi-trash">
                                                        </i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })
                        )
                        : (<tr className="no-dts">
                            <td colSpan="5">لا يتوفر بيانات</td>
                        </tr>)
                }
                </tbody>
            </table>
        </div>
    );
};

export default InvoiceProductsComponent;