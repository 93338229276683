import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/cruds/expenses/EditHook";
import SelectInput from "../../../shared/inputs/SelectInput";
import DateInput from "../../../shared/inputs/DateInput";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";


const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        expense_categories
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">
                        <SelectInput label={'المخزن'} name={'warehouse_id'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['warehouse_id']?.[0] ?? ''}>
                            <option value="" defaultValue>اختر</option>
                            {
                                warehouses && warehouses.map(function (warehouse) {
                                    return (<option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>);
                                })
                            }
                        </SelectInput>

                        <SelectInput label={'قسم الخاص بالنفقة'}
                                     name={'expense_category_id'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['expense_category_id']?.[0] ?? ''}>
                            <option value="" defaultValue>اختر</option>
                            {
                                expense_categories && expense_categories.map(function (expense_category) {
                                    return (<option key={expense_category.id}
                                                    value={expense_category.id}>{expense_category.name}</option>);
                                })
                            }
                        </SelectInput>

                        <DateInput label={'التاريخ'}
                                   name={'date'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['dob']?.[0] ?? ''}/>

                        <TextInput
                            type="number"
                            label={"اجمالي المدفوع"}
                            name={"amount"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-3 col-sm-6 col-12"}
                            error={errors?.["phone"]?.[0] ?? ""}/>

                        <TextAreaInput label={'تفاصيل النفقة (المصروف)'}
                                       name={'details'}
                                       formik={formik}
                                       allow_formik_value={true}
                                       className={'col-lg-12 col-sm-6 col-12'}
                                       error={errors?.['details']?.[0] ?? ''}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Edit;