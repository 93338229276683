import React, {useEffect, useState} from 'react';
import VoucherComponent from "../../../components/financial_accounts/VoucherComponent";
import {endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import AxiosInstance from "../../../config/AxiosInstance";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

const VoucherTransaction = () => {

    const currency = useRtkState('Settings').settings?.currency;
    const params = useParams();

    const [voucherTransaction, setVoucherTransaction] = useState({});

    useEffect(() => {
        AxiosInstance.get(`VoucherTransaction?transaction_id=${params?.transaction_id}`, {}).then(async function ({data}) {
            let voucherTransaction = data?.data;
            console.log({voucherTransaction})
            await setVoucherTransaction(voucherTransaction);
        }).catch(function ({response}) {
            //
        });
    }, []);


    return (
        <div>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {" طباعة الايصال"} </title>
            </Helmet>
            <VoucherComponent voucherTransaction={voucherTransaction} currency={currency}/>
        </div>
    );
};

export default VoucherTransaction;