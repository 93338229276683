import React from "react";

const SaleInfoComponent = ({ invoice, invoice_type }) => {
  return (
    <>
      <div className="bill-head">
        <p>
          نوع الفاتورة :
          {invoice_type == "sale" ? 
          (<span>مبيعات</span>) : 
          (<span>مرتجع مبيعات</span>)}
        </p>
        <em></em>
        <p>
          تاريخ الفاتورة :<span>{invoice?.date}</span>
        </p>
      </div>
      <div className="dts-bill">
        <p>
          رقم الفاتورة :<span>{invoice?.reference_code}</span>
        </p>
        <p>
          المخزن :<span>{invoice?.warehouse?.name}</span>
        </p>
        <p>
          العميل :<span>{invoice?.customer?.name}</span>
        </p>
      </div>
    </>
  );
};

export default SaleInfoComponent;
