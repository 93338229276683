import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";

/* create hook */
import CreateHook from "../../../hooks/cruds/users/CreateHook";
import NumberInput from "../../../shared/inputs/NumberInput";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";


const Create = () => {

    const {
        module_name,
        slug,
        formik,
        roles,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>
            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <TextInput label={'الاسم الاول'}
                                   name={'firstname'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'الاسم الثاني'}
                                   name={'lastname'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'البريد الالكتروني'}
                                   name={'email'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['email']?.[0] ?? ''}/>

                        <NumberInput label={'الهاتف'}
                                     name={'phone'}
                                     formik={formik}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['phone']?.[0] ?? ''}/>


                        <SelectInput label="الصلاحية" name="role_id" formik={formik}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['role_id']?.[0] ?? ''}>
                            <option value="" defaultValue disabled={true}>اختر</option>
                            {
                                roles && roles.map(function (role) {
                                    return (<option key={role.id} value={role.id}>{role.name}</option>);
                                })
                            }
                        </SelectInput>


                        <TextInput type={'password'}
                                   label={'كلمة المرور'}
                                   name={'password'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['password']?.[0] ?? ''}/>

                        <TextInput type={'password'}
                                   label={'تاكيد كلمة المرور'}
                                   name={'password_confirmation'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['password_confirmation']?.[0] ?? ''}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                        <RestButton className={'btn btn-danger'}/>
                    </div>

                </form>
            </CardComponent>
        </>
    );

};

export default Create;