import React from 'react';
import {deleteBtn} from "../../../config/images-path";
import {confirmDelete, deletedSweetAlert, errorSweetAlert, trans, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import AxiosInstance from "../../../config/AxiosInstance";
import {reRenderItems} from "../../../store/table/slices/tableSlice";

const DeleteAllComponent = () => {

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items_selected = table?.items_selected;
    const deleteAllHandler = () => {
        let ids = [];
        items_selected.forEach(function (item_selected) {
            ids.push(item_selected?.id);
        })
        // console.log(ids)
        confirmDelete(async () => {
            await AxiosInstance.post(`${table?.endpoint}/bulk_delete`, {ids: ids}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }

    return (
        <>
            <li>
                <a onClick={deleteAllHandler}
                   data-bs-toggle="tooltip"
                   data-bs-placement="top"
                   title={trans('deleteAllSelected')}>
                    <img src={deleteBtn} alt="img"/>
                </a>
            </li>
        </>
    );
};

export default DeleteAllComponent;
