import React from 'react';


import {logo} from '../../../config/images-path'
import {Link} from "react-router-dom";
import {prefixRouterPath, useRtkState} from "../../../config/helper";

const LogoComponent = ({is_allow_redirect = true}) => {
    const Settings = useRtkState('Settings').settings;
    const permissions = useRtkState('Settings')?.permissions;


    return (
        <div className="header-left">
            {
                Settings?.logo &&
                (
                    (permissions.length > 0) && permissions.includes("home_page") ?
                        (
                            <a href={is_allow_redirect ? prefixRouterPath('') : ''} className="logo">
                                <img src={Settings?.logo} alt="logo"/>
                            </a>
                        )
                        :
                        (
                            <a href={"#!"} className="logo">
                                <img src={Settings?.logo} alt="logo"/>
                            </a>
                        )
                )

            }

        </div>
    );
};

export default LogoComponent;
