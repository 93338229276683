import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {prefixRouterPath} from "../../config/helper";
import reports_sub_menus from "../../config/reports_sub_menus";
import AxiosInstance from "../../config/AxiosInstance";

const StatisticCount = () => {

    const [items_count, setItemCount] = useState([]);

    useEffect(() => {
        AxiosInstance.get("get-home-page-modules-items-count", {})
            .then(async function ({data}) {
                await setItemCount(data.data);
            })
            .catch(function ({response}) {
                //
            });
    }, []);

    return (
        <ul className="list-pages-home">
            <li>
                <Link to={prefixRouterPath("purchases-module/purchases")}>
                    <i className="bi bi-basket"> </i>
                    <h3>
                        <span>{items_count['purchases_count'] ?? 0}</span>
                        <p>المشتريات</p>
                    </h3>
                </Link>
            </li>
            <li>
                <Link to={prefixRouterPath("purchases-module/return_purchases")}>
                    <i className="bi bi-repeat"> </i>
                    <h3>
                        <span>{items_count['return_purchases_count'] ?? 0}</span>
                        <p>مرتجعات المشتريات</p>
                    </h3>
                </Link>
            </li>
            <li>
                <Link to={prefixRouterPath("sales-module/sales")}>
                    <i className="bi bi-cash-stack"> </i>
                    <h3>
                        <span>{items_count['sales_count'] ?? 0}</span>
                        <p>المبيعات</p>
                    </h3>
                </Link>
            </li>
            <li>
                <Link to={prefixRouterPath("sales-module/return_sales")}>
                    <i className="bi bi-repeat"> </i>
                    <h3>
                        <span>{items_count['return_sales_count'] ?? 0}</span>
                        <p>مرتجعات المبيعات</p>
                    </h3>
                </Link>
            </li>
            <li>
                <Link to={prefixRouterPath("product-settings/products")}>
                    <i className="bi bi-grid"> </i>
                    <h3>
                        <span>{items_count['products'] ?? 0}</span>
                        <p>الاصناف</p>
                    </h3>
                </Link>
            </li>

            <li>
                <Link to={prefixRouterPath("reports/index")}>
                    <i className="bi bi-file-earmark-text"> </i>
                    <h3>
                        <span>{reports_sub_menus().length}</span>
                        <p>التقارير</p>
                    </h3>
                </Link>
            </li>

        </ul>
    );
};

export default StatisticCount;