import React, {useEffect, useRef, useState} from 'react';
import ReactECharts from "echarts-for-react";
import {useRtkState} from "../../config/helper";
import AxiosInstance from "../../config/AxiosInstance";
import LoadingComponent from "../global/LoadingComponent";
import BarSkeleton from '../../shared/Skeleton/Charts/BarSkeleton';

const MostProductsDuringNumberOfSales = ({data, values, isLoading = true}) => {

    const settings = useRtkState("Settings");
    let chartColor = settings?.systemMode === "dark" ? "#caccdb" : "#212b36";
    let lineColor = settings?.systemMode === "dark" ? "#193156" : "#e5e5e5";

    const chartRef = useRef(null);
    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.getEchartsInstance) {
                chartRef.current.getEchartsInstance().resize();
            }
        };
        const resizeTimeout = setTimeout(resizeChart, 100);
        window.addEventListener("resize", resizeChart);
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", resizeChart);
        };
    }, []);


    const bar = {
        grid: {
            top: "3%",
            left: "1%",
            right: "1%",
            bottom: "1%",
            containLabel: true,
        },
        xAxis: [
            {
                data: data,
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        fontFamily: "regular",
                        color: chartColor,
                    },
                    width: 150,
                    overflow: "break",
                    lineHeight: 18,
                },
            },
        ],
        yAxis: {
            type: "value",
            splitLine: {
                lineStyle: {
                    type: "dashed",
                    color: lineColor,
                },
            },
            axisLabel: {
                textStyle: {
                    color: chartColor,
                },
            },
        },
        tooltip: {
            textStyle: {
                fontFamily: "regular",
            },
            axisPointer: {
                type: "shadow",
            },
        },
        series: [
            {
                type: "bar",
                barWidth: '20%',
                color: "#1587b0",
                itemStyle: {
                    borderRadius: [50, 50, 0, 0],
                },
                data: values,
            },
        ],
    };

    return (
        <div className="position-relative">
            <h3 className="title-chart">
                <i className="bi bi-bar-chart-line"> </i>
                أكثر الاصناف من حيث قيمة المبيعات
            </h3>
            { !isLoading ?  <ReactECharts ref={chartRef} option={bar}> </ReactECharts> : (<BarSkeleton/>)}

        </div>
    );
};

export default MostProductsDuringNumberOfSales;