import React from 'react';
import DeleteBtnTableComponent from "./table_btns/DeleteBtnTableComponent";
import EditBtnTableComponent from "./table_btns/EditBtnTableComponent";

const TableButtonsComponent = ({item, deleteHandler, redirect_to}) => {
    return (
        <>
            <div className="flex-btn-table">
                <EditBtnTableComponent redirect_to={redirect_to}/>
                <DeleteBtnTableComponent item={item} deleteHandler={deleteHandler}/>
            </div>
        </>
    );
};

export default TableButtonsComponent;
