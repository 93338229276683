import React from "react";

const PurchaseInfoComponent = ({invoice, invoice_type}) => {
    return (
        <>

            <div className="bill-head">
              <p>نوع الفاتورة :{invoice_type == "purchase" ? <span>مشتريات</span> : <span>مرتجع مشتريات</span>}</p>
              <p>تاريخ الفاتورة :<span>{invoice?.date}</span></p>
              <em> </em>
            </div>

            <div className="dts-bill">
                <p>رقم الفاتورة :<span>{invoice?.reference_code}</span></p>
                <p>المخزن :<span>{invoice?.warehouse?.name}</span></p>
                <p>المورد :<span>{invoice?.supplier?.name}</span></p>
            </div>

        </>
    );
};

export default PurchaseInfoComponent;
