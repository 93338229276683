import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import {ResetAllInitialValues, SetInvoiceType, SetIsReturnInvoice} from "../../../store/invoices/InvoiceOperations";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const submitForm = useRef();
    const navigate = useNavigate();
    const prefix = 'accounts-balances';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'receipt_vouchers';
    const module_endpoint = 'receipt_vouchers';
    const module_name = 'سندات القبض';
    const slug = 'اضافة سند قبض جديد';
    const currency = useRtkState('Settings').settings?.currency;

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [sale, setSale] = useState(null);
    const [customer_account_transactions, setCustomerAccountTransactions] = useState([]);
    const [rest_sale_amount, setRestSaleAmount] = useState(0);
    const [operation_type, setOperationType] = useState(2);
    const [date, setDate] = useState(new Date().toJSON().slice(0, 10));
    const [sale_id, setSaleId] = useState(null);
    const [bond_type, setBondsType] = useState("3");
    const [safes, setSafes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [sales, setSales] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [purchase, setPurchase] = useState(null);
    const [purchases, setPurchases] = useState([]);
    const [purchase_id, setPurchaseId] = useState(null);
    const [rest_purchase_amount, setRestPurchaseAmount] = useState(0);
    const [supplier_account_transactions, setSupplierAccountTransactions] = useState([]);

    const formik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: Yup.object({
            // amount: Yup.number().required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                // navigate(prefixRouterPath(route_endpoint));
                if (sale_id) {
                    CustomerBalanceAccountTransactionsAxios(sale_id)
                }
                if (purchase_id) {
                    SupplierBalanceAccountTransactionsAxios(purchase_id);
                }
                successSweetAlert();
                setErrors({});
                setIsSubmitLoading(false);
                document.getElementById("SubmitFormID").reset();
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = (e) => {
        setErrors({});
    }

    const SetBondsTypeAction = (e) => {
        setSale(null);
        setPurchase(null)
        setSupplierAccountTransactions([]);
        setCustomerAccountTransactions([]);
        setBondsType(e?.target?.value)
    }

    const ChangeInitialDateHandler = (event) => {
        let value = event?.target?.value;
        setDate(value);
    };

    useEffect(() => {
        AxiosInstance.get('safes?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSafes(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('customers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setCustomers(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSuppliers(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    const CustomerBalanceAccountTransactionsAxios = (sale_id) => {
        if (sale_id) {

            setSaleId(sale_id);

            AxiosInstance.get(`receipt_vouchers/get-customer-balance-account-transactions?sale_id=${sale_id}`, {}).then(async function ({data}) {
                await setCustomerAccountTransactions(data?.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(`accounts-balances/total-balances-for-customers?sale_id=${sale_id}`, {}).then(async function ({data}) {
                let transactions_amount_for_purchase = data?.data?.transactions_amount_for_sale;
                await setRestSaleAmount(transactions_amount_for_purchase);
            }).catch(function ({response}) {
                //
            });
        }
    }

    const SupplierBalanceAccountTransactionsAxios = (purchase_id) => {

        setPurchaseId(purchase_id);

        if (purchase_id) {
            AxiosInstance.get(`financial-transactions/account-statement/return_purchases?purchase_id=${purchase_id}`, {}).then(async function ({data}) {
                await setSupplierAccountTransactions(data?.data);
                console.log({data: data?.data});
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get(`accounts-balances/total-balances-for-suppliers?purchase_id=${purchase_id}`, {}).then(async function ({data}) {
                let transactions_amount_for_purchase = data?.data?.transactions_amount_for_purchase;
                await setRestPurchaseAmount(transactions_amount_for_purchase);
            }).catch(function ({response}) {
                //
            });
        }
    }

    const handleOnFormChange = (event) => {
        if (event?.target?.name === 'customer_id') {
            let customer_id = event?.target?.value;
            AxiosInstance.get(`sales?sort_type=asc&sort_attribute=id&customer_id=${customer_id}`, {}).then(async function ({data}) {
                let sales = data.data;
                console.log(sales);
                if (sales.length > 0) {
                    await setSales(data.data);
                } else {
                    setSales([]);
                }
            }).catch(function ({response}) {
                //
            });
        }

        if (event?.target?.name === 'sale_id') {
            let sale_id = event?.target?.value;
            setSale(null);
            if (sale_id) {
                sales.forEach(function (sale) {
                    if (sale.id == sale_id) {
                        setSale(sale);
                        return false;
                    }
                });
                CustomerBalanceAccountTransactionsAxios(sale_id);
            } else {
                setSale(null);
            }
        }

        if (event?.target?.name === 'supplier_id') {
            let supplier_id = event?.target?.value;
            setPurchase(null);
            AxiosInstance.get(`purchases?sort_type=asc&sort_attribute=id&supplier_id=${supplier_id}`, {}).then(async function ({data}) {
                let purchases = data.data;
                if (purchases.length > 0) {
                    await setPurchases(data.data);
                } else {
                    setPurchases([]);
                }
            }).catch(function ({response}) {
                //
            });
        }

        if (event?.target?.name === 'purchase_id') {
            let purchase_id = event?.target?.value;
            setPurchase(null);
            if (purchase_id) {
                purchases.forEach(function (purchase) {
                    if (purchase.id == purchase_id) {
                        setPurchase(purchase);
                        return false;
                    }
                });
                SupplierBalanceAccountTransactionsAxios(purchase_id);
            } else {
                setPurchase(null);
            }
        }

        if (event?.target?.name === 'operation_type') {
            let operation_type = event?.target?.value;
            setOperationType(operation_type);
        }
    };

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeInitialDateHandler,
        date,
        bond_type,
        safes,
        handleOnFormChange,
        currency,
        customers,
        sales,
        sale,
        customer_account_transactions,
        rest_sale_amount,
        operation_type,
        suppliers,
        purchases,
        rest_purchase_amount,
        supplier_account_transactions,
        purchase,
        SetBondsTypeAction,
    };
}