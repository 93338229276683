import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";
import SelectInput from "../../../shared/inputs/SelectInput";

/* edit hook */
import EditHook from "../../../hooks/cruds/product_categories/EditHook";


const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        categories,
        previewImage,
        setPreviewImage
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">
                    <SelectInput label={'القسم الرئيسي'} name={'parent_id'} formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-4 col-sm-6 col-12'}
                                     error={errors?.['parent_id']?.[0] ?? ''}>
                            <option value="" defaultValue>اضافة قسم رئيسي</option>
                            {
                                categories && categories.map(function (category) {
                                    return (<option key={category.id} value={category.id}>{category.name}</option>);
                                })
                            }
                        </SelectInput>

                        <TextInput label={'الاسم'}
                                   name={'name'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        {/*<ImageInput label={'الصورة'}*/}
                        {/*            name={'image'}*/}
                        {/*            formik={formik}*/}
                        {/*            previewImage={previewImage}*/}
                        {/*            setPreviewImage={setPreviewImage}*/}
                        {/*            className={'col-lg-8 col-sm-12 col-12'}*/}
                        {/*            error={errors?.['image']?.[0] ?? ''}/>*/}

                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Edit;