import React from 'react';
import {useRtkState} from "../../config/helper";

const ShowInvoiceComponent = () => {

    const InvoiceOperations = useRtkState('InvoiceOperations');

    const tax_rate = InvoiceOperations?.tax_rate;
    const tax_amount = InvoiceOperations?.tax_amount;

    const discount_type = InvoiceOperations?.discount_type;
    const discount_rate = InvoiceOperations?.discount_rate;
    const discount_amount = InvoiceOperations?.discount_amount;

    const shipping = InvoiceOperations?.shipping;

    const sub_grand_total = InvoiceOperations?.sub_grand_total;
    const grand_total = InvoiceOperations?.grand_total;

    const currency = useRtkState("Settings").settings?.currency;

    return (
        <ul className="total-order">
            <li>
                <h4>الاجمالي الفرعي</h4>
                <h5>
                    <span> {sub_grand_total} </span>
                    {currency}
                </h5>
            </li>
            <li className="discount">
                <h4>الخصم </h4>
                <h5>
                    {
                        (discount_type === 1)
                            ?
                            (<><span> {discount_rate} </span> % </>)
                            :
                            (<><span> {discount_amount} </span> {currency} </>)
                    }
                </h5>
            </li>
            <li>
                <h4>الضريبة %</h4>
                <h5>
                    (% <span> {tax_rate} </span>)<span> {tax_amount} </span>
                    {currency}
                </h5>
            </li>
            <li>
                <h4>الشحن</h4>
                <h5>
                    <span> {shipping} </span>
                    {currency}
                </h5>
            </li>

            <li className="total">
                <h4>اجمالي الفاتورة</h4>
                <h5>
                    <span> {grand_total} </span>
                    {currency}
                </h5>
            </li>
        </ul>
    );
};

export default ShowInvoiceComponent;