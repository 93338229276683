import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {getBearToken, prefixRouterPath, trans, useRtkState, warningSweetAlert} from "./config/helper";

import authenticated_routes from './routes/authenticated'; /* authenticated */
import guested_routes from './routes/guested';
import {useEffect} from "react";
import {useDispatch} from "react-redux"; /* guested */
import {
    ChangeSideMenuTypeAction,
    ChangeSystemModeAction,
    fetchAuth,
    fetchSettings,
    SetUserPermissions
} from "./store/settings/SettingsSlice";
import AxiosInstance from "./config/AxiosInstance";
import {APP_URL} from "./config/constants";
import {getCookie, removeCookie} from "./hooks/global/useCookie";
import {ChangeIsShowProductsList} from "./store/pos/Pos";

const router = createBrowserRouter(getBearToken() ? authenticated_routes : guested_routes);

function App() {

    const settings = useRtkState('Settings');
    const dispatch = useDispatch();

    useEffect(function () {
        /** check authentication */
        AxiosInstance.get(`${APP_URL}auth/profile`, {}).then(async function ({data}) {
            dispatch(fetchAuth()); // fetch auth and store it in redux

            let permissions = data?.data;
            dispatch(SetUserPermissions(permissions ?? null, {})); // fetch permissions

        }).catch(function ({response}) {
            if (response?.data?.status === 401 && window.location.href.indexOf("login") < 0) {
                removeCookie('token');
                window.location.href = prefixRouterPath('login');
            }
        });

        /** adjust System Mode */
        let systemMode = getCookie('systemMode');
        dispatch(ChangeSystemModeAction((systemMode === null ? settings?.systemMode : systemMode), {}));

        /** adjust Side Type */
        let sideMenuType = getCookie('SideMenuType');
        dispatch(ChangeSideMenuTypeAction((sideMenuType === null ? settings?.SideMenuType : sideMenuType), {}));

        /** fetch settings*/
        dispatch(fetchSettings());
    }, [])


    return (
        /* routers */
        <RouterProvider router={router}/>
        /* routers */
    );
}

export default App;
