import React from 'react';

import {logout} from '../../../config/images-path'
import useAutoClose from "../../../hooks/global/useAutoClose";
import {prefixRouterPath, useRtkState} from "../../../config/helper";
import {Link, useNavigate} from "react-router-dom";
import LogoutHook from '../../../hooks/system/LogoutHook';

const ProfileComponent = ({submenuHandler}) => {
    const auth = useRtkState('Settings')?.auth;

    const navigate = useNavigate();
    const logoutHandler = async (e) => {
        e.preventDefault();
        LogoutHook(navigate);
    }

    const permissions = useRtkState('Settings').permissions;


    const {menu, isOpen, openHandle, closeSubmenuHandler} = useAutoClose(submenuHandler);

    return (
        <li className="nav-item dropdown has-arrow main-drop">

            <a onClick={openHandle} className={`${isOpen ? 'show' : ''} nav-link userset`} href="#!">
                <span className="user-img">
                    <i className='bi bi-person-circle'> </i>
                </span>
            </a>

            <div ref={menu} className={`${isOpen ? 'show' : ''} dropdown-menu menu-drop-user profileHeader`}>
                <div className="profilename">

                    <div className="profileset">
                        <div className="profilesets">
                            <h6>{(auth?.firstname ?? "---") + " " + (auth?.lastname ?? "---")}</h6>
                        </div>
                    </div>

                    <hr className="m-0"/>

                    <Link onClick={closeSubmenuHandler} to={prefixRouterPath('settings/profile')} className="dropdown-item">
                        <i className="bi bi-person-circle"> </i>
                        حسابي
                    </Link>

                    {
                        permissions["settings"] && permissions["settings"].length > 0
                        &&
                        (
                            <Link onClick={closeSubmenuHandler} to={prefixRouterPath('settings/general')} className="dropdown-item">
                                <i className="bi bi-gear"> </i>
                                الاعدادات
                            </Link>
                        )
                    }

                    {
                        (
                            (permissions["pos"] && permissions["pos"].length > 0)
                            ||
                            (auth?.user_type == "2")
                        )
                        &&
                        (
                            <Link onClick={closeSubmenuHandler} to={prefixRouterPath('pos/start-session')} className="dropdown-item pos-profile-link">
                                <i className="bi bi-display"> </i>
                                نقطة البيع
                            </Link>
                        )
                    }

                    <hr className="m-0"/>
                    <a onClick={logoutHandler} className="dropdown-item logout pb-0" href="#!">
                        <img src={logout} className="" alt="img"/>
                        تسجيل خروج
                    </a>
                </div>
            </div>
        </li>
    );
};

export default ProfileComponent;
