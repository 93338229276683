import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";

export default () => {
    /* table */
    const prefix = 'expenses-module';
    const module_endpoint = 'expense_categories';
    const module_name = 'اقسام المصروفات';
    const create_redirect = prefixRouterPath((prefix ? prefix + '/' : '') + 'expense_categories/create');

    const headers = [
        {
            label: 'الصورة',
            key: 'image',
            printable: true,
            sortable: false,
            can_export_excel: false,
        },
        {
            label: 'الاسم',
            key: 'name',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'اسم المصروف الرئيسي',
            key: 'expense_category',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'وقت الانشاء',
            key: 'creation_date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجراء',
            key: 'action',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(reRenderItems())
    }, []);

    /* ---------------- delete ------------------ */
    const deleteHandler = (item) => {
        confirmDelete(async () => {
            await AxiosInstance.delete(`${module_endpoint}/${item.id}`, {}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }
    /* ---------------- delete ------------------ */


    const [expense_categories, setExpenseCategories] = useState([]);
    useEffect(() => {
        AxiosInstance.get('expense_categories?parent_id=null', {}).then(async function ({data}) {
            await setExpenseCategories(data.data);
        }).catch(function ({response}) {
            //
        });
    }, [])

    const OnChangeExpenseCategoriesHandler = (event) => {
        dispatch(appendFilterParams({...table.params, parent_id: event.target.value}, {}));
    }

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        deleteHandler,
        expense_categories,
        OnChangeExpenseCategoriesHandler,
    };
}