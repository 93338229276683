import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const prefix = 'product-settings';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'product_categories';
    const module_endpoint = 'product_categories';
    const module_name = 'الاقسام الرئيسية والفرعية';
    const slug = 'اضافة قسم رئيسي او فرعي جديد';
    const [categories, setCategories] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {

        PermissionMiddleware(permissions, "product_categories");

        if (permissions.length > 0) {
            AxiosInstance.get('product_categories/categories-with-its-subcategories', {}).then(async function ({data}) {
                await setCategories(data.data);
            }).catch(function ({response}) {
                //
            });
        }
    }, [permissions]);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, trans('The_name_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const [previewImage, setPreviewImage] = useState('');
    const resetForm = () => {
        setPreviewImage('');
        setErrors({});
    }

    return {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        categories,
        previewImage,
        setPreviewImage
    };
}