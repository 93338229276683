import React, {useEffect, useState} from 'react';
import menus from "../../config/menus";
import useMenuAutoClose from "../../hooks/global/useMenuAutoClose";
import {Link, useLocation} from "react-router-dom";
import LogoComponent from '../../components/layout/header/LogoComponent';
import {useRtkState} from "../../config/helper";

const MenuComponent = ({isMenuOpen, menu, submenuHandler, closeSubmenuHandler}) => {

        // const {menu_list, menu, submenuHandler, closeSubmenuHandler} = useMenuAutoClose()
        const permissions = useRtkState('Settings').permissions;
        const SideMenuType = useRtkState('Settings').SideMenuType;
        const permissions_by_group_keys = Object.keys(useRtkState('Settings')?.permissions_by_group ?? null);

        let location = useLocation();


        const RemoveAllActiveMenu = () => {
            const parentItems = document.getElementsByClassName('active_menu_link');
            for (let i = 0; i < parentItems.length; i++) {
                let parentItem = parentItems[i];
                parentItem.classList.remove("active");
                parentItem.classList.remove("active_menu_link");

                parentItem.closest("ul")?.classList?.remove("active");
                parentItem.closest(".submenu").getElementsByClassName("main_subdrop")[0].classList.remove("subdrop");
            }
        };

        const AuthSetActiveMenu = () => {
            const parentItems = document.getElementsByClassName('active_menu_link');
            for (let i = 0; i < parentItems.length; i++) {
                let parentItem = parentItems[i];
                parentItem.classList.add("active_menu_link");

                if (SideMenuType === "vertical") {
                    parentItem.closest("ul")?.classList?.add("active");
                }
                parentItem.closest(".submenu").getElementsByClassName("main_subdrop")[0].classList.add("subdrop");
            }
        };

        const [menuActiveTarget, setMenuActiveTarget] = useState("");

        useEffect(() => {
            AuthSetActiveMenu();
        }, [permissions, menuActiveTarget, SideMenuType]);


        const SetActiveMenu = (e) => {
            RemoveAllActiveMenu();
            setMenuActiveTarget(e.target);
            AuthSetActiveMenu();
        };


        return (
            /* Horizontal Menu */
            /* https://fontawesome.com/v5/search */

            <div className={`sidebar new-header ${isMenuOpen === true ? 'active' : ''}`}>
                <LogoComponent/>
                <div id="sidebar-menu" className="sidebar-menu">

                    {/* start dynamic menu */}
                    <ul id="parentMenuOrSlider" className={`nav menu_list ${SideMenuType}`}>
                        {
                            menus.map(function (single_menu, index) {
                                return <>
                                    {
                                        (permissions_by_group_keys.includes(single_menu?.route))
                                        &&
                                        (
                                            <li className="submenu" key={index}>
                                                {/* main module */}

                                                <Link
                                                    to={single_menu.sub_menus.length <= 0 ? (single_menu.route) : "#!"}
                                                    onClick={(e) => {
                                                        // e.preventDefault();
                                                        return submenuHandler(single_menu.route, menu.status);
                                                    }}
                                                    className={`main_subdrop ${(menu.menu === single_menu.route && menu.status) ? 'subdrop' : ''}`}>
                                                      <span>
                                                        <img src={single_menu.icon} alt={single_menu.menu_name}/>
                                                          {single_menu.menu_name}
                                                      </span>

                                                    {
                                                        (single_menu.sub_menus.length > 0) &&
                                                        <span className="menu-arrow"> </span>
                                                    }
                                                </Link>


                                                {/* links */}
                                                {
                                                    (single_menu.sub_menus.length > 0)
                                                    &&
                                                    (<ul id={`menu_${single_menu.route}`}
                                                         className={(menu.menu === single_menu.route && menu.status) ? 'active' : ''}>
                                                        {
                                                            single_menu.sub_menus.map(function (sub_menu, index) {
                                                                return (
                                                                    (permissions.includes(sub_menu?.permission?.name)) &&
                                                                    <>
                                                                        <li onClick={e => closeSubmenuHandler(SideMenuType)}
                                                                            key={index}>
                                                                            <Link
                                                                                onClick={SetActiveMenu}
                                                                                className={`menu_link ${location?.pathname === sub_menu.route ? "active active_menu_link" : ""}`}
                                                                                to={sub_menu.route}>
                                                                                <span>
                                                                                   <i className={sub_menu.icon}> </i> {sub_menu.menu_name}
                                                                                </span>
                                                                            </Link>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </ul>)
                                                }
                                            </li>
                                        )
                                    }
                                </>;
                            })
                        }
                    </ul>
                    {/* start dynamic menu */}

                </div>
            </div>
            /* Horizontal Menu */
        );
    }
;


export default MenuComponent;
