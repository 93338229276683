import {
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, warningSweetAlert
} from "../../../config/helper";
import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import {setLocale} from 'yup';

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {

    const prefix = 'expenses-module';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'expenses';
    const module_endpoint = 'expenses';
    const module_name = 'النفقات (المصروفات)';

    const slug = 'اضافة نفقة (مصروف) جديد';

    const [warehouses, setWarehouses] = useState([]);
    const [expense_categories, setExpense_categories] = useState([]);

    useEffect(() => {
        AxiosInstance.get('warehouses', {}).then(async function ({data}) {
            await setWarehouses(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('expense_categories', {}).then(async function ({data}) {
            await setExpense_categories(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            warehouse_id: '',
            expense_category_id: '',
            date: '',
            amount: '',
            details: '',
        },
        validationSchema: Yup.object({
            warehouse_id: Yup.string().required(trans('This_field_is_required')),
            expense_category_id: Yup.string().required(trans('This_field_is_required')),
            date: Yup.string().required(trans('This_field_is_required')),
            amount: Yup.string().required(trans('This_field_is_required')),
            details: Yup.string().required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                continueSweetAlert(() => {
                    navigate(prefixRouterPath(route_endpoint));
                }, () => {
                    submitForm.current.reset();
                    successSweetAlert();
                });
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    return {module_name, slug, formik, submitForm, isSubmitLoading, errors, resetForm, warehouses, expense_categories};
}