import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";

/* edit hook */
import EditHook from "../../../hooks/cruds/brands/EditHook";
import LoadingComponent from "../../../components/global/LoadingComponent";

const Edit = () => {
    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        previewImage,
        setPreviewImage,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>
                    {isOldItemsLoading ? <LoadingComponent/> : ""}
                    <TextInput
                        label={"الاسم"}
                        name={"name"}
                        formik={formik}
                        allow_formik_value={true}
                        className={"col-lg-4 col-sm-6 col-12"}
                        error={errors?.["name"]?.[0] ?? ""}
                    />
                    {/*<ImageInput*/}
                    {/*    label={"الصورة"}*/}
                    {/*    name={"image"}*/}
                    {/*    formik={formik}*/}
                    {/*    previewImage={previewImage}*/}
                    {/*    setPreviewImage={setPreviewImage}*/}
                    {/*    className={"col-lg-4 col-sm-6 col-12"}*/}
                    {/*    error={errors?.["image"]?.[0] ?? ""}*/}
                    {/*/>*/}

                    <div className="card-footer">
                        <SubmitButton
                            isSubmitLoading={isSubmitLoading}
                            className={"btn btn-primary"}
                        />
                    </div>
                </form>
            </CardComponent>
        </>
    );
};

export default Edit;
