import React from 'react';
import {trans} from '../../config/helper';
import {noResults} from "../../config/images-path";

const EmptyContentComponent = ({text}) => {
    return (
        <>
            <div className="noresult">
                <img src={noResults} alt="noResults"/>
                {<h4>{text ? text : trans('There is no data to display')}</h4>}
            </div>
        </>
    );
};


export default EmptyContentComponent;
