import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";

/* edit hook */
import EditHook from "../../../hooks/cruds/safes/EditHook";
import LoadingComponent from "../../../components/global/LoadingComponent";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import NormalInput from "../../../shared/inputs/NormalInput";
import SelectInput from "../../../shared/inputs/SelectInput";

const Edit = () => {
    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeInitialDateHandler,
        initial_balance_date,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>
                    {isOldItemsLoading ? <LoadingComponent/> : ""}

                    <div className="row">
                        <TextInput
                            label={"الاسم"}
                            name={"name"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-lg-4 col-sm-6 col-12"}
                            error={errors?.["name"]?.[0] ?? ""}
                        />

                        <h3 className='title-h3'>رصيد الخزنة السابق</h3>

                        <TextInput label={'الرصيد الافتتاحي'}
                                   name={'initial_balance'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['initial_balance']?.[0] ?? ''}
                                   placeholder={"0"}/>

                        <SelectInput
                            label="اختر (رصيد اضافي ام مدين)"
                            name="initial_balance_type"
                            formik={formik}
                            allow_formik_value={true}
                            className={'col-lg-3 col-sm-6 col-12'}
                            error={errors?.['initial_balance_type']?.[0] ?? ''}>

                            <option value="1">رصيد اضافي</option>
                            <option value="2">مدين</option>

                        </SelectInput>

                        <TextInput
                            type="date"
                            label={"تاريخ الرصيد"}
                            name={"initial_balance_date"}
                            formik={formik}
                            allow_formik_value={true}
                            className={"col-xl-3 col-md-6 col-sm-6  col-12"}
                            error={errors?.["date"]?.[0] ?? ""}
                            onChangeHandler={event => ChangeInitialDateHandler(event)}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );
};

export default Edit;
