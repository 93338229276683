import React, {useRef} from "react";
import {
    successSweetAlert,
    trans,
    useRtkState,
    warningSweetAlert,
} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import EmptyContentComponent from "../../../global/EmptyContentComponent";
import {appendFilterParams, AppendProductToInvoiceAction, fetchAllProducts} from "../../../../store/pos/Pos";
import LoadingComponent from "../../../global/LoadingComponent";
import useGetCurrentProductInvoiceQty from "../../../../hooks/system/pos/useGetCurrentProductInvoiceQty";
import soundFile from "../../../../assets/dashboard/img/1.mp3";
import {Pagination} from "@mui/material";
import ProductSkeleton from "../../../../shared/Skeleton/ProductPos/ProductSkeleton";


const ShowProductsListComponent = () => {
    const audioRef = useRef(null);
    const posState = useRtkState("Pos");

    const dispatch = useDispatch();

    const PlaySound = () => {
        const audioElement = document.getElementById("audioElementId");
        audioElement.play(); // Play the sound
    };

    /* append product to invoice */
    const AppendProductAction = (product) => {
        if (
            product["warehouse_manage_stock_quantity"] <=
            useGetCurrentProductInvoiceQty(posState, product)
        ) {
            warningSweetAlert(
                trans("This Quantity isn't available in stock") +
                " " +
                posState?.warehouse_selected?.name
            );
            return false;
        }
        PlaySound();
        dispatch(AppendProductToInvoiceAction(product, {}));
    };

    const pagination = posState.pagination;

    /* filter */
    const paginationPageHandler = (e, page) => {
        dispatch(appendFilterParams({...posState.params, page: page}, {}));
        dispatch(fetchAllProducts());
    }

    return (
        <>
            <div
                className={`products-div ${
                    posState?.products && posState?.products.length > 0 ? "" : "no-products"
                }`}
            >
                <>
                    {posState?.is_products_loading ? <ProductSkeleton/> : "" }
                     {(posState?.products && posState?.products.length > 0) ||
                     posState?.is_products_loading ? ( posState?.products.map(function (product) {
                            return (
                                /* active */
                                <div
                                    key={product?.id}
                                    onClick={(e) => AppendProductAction(product)}
                                    className={`prd-item ${
                                        product?.warehouse_manage_stock_quantity <= 0
                                            ? "zero-qty"
                                            : ""
                                    } 
                                    ${ posState?.invoice_products.some(function (obj) {
                                            return obj.hasOwnProperty('id') && obj.id === product?.id;
                                        })
                                            ? "active"
                                            : ""
                                    } 
                                    ${ product?.warehouse_manage_stock_quantity <= 0 &&
                                        !posState?.is_show_ended_products_list
                                            ? "d-none"
                                            : ""
                                    }`}
                                >
                                    <img src={product?.image_url} alt={product?.name}/>
                                    <p>{product?.name}</p>
                                    <span>منتهي</span>
                                </div>
                            );
                        })
                    ) : (
                        <EmptyContentComponent/>
                    )}
                    <audio ref={audioRef} id="audioElementId" src={soundFile}/>
                </>
            </div>

            {
                (pagination && pagination.last_page > 1)
                &&
                <Pagination
                    shape="rounded"
                    count={pagination.last_page}
                    page={pagination.current_page}
                    variant="outlined"
                    color="primary"
                    onChange={paginationPageHandler}/>
            }

        </>
    );
};

export default ShowProductsListComponent;
