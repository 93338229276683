import React, {useEffect, useState} from 'react';
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import NormalInput from "../../../shared/inputs/NormalInput";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import AxiosInstance from "../../../config/AxiosInstance";
import PaymentHook from "../../../hooks/system/invoice/PaymentHook";

const PaymentMethodsComponent = ({errors}) => {

    /* payments */
    const {
        CreateNewPaymentHandler,
        localStatePayments,
        handlePaymentsChange,
        payment_types,
        DeletePaymentHandler
    } = PaymentHook();
    /* payments */

    return (
        <>
            {
                (localStatePayments && localStatePayments.length > 0)
                &&
                (
                    localStatePayments.map(function (payment, index) {
                        return (
                            <div className={"col-md-12"}>
                                <div className="row custom-row">
                                    <NormalSelectInput
                                        label={"نوع طريقة الدفع"}
                                        name={`payments[${index}][payment_type_id]`}
                                        // value={payment?.payment_type_id ?? 0}
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'payment_type_id', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.payment_type_id`]?.[0] ?? ""}>
                                        {
                                            payment_types && payment_types.map(function (payment_type) {
                                                return (
                                                    <option
                                                        selected={payment?.payment_type_id && payment?.payment_type_id == payment_type.id}
                                                        key={payment_type.id} value={payment_type.id}>
                                                        {payment_type.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </NormalSelectInput>

                                    <NormalInput
                                        label={"المبلغ المدفوع"}
                                        type="number"
                                        min={0}
                                        name={`payments[${index}][amount]`}
                                        value={payment?.amount ?? 0}
                                        // placeholder={InvoiceOperations?.paid_amounts ?? 0}
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'amount', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.amount`]?.[0] ?? ""}/>

                                    <NormalInput
                                        label={"رقم فاتورة الدفع (اختياري)"}
                                        type="number"
                                        min={0}
                                        name={`payments[${index}][payment_serial_number]`}
                                        value={payment?.payment_serial_number ?? 0}
                                        // placeholder={InvoiceOperations?.payment_serial_number ?? 0}
                                        onChangeHandler={(e) => handlePaymentsChange(index, 'payment_serial_number', e.target.value)}
                                        className={"col-md-4"}
                                        error={errors?.[`payments.${index}.payment_serial_number`]?.[0] ?? ""}/>

                                    {
                                        (index > 0)
                                        &&
                                        (
                                            <div className="abs-x">
                                                <SimpleButton clickAction={() => DeletePaymentHandler(index)}
                                                              className="btn btn-danger btn-sm"
                                                              btn_name="x"/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                )
            }

            <div className="mb-3">
                <SimpleButton clickAction={CreateNewPaymentHandler} className="btn btn-info btn-sm"
                              btn_name="اضافة طريقة دفع اخرى"/>
            </div>

        </>
    );
};

export default PaymentMethodsComponent;