import React from 'react';
import {errorValidation} from "../../config/images-path";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import CustomTooltip from '../material-ui/CustomTooltip';

const NormalInput = ({readOnly, id, type, label, error, name, value, className, placeholder, onChangeHandler, onKeyUpHandler, min, max,  step=".01"}) => {
    return (
        <>
            {
                <div className={`${className} ${type === 'hidden' ? 'd-none' : ''}`}>
                    <div className="form-group">

                        <div className="flex-label">
                            {label && <label>{label}</label>}

                            <span className="flex-error">
                                <CustomTooltip title={error}>
                                    <em>
                                        {error && (
                                            <img
                                                src={errorValidation}
                                                className={"warning-error"}
                                                alt={"errorValidation"}
                                            />
                                        )}
                                    </em>
                                </CustomTooltip>
                            </span>

                        </div>

                        <input readOnly={readOnly}
                               className={`form-control ${error ? 'border border-danger' : ''}`}
                               id={id}
                               name={name}
                               value={value}
                               type={type}
                               min={min}
                               max={max}
                               step={step}
                               placeholder={placeholder}
                               autoComplete="off"
                               onKeyUp={onKeyUpHandler}
                               onChange={onChangeHandler}/>

                    </div>
                </div>
            }
        </>
    );
};

export default NormalInput;
