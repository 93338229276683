import React from 'react'
import { Tooltip } from "@mui/material";

const CustomTooltip = ({title, children, placement}) => {
  return (
    <Tooltip placement={placement} title={title}>
      {children}
    </Tooltip>
  )
}

export default CustomTooltip