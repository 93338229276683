import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../store/invoices/InvoiceOperations";
import {useParams} from "react-router-dom";

export default () => {


    const params = useParams();
    const expense_slug = params?.expense_type;

    /* table */
    const prefix = "accounts-balances";
    const module_endpoint = `financial-transactions/account-statement/expenses?expense_slug=${expense_slug}`;
    const module_name = ' كشف حساب المصروفات';
    const create_redirect = "";


    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;


    const headers = [
        {
            label: 'نوع المصروف',
            key: 'expense.title',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'تاريخ العملية',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'combined_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'combined_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'balanced_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'balanced_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'البيان',
            key: 'statement',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        // {
        //     label: 'المستخدم',
        //     key: 'transaction_username',
        //     printable: true,
        //     sortable: false,
        //     can_export_excel: true,
        // },
        {
            label: 'التفاصيل',
            key: 'transaction_info',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }
    ];

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(reRenderItems())
    }, []);


    /* ---------------- searches ------------------ */
    const searchDateHandler = (event) => {
        dispatch(appendFilterParams({...table.params, date: event.target.value}, {}));
    }

    const OnChangePurchase = (event) => {
        dispatch(appendFilterParams({...table.params, purchase_id: event.target.value}, {}));
    }
    const OnMonthChange = (event) => {
        dispatch(appendFilterParams({...table.params, month: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        searchDateHandler,
        OnChangePurchase,
        OnMonthChange,
    };
}