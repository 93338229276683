import React, {useEffect, useState} from 'react';
import NormalInput from "../../shared/inputs/NormalInput";
import NormalSelectInput from "../../shared/inputs/NormalSelectInput";
import {trans, useRtkState, warningSweetAlert} from "../../config/helper";

import {
    SetDateHandler,
    SetNotesHandler,
    UpdateTotalTax,
    UpdateTotalDiscountType,
    UpdateTotalDiscountRate,
    UpdateTotalShipping,
    UpdateTotalDiscountAmount,
    SetInvoicePaidAmountAction,
    SetInvoicePaymentStatusAction,
    SetInvoiceSafeIdAction,
    SetInvoicePaymentsAction, ChangeIsDamageAction,
} from "../../store/invoices/InvoiceOperations";

import {useDispatch} from "react-redux";
import NormalTextAreaInput from "../../shared/inputs/NormalTextAreaInput";
import {SetPosPaidAmountAction, SetPosPaymentStatusAction} from "../../store/pos/Pos";
import SelectInput from "../../shared/inputs/SelectInput";
import AxiosInstance from "../../config/AxiosInstance";
import SimpleButton from "../../shared/buttons/SimpleButton";
import PaymentHook from "../../hooks/system/invoice/PaymentHook";

const InvoiceSettingComponent = ({errors}) => {

        const InvoiceOperations = useRtkState('InvoiceOperations');
        const dispatch = useDispatch();

        const ChangeTotalTaxHandler = (event) => {
            let value = event.target.value;
            if (value < 0) {
                value = 0;
            }
            dispatch(UpdateTotalTax(value, {}));
        };

        const ChangeDiscountTypeHandler = (event) => {
            const value = event.target.value;
            dispatch(UpdateTotalDiscountType(value, {}));
        };

        const ChangeDiscountRateHandler = (event) => {
            let value = event.target.value;
            if (value < 0) {
                value = 0;
            }
            dispatch(UpdateTotalDiscountRate(value, {}));
        };

        const ChangeDiscountAmountHandler = (event) => {
            let sub_grand_total = InvoiceOperations.sub_grand_total;
            let value = event.target.value;
            if (sub_grand_total - value <= 0) {
                warningSweetAlert(trans("The total cannot be zero or less ."));
                value = 0;
            }
            dispatch(UpdateTotalDiscountAmount(value, {}));
        };

        const ChangeShippingHandler = (event) => {
            let value = event.target.value;
            if (value < 0) {
                value = 0;
            }
            dispatch(UpdateTotalShipping(value, {}));
        };

        const ChangeDateHandler = (event) => {
            let value = event.target.value;
            dispatch(SetDateHandler(value, {}));
        };

        const ChangeNotesHandler = (event) => {
            let value = event.target.value;
            dispatch(SetNotesHandler(value, {}));
        };

        const PaymentStatusHandler = () => {
            dispatch(SetInvoicePaymentStatusAction(InvoiceOperations?.payment_status === 1 ? 0 : 1, {}));
        }

        const ChangeIsDamage = () => {
            dispatch(ChangeIsDamageAction(InvoiceOperations?.is_damage === 1 ? 0 : 1, {}));
        }

        const ChangeSafeIdHandler = (event) => {
            let value = event.target.value;
            dispatch(SetInvoiceSafeIdAction(value, {}));
        }

        const [safes, setSafes] = useState([]);
        useEffect(() => {
            AxiosInstance.get('safes?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
                await setSafes(data.data);
            }).catch(function ({response}) {
                //
            });
        }, []);

        /* payments */
        const {
            CreateNewPaymentHandler,
            localStatePayments,
            handlePaymentsChange,
            payment_types,
            DeletePaymentHandler
        } = PaymentHook();
        /* payments */


        return (
            <div className="row">

                <NormalInput
                    label={"الضريبة (%)"}
                    type="number"
                    name={"tax_rate"}
                    value={InvoiceOperations?.tax_rate ?? 0}
                    // placeholder={InvoiceOperations?.tax_rate ?? 0}
                    onChangeHandler={ChangeTotalTaxHandler}
                    min={0}
                    max={100}
                    className={"col-xl-2 col-md-4 col-sm-4 col-12"}
                    error={errors?.["tax_rate"]?.[0] ?? ""}/>

                <NormalInput
                    label={"اجمالي الضريبة"}
                    type="hidden"
                    min={0}
                    max={100}
                    readOnly={true}
                    name={"tax_amount"}
                    value={InvoiceOperations?.tax_amount ?? 0}
                    // placeholder={InvoiceOperations?.tax_amount ?? 0}
                    className={"col-xl-2 col-md-4 col-sm-4 col-12"}
                    error={errors?.["tax_amount"]?.[0] ?? ""}/>

                <NormalSelectInput
                    label={"نوع الخصم"}
                    name={"discount_type"}
                    onChangeHandler={ChangeDiscountTypeHandler}
                    className={"col-xl-2 col-md-4 col-sm-4 col-12"}
                    error={errors?.["discount_type"]?.[0] ?? ""}>
                    <option value={0} selected={InvoiceOperations?.discount_type}>قيمة</option>
                    <option value={1} selected={InvoiceOperations?.discount_type}>نسبة</option>
                </NormalSelectInput>

                {
                    InvoiceOperations?.discount_type ? (
                        <NormalInput
                            label={"معدل الخصم"}
                            type="number"
                            min={0}
                            max={100}
                            name={"discount_rate"}
                            value={InvoiceOperations?.discount_rate ?? 0}
                            // placeholder={InvoiceOperations?.discount_rate ?? 0}
                            onChangeHandler={ChangeDiscountRateHandler}
                            className={"col-xl-2 col-md-4 col-sm-4 col-12"}
                            error={errors?.["discount_rate"]?.[0] ?? ""}/>
                    ) : (
                        <NormalInput
                            label={"قيمة الخصم"}
                            type="number"
                            min={0}
                            name={"discount_amount"}
                            value={InvoiceOperations?.discount_amount ?? 0}
                            // placeholder={InvoiceOperations?.discount_amount ?? 0}
                            onChangeHandler={ChangeDiscountAmountHandler}
                            className={"col-xl-2 col-md-4 col-sm-4 col-12"}
                            error={errors?.["discount_amount"]?.[0] ?? ""}/>
                    )
                }

                <NormalInput
                    label={"اجمالي سعر الشحن"}
                    type="number"
                    min={0}
                    name={"shipping"}
                    value={InvoiceOperations?.shipping ?? 0}
                    // placeholder={InvoiceOperations?.shipping ?? 0}
                    onChangeHandler={ChangeShippingHandler}
                    className={"col-xl-2 col-md-6 col-sm-6 col-12"}
                    error={errors?.["shipping"]?.[0] ?? ""}/>

                <NormalInput
                    type="date"
                    label={"التاريخ"}
                    name={"date"}
                    value={InvoiceOperations?.date ?? new Date().toJSON().slice(0, 10)}
                    className={"col-xl-2 col-md-6 col-sm-6  col-12"}
                    error={errors?.["date"]?.[0] ?? ""}
                    onChangeHandler={event => ChangeDateHandler(event)}/>

                <NormalSelectInput
                    label={"الخزنة"}
                    name={"safe_id"}
                    value={InvoiceOperations?.safe_id ?? 0}
                    onChangeHandler={ChangeSafeIdHandler}
                    className={"col-xl-2 col-md-6 col-sm-6  col-12"}
                    error={errors?.["safe_id"]?.[0] ?? ""}>
                    {safes && safes.map(function (safe) {
                        return (
                            <optgroup key={safe.id} label={safe.name}>
                                {
                                    safe.accounts.map(function (safe) {
                                        return (<option disabled={!safe?.is_debit} key={safe.id} value={safe.id}>
                                            {safe.title} {safe?.is_debit ? "" : "(لا يوجد بها رصيد)"}
                                        </option>);
                                    })
                                }
                            </optgroup>
                        );
                    })}
                </NormalSelectInput>


                {
                    !InvoiceOperations?.is_return_invoice
                    &&
                    <div className="col-12 mt-2">
                        <div className="switch-holder">
                            <div className="switch-label">
                                <span>حالة الدفع</span>
                            </div>
                            <div className="switch-toggle">
                                <input name="payment_status"
                                       onChange={PaymentStatusHandler}
                                       type="checkbox"
                                       id="payment-status"
                                       value={InvoiceOperations?.payment_status}
                                       checked={InvoiceOperations?.payment_status === 1}/>
                                <label htmlFor="payment-status"> </label>
                            </div>
                        </div>
                    </div>
                }

                {
                    (InvoiceOperations?.is_return_invoice)
                    &&
                    (InvoiceOperations?.invoice_type === "sale")
                    &&
                    <NormalSelectInput
                        label={"نوع المرتجع"}
                        name={"is_damage"}
                        onChangeHandler={ChangeIsDamage}
                        className={"col-xl-2 col-md-6 col-sm-6  col-12"}
                        error={errors?.["is_damage"]?.[0] ?? ""}>

                        <option key={0} value={0} selected={InvoiceOperations?.is_damage === 0}>يرجع المخزن</option>
                        <option key={1} value={1} selected={InvoiceOperations?.is_damage === 1}>تالف</option>

                    </NormalSelectInput>
                }

                {
                    (localStatePayments && localStatePayments.length > 0)
                    &&
                    (
                        localStatePayments.map(function (payment, index) {
                            return (
                                <div className={"col-md-12"}>
                                    <div className="row custom-row">
                                        <NormalSelectInput
                                            label={"نوع طريقة الدفع"}
                                            name={`payments[${index}][payment_type_id]`}
                                            // value={payment?.payment_type_id ?? 0}
                                            onChangeHandler={(e) => handlePaymentsChange(index, 'payment_type_id', e.target.value)}
                                            className={"col-md-4"}
                                            error={errors?.[`payments.${index}.payment_type_id`]?.[0] ?? ""}>
                                            {
                                                payment_types && payment_types.map(function (payment_type) {
                                                    return (
                                                        <option
                                                            selected={payment?.payment_type_id && payment?.payment_type_id == payment_type.id}
                                                            key={payment_type.id} value={payment_type.id}>
                                                            {payment_type.name}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </NormalSelectInput>

                                        <NormalInput
                                            label={"المبلغ المدفوع"}
                                            type="number"
                                            min={0}
                                            name={`payments[${index}][amount]`}
                                            value={payment?.amount ?? 0}
                                            // placeholder={InvoiceOperations?.paid_amounts ?? 0}
                                            onChangeHandler={(e) => handlePaymentsChange(index, 'amount', e.target.value)}
                                            className={"col-md-4"}
                                            error={errors?.[`payments.${index}.amount`]?.[0] ?? ""}/>

                                        <NormalInput
                                            label={"رقم فاتورة الدفع (اختياري)"}
                                            type="number"
                                            min={0}
                                            name={`payments[${index}][payment_serial_number]`}
                                            value={payment?.payment_serial_number ?? 0}
                                            // placeholder={InvoiceOperations?.payment_serial_number ?? 0}
                                            onChangeHandler={(e) => handlePaymentsChange(index, 'payment_serial_number', e.target.value)}
                                            className={"col-md-4"}
                                            error={errors?.[`payments.${index}.payment_serial_number`]?.[0] ?? ""}/>

                                        {
                                            (index > 0)
                                            &&
                                            (
                                                <div className="abs-x">
                                                    <SimpleButton clickAction={() => DeletePaymentHandler(index)}
                                                                  className="btn btn-danger btn-sm" btn_name="حذف"/>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    )
                }

                <div className="mb-3">
                    <SimpleButton clickAction={CreateNewPaymentHandler} className="btn btn-info btn-sm"
                                  btn_name="اضافة طريقة دفع اخرى"/>
                </div>


                <NormalTextAreaInput
                    label={"ملاحظات"}
                    name={"notes"}
                    className={"col-12"}
                    value={InvoiceOperations?.notes ?? ''}
                    error={errors?.["notes"]?.[0] ?? ""}
                    onChangeHandler={ChangeNotesHandler}/>

            </div>
        );
    }
;

export default InvoiceSettingComponent;