import {useSelector} from "react-redux";
import {getCookie} from "../hooks/global/useCookie";
import locale from '../locales'
import Swal from "sweetalert2";

import {setLocale} from 'yup';
import {ar} from 'yup-locales';
import {fr} from 'yup-locales';
import {toast} from "react-toastify";
import useNumberToArabicWords from "../hooks/global/useNumberToArabicWords";

export const useRtkState = (slice) => {
    return useSelector((state) => state[slice]);
}

export const selectedOrNo = (id, items) => {
    const item_ids = [];
    items.forEach(function (item) {
        item_ids.push(item.id);
    })
    return item_ids.includes(id);
}

export const prefixRouterPath = (path = '') => {
    return '/system' + (path ? ('/' + path) : '')
}

export const getBearToken = () => {
    return getCookie('token') ? `Bearer ${getCookie('token')}` : null;
}

export const getYupLocaleObject = () => {
    if (getLocale() === 'ar') {
        return ar;
    } else if (getLocale() === 'fr') {
        return fr;
    }
}

export const getLocale = () => {
    return 'ar';
}

export const trans = (key) => {
    if (locale[getLocale()] && locale[getLocale()][key]) {
        return locale[getLocale()][key];
    }
    return key;
}

export const clearOldState = (state) => {
    if (state.old_endpoint !== "" && state.endpoint !== state.old_endpoint) {
        state.params = {};
    }
    state.items_selected = [];
}

export const confirmDelete = (callback) => {
    /* https://sweetalert2.github.io/#usage */
    Swal.fire({
        title: trans('be_careful'),
        text: trans('Do_you_want_delete_?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: trans('yes'),
        cancelButtonText: trans('no'),
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        }
    })
}

export const GeneralConfirmation = (message, callback) => {
    /* https://sweetalert2.github.io/#usage */
    Swal.fire({
        title: trans('be_careful'),
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: trans('yes'),
        cancelButtonText: trans('no'),
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        }
    })
}

export const continueSweetAlert = (callback, ContinueCallback) => {
    Swal.fire({
        title: trans('Do_you_want_to_continue_or_return_to_the_list?'),
        showDenyButton: true,
        confirmButtonText: trans('Back_to_the_list'),
        denyButtonText: trans('continue'),
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        } else if (result.isDenied) {
            ContinueCallback()
        }
    })
}

export const deletedSweetAlert = () => {
    Swal.fire(trans('well_done'), trans('deleted_successfully'), 'success');
}

export const successSweetAlert = () => {
    toast.success(trans('done_successfully'), {
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
    });
    // Swal.fire({
    //     position: 'center',
    //     icon: 'success',
    //     title: trans('done_successfully'),
    //     showConfirmButton: false,
    //     timer: 1500
    // })
    // Swal.fire(trans('well_done'), trans('done_successfully'), 'success');
}

export const successSweetAlert2 = (title) => {
    Swal.fire({
        position: 'bottom-start',
        icon: 'success',
        title: title,
        showConfirmButton: false,
        timer: 1500
    })
}

export const errorSweetAlert = () => {
    Swal.fire({icon: 'error', title: 'Oops...', text: trans('Something_went_wrong')})
}

export const warningSweetAlert = (text = trans('Something_went_wrong!')) => {
    Swal.fire({icon: 'warning', title: trans('sorry'), text: text})
}

export const getPrintableKeys = (headers) => {
    let keys = [];
    headers.forEach(function (header) {
        if (header.printable) {
            keys.push(header.key);
        }
    });
    return keys;
}

export const preparingCanExportExcel = (headers) => {
    let keys = [];
    headers.forEach(function (header) {
        if (header.can_export_excel) {
            keys.push(header);
        }
    });
    return keys;
}

export const allow_vertical_sidebar_helper = () => {
    return false;
}

export const preparing_invoice_product = (
    id = '',
    name = '',
    image_url = '',
    product_price = 0.00,
    product_cost = 0.00,
    unit_id = '',
    quantity = 0,
    tax_value = 0.00,
    discount_type = 0,
    discount_value = 0.00,
    sub_total = 0.00,
    sale_unit = null,
    purchase_unit = null,
) => {
    return {
        'id': id,
        'name': name,
        'image_url': image_url,
        'product_price': product_price,
        'product_cost': product_cost,
        'unit_id': unit_id,
        'quantity': quantity,
        'tax_value': tax_value,
        'discount_type': discount_type,
        'discount_value': discount_value,
        'sub_total': sub_total,
        'sale_unit': sale_unit,
        'purchase_unit': purchase_unit,
    };
}

export const GetAccountStatementType = (invoice_type, type) => {
    let types = {
        "purchase_1": "مشتريات",
        "purchase_2": "مرتجع مشتريات",
        "sale_1": "مبيعات",
        "sale_2": "مرتجع مبيعات",
        "3": "تسديدات او مودفوعات ديون",
    };

    if (parseInt(type) === 3) {
        return types["3"];
    } else if (types[invoice_type + "_" + type]) {
        return types[invoice_type + "_" + type];
    } else {
        return "----";
    }
}

function padNumber(number) {
    return number.toString().padStart(2, '0');
}

export const FormattingTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12) || 12;
    return `${formattedHours}:${padNumber(minutes)} ${amOrPm}`;
}

export const PreventInputDefault = (event) => {
    // Check if the pressed key is "Enter" (keyCode 13) or "Return" (keyCode 10)
    if (event.key === "Enter" || event.keyCode === 13 || event.keyCode === 10) {
        event.preventDefault(); // Prevent the form from submitting
    }
}

function convertToArabicStyle(number) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number.toString().replace(/\d/g, digit => arabicDigits[digit]);
}

export const CurrencyFormatStyle = (amount, allow_currency = false, lang = "en", current_currency = "") => {
    let options = {
        // maximumSignificantDigits: 3,
        maximumFractionDigits: 2,
        currencySign: "accounting",
        style: 'decimal'
    };
    if (allow_currency) {
        options["style"] = "currency";
        options["currency"] = "egp";
    }

    if (allow_currency && current_currency === "ر.س") {
        options["currency"] = "sar";
    }
    if (lang === "ar") {
        return convertToArabicStyle(Intl.NumberFormat('ar', options).format(amount));
    }
    return Intl.NumberFormat('ar', options).format(amount);
}


export const ConvertNumberToArabicWords = (amount, current_currency = "ر.س") => {
    /* https://stackoverflow.com/questions/24594535/convert-arabic-numbers-to-words-with-javascript */
    let currency = "SAR";
    if (current_currency === "ج.م") {
        currency = "EGP"
    }
    return useNumberToArabicWords(amount, currency) + " لا غير ";
}

