import React from 'react';

const CardComponent = ({children, is_show = 1, custom_class = "", card_title}) => {
    return (
        is_show > 0 &&
        <div className={`card ${custom_class} ${is_show ? '' : 'd-none'}`}>

            {card_title && <div className="card-header">
                <h5 className="card-title">{card_title}</h5>
            </div>}

            <div className="card-body">
                {children}
            </div>
        </div>

    );
};

export default CardComponent;
