import React from "react";
import {useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {ChangeInvoicePaidAction} from "../../../../store/pos/Pos";

const InvoiceSettingsComponent = () => {
    const posState = useRtkState("Pos");
    const dispatch = useDispatch();
    const settings = useRtkState("Settings").settings;
    const currency = settings?.currency;
    const default_safe_id = settings?.default_safe_id;

    const ChangeInvoicePaid = (event) => {
        const value = event?.target?.value;
        dispatch(ChangeInvoicePaidAction(value, {}));
    };

    return (
        <>

            {/* hidden form submit "Not better way must be in main submit method action" */}
            <input type="hidden" name={`safe_id`} value={default_safe_id}/>
            <input type="hidden" name={`payment_status`} value={posState?.payment_status}/>


            {/*<input type="hidden" name={`payment_type`} value={posState?.payment_type}/>*/}
            {/*<input type="hidden" name={`paid_amount`} value={posState?.paid_amount}/>*/}

            {/*<input type="hidden" name={`tax_rate`} value={posState?.invoice_tax}/>*/}
            {/*<input type="hidden" name={`discount_amount`} value={posState?.invoice_discount}/>*/}
            {/*<input type="hidden" name={`shipping`} value={posState?.shipping}/>*/}
            {/*<input type="hidden" name={`notes`} value={posState?.notes}/>*/}
            {/* hidden form submit "Not better way must be in main submit method action" */}

            <div className="total-box">
                <div className="total-price">
                    <em>الاجمالي</em>
                    <h3>{posState?.invoice_grand_total} <span>{currency}</span></h3>
                </div>

                <div className="paid-restmoney">
                    <div className="input-box paid-div">
                        <span>المدفوع</span>
                        <input readOnly={posState?.invoice_grand_sub_total <= 0} onChange={ChangeInvoicePaid}
                               type="number" min={0} value={posState?.invoice_paid}
                               placeholder="0.00"/>
                    </div>

                    <div className="input-box restmoney-div">
                        <span>المتبقي</span>
                        <input type="text" min={0}
                               value={!isNaN(posState?.invoice_rest_of_paid) ? posState?.invoice_rest_of_paid : ""}
                               placeholder={posState?.invoice_rest_of_paid !== "" ? posState?.invoice_rest_of_paid : "0.00"}
                               readOnly/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceSettingsComponent;
