import React from "react";
import PaymentMethodsComponent from "../components/PaymentMethodsComponent";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import PayingAnAdvancePaymentToSupplierHook
    from "../../../hooks/system/financial_accounts/PayingAnAdvancePaymentToSupplierHook";

export default ({safes}) => {
    const {
        currency,
        suppliers,
        isSubmitLoading,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    } = PayingAnAdvancePaymentToSupplierHook();

    return (
        <>
            <form ref={submitForm} onSubmit={formik.handleSubmit}>
                <div className="flex-radio-box">
                    <input
                        type="radio"
                        id="nature_of_account_1"
                        name="nature_of_account"
                        value="1"
                        checked={natureOfAccountChecked === "nature_of_account_1"}/>
                    <label htmlFor="nature_of_account_1" onClick={e => ChangeNatureOfAccount("nature_of_account_1")}>دفعة
                        مقدمة لمورد
                    </label>

                    <input
                        type="radio"
                        id="nature_of_account_2"
                        name="nature_of_account"
                        value="2"
                        checked={natureOfAccountChecked === "nature_of_account_2"}/>
                    <label htmlFor="nature_of_account_2" onClick={e => ChangeNatureOfAccount("nature_of_account_2")}>
                        استرجاع مبلغ دفعة مقدمة من مورد
                    </label>
                </div>

                <NormalSelectInput
                    label={"الخزنة"}
                    name="safe_id"
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["safe_id"]?.[0] ?? ""}
                >
                    {safes &&
                    safes.map(function (safe) {
                        return (
                            <optgroup key={safe.id} label={safe.name}>
                                {safe.accounts.map(function (safe) {
                                    return (
                                        <option key={safe.id} value={safe.id}>
                                            {safe.title}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        );
                    })}
                </NormalSelectInput>

                <NormalSelectInput
                    label={"المورد"}
                    name={"supplier_id"}
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["supplier_id"]?.[0] ?? ""}
                >
                    <option selected={true}>اختر المورد</option>
                    {suppliers &&
                    suppliers.map(function (supplier) {
                        return (
                            <option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                            </option>
                        );
                    })}
                </NormalSelectInput>

                <PaymentMethodsComponent errors={errors}/>

                <NormalTextAreaInput className={"col-12"} label={"ملاحظات"}/>

                <div className="card-footer">
                    <SubmitButton
                        isSubmitLoading={isSubmitLoading}
                        className={"btn btn-primary"}
                    />
                </div>
            </form>
        </>
    );
};
