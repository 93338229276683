import React from "react";
import PaymentMethodsComponent from "../components/PaymentMethodsComponent";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import PayingSaleInvoiceHook from "../../../hooks/system/financial_accounts/PayingSaleInvoiceHook";

export default ({safes}) => {
    const {
        currency,
        onChangeCustomerGetTheirOwnSales,
        customers,
        onChangeSale,
        sales,
        sale,
        isSubmitLoading,
        grand_total,
        total_paid_payments,
        total_rest_payments,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    } = PayingSaleInvoiceHook();

    return (
        <>
            <form ref={submitForm} onSubmit={formik.handleSubmit}>
                <div className="flex-radio-box">

                    <input checked={natureOfAccountChecked === "nature_of_account_2"} type="radio"
                           id="nature_of_account_2" name="nature_of_account" value="2"/>
                    <label htmlFor="nature_of_account_2" onClick={e => ChangeNatureOfAccount("nature_of_account_2")}>تحصيل
                        فاتورة مبيعات</label>

                    <input checked={natureOfAccountChecked === "nature_of_account_1"} type="radio"
                           id="nature_of_account_1" name="nature_of_account" value="1"/>
                    <label htmlFor="nature_of_account_1" onClick={e => ChangeNatureOfAccount("nature_of_account_1")}>استرجاع
                        فاتورة مبيعات</label>

                </div>

                <NormalSelectInput
                    label={"الخزنة"}
                    name="safe_id"
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["safe_id"]?.[0] ?? ""}>
                    {safes &&
                    safes.map(function (safe) {
                        return (
                            <optgroup key={safe.id} label={safe.name}>
                                {safe.accounts.map(function (safe) {
                                    return (
                                        <option key={safe.id} value={safe.id}>
                                            {safe.title}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        );
                    })}
                </NormalSelectInput>

                <NormalSelectInput
                    label={"العميل"}
                    name={"customer_id"}
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["customer_id"]?.[0] ?? ""}
                    onChangeHandler={onChangeCustomerGetTheirOwnSales}>
                    <option selected={true}>اختر العميل</option>
                    {customers &&
                    customers.map(function (customer) {
                        return (
                            <option key={customer.id} value={customer.id}>
                                {customer.name}
                            </option>
                        );
                    })}
                </NormalSelectInput>

                <NormalSelectInput
                    id="saleID"
                    onChangeHandler={onChangeSale}
                    label={"فاتورة المبيعات"}
                    name={"sale_id"}
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["sale_id"]?.[0] ?? ""}>
                    <option selected={true}>اختر الفاتورة</option>
                    {sales && sales.length > 0 ? (
                        sales.map(function (sale) {
                            return (
                                <option key={sale.id} value={sale.id}>
                                    {sale.reference_code}
                                </option>
                            );
                        })
                    ) : (
                        <option selected={true} disabled={true}>
                            لايوجد فواتير ,اختر المورد
                        </option>
                    )}
                </NormalSelectInput>

                {
                    sale && (
                        <div className="totals-inviose-tree">
                            <p>
                                الاجمالي : <span>{grand_total}</span> <em>{currency}</em>
                            </p>{" "}
                            <p>
                                المدفوع : <span>{total_paid_payments}</span> <em>{currency}</em>
                            </p>{" "}
                            {total_rest_payments > 0 ? (
                                <p>
                                    المتبقي : <span>{total_rest_payments}</span> <em>{currency}</em>
                                </p>
                            ) : (
                                <p>تم تسديد المبلغ بالكامل</p>
                            )}
                        </div>
                    )
                }

                {
                    sale && (total_rest_payments > 0 || natureOfAccountChecked === "nature_of_account_1") ?
                        <>
                            <PaymentMethodsComponent errors={errors}/>

                            <NormalTextAreaInput className={"col-12"} label={"ملاحظات"}/>

                            <div className="card-footer">
                                <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                            </div>
                        </>
                        : ""
                }
            </form>
        </>
    );
};
