import React from 'react';
import {logo} from "../../../config/images-path";

const LogoComponent = () => {
    return (
        <a href="src/components/auth#!" className="login-logo">
            <img src={logo} alt=""/>
        </a>
    );
};

export default LogoComponent;
