import React from "react";
import { Helmet } from "react-helmet";
import { SYSTEM_TITLE } from "../../../config/constants";

/* components & shared */
import CardComponent from "../../../components/cards/CardComponent";

/* hook */
import ShowHook from "../../../hooks/cruds/products/ShowHook";
import ShowProductDetails from "../../../components/cruds/products/ShowProductDetails";
import LoadingComponent from "../../../components/global/LoadingComponent";

const Index = () => {
  const { module_name, isLoading, product, product_stocks } = ShowHook();

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <title>
          {" "}
          {SYSTEM_TITLE} | {module_name}{" "}
        </title>
      </Helmet>

      {isLoading ? <LoadingComponent /> : ""}
      <ShowProductDetails product={product} product_stocks={product_stocks} />
    </>
  );
};

export default Index;
