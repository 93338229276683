import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import {useReactToPrint} from 'react-to-print';

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import LoadingComponent from "../../../components/global/LoadingComponent";
import TableComponent from "../../../components/table/TableComponent";
import TableHeaderComponent from "../../../components/table/TableHeaderComponent";
import TableButtonsComponent from "../../../components/table/TableButtonsComponent";
import TdSelectItemComponent from "../../../components/table/TableTbody/TdSelectItemComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {prefixRouterPath, selectedOrNo} from "../../../config/helper";

/* hook */
import IndexHook from "../../../hooks/cruds/products/IndexHook";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import {Link} from "react-router-dom";
import EditBtnTableComponent from "../../../components/table/table_btns/EditBtnTableComponent";
import DeleteBtnTableComponent from "../../../components/table/table_btns/DeleteBtnTableComponent";


const Index = () => {

    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
        productCategories,
        CategoryChangeHandler,
    } = IndexHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}>
                <Link to={prefixRouterPath("product-settings/import-products")} className="btn btn-success">
                    <i className="bi bi-upload"> </i>
                    <span>استيراد الاصناف</span>
                </Link>
            </PageHeader>

            <CardComponent>
                {/* -------------------- Table Header -------------------- */}
                <TableHeaderComponent allow_delete={false}>

                    <NormalSelectInput className={'col-md-4 col-sm-6 col-12'} onChangeHandler={CategoryChangeHandler}>
                        <option value="" selected>-اختر القسم-</option>
                        {
                            productCategories && productCategories.map(function (productCategory) {
                                return (
                                    <optgroup label={productCategory.name}>
                                        {
                                            productCategory?.sub_categories && productCategory?.sub_categories.map(function (sub_category) {
                                                return (
                                                    <option key={sub_category.id}
                                                            value={sub_category.id}>
                                                        {sub_category.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </optgroup>
                                );
                            })
                        }
                    </NormalSelectInput>

                    <NormalInput onChangeHandler={searchKeyHandler}
                                 name={'search_key'}
                                 className={'col-md-4 col-sm-6 col-10'}
                                 placeholder={'ابحث ...'}/>

                </TableHeaderComponent>
                {/* -------------------- Table Header -------------------- */}

                {/* -------------------- Table -------------------- */}
                <TableComponent headers={headers}>
                    {items.map(function (item) {
                        return (
                            <tr className={selectedOrNo(item.id, items_selected) ? '' : 'prevent-print'} key={item.id}>
                                <td className="prevent-print">
                                    <TdSelectItemComponent is_exportable={false} item={item}/>
                                </td>

                                <td>
                                    <img className="table-img" src={(item?.image_url ?? null)}
                                         alt={(item?.name ?? null)}/>
                                </td>
                                <td>{(item?.name ?? null)}</td>
                                <td>{(item?.product_category?.name ?? '----')}</td>
                                <td>{(item?.brand?.name ?? '----')}</td>
                                <td>{(item?.purchase_unit?.name ?? '----')}</td>
                                <td>{(item?.sale_unit?.name ?? '----')}</td>
                                <td>{(item?.product_price ?? '----')}</td>
                                <td><span className='text-green'>{(item?.code ?? '----')}</span></td>
                                <td className="prevent-print">
                                    <Link
                                        to={prefixRouterPath('product-settings/print-barcode') + `?product_barcode=${item?.code}`}
                                        className="barcode-icon">
                                        <i className="bi bi-upc-scan"> </i>
                                    </Link>
                                </td>
                                <td className="prevent-print">

                                    <div className="flex-btn-table">
                                        <Link
                                            to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + `/show/${item?.slug}/${item?.name}`)}
                                            className="btn-info"><i className="bi bi-eye"> </i></Link>

                                        <EditBtnTableComponent
                                            redirect_to={prefixRouterPath((prefix ? prefix + '/' : '') + module_endpoint + '/edit/' + (item?.slug ?? null))}/>
                                        <DeleteBtnTableComponent item={item} deleteHandler={deleteHandler}/>
                                    </div>

                                </td>
                            </tr>
                        );
                    })}
                </TableComponent>
                {/* -------------------- Table -------------------- */}
            </CardComponent>
        </>
    );
};

export default Index;
