import React from 'react';
import {useRtkState} from "../../../config/helper";

const InvoiceHeaderSettingsComponent = () => {

    const settings = useRtkState("Settings")?.settings;

    return (
        <>
            <div className="header-invoice">
                <h3>{settings?.system_name}</h3>
                <img className="logo-store" src={settings?.logo} alt={settings?.system_name}/>
            </div>
        </>
    );
};

export default InvoiceHeaderSettingsComponent;