import React from 'react';

const ModulePermission = ({module_name, children}) => {
    return (
        <li>
            <h4>{module_name}</h4>
            {children}
        </li>
    );
};

export default ModulePermission;