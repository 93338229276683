import React from 'react';

const OnePermission = ({permission_name, name, value, onChange, checked}) => {
    return (
        <div className="roles-check">
            <label className="switch">
                <p>{permission_name}</p>
                <input type="checkbox" name={name} value={value} onChange={onChange} checked={checked}/>
            </label>
        </div>
    );
};

export default OnePermission;