import React from 'react';

const HeadingComponent = () => {
    return (
        <div className="login-userheading">
            <h2>مرحبا بك , تسجيل الدخول</h2>
            <p>سجل الدخول الي حسابك واستمتع بكل المميزات</p>
        </div>
    );
};

export default HeadingComponent;
