import {
    confirmDelete,
    deletedSweetAlert,
    errorSweetAlert,
    prefixRouterPath,
    preparingCanExportExcel,
    useRtkState
} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {
    appendFilterParams,
    endpoint, exportHeaders,
    reRenderItems,
} from "../../../store/table/slices/tableSlice";
import {useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";

export default () => {

    const prefix = 'expenses-module';
    const module_endpoint = 'expenses';
    const module_name = 'النفقات (المصروفات)';
    const create_redirect = prefixRouterPath((prefix ? prefix + '/' : '') + 'expenses/create');

    const headers = [
        {
            label: 'الرقم المرجعي',
            key: 'reference_code',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'التاريخ',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'المخزن',
            key: 'warehouse',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'قسم النتفقات (المصروفات)',
            key: 'expense_category',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'اجمالي المدفوع',
            key: 'amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'سبب المدفوع',
            key: 'details',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'وقت الانشاء',
            key: 'creation_date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجراء',
            key: 'action',
            printable: false,
            sortable: false,
            can_export_excel: false,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;


    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(reRenderItems())
    }, []);


    /* ---------------- searches ------------------ */
    const searchKeyHandler = (event) => {
        dispatch(appendFilterParams({...table.params, search_key: event.target.value}, {}));
    }
    /* ---------------- searches ------------------ */


    /* ---------------- delete ------------------ */
    const deleteHandler = (item) => {
        confirmDelete(async () => {
            await AxiosInstance.delete(`${module_endpoint}/${item.id}`, {}).then(async function ({data}) {
                deletedSweetAlert();
                dispatch(reRenderItems());
            }).catch(function ({response}) {
                errorSweetAlert();
            });
        });
    }
    /* ---------------- delete ------------------ */

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        items_selected,
        isLoading,
        searchKeyHandler,
        deleteHandler,
    };
}