import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import FinancialAccountsTreeHook from "../../../hooks/system/financial_accounts/FinancialAccountsTreeHook";

/* https://bkrem.github.io/react-d3-tree/docs */
import Tree from "react-d3-tree";
import "./custom-tree.css";
import AddNewNodeComponent from "../../../components/financial_accounts/AddNewNodeComponent";
import CreateBondsComponent from "../../../components/financial_accounts/CreateBondsComponent";
import ShowNodeDetailComponent from "../../../components/financial_accounts/ShowNodeDetailComponent";
import CustomTooltip from "../../../shared/material-ui/CustomTooltip";
import {Link} from "react-router-dom";
import {prefixRouterPath} from "../../../config/helper";

const FinancialAccountsTree = () => {
    const {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        orgChart,
        initialDepth,
        OnclickNodeAction,
        showTreeSide,
        OpenTreeSideAction,
        CloseTreeSideAction,
        translation,
        zoom,
        activeTab,
        handleTabClick,
        allowAddBillOfExchange,
        nodeSelected,
        totalBalance,
        openAccountSmallDetails,

        StoreNewNodeFormik,
        ResetStoreNewNodeForm,
        isStoreNewNodeFormSubmitLoading,
        storeNewNodeErrors,
        StoreNewNodeRef,
        selectedDepth,
        openBondSideBar,
        account_type,
    } = FinancialAccountsTreeHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} route={create_redirect}/>

            <div className="info-box">
                <i className="bi bi-info-circle"> </i>
                <p>
                    يمكنك الضغط علي اي اصل من الشجرة حتي تتمكن من اضافة اصل جديد او سند له
                </p>
            </div>

            <div className="flex-btns-tree">
                <button className="btn-2" onClick={e => openBondSideBar("purchase")}>
                    تسديد مشتريات
                </button>

                <button className="btn-1" onClick={e => openBondSideBar("sale")}>
                    تحصيل فاتورة مبيعات
                </button>

                <button className="btn-4" onClick={e => openBondSideBar("supplier")}>
                    دفعة مقدمة مورد
                </button>

                <button className="btn-3" onClick={e => openBondSideBar("customer")}>
                    دفعة مقدمة عميل
                </button>
            </div>

            <CardComponent>
                {openAccountSmallDetails && (
                    <div className="dts-tree">
                        <h3>{nodeSelected?.name}</h3>
                        <div className="dts-fx">
                            <h6>رصيد مجمع</h6>
                            <p>
                                مدين
                                <span>{totalBalance?.combined_debit_amount}</span>
                            </p>
                            <p>
                                دائن
                                <span>{totalBalance?.combined_credit_amount}</span>
                            </p>
                        </div>

                        <div className="dts-fx">
                            <h6>رصيد اجمالي</h6>
                            <p>
                                مدين
                                <span>{totalBalance?.balanced_debit_amount}</span>
                            </p>
                            <p>
                                دائن
                                <span>{totalBalance?.balanced_credit_amount}</span>
                            </p>
                        </div>

                        {
                            selectedDepth > 1 && (
                                <div className="flex-add-node">

                                    {
                                        (account_type === "purchase") ||
                                        (account_type === "return_purchase") ||
                                        (account_type === "sale") ||
                                        (account_type === "return_sale") ||
                                        (account_type === "safe") ||
                                        (account_type === "internal_safe") ||
                                        (account_type === "capital") ||
                                        (account_type === "capital_partner")
                                            ?
                                            (
                                                <>
                                                    {
                                                        (account_type === "return_sale") &&
                                                        <Link
                                                            to={prefixRouterPath("sales-module/return_sales/create")}
                                                            className="add-node">
                                                            <i className="bi bi-plus-lg"> </i>
                                                            اضافة مرتجع مبيعات
                                                        </Link>
                                                    }

                                                    {
                                                        (account_type === "return_purchase") &&
                                                        <Link
                                                            to={prefixRouterPath("purchases-module/return_purchases/create")}
                                                            className="add-node">
                                                            <i className="bi bi-plus-lg"> </i>
                                                            اضافة مرتجع مشتريات
                                                        </Link>
                                                    }

                                                    {
                                                        (account_type === "purchase") &&
                                                        <span className="add-node"
                                                              onClick={e => OpenTreeSideAction("document")}>
                                                          <i className="bi bi-plus-lg"> </i>
                                                          تسديد مشتريات
                                                        </span>
                                                    }

                                                    {
                                                        (account_type === "sale") &&
                                                        <span className="add-node"
                                                              onClick={e => OpenTreeSideAction("document")}>
                                                          <i className="bi bi-plus-lg"> </i>
                                                          تحصيل مبيعات
                                                        </span>
                                                    }

                                                    {
                                                        (account_type === "capital_partner") &&
                                                        <span className="add-node"
                                                              onClick={e => OpenTreeSideAction("document")}>
                                                          <i className="bi bi-plus-lg"> </i>
                                                          اضافة رأس مال للشريك
                                                        </span>
                                                    }

                                                </>
                                            )
                                            :
                                            (
                                                <span className="add-node"
                                                      onClick={e => OpenTreeSideAction("document")}>
                                                  <i className="bi bi-plus-lg"> </i>
                                                  اضافة سند صرف او قبض
                                                </span>
                                            )
                                    }


                                    {
                                        (account_type === "normal") &&
                                        <span className="add-node" onClick={e => OpenTreeSideAction("AddNode")}>
                                          <i className="bi bi-plus-lg"> </i>
                                          اضافة حساب
                                        </span>
                                    }

                                    {
                                        (account_type === "capital") &&
                                        <span className="add-node" onClick={e => OpenTreeSideAction("AddNode")}>
                                          <i className="bi bi-plus-lg"> </i>
                                          اضافة شريك رأس مال
                                        </span>
                                    }

                                    {
                                        (account_type === "safe") &&
                                        <span className="add-node" onClick={e => OpenTreeSideAction("AddNode")}>
                                          <i className="bi bi-plus-lg"> </i>
                                          اضافة حساب خزنة
                                        </span>
                                    }
                                </div>
                            )
                        }
                    </div>
                )}

                <div id="treeWrapper" style={{height: "calc(100vh - 315px)", direction: "ltr"}}>
                    <Tree
                        orientation={"vertical"}
                        shouldCollapseNeighborNodes={true}
                        depthFactor={150}
                        nodeSize={{x: 250, y: 200}}
                        translate={translation}
                        pathFunc={"diagonal"}
                        zoom={zoom}
                        initialDepth={initialDepth}
                        separation={{siblings: 1, nonSiblings: 1}}
                        enableLegacyTransitions={true}
                        collapsible={true}
                        zoomable={true}
                        data={orgChart}
                        onNodeClick={OnclickNodeAction}
                    />
                </div>

                <div className={`side-tree ${showTreeSide ? "active" : ""}`}>
                    <h3 className="header-tree">
                        <i className="bi bi-diagram-3"> </i>شجرة الحسابات
                        <i className="bi bi-x" onClick={CloseTreeSideAction}>
                            {" "}
                        </i>
                    </h3>

                    <div className="card-body">
                        {/*<ShowNodeDetailComponent nodeSelected={nodeSelected}/>*/}

                        {
                            activeTab === "document"
                                ?
                                (<CreateBondsComponent nodeSelected={nodeSelected} account_type={account_type}/>)
                                :
                                (
                                    <AddNewNodeComponent
                                        formik={StoreNewNodeFormik}
                                        resetForm={ResetStoreNewNodeForm}
                                        isSubmitLoading={isStoreNewNodeFormSubmitLoading}
                                        errors={storeNewNodeErrors}
                                        StoreNewNodeRef={StoreNewNodeRef}/>
                                )
                        }
                    </div>
                </div>

                <div className={`side-tree-overlay ${showTreeSide ? "active" : ""}`} onClick={CloseTreeSideAction}>

                </div>
            </CardComponent>
        </>
    );
};

export default FinancialAccountsTree;
