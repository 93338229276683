import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";

/* edit hook */
import EditHook from "../../../hooks/cruds/return_purchases/EditHook";

/* others */
import LoadingComponent from "../../../components/global/LoadingComponent";
import SelectInput from "../../../shared/inputs/SelectInput";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import ProductListComponent from "../../../components/ProductList/ProductListComponent";
import RestButton from "../../../shared/buttons/RestButton";
import InvoiceProductsListComponent from "../../../components/invoice/InvoiceProductsListComponent";
import InvoiceSettingComponent from "../../../components/invoice/InvoiceSettingComponent";
import ShowInvoiceComponent from "../../../components/invoice/ShowInvoiceComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import InfoPage from "../../../components/global/InfoPage";


const Edit = () => {

    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        suppliers,
        isOldItemsLoading,
        oldItems,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,
        /* start product list operations */
        onChangeHandler,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                {isOldItemsLoading ? <LoadingComponent/> : ''}

                <CardComponent>
                    <div className="row">
                        {/* reference code */}
                        <NormalInput
                            readOnly={true}
                            value={oldItems?.reference_code}
                            className={"col-lg-4 col-sm-6 col-12"}
                            name={"reference_code"}
                            label={"الرقم المرجعي للفاتورة"}
                            placeholder={"الرقم المرجعي للفاتورة"}
                            error={errors?.["reference_code"]?.[0] ?? ""}/>
                    </div>
                </CardComponent>

                {/* show products after search */}
                <CardComponent custom_class="card-custom"
                               card_title="اختر الاصناف المراد ارجاعها"
                               is_show={InvoiceOperations?.products?.length > 0}>
                    <ProductListComponent/>
                </CardComponent>

                {/* show products after select products */}
                <CardComponent is_show={invoice_products?.length > 0}>
                    <InvoiceProductsListComponent errors={errors}/>
                </CardComponent>

                {/* show invoice & invoice settings */}
                <CardComponent
                    is_show={invoice_products?.length && (Math.abs(InvoiceOperations?.sub_grand_total) ?? 0) > 0}>
                    <div className="flex-total-order">
                        <InvoiceSettingComponent errors={errors}/>
                        <ShowInvoiceComponent/>
                    </div>

                    {invoice_products?.length && (Math.abs(InvoiceOperations?.sub_grand_total) ?? 0) > 0 ? (
                        <div className="card-footer">
                            <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                            {/*<RestButton className={"btn btn-danger"}/>*/}
                        </div>
                    ) : ""}

                </CardComponent>
            </form>
        </>
    );

};

export default Edit;