import React from 'react';
import {trans, useRtkState, warningSweetAlert} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {ChangeInvoicePaidAction} from "../../../../store/pos/Pos";

const KeypadComponent = () => {

    const state = useRtkState('Pos');
    const dispatch = useDispatch();


    let keypad_buttons = [];
    for (let i = 11; i >= 1; i--) {
        if (i === 2) {  
            keypad_buttons.push('.')
        } else if (i === 1) {
            keypad_buttons.push(0)
        } else {
            keypad_buttons.push(i - 2);
        }
    }

    const ClickKeypadAction = (keypad_button) => {

        if (state?.invoice_grand_total <= 0) {
            warningSweetAlert(trans("There no products in invoice ."));
            return false;
        }

        let invoice_paid = state.invoice_paid;

        if (keypad_button === 'del') {
            dispatch(ChangeInvoicePaidAction(invoice_paid.slice(0, -1), {}))
        } else {
            dispatch(ChangeInvoicePaidAction(invoice_paid + keypad_button, {}))
        }
    }

    return (
        <div className="keypad">

            {
                keypad_buttons.map(function (keypad_button, index) {
                    return (<button disabled={state?.invoice_grand_total <= 0} key={index} type="button" onClick={e => ClickKeypadAction(keypad_button)} className="num-btn"
                                    data-number={keypad_button}>
                        {keypad_button}
                    </button>)
                })
            }

            <button type="button" onClick={e => ClickKeypadAction('del')}
                    className="num-btn backspace-btn">
                <i className="bi bi-backspace">
                </i>
            </button>

        </div>
    );
};

export default KeypadComponent;