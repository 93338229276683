import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/cruds/users/EditHook";
import NumberInput from "../../../shared/inputs/NumberInput";
import SelectInput from "../../../shared/inputs/SelectInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";


const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        roles,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">
                        <TextInput label={'الاسم الاول'}
                                   name={'firstname'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'الاسم الثاني'}
                                   name={'lastname'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['name']?.[0] ?? ''}/>

                        <TextInput label={'البريد الالكتروني'}
                                   name={'email'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['email']?.[0] ?? ''}/>

                        <NumberInput label={'الهاتف'}
                                     name={'phone'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['phone']?.[0] ?? ''}/>

                        <SelectInput label="الصلاحية" allow_formik_value={true} name="role_id" formik={formik}
                                     className={'col-lg-3 col-sm-6 col-12'}
                                     error={errors?.['role_id']?.[0] ?? ''}>
                            <option value="" defaultValue disabled={true}>اختر</option>
                            {
                                roles && roles.map(function (role) {
                                    return (<option key={role.id} value={role.id}>{role.name}</option>);
                                })
                            }
                        </SelectInput>


                        <TextInput type={'password'}
                                   label={'كلمة المرور'}
                                   name={'password'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['password']?.[0] ?? ''}/>

                        <TextInput type={'password'}
                                   label={'تاكيد كلمة المرور'}
                                   name={'password_confirmation'}
                                   formik={formik}
                                   className={'col-lg-3 col-sm-6 col-12'}
                                   error={errors?.['password_confirmation']?.[0] ?? ''}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Edit;