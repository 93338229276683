import React, {useRef, useState} from "react";
import PopUp from "../../../../shared/material-ui/PopUp";
import InvoicePrintComponent from "../../../invoice/InvoicePrintComponent";
import {trans, useRtkState, warningSweetAlert} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import ShortcutsComponent from "./ShortcutsComponent";
import SubmitButton from "../../../../shared/buttons/SubmitButton";
import DisplayPaymentComponent from "../ConfirmPayment/DisplayPaymentComponent";
import PaymentSettingComponent from "../ConfirmPayment/PaymentSettingComponent";
import {ChangeConfirmPaymentPopUpAction} from "../../../../store/pos/Pos";
import EmptyContentComponent from "../../../global/EmptyContentComponent";
import ControlButtonHooks from "../../../../hooks/system/pos/ControlButtonHooks";

const ControlButtonsComponent = ({isSubmitLoading}) => {

    const {
        printInvoice,
        saleActionType,
        ChooseSaleActionType,
        OpenConfirmPaymentPopUp,
        CloseConfirmPaymentPopUp,
        PopupConfirmPaidAction,
        posState,
        ConfirmPaidBtn,
        payRef,
        printRef,
        holdRef,
        confirmPayRef,
    } = ControlButtonHooks();

    return (
        <div className="btns-right">
            <input type="hidden" name="sale_action_type" value={saleActionType}/>
            <div className="btns-pay-prt-hld">

                <>
                    {/*<div className={`${isSubmitLoading ? "loader-btns-pos" : "d-none"}`}>
                    <i className="loader-btn"> </i>
                     </div>*/}

                    {/* ChooseSaleActionType('paid') */}
                    <button ref={payRef} disabled={posState?.invoice_grand_total <= 0} type="button"
                            onClick={OpenConfirmPaymentPopUp} className="payment-btn">
                        <i className="bi bi-wallet2"> </i>
                        دفع
                    </button>

                    {/* hidden button "d-none" will be active when popup is opening */}
                    <button className="payment-btn d-none"
                            disabled={!posState?.is_confirm_payment_popup_opened}
                            ref={ConfirmPaidBtn}
                            onClick={(e) => ChooseSaleActionType("paid")}>
                        <i className="bi bi-wallet2"> </i>
                        تاكيد الدفع
                    </button>
                    {/* hidden button "d-none" will be active when popup is opening */}

                    <div className="flex-btns">
                        <button ref={holdRef} disabled={posState?.invoice_grand_total <= 0}
                                onClick={(e) => ChooseSaleActionType("hold")} className="hold-btn">
                            <i className="bi bi-pause-circle"> </i>
                            تعليق
                        </button>
                        <button id="print-invoice" ref={printRef} disabled={posState?.invoice_grand_total <= 0}
                                onClick={printInvoice}
                                type="button" className="print-btn">
                            <i className="bi bi-printer"> </i>
                            طباعة
                        </button>
                    </div>
                </>

            </div>

            {/* Confirm Payment */}
            {/* <PopUp other_modal_class="confirm-paid" isOpen={posState?.is_confirm_payment_popup_opened}
                   setIsOpen={CloseConfirmPaymentPopUp} title="تاكيد عملية الدفع">
                {
                    posState?.invoice_grand_total > 0
                        ?
                        <>
                            <div className="paid-card">
                                <PaymentSettingComponent confirmPayRef={confirmPayRef}/>

                                <DisplayPaymentComponent/>
                            </div>

                            <div className="card-footer">
                                <button ref={confirmPayRef} onClick={PopupConfirmPaidAction}
                                        className="btn btn-primary">
                                    <i className={`${posState?.is_pos_submit_loader ? "loader-btn" : "bi bi-wallet2"}`}> </i>
                                    تاكيد الدفع
                                </button>
                            </div>

                        </>
                        :
                        <EmptyContentComponent/>
                }
            </PopUp> */}

            {/*<PopUp isOpen={isPrintPopUpOpened} setIsOpen={setIsPrintPopUpOpened} title="طباعة فاتورة مبيعات">
                <InvoicePrintComponent/>
            </PopUp>*/}

            <ShortcutsComponent payRef={payRef} confirmPayRef={confirmPayRef} printRef={printRef} holdRef={holdRef}/>
        </div>
    );
};

export default ControlButtonsComponent;
