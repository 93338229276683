import {useEffect, useState} from "react";
import {AppendParamsValues, ChangeSearchLoading, fetchProducts} from "../../../store/invoices/InvoiceOperations";
import {PRODUCT_PER_PAGE} from "../../../config/constants";

export default (dispatch) => {

    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        dispatch(ChangeSearchLoading(true, {}));
        const delay = setTimeout(() => {
            dispatch(AppendParamsValues({search_key: searchQuery, per_page: PRODUCT_PER_PAGE}, {}));
            dispatch(fetchProducts());
        }, 500);

        return () => clearTimeout(delay);
    }, [searchQuery]);
    const handleOnFormChange = (event) => {
        /* if search_products changed */
        if (event?.target?.name === 'search_products') {
            let search_products_val = event?.target?.value;
            setSearchQuery(search_products_val);
        }
    };

    return {
        handleOnFormChange,
    };
};