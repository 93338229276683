import React from "react";
import { Link } from "react-router-dom";
import { prefixRouterPath } from "../../config/helper";
import CardComponent from "../../components/cards/CardComponent";

const Page404 = () => {
  return (
    <>
      <CardComponent>
        <div className="error-box">
          <h1>404</h1>
          <h3>نأسف لك , الصفحة غير موجودة!</h3>
          <p>الصفحة المطلوبة لم يتم العثور عليها</p>
          {/*<Link to={prefixRouterPath("")} className="btn btn-primary">*/}
          {/*  العودة للرئيسية*/}
          {/*</Link>*/}
        </div>
      </CardComponent>
    </>
  );
};

export default Page404;
