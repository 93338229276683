import {
    confirmDelete,
    continueSweetAlert,
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    successSweetAlert,
    trans, useRtkState, warningSweetAlert
} from "../../../config/helper";

import {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import {setLocale} from 'yup';
import {
    fetchProducts,
    ResetAllInitialValues,
    AppendParamsValues,
    SetInvoiceType, SetIsReturnInvoice, SetDateHandler, UpdateInvoiceProducts, AppendProductToProductsListAction,
} from "../../../store/invoices/InvoiceOperations";

import {useDispatch} from "react-redux";
import {PRODUCT_PER_PAGE} from "../../../config/constants";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}

export default () => {

    const params = useParams();

    const module_name = ` عرض التحويل رقم ${params?.slug}`;
    const transfer_stocks_endpoint = `transfer_stocks/slug/${params?.slug}`;


    const [transfer_stock, setSetTransferStock] = useState([]);
    const [is_loading, setIsLoading] = useState(false);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "transfer_stocks");

        if (permissions.length > 0) {
            setIsLoading(true);
            AxiosInstance.get(transfer_stocks_endpoint, {}).then(async function ({data}) {
                await setSetTransferStock(data.data);
                setIsLoading(false);
            }).catch(function ({response}) {
                //
            });
        }

    }, [permissions]);


    return {
        module_name,
        transfer_stock,
        is_loading,
    };
}