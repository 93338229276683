import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import SelectInput from "../../../shared/inputs/SelectInput";

/* create hook */
import CreateHook from "../../../hooks/cruds/return_sales/CreateHook";

/* others */
import ProductListComponent from "../../../components/ProductList/ProductListComponent";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import InfoPage from "../../../components/global/InfoPage";
import InvoiceSettingComponent from "../../../components/invoice/InvoiceSettingComponent";
import ShowInvoiceComponent from "../../../components/invoice/ShowInvoiceComponent";
import InvoiceProductsListComponent from "../../../components/invoice/InvoiceProductsListComponent";


const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,

        /* start product list operations */
        reference_code_ref,
        is_reference_code_loading,
        SearchInvoiceReferenceCodeHandler,
        InvoiceOperations,
        invoice_products,
        /* start product list operations */
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {SYSTEM_TITLE} | {module_name}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <InfoPage/>

            <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                <CardComponent>
                    <div className="row">
                        {/* reference code */}
                        <TextInput
                            className={"col-lg-4 col-sm-6 col-12"}
                            name={"reference_code"}
                            input_ref={reference_code_ref}
                            label={"الرقم المرجعي للفاتورة"}
                            error={errors?.["reference_code"]?.[0] ?? ""}/>
                    </div>

                    <div className="card-footer">
                        {/* button to search Action */}
                        <SimpleButton
                            clickAction={SearchInvoiceReferenceCodeHandler}
                            className={"btn btn-primary"}
                            btn_name={"بحث"}
                            isLoading={is_reference_code_loading}/>
                    </div>
                </CardComponent>


                {/* show products after search */}
                <CardComponent custom_class="card-custom"
                               card_title="اختر الاصناف المراد ارجاعها"
                               is_show={InvoiceOperations?.products?.length > 0}>
                    <ProductListComponent/>
                </CardComponent>

                {/* show products after select products */}
                <CardComponent is_show={invoice_products?.length > 0}>
                    <InvoiceProductsListComponent errors={errors}/>
                </CardComponent>

                {/* show invoice & invoice settings */}
                <CardComponent is_show={invoice_products?.length && (InvoiceOperations?.sub_grand_total ?? 0) > 0}>
                    <div className="flex-total-order">
                        <InvoiceSettingComponent errors={errors}/>
                        <ShowInvoiceComponent/>
                    </div>


                    {invoice_products?.length && (Math.abs(InvoiceOperations?.sub_grand_total) ?? 0) > 0 ? (
                        <div className="card-footer">
                            <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                            <RestButton className={"btn btn-danger"}/>
                        </div>
                    ) : ""}

                </CardComponent>

            </form>
        </>
    );
};

export default Create;
