import {preparingCanExportExcel, useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../store/table/slices/tableSlice";
import React, {useEffect, useState} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../store/invoices/InvoiceOperations";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    /* table */
    const prefix = "accounts-balances";
    const module_endpoint = "accounting-trial-balance?per_page=100";
    const module_name = 'ميزان المراجعة المحاسبي';
    const create_redirect = "";

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;

    const headers = [
        {
            label: 'الحساب',
            key: 'title',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }, {
            label: 'الحساب الرئيسي',
            key: 'parent_account_title',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'combined_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'combined_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: ' مدين ',
            key: 'balanced_debit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'دائن',
            key: 'balanced_credit_amount',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }
    ];

    const [totalBalance, setTotalBalance] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [accounting_trial_balance_total_amount, setAccountingTrialBalanceTotalAmount] = useState(null);


    const [maxLevel, setMaxLevel] = useState(0);


    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "accounting-trial-balance");

        if (permissions.length > 0 && permissions.includes("accounting-trial-balance")) {
            dispatch(endpoint(module_endpoint, {}));
            dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
            dispatch(reRenderItems())

            AxiosInstance.get('accounting-trial-balance-total-amount', {}).then(async function ({data}) {
                await setAccountingTrialBalanceTotalAmount(data.data);
            }).catch(function ({response}) {
                //
            });

            AxiosInstance.get('financial-accounts-has-subs', {}).then(async function ({data}) {
                await setAccounts(data.data);
            }).catch(function ({response}) {
                //
            });

        }

    }, [permissions]);

    useEffect(() => {
        if (items.length > 0) {
            let maxLevel = -Infinity;
            let highestLevelItem = null;

            items.forEach(item => {
                if (item.level > maxLevel) {
                    maxLevel = item.level;
                    highestLevelItem = item;
                }
            });

            setMaxLevel(maxLevel);

            /*console.log("items", items)
            console.log("maxLevel", maxLevel)*/
        }
    }, [items]);

    const OnAccountChange = (event) => {
        dispatch(appendFilterParams({...table.params, parent_id: event.target.value}, {}));
        dispatch(reRenderItems())
    }

    const setTrSpecialColor = (level) => {
        let color = "";
        if (level == 1) {
            color = "bg-primary";
        }
        switch (level) {
            case 1:
                color = "bg-table-color-1";
                break;
            case 2:
                color = "bg-table-color-2";
                break;
            case 3:
                color = "bg-table-color-3";
                break;
            case 4:
                color = "bg-table-color-4";
                break;
            case 5:
                color = "bg-table-color-5";
                break;
            default:
                color = "";
        }
        return color;
    }

    const drawThSpace = (level, isInTable = false) => {
        return Array.from({length: level}).map((_, i) => (
            i == 0 ? <th colSpan={level + 1}>{isInTable ? "الــحــســاب" : ""}</th> : ""
        ));
    }

    const drawTdSpace = (level) => {
        return Array.from({length: level}).map((_, i) => (
            <td key={i}>
                <span className={"table-vertical-line"}>----</span>
            </td>
        ));
    }


    const exportAccountTrialBalanceAction = (e) => {
        e.preventDefault();

        AxiosInstance.get('accounting-trial-balance-export', {
            responseType: 'blob'
        }).then(async function ({data}) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'trial_balance.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(function ({response}) {
            console.error("There was an error exporting the file!", response);
        });
    }

    return {
        table,
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        accounts,
        accounting_trial_balance_total_amount,
        OnAccountChange,
        maxLevel,
        setTrSpecialColor,
        drawThSpace,
        drawTdSpace,
        exportAccountTrialBalanceAction,
    };
}