import React from 'react';
import ContentLoader from 'react-content-loader';
import { useRtkState } from '../../../config/helper';

const ProductSkeleton = ({ props }) => {
  const settings = useRtkState('Settings');
  let tableSkeletonColor1 =
    settings?.systemMode === 'dark' ? '#182a46' : '#e8e9ef';
  let tableSkeletonColor2 =
    settings?.systemMode === 'dark' ? '#233b60' : '#f0f2f5';

  const renderContentLoaders = () => {
    const times = 44;
    const loaders = [];

    for (let i = 0; i < times; i++) {
      loaders.push(
        <ContentLoader
          key={i}
          rtl
          speed={2}
          width={"100%"}
          height={60}
          viewBox="0 0 100% 60"
          backgroundColor={tableSkeletonColor1}
          foregroundColor={tableSkeletonColor2}
          {...props}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="60" />
        </ContentLoader>
      );
    }

    return loaders;
  };

  return <div className='product-skeleton'>{renderContentLoaders()}</div>;
};

export default ProductSkeleton;

