import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/system/settings/SettingsHook";
import ImageInput from "../../../shared/inputs/ImageInput";
import TextInput from "../../../shared/inputs/TextInput";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import SelectInput from "../../../shared/inputs/SelectInput";
import {useDispatch} from "react-redux";
import {fetchSettings} from "../../../store/settings/SettingsSlice";

const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        currencies,
        warehouses,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        previewImage,
        setPreviewImage,
        safes,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">

                        <TextInput label={'اسم النظام'}
                                   name={'system_name'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['system_name']?.[0] ?? ''}/>


                        <SelectInput label={'المستودع او المخزن الافتراضي'} name={'default_warehouse_id'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-4 col-sm-4 col-12'}
                                     error={errors?.['default_warehouse_id']?.[0] ?? ''}>

                            <option disabled selected={true}>اختر المخزن</option>
                            {
                                warehouses.map(function (warehouse, index) {
                                    return <option key={warehouse?.id} value={warehouse?.id}>
                                        {warehouse?.name}
                                    </option>;
                                })
                            }
                        </SelectInput>


                        <SelectInput label={'الخزنة الافتراضية'} name={'default_safe_id'}
                                     formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-4 col-sm-4 col-12'}
                                     error={errors?.['default_safe_id']?.[0] ?? ''}>

                            <option disabled selected={true}>اختر الخزنة</option>
                            {
                                safes.map(function (safe, index) {
                                    return (
                                        <optgroup key={safe.id} label={safe.name}>
                                            {
                                                safe.accounts.map(function (safe) {
                                                    return (<option key={safe.id} value={safe.id}>{safe.title}</option>);
                                                })
                                            }
                                        </optgroup>
                                    );
                                })
                            }
                        </SelectInput>


                        <SelectInput label={'العملة'} name={'currency'} formik={formik}
                                     allow_formik_value={true}
                                     className={'col-lg-4 col-sm-4 col-12'}
                                     error={errors?.['currency']?.[0] ?? ''}>
                            <option value="" defaultValue>اختر العملة</option>
                            {
                                currencies && currencies.map(function (currency) {
                                    return (<option key={currency.id} value={currency.code}>
                                        {currency.name} ({currency.code})
                                    </option>);
                                })
                            }
                        </SelectInput>

                        {/*<TextInput label={'الـ timezone'}*/}
                        {/*           name={'time_zone'}*/}
                        {/*           formik={formik}*/}
                        {/*           allow_formik_value={true}*/}
                        {/*           className={'col-lg-4 col-sm-6 col-12'}*/}
                        {/*           error={errors?.['time_zone']?.[0] ?? ''}/>*/}

                        <TextInput label={'البريد الالكتروني'}
                                   name={'email'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['email']?.[0] ?? ''}/>

                        <TextInput label={'الهاتف'}
                                   name={'phone'}
                                   type={'number'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['phone']?.[0] ?? ''}/>


                        <TextInput label={'العنوان'}
                                   name={'address'}
                                   formik={formik}
                                   allow_formik_value={true}
                                   className={'col-lg-4 col-sm-6 col-12'}
                                   error={errors?.['address']?.[0] ?? ''}/>

                        <ImageInput label={'الصورة'}
                                    name={'logo'}
                                    formik={formik}
                                    previewImage={previewImage}
                                    setPreviewImage={setPreviewImage}
                                    className={'col-lg-12 col-sm-12 col-12'}
                                    error={errors?.['image']?.[0] ?? ''}/>
                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>

                </form>
            </CardComponent>
        </>
    );

};

export default Edit;