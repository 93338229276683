import React from 'react';
import {Link} from "react-router-dom";

const EditBtnTableComponent = ({redirect_to}) => {
    return (
        <Link to={redirect_to} className="btn-info"><i className="bi bi-pencil"> </i></Link>
    );
};

export default EditBtnTableComponent;
