import {prefixRouterPath} from "./helper";
import {box, group, settings, money, report, system, invoice} from "./images-path";
import reports_sub_menus from "./reports_sub_menus";

/**
 * (Hint) Before add new route
 * 1- make sure this "permission_id" returned in permissions (backend api)
 * 2- add route here with ( permission: {id: "permission_id", name: "permission_name"} ),
 *
 * You can add new permission in "permissionList.js" in config
 *
 *
 *| Important Note :
 *| When move from "sub_menus" to others ==> (Don't Forget move it in "backend api")
 *|
 *
 *
 */

export default [
    {
        route: 'system_basics',
        menu_name: 'اساسيات النظام',
        icon: system,
        sub_menus: [
            {
                route: prefixRouterPath('product-settings/warehouses'),
                menu_name: 'المخازن',
                permission: {id: 5, name: "warehouses"},
            },
            {
                route: prefixRouterPath('product-settings/safes'),
                menu_name: 'الخزن',
                permission: {id: 29, name: "safes"},
            },
            {
                route: prefixRouterPath('product-settings/product_categories'),
                menu_name: 'الاقسام الرئيسية والفرعية',
                permission: {id: 9, name: "product_categories"},
            },
            {
                route: prefixRouterPath('product-settings/brands'),
                menu_name: 'الماركات',
                permission: {id: 10, name: "brands"},
            },
            {
                route: prefixRouterPath('product-settings/base_units'),
                menu_name: 'الوحدات الاساسية',
                permission: {id: 6, name: "base_units"},
            },
            {
                route: prefixRouterPath('product-settings/units'),
                menu_name: 'الوحدات الفرعية',
                permission: {id: 7, name: "units"},
            },
            {
                route: prefixRouterPath('product-settings/currencies'),
                menu_name: 'العملات',
                permission: {id: 8, name: "currencies"},
            },
            {
                route: prefixRouterPath('product-settings/print-barcode'),
                menu_name: 'طباعة الباركود',
                permission: {id: 13, name: "print-barcode"},
            },
            // {
            //     route: prefixRouterPath('expenses-module/expense_categories'),
            //     menu_name: 'اقسام المصروفات',
            //     permission: {id: 18, name: "expense_categories"},
            // },
            {
                route: prefixRouterPath('product-settings/payment_types'),
                menu_name: 'انواع المدفوعات',
                permission: {id: 33, name: "payment_types"},
            },
        ]
    },
    {
        route: 'users_accounts',
        menu_name: 'الحسابات',
        icon: group,
        sub_menus: [
            {
                route: prefixRouterPath('admins/admins'),
                menu_name: 'حسابات مشرفين النظام',
                permission: {id: 2, name: "admins"},
            },
            {
                route: prefixRouterPath('users/suppliers'),
                menu_name: 'حسابات الموردين',
                permission: {id: 3, name: "suppliers"},
            },
            {
                route: prefixRouterPath('users/customers'),
                menu_name: 'حسابات العملاء',
                permission: {id: 4, name: "customers"},
            },
        ]
    },
    {
        route: 'warehouses_products',
        menu_name: 'الاصناف',
        icon: box,
        sub_menus: [
            {
                route: prefixRouterPath('product-settings/products'),
                menu_name: 'الاصناف',
                permission: {id: 11, name: "products"},
            },
            {
                route: prefixRouterPath('stocks/transfer_stocks'),
                menu_name: 'التحويل بين المخازن',
                permission: {id: 34, name: "transfer_stocks"},
            },
            {
                route: prefixRouterPath('product-settings/product-prices-list'),
                menu_name: 'تحديث الاسعار',
                permission: {id: 12, name: "product-prices-list"},
            },
        ]
    },
    {
        route: 'invoices',
        menu_name: 'الفواتير الالكترونية',
        icon: invoice,
        sub_menus: [
            {
                route: prefixRouterPath('purchases-module/purchases'),
                menu_name: 'المشتريات',
                permission: {id: 14, name: "purchases"},
            },
            {
                route: prefixRouterPath('sales-module/sales'),
                menu_name: 'المبيعات',
                permission: {id: 16, name: "sales"},
            },
            {
                route: prefixRouterPath('purchases-module/return_purchases'),
                menu_name: 'مرتجعات المشتريات',
                permission: {id: 15, name: "return_purchases"},
            },
            {
                route: prefixRouterPath('sales-module/return_sales'),
                menu_name: 'مرتجعات المبيعات',
                permission: {id: 17, name: "return_sales"},
            }
        ]
    },
    {
        route: 'safe',
        menu_name: 'المالية',
        icon: money,
        sub_menus: [
            // {
            //     route: prefixRouterPath('accounts-balances/exchange_bonds/create'),
            //     menu_name: 'اضافة سندات الصرف',
            //     permission: {id: 31, name: "exchange_bonds"},
            // },
            // {
            //     route: prefixRouterPath('accounts-balances/receipt_vouchers/create'),
            //     menu_name: 'اضافة سندات القبض',
            //     permission: {id: 32, name: "receipt_vouchers"},
            // },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-safes'),
                menu_name: 'استاذ عام خزن',
                permission: {id: 30, name: "accounts-balances-for-safes"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-suppliers'),
                menu_name: 'أستاذ عام موردين',
                permission: {id: 20, name: "accounts-balances-for-suppliers"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-customers'),
                menu_name: 'أستاذ عام عملاء',
                permission: {id: 21, name: "accounts-balances-for-customers"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-expenses'),
                menu_name: 'أستاذ عام المصروفات',
                permission: {id: 41, name: "accounts-balances-for-expenses"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-purchases'),
                menu_name: 'أستاذ عام مشتريات',
                permission: {id: 37, name: "accounts-balances-for-purchases"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-return-purchases'),
                menu_name: 'أستاذ عام مرتجع مشتريات',
                permission: {id: 38, name: "accounts-balances-for-return-purchases"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-sales'),
                menu_name: 'أستاذ عام مبيعات',
                permission: {id: 39, name: "accounts-balances-for-sales"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounts-balances-for-return-sales'),
                menu_name: 'أستاذ عام مرتجع مبيعات',
                permission: {id: 40, name: "accounts-balances-for-return-sales"},
            },
            {
                route: prefixRouterPath('accounts-balances/accounting-trial-balance'),
                menu_name: 'ميزان المراجعة المحاسبي',
                permission: {id: 42, name: "accounting-trial-balance"},
            },
            {
                route: prefixRouterPath('financial-accounts/tree'),
                menu_name: 'شجرة الحسابات',
                permission: {id: 43, name: "financial_accounts"},
            },
        ]
    },
    {
        route: 'reports',
        menu_name: 'التقارير',
        icon: report,
        permission: 'reports',
        sub_menus: [...reports_sub_menus()]
    },
    {
        route: 'settings',
        menu_name: 'الاعدادات',
        icon: settings,
        sub_menus: [
            {
                route: prefixRouterPath('settings/general'),
                menu_name: 'الاعدادات العامة',
                permission: {id: 22, name: "settings"},
            },
            {
                route: prefixRouterPath('roles/roles-permissions'),
                menu_name: 'اعدادات الادوار والصلاحيات',
                permission: {id: 1, name: "roles-permissions"},
            },
            {
                route: prefixRouterPath('settings/work-shifts'),
                menu_name: 'الورديات',
                permission: {id: 36, name: "work-shifts"},
            }
        ]
    },
]