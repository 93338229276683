import React from 'react';
import {search_whites} from "../../../config/images-path";
import {useRtkState} from "../../../config/helper";
import {useDispatch} from "react-redux";
import {reRenderItems} from "../../../store/table/slices/tableSlice";

const FilterContentComponent = ({children, isFilterShow,custom_class_search = ""}) => {
    const table = useRtkState('table');
    const dispatch = useDispatch();

    const searchHandler = (event) => {
        event.preventDefault();
        dispatch(reRenderItems())
    }

    return (
        <div className={`${isFilterShow ? 'displayBlock' : ''} ${custom_class_search} `} id="filter_inputs">
                <form onSubmit={searchHandler} action="">
                    <div className="row">
                        {children}
                        <div className="col-sm-1 col-2 pd-0">
                            <button className="btn btn-filters btn-success">
                                <img src={search_whites} alt="img"/>
                            </button>
                        </div>
                    </div>
                </form>
        </div>
    );
};

export default FilterContentComponent;
