import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";

/* create hook */
import CreateHook from "../../../hooks/cruds/expense_categories/CreateHook";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import SelectInput from "../../../shared/inputs/SelectInput";

const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        previewImage,
        setPreviewImage,
        expense_categories,
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {" "}
                    {SYSTEM_TITLE} | {module_name}{" "}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    <SelectInput
                        label={"قسم المصروف الرئيسي"}
                        name={"parent_id"}
                        formik={formik}
                        className={"col-lg-4 col-sm-6 col-12"}
                        error={errors?.["parent_id"]?.[0] ?? ""}>
                        <option value="" selected={true}>قسم رئيسي جديد</option>
                        {expense_categories && expense_categories.map(function (expense_category) {
                            return (
                                <option key={expense_category.id} value={expense_category.id}>
                                    {expense_category.name}
                                </option>
                            );
                        })}
                    </SelectInput>

                    <ImageInput
                        label={"الصورة"}
                        name={"image"}
                        formik={formik}
                        previewImage={previewImage}
                        setPreviewImage={setPreviewImage}
                        className={"col-lg-4 col-sm-6 col-12"}
                        error={errors?.["image"]?.[0] ?? ""}
                    />

                    <TextInput
                        label={"الاسم"}
                        name={"name"}
                        formik={formik}
                        className={"col-lg-4 col-sm-6 col-12"}
                        error={errors?.["name"]?.[0] ?? ""}
                    />

                    <TextAreaInput
                        label={"ملاحظات"}
                        name={"description"}
                        formik={formik}
                        className={"col-lg-4 col-sm-6 col-12"}
                        error={errors?.["description"]?.[0] ?? ""}
                    />

                    <div className="card-footer">
                        <SubmitButton
                            isSubmitLoading={isSubmitLoading}
                            className={"btn btn-primary"}
                        />
                        <RestButton className={"btn btn-danger"}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );
};

export default Create;
