import React, { useRef } from "react";
import PageHeader from "../../layout/PageHeader";
import CardComponent from "../../cards/CardComponent";
import ReactToPrint from "react-to-print";

export default ({ product, product_stocks }) => {
  const PrintRef = useRef();

  return (
    <>
      <PageHeader title="تفاصيل المنتج" />
      <div ref={PrintRef}>
        <CardComponent>
          <div className="prd-dts">
            {product?.image_url ? <img src={product?.image_url} alt="" /> : ""}

            <div className="content-prd">
              <h4>{product?.name}</h4>
              <div class="table-content-prd">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>القسم</td>
                      <td>{product?.product_category?.name ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>الماركة</td>
                      <td>{product?.brand?.name ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>تنبيه المخزن عند الكمية</td>
                      <td>{product?.stock_alert ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>سعر الصنف (سعر البيع)</td>
                      <td>{product?.product_price ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>أقل سعر بيع</td>
                      <td>{product?.min_product_price ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>أعلى سعر بيع</td>
                      <td>{product?.max_product_price ?? "----"}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>الباركود</td>
                      <td>{product?.code ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>وحدة الشراء</td>
                      <td>{product?.purchase_unit?.name ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>وحدة البيع</td>
                      <td>{product?.sale_unit?.name ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>الوحدة المكافئة</td>
                      <td>{product?.equivalent_unit_value ?? "----"}</td>
                    </tr>
                    <tr>
                      <td>ملاحظات</td>
                      <td>{product?.notes ?? "..."}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardComponent>

        {product?.opening_balance_purchases_items &&
        product?.opening_balance_purchases_items?.length ? (
          <CardComponent
            card_title="رصيد اول مدة للمخازن"
            custom_class="card-custom"
          >
            <table className="table table-custom">
              <thead>
                <tr>
                  <th>المخزن</th>
                  <th>سعر التكلفة</th>
                  <th>الكمية</th>
                </tr>
              </thead>
              <tbody>
                {product?.opening_balance_purchases_items.map(function (st) {
                  return (
                    <tr>
                      <td>{st?.warehouse_name}</td>
                      <td>{st?.product_cost}</td>
                      <td>{st?.qty}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardComponent>
        ) : (
          ""
        )}

        {product_stocks && product_stocks?.length ? (
          <CardComponent
            card_title="الكمية في المخازن"
            custom_class="card-custom"
          >
            <table className="table table-custom">
              <thead>
                <tr>
                  <th>المخزن</th>
                  <th>
                    الكمية المتاحة بالـ {product?.purchase_unit?.name ?? ""}
                  </th>
                  <th> الكمية المتاحة بالـ {product?.sale_unit?.name ?? ""}</th>
                </tr>
              </thead>

              <tbody>
                {product_stocks.map(function (ps) {
                  return (
                    <tr>
                      <td>{ps?.warehouse_name}</td>
                      <td>{ps?.total_base_unit_stock}</td>
                      <td>{ps?.total_total_stock}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardComponent>
        ) : (
          ""
        )}
      </div>

      <ReactToPrint
        bodyClass="print-agreement"
        content={() => PrintRef.current}
        trigger={() => (
          <button className="btn btn-primary print-bill">
            <i className="bi bi-printer"> </i> طباعة
          </button>
        )}
      />
    </>
  );
};
