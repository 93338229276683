import React, {useEffect} from "react";
import CustomTooltip from "../../../../shared/material-ui/CustomTooltip";
import {useRtkState} from "../../../../config/helper";

const ShortcutsComponent = ({payRef, confirmPayRef, printRef, holdRef}) => {

    const posState = useRtkState("Pos");

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                payRef.current.click();
            }
            if (event.key === 'F2') {
                printRef.current.click();
            }
            if (event.key === 'F4') {
                holdRef.current.click();
            }
        });
    }, []);

    return (
        <ul className="shortcuts">
            <li>
                <CustomTooltip title="اضغط علي زر F2 لعمل طباعة">
                    <em>F2</em>
                </CustomTooltip>
            </li>
            <li>
                <CustomTooltip title="اضغط علي زر F4 لعمل تعليق للفاتورة">
                    <em>F4</em>
                </CustomTooltip>
            </li>
            <li>
                <CustomTooltip title="اضغط علي زر F11 لملئ الشاشة">
                    <em>F11</em>
                </CustomTooltip>
            </li>
            <li>
                <CustomTooltip title="اضغط علي زر Enter لدفع الفاتورة">
                    <em>Enter</em>
                </CustomTooltip>
            </li>
        </ul>
    );
};

export default ShortcutsComponent;
