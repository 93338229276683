import React from "react";
import PaymentMethodsComponent from "../components/PaymentMethodsComponent";
import NormalTextAreaInput from "../../../shared/inputs/NormalTextAreaInput";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import PayingPurchaseInvoiceHook from "../../../hooks/system/financial_accounts/PayingPurchaseInvoiceHook";

export default ({safes}) => {
    const {
        currency,
        onChangeSupplierGetTheirOwnPurchases,
        suppliers,
        onChangePurchase,
        purchases,
        purchase,
        isSubmitLoading,
        grand_total,
        total_paid_payments,
        total_rest_payments,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    } = PayingPurchaseInvoiceHook();

    return (
        <>
            <form ref={submitForm} onSubmit={formik.handleSubmit}>
                <div className="flex-radio-box">
                    <input
                        type="radio"
                        id="nature_of_account_1"
                        name="nature_of_account"
                        value="1"
                        checked={natureOfAccountChecked === "nature_of_account_1"}
                    />

                    <label htmlFor="nature_of_account_1" onClick={e => ChangeNatureOfAccount("nature_of_account_1")}>
                        تسديد فاتورة مشتريات
                    </label>

                    <input
                        type="radio"
                        id="nature_of_account_2"
                        name="nature_of_account"
                        value="2"
                        checked={natureOfAccountChecked === "nature_of_account_2"}
                    />
                    <label htmlFor="nature_of_account_2" onClick={e => ChangeNatureOfAccount("nature_of_account_2")}>
                        استرجاع مبلغ فاتورة مشتريات
                    </label>
                </div>

                <NormalSelectInput
                    label={"الخزنة"}
                    name="safe_id"
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["safe_id"]?.[0] ?? ""}
                >
                    {safes &&
                    safes.map(function (safe) {
                        return (
                            <optgroup key={safe.id} label={safe.name}>
                                {safe.accounts.map(function (safe) {
                                    return (
                                        <option key={safe.id} value={safe.id}>
                                            {safe.title}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        );
                    })}
                </NormalSelectInput>

                <NormalSelectInput
                    label={"المورد"}
                    name={"supplier_id"}
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["supplier_id"]?.[0] ?? ""}
                    onChangeHandler={onChangeSupplierGetTheirOwnPurchases}
                >
                    <option selected={true}>اختر المورد</option>
                    {suppliers &&
                    suppliers.map(function (supplier) {
                        return (
                            <option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                            </option>
                        );
                    })}
                </NormalSelectInput>

                <NormalSelectInput
                    id="purchaseID"
                    onChangeHandler={onChangePurchase}
                    label={"فاتورة المشتريات"}
                    name={"purchase_id"}
                    formik={formik}
                    className={"col-12"}
                    error={errors?.["purchase_id"]?.[0] ?? ""}
                >
                    <option selected={true}>اختر الفاتورة</option>
                    {purchases && purchases.length > 0 ? (
                        purchases.map(function (purchase) {
                            return (
                                <option key={purchase.id} value={purchase.id}>
                                    {purchase.reference_code}
                                </option>
                            );
                        })
                    ) : (
                        <option selected={true} disabled={true}>
                            لايوجد فواتير ,اختر المورد
                        </option>
                    )}
                </NormalSelectInput>

                {
                    purchase && (
                        <div className="totals-inviose-tree">
                            <p>
                                الاجمالي : <span>{grand_total}</span> <em>{currency}</em>
                            </p>{" "}
                            <p>
                                المدفوع : <span>{total_paid_payments}</span> <em>{currency}</em>
                            </p>{" "}
                            {total_rest_payments > 0 ? (
                                <p>
                                    المتبقي : <span>{total_rest_payments}</span> <em>{currency}</em>
                                </p>
                            ) : (
                                <p>تم تسديد المبلغ بالكامل</p>
                            )}
                        </div>
                    )
                }

                {
                    purchase && (total_rest_payments > 0 || natureOfAccountChecked === "nature_of_account_2") ?
                        <>
                            <PaymentMethodsComponent errors={errors}/>

                            <NormalTextAreaInput className={"col-12"} label={"ملاحظات"}/>

                            <div className="card-footer">
                                <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                            </div>
                        </>
                        : ""
                }


            </form>
        </>
    );
};
