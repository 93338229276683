import React from "react";
import SearchComponent from "./components/PosCore/SearchComponent";
import ShowProductsListComponent from "./components/PosCore/ShowProductsListComponent";
import CategoriesAndBrandsComponent from "./components/PosCore/CategoriesAndBrandsComponent";
import ControlButtonsComponent from "./components/PosCore/ControlButtonsComponent";
import ShowWarehouseAndClientsListComponent from "./components/PosCore/ShowWarehouseAndClientsListComponent";
import InvoiceProductsComponent from "./components/PosCore/InvoiceProductsComponent";
import KeypadComponent from "./components/PosCore/KeypadComponent";
import InvoiceSettingsComponent from "./components/PosCore/InvoiceSettingsComponent";
import InvoiceDate from "./components/PosCore/InvoiceDate";
import {useRtkState} from "../../config/helper";
import EmptyContentComponent from "../global/EmptyContentComponent";
import DisplayPaymentComponent from "./components/ConfirmPayment/DisplayPaymentComponent";
import PaymentSettingComponent from "./components/ConfirmPayment/PaymentSettingComponent";
import {ChangeConfirmPaymentPopUpAction} from "../../store/pos/Pos";
import {useDispatch} from "react-redux";
import Split from "react-split";

const PosCoreComponent = ({formik, handleOnFormChange, submitForm, isSubmitLoading, errors, isPosLoading}) => {

    const dispatch = useDispatch();

    const CloseConfirmPayment = () => {
        dispatch(ChangeConfirmPaymentPopUpAction(false, {}));
    };

    const posState = useRtkState("Pos");

    return (

        <Split className="pos-content" sizes={[50, 50]} minSize={637} expandToMin={true} gutterSize={2}
               direction="horizontal" cursor="col-resize">

            {/* **************** left section **************** */}
            <form onChange={handleOnFormChange} ref={submitForm} onSubmit={formik.handleSubmit}>

                <div className="left-sec">
                    <InvoiceDate is_hidden={true}/>
                    <ShowWarehouseAndClientsListComponent formik={formik} errors={errors}/>
                    <InvoiceProductsComponent formik={formik} errors={errors}/>
                    <InvoiceSettingsComponent/>

                    <div className="btns-pos">
                        <ControlButtonsComponent isSubmitLoading={isSubmitLoading}/>
                        <KeypadComponent/>
                    </div>
                </div>

                <div className={`confirm-payment ${posState?.is_confirm_payment_popup_opened ? "active" : ""}`}>
                    <h3 className="header-menu-confirm">
                        <i className="bi bi-x" onClick={CloseConfirmPayment}>
                            {" "}
                        </i>
                        تأكيد عملية الدفع
                    </h3>
                    {posState?.invoice_grand_total > 0 ? (
                        <>
                            <div className="paid-card">
                                <PaymentSettingComponent errors={errors}/>

                                <DisplayPaymentComponent/>
                            </div>

                            <div className="card-footer">
                                <button className="btn btn-primary">
                                    <i className={`${posState?.is_pos_submit_loader ? "loader-btn" : "bi bi-wallet2"}`}>
                                        {" "}
                                    </i>
                                    تاكيد الدفع
                                </button>
                            </div>
                        </>
                    ) : (
                        <EmptyContentComponent/>
                    )}
                </div>
                <div className={`confirm-payment-overlay ${posState?.is_confirm_payment_popup_opened ? "active" : ""}`}
                     onClick={CloseConfirmPayment}>
                    //
                </div>
            </form>

            {/* **************** right section **************** */}
            <div className="right-sec">
                <div className="show-products">
                    {
                        (posState?.products && posState?.products.length > 0)
                        ||
                        (posState?.is_products_loading) ? (<CategoriesAndBrandsComponent/>) : ("")
                    }
                    <ShowProductsListComponent/>
                </div>
            </div>
        </Split>
    );
};

export default PosCoreComponent;
