import {
    getLocale,
    getYupLocaleObject,
    prefixRouterPath,
    trans,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";

import {setLocale} from 'yup';

if (getLocale() !== 'en') {
    setLocale(getYupLocaleObject());
}


export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'expenses-module';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'expenses';
    const module_endpoint = 'expenses';
    const module_name = 'النفقات (المصروفات)';

    const slug = 'تعديل بيانات ' + (oldItems?.name ?? '----');

    const [warehouses, setWarehouses] = useState([]);
    const [expense_categories, setExpense_categories] = useState([]);


    useEffect(() => {

        AxiosInstance.get('warehouses', {}).then(async function ({data}) {
            await setWarehouses(data.data);
        }).catch(function ({response}) {
            //
        });

        AxiosInstance.get('expense_categories', {}).then(async function ({data}) {
            await setExpense_categories(data.data);
        }).catch(function ({response}) {
            //
        });

        /* get old item */
        AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
            const old_item = data.data;
            await setOldItems(old_item);

            /* set inputs values */
            await formik.setValues({
                warehouse_id: old_item?.warehouse?.id,
                expense_category_id: old_item?.expense_category?.id,

                date: old_item?.date,
                amount: old_item?.amount,
                details: old_item?.details,
            });
            setIsOldItemsLoading(false);
        }).catch(function ({response}) {
            if (response.data.status === 404) {
                navigate(prefixRouterPath(route_endpoint));
                warningSweetAlert(trans('This_page_no_longer_exists'));
            }
        });
    }, []);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            warehouse_id: '',
            expense_category_id: '',
            date: '',
            amount: '',
            details: '',
        },
        validationSchema: Yup.object({
            warehouse_id: Yup.string().required(trans('This_field_is_required')),
            expense_category_id: Yup.string().required(trans('This_field_is_required')),
            date: Yup.string().required(trans('This_field_is_required')),
            amount: Yup.string().required(trans('This_field_is_required')),
            details: Yup.string().required(trans('This_field_is_required')),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });


    const resetForm = () => {
        setErrors({});
    }

    return {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        warehouses,
        expense_categories
    };
}