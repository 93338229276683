import React from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import ImageInput from "../../../shared/inputs/ImageInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoadingComponent from "../../../components/global/LoadingComponent";

/* edit hook */
import EditHook from "../../../hooks/cruds/work-shifts/EditHook";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";


const Edit = () => {

    const {
        module_name,
        slug,
        isOldItemsLoading,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        users,
    } = EditHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {module_name} </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <CardComponent>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                    {isOldItemsLoading ? <LoadingComponent/> : ''}

                    <div className="row">

                        <NormalSelectInput
                            label={"الموظف"}
                            name={"user_id"}
                            error={errors?.['user_id']?.[0] ?? ''}
                            className={'col-md-3 col-sm-6 col-12'}>
                            <option value="" selected disabled={true}>اختر</option>
                            {
                                users && users.map(function (user) {
                                    return (
                                        <option key={user.id} value={user.id} selected={oldItems?.user_id === user.id}>
                                            {((user?.firstname ?? "--") + " " + (user?.lastname ?? "--"))}
                                        </option>
                                    );
                                })
                            }
                        </NormalSelectInput>

                        <TextInput
                            type="date"
                            label={"التاريخ"}
                            name={"start_date"}
                            formik={formik}
                            allow_formik_value={true}
                            className={'col-lg-3 col-sm-6 col-12'}
                            error={errors?.['start_date']?.[0] ?? ''}/>

                        <TextInput
                            type="time"
                            label={"وقت البداء"}
                            name={"start_time"}
                            formik={formik}
                            allow_formik_value={true}
                            className={'col-lg-3 col-sm-6 col-12'}
                            error={errors?.['start_time']?.[0] ?? ''}/>

                    </div>

                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={'btn btn-primary'}/>
                    </div>
                </form>
            </CardComponent>
        </>
    );

};

export default Edit;