
import React from 'react';
import { useRtkState } from '../../../config/helper';
import { useDispatch } from 'react-redux';
import { ChangeSideMenuTypeAction } from '../../../store/settings/SettingsSlice';

const SideMenuTypeComponent = () => {
  const settings = useRtkState("Settings");
  const dispatch = useDispatch();

  const ChangeSideType = (mode) => {
      dispatch(ChangeSideMenuTypeAction(mode, {}));
  };
  return (
    <div className="flex-content">
    <div className="toggle-mode">
      <input type="radio" id="horizontal" name="sidebar-type" checked={settings?.SideMenuType === "horizontal"}/>
      <label htmlFor="horizontal" onClick={(e) => ChangeSideType("horizontal")}>
        <div className="type-sidebar">
          <div className="header-side">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="body-side">
            <ul>
              <li className="sidebar-shape"></li>
              <li className="body-shape"></li>
            </ul>
          </div>
        </div>
        <span>قائمة علوية</span>
      </label>
    </div>
    <div className="toggle-mode">
      <input type="radio" id="vertical" name="sidebar-type" checked={settings?.SideMenuType === "vertical"}/>
      <label htmlFor="vertical" onClick={(e) => ChangeSideType("vertical")}>
      <div className="type-sidebar vertical-side">
          <div className="header-side">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="body-side">
            <ul>
              <li className="sidebar-shape"></li>
              <li className="body-shape"></li>
            </ul>
          </div>
        </div>
        <span>قائمة جانبية</span>
      </label>
    </div>
  </div>
  );
}

export default SideMenuTypeComponent;
