import React, {useEffect, useRef, useState} from 'react';
import AxiosInstance from "../../../config/AxiosInstance";
import {successSweetAlert, useRtkState, warningSweetAlert} from "../../../config/helper";
import {useDispatch} from "react-redux";
import PaymentHook from "../../../hooks/system/invoice/PaymentHook";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SetLocalStatePaymentsAction} from "../../../store/invoices/InvoiceOperations";

export default () => {

    const currency = useRtkState('Settings').settings?.currency;
    let dispatch = useDispatch();
    let [isSubmitLoading, setIsSubmitLoading] = useState(false);
    let [errors, setErrors] = useState({});
    const submitForm = useRef();
    const module_endpoint = "bonds/PayingPurchaseInvoice";
    const {payment_skeleton} = PaymentHook();

    const [suppliers, setSuppliers] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [purchase, setPurchase] = useState(null);
    const [grand_total, setGrandTotal] = useState(null);
    const [total_paid_payments, setTotal_paid_payments] = useState(null);
    const [total_rest_payments, setTotal_rest_payments] = useState(null);


    let [natureOfAccountChecked, setNatureOfAccountChecked] = useState("nature_of_account_1");
    const ChangeNatureOfAccount = (nature_of_account) => {
        setNatureOfAccountChecked(nature_of_account);
    };

    const onChangeSupplierGetTheirOwnPurchases = (event) => {
        setPurchase(null);
        let supplier_id = event?.target?.value;
        AxiosInstance.get(`purchases?sort_type=asc&sort_attribute=id&supplier_id=${supplier_id}`, {}).then(async function ({data}) {
            let purchases = data.data;
            if (purchases.length > 0) {
                await setPurchases(data.data);
            } else {
                setPurchases([]);
            }
        }).catch(function ({response}) {
            //
        });
    }

    const setPurchaseByReferenceCode = (reference_code) => {
        AxiosInstance.get(`purchases/slug/${reference_code}`, {}).then(async function ({data}) {
            let purchase = data.data;
            setPurchase(purchase);
        }).catch(function ({response}) {
            //
        });
    }

    const onChangePurchase = (event) => {
        let selectElement = document.getElementById("purchaseID");
        let selectedOption = selectElement.options[selectElement.selectedIndex];
        let reference_code = selectedOption.text;
        setPurchase(null);
        setPurchaseByReferenceCode(reference_code);
    }

    const handleReset = () => {
        if (submitForm.current) {
            submitForm.current.reset();
        }
    };

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                let payments = [payment_skeleton];
                dispatch(SetLocalStatePaymentsAction(payments, {}));
                successSweetAlert();

                /* update amounts */
                setPurchaseByReferenceCode(purchase?.reference_code);
                setPurchase(purchase);

                setTimeout(() => {
                    setPurchase(null);
                    handleReset();
                }, 1500);
            }).catch(function ({response}) {
                let errors = response.data;
                setIsSubmitLoading(false);
                if (errors.status === 420) {
                    warningSweetAlert(errors?.message);
                    return false;
                }
                setErrors(errors.data);
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    useEffect(() => {
        AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSuppliers(data.data);
        }).catch(function ({response}) {
            //
        });

    }, []);

    useEffect(() => {
        setGrandTotal(purchase?.grand_total);
        setTotal_paid_payments(purchase?.total_paid_payments);
        setTotal_rest_payments(purchase?.total_rest_payments);
    }, [purchase])


    return {
        currency,
        onChangeSupplierGetTheirOwnPurchases,
        suppliers,
        onChangePurchase,
        purchases,
        purchase,
        isSubmitLoading,
        grand_total,
        total_paid_payments,
        total_rest_payments,
        formik,
        errors,
        submitForm,
        natureOfAccountChecked,
        ChangeNatureOfAccount
    }
};