import React from "react";
import ErrorValidateComponent from "../../components/global/ErrorValidateComponent";
import {errorValidation} from "../../config/images-path";
import CustomTooltip from "../material-ui/CustomTooltip";

const TextAreaInput = ({className, label, placeholder, formik, name, value, error, onChangeHandler, onKeyupHandler, id}) => {

    const server_error = error;
    const front_error = (formik && formik.touched[name] && formik.errors[name]) || server_error;
    const error_text = front_error || server_error;

    return (
        <div className={className}>
            <div className="form-group">

                <div className="flex-label">
                    {label && <label>{label}</label>}
                    <span className="flex-error">
                      <CustomTooltip title={error_text}>
                            <em>
                                {error_text && (
                                    <img src={errorValidation} className={"warning-error"} alt={"errorValidation"}/>
                                )}
                            </em>
                    </CustomTooltip>
                    </span>
                </div>

                <textarea
                    id={id}
                    name={name}
                    className={`form-control ${front_error ? "border border-danger" : ""}`}
                    placeholder={placeholder}
                    onChange={onChangeHandler}
                    onKeyUp={onKeyupHandler}
                    value={value}> </textarea>
            </div>
        </div>
    );
};

export default TextAreaInput;
