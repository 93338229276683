import React from 'react';
import LogoComponent from "./login/LogoComponent";
import HeadingComponent from "./login/HeadingComponent";
import ImagePreview from "./login/ImagePreview";

const LoginComponent = ({children}) => {
    return (

        <section className="login-content">
            <div className="container h-100">
                <div className="flex-login">
                    <div className="right-div">
                        <LogoComponent/>
                        <HeadingComponent/>
                        {children}
                    </div>
                    <div className="left-div">
                        <ImagePreview/>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default LoginComponent;