import React, { useEffect, useRef, useState } from "react";
import InvoiceHeaderSettingsComponent from "./InvoiceDetailsComponent/InvoiceHeaderSettingsComponent";
import InvoiceItemsTableComponent from "./InvoiceDetailsComponent/InvoiceItemsTableComponent";
import InvoicePricingDetailComponent from "./InvoiceDetailsComponent/InvoicePricingDetailComponent";
import InvoiceNotesComponent from "./InvoiceDetailsComponent/InvoiceNotesComponent";
import InvoiceTotalPricingComponent from "./InvoiceDetailsComponent/InvoiceTotalPricingComponent";
import InvoiceFooterComponent from "./InvoiceDetailsComponent/InvoiceFooterComponent";
import InvoiceButtonsComponent from "./InvoiceDetailsComponent/InvoiceButtonsComponent";
import PurchaseInfoComponent from "./InvoiceDetailsComponent/PurchaseInfoComponent";
import ReactToPrint from "react-to-print";

const ShowPurchaseDetailsComponent = ({ invoice, invoice_type }) => {
  let invoice_types = {
    purchase: "المبلغ المدفوع",
    return_purchase: "المبلغ المستلم",
    sale: "المبلغ المستلم",
    return_sale: "المبلغ المدفوع",
  };

  const [restAmount, setRestAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);

  useEffect(() => {
    let amount = 0;
    if (invoice?.payments && invoice?.payments.length > 0) {
      invoice?.payments.forEach(function (payment) {
        amount += payment?.amount;
      });
    }

    setTotalPaidAmount(parseFloat(amount.toFixed(2)));

    let rest = (invoice?.grand_total ?? 0) - amount;
    setRestAmount(parseFloat(rest.toFixed(2)));
  }, [invoice]);

  const PrintRef = useRef();

  return (
    <>
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => PrintRef.current}
        trigger={() => (
          <button className="btn btn-primary print-bill">
            <i className="bi bi-printer"> </i> طباعة الفاتورة
          </button>
        )}
      />
      <div id="printInvoiceID" ref={PrintRef} className="purchases-bill">
        <div className="bg-bill">
          <InvoiceHeaderSettingsComponent />
          <PurchaseInfoComponent invoice={invoice} invoice_type={invoice_type} />{" "}
          {/* belongs to purchase */}
          <InvoiceItemsTableComponent items={invoice?.purchase_items} />
          <div className="flex-boxs">
            <div className="right-box">
              <InvoicePricingDetailComponent
                invoice={invoice}
                invoice_types={invoice_types}
                invoice_type={invoice_type}
                totalPaidAmount={totalPaidAmount}
              />

              <InvoiceNotesComponent invoice={invoice} />
            </div>

            <InvoiceTotalPricingComponent
              invoice={invoice}
              invoice_types={invoice_types}
              invoice_type={invoice_type}
              totalPaidAmount={totalPaidAmount}
              restAmount={restAmount}
            />
          </div>
        </div>

        <InvoiceFooterComponent />
      </div>
    </>
  );
};

export default ShowPurchaseDetailsComponent;
