import React from 'react';

const TablePrint = () => {
    return (
        <div className="table-responsive">
            <table id="print-table" className="print-table table">
                {/* print here */}
            </table>
        </div>
    );
};

export default TablePrint;
