import React from 'react';
import {Link} from "react-router-dom";

const PageHeader = ({children, title, slug, route, title_icon, allow_icon = true, redirect_name = "اضافة جديدة"}) => {
    return (
        <>
            <div className="page-header">
                <div className="page-title">
                    <h4>
                        {/* {title_icon && <i className={title_icon}></i>} */}
                        {title}
                    </h4>
                    {slug && <h6>{slug}</h6>}
                </div>
                {
                    route &&
                    (<div className="page-btn">
                      {children ?? ""}
                        <Link to={route} className="btn btn-primary">
                            {allow_icon && <i className="bi bi-plus-lg"> </i>}
                            <span>{redirect_name}</span>
                        </Link>
                    </div>)
                }
            </div>
        </>
    );
};

export default PageHeader;
