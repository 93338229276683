import React from 'react';
import Barcode from 'react-barcode';

const ShowBarcode = ({code, qty}) => {

  const BarCodeWithCountRequired = () => {
    let barcode = [<Barcode value={code} />];
    if (qty > 1) {
      barcode = [];
      for (let i = 1; i <= qty; i++) {
        barcode[i] = <Barcode value={code} />;
      }
    }
    return barcode;
  };


  return (
    <div id="barcode-id" className="barcode-list">
    {qty > 0 &&
      BarCodeWithCountRequired().map(function (barcode) {
        return barcode;
      })}
  </div>
  );
}

export default ShowBarcode;
