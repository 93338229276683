import React from "react";
// Import Swiper React components
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from "react";

const CustomSwiper = ({ children }) => {
  const prevRef = useRef < HTMLDivElement > null;
  const nextRef = useRef < HTMLDivElement > null;

  return (
    <Swiper
      modules={[Navigation]}
      navigation={{
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      }}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.update();
      }}
      spaceBetween={7}
      slidesPerView="auto"
      freeMode={true}
    >
      {children}
    </Swiper>
  );
};

export default CustomSwiper;
