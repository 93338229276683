import React, {useEffect, useState} from 'react';
import AxiosInstance from "../../config/AxiosInstance";
import CreatePayingAnExpenseComponent from "./bonds/CreatePayingAnExpenseComponent";
import CreatePayingPurchaseInvoiceComponent from "./bonds/CreatePayingPurchaseInvoiceComponent";
import CreatePayingSaleInvoiceComponent from "./bonds/CreatePayingSaleInvoiceComponent";
import CreatePayingAnAdvancePaymentToSupplierComponent from "./bonds/CreatePayingAnAdvancePaymentToSupplierComponent";
import CreatePayAnAdvancePaymentFromCustomerComponent from "./bonds/CreatePayAnAdvancePaymentFromCustomerComponent";
import CreateCapitalComponent from "./bonds/CreateCapitalPartnerComponent";

const CreateBondsComponent = ({nodeSelected, account_type}) => {

    const [safes, setSafes] = useState([]);

    useEffect(() => {
        AxiosInstance.get('safes?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSafes(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    return (
        <>
            <div className="">
                {
                    account_type === "normal" ?
                        <CreatePayingAnExpenseComponent safes={safes} nodeSelected={nodeSelected}/> : ""
                }

                {
                    account_type === "purchase" ?
                        <CreatePayingPurchaseInvoiceComponent safes={safes}/> : ""
                }

                {
                    account_type === "sale" ?
                        <CreatePayingSaleInvoiceComponent safes={safes}/> : ""
                }

                {
                    account_type === "supplier" ?
                        <CreatePayingAnAdvancePaymentToSupplierComponent safes={safes}/> : ""
                }

                {
                    account_type === "customer" ?
                        <CreatePayAnAdvancePaymentFromCustomerComponent safes={safes}/> : ""
                }

                {
                    account_type === "capital_partner" ?
                        <CreateCapitalComponent safes={safes}  nodeSelected={nodeSelected}/> : ""
                }
            </div>
        </>
    );
};

export default CreateBondsComponent;