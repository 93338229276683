import {
    prefixRouterPath,
    trans, useRtkState,
    warningSweetAlert
} from "../../../config/helper";
import {useRef, useState, useEffect} from "react";
import AxiosInstance from "../../../config/AxiosInstance";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import PermissionMiddleware from "../../../middlewares/PermissionMiddleware";

export default () => {
    /* table */
    const params = useParams();
    const [isOldItemsLoading, setIsOldItemsLoading] = useState(true);
    const [oldItems, setOldItems] = useState({});

    const prefix = 'admins';
    const route_endpoint = (prefix ? prefix + '/' : '') + 'admins';
    const module_endpoint = 'users';
    const module_name = 'المشرفين';

    const slug = 'تعديل بيانات ' + ((oldItems?.firstname + ' ' + oldItems?.lastname) ?? '----');

    const [roles, setRoles] = useState([]);

    const permissions = useRtkState('Settings')?.permissions;

    useEffect(() => {
        PermissionMiddleware(permissions, "admins");

        if (permissions.length > 0) {
            AxiosInstance.get("roles", {}).then(async function ({data}) {
                const roles = data.data;
                setRoles(roles);
            }).catch(function (response) {
                //
            });

            AxiosInstance.get(module_endpoint + '/slug/' + params?.slug, {}).then(async function ({data}) {
                const old_item = data.data;
                await setOldItems(old_item);

                /* set inputs values */
                await formik.setValues({
                    firstname: old_item?.firstname,
                    lastname: old_item?.lastname,
                    email: old_item?.email,
                    phone: old_item?.phone,
                    role_id: old_item?.role?.id,
                });

                setIsOldItemsLoading(false);
            }).catch(function ({response}) {
                if (response.data.status === 404) {
                    navigate(prefixRouterPath(route_endpoint));
                    warningSweetAlert(trans('This_page_no_longer_exists'));
                }
            });
        }
    }, [permissions]);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const submitForm = useRef();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema: Yup.object({
            firstname: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            lastname: Yup.string()
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            email: Yup.string().email(trans('The_email_format_is_invalid'))
                .min(2, trans('This_field_must_be_at_least_2_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .required(trans('This_field_is_required')),

            phone: Yup.number().required(trans('This_field_is_required')),

            password: Yup.string()
                .min(6, trans('This_field_must_be_at_least_6_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .nullable(),

            password_confirmation: Yup.string()
                .min(6, trans('This_field_must_be_at_least_6_characters'))
                .max(50, trans('The_name_must_not_be_greater_than_50_characters'))
                .nullable(),
        }),
        onSubmit: async (values) => {
            setIsSubmitLoading(true);
            const formData = new FormData(submitForm.current);
            formData.append('_method', 'PUT')
            // console.log(Object.fromEntries(formData.entries()))
            await AxiosInstance.post(module_endpoint + '/' + oldItems?.id, formData).then(async function ({data}) {
                setErrors({});
                setIsSubmitLoading(false);
                navigate(prefixRouterPath(route_endpoint));
            }).catch(function ({response}) {
                setErrors(response.data.data);
                setIsSubmitLoading(false);
                // console.log(response.data)
                // warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const resetForm = () => {
        setErrors({});
    }

    return {
        module_name,
        slug,
        isOldItemsLoading,
        roles,
        oldItems,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
    };
}