import React, {useEffect} from 'react';
import SelectInput from "../../shared/inputs/SelectInput";
import {confirmDelete, useRtkState} from "../../config/helper";
import {useDispatch} from "react-redux";
import {
    fetchAllUnits,
    UpdateInvoiceProducts,
    UpdateInvoiceTotalGrand,
    UpdateInvoiceProductSetting, AppendProductToProductsListAction
} from "../../store/invoices/InvoiceOperations";
import NormalInput from "../../shared/inputs/NormalInput";
import NormalSelectInput from "../../shared/inputs/NormalSelectInput";

const InvoiceProductsListComponent = ({errors}) => {

        const dispatch = useDispatch();

        const currency = useRtkState('Settings').settings?.currency;
        const InvoiceOperations = useRtkState('InvoiceOperations');
        const units = InvoiceOperations.units;
        const invoice_products = InvoiceOperations.invoice_products;
        const type = InvoiceOperations.invoice_type; // "sale" or "purchase"
        const tb_type = (type === "purchase" ? "الشراء" : "البيع");

        useEffect(function () {
            dispatch(fetchAllUnits());
        }, [])


        const ChangeProductInvoice = (event, product, attribute) => {
            const value = event?.target?.value;
            dispatch(UpdateInvoiceProductSetting({value, product, attribute}, {}))
        }

        const deleteProductFromTableAction = (product) => {
            confirmDelete(async function () {
                const new_invoice_products = invoice_products.filter(function (invoice_product) {
                    return invoice_product.id !== product.id;
                });
                await dispatch(UpdateInvoiceProducts(new_invoice_products, {}));
                await dispatch(AppendProductToProductsListAction(product, {}));
            });
        }

        return (
            <div className="table-responsive">
                {
                    (invoice_products && invoice_products.length > 0)
                    &&
                    <table className="table table-custom">
                        <thead>
                        <tr>
                            <th>
                                اسم الصنف
                                {InvoiceOperations?.is_return_invoice &&
                                <b> ( المراد ارجاعه ) <i className="bi bi-info-circle"> </i> </b>}
                            </th>
                            <th>
                                {
                                    type === "purchase"
                                        ?
                                        (" سعر تكلفة الصنف ")
                                        :
                                        (" سعر بيع الصنف ")
                                }
                                {InvoiceOperations?.is_return_invoice && <b> - وقت الفاتورة - </b>}
                                ({currency})
                            </th>
                            <th>
                                الكمية
                                {InvoiceOperations?.is_return_invoice &&
                                <b> ( المراد ارجاعها ) </b>}
                            </th>
                            <th>الضريبة %</th>
                            <th>الخصم</th>
                            <th>{"الاجمالي"} ({currency})</th>
                            <th>التحكم</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            invoice_products.map(function (product, index) {
                                return (
                                    <tr key={product?.id}>

                                        <td>
                                            <div className="productimgname">
                                                <img src={product?.image_url} alt={product?.name}/>
                                                <p>
                                                    {product?.name}
                                                </p>
                                            </div>
                                        </td>

                                        {/* items operations */}

                                        {/* product_id */}
                                        <td className="d-none">
                                            <input name={`${type}_items[${index}][product_id]`}
                                                   value={product?.id}
                                                   type="number"/>
                                        </td>

                                        {/* ==================================== */}

                                        {/* product_cost "+++++calc" in purchases */}
                                        <td className={type === "purchase" ? "" : "d-none"}>
                                            <NormalInput
                                                onChangeHandler={event => ChangeProductInvoice(event, product, 'product_cost')}
                                                type={'number'}
                                                min={0}
                                                name={`${type}_items[${index}][product_cost]`}
                                                className={'input-table-sm'}
                                                placeholder="0.00"
                                                value={product?.product_cost > 0 ? product?.product_cost : ''}
                                                error={errors?.[`${type}_items.${index}.product_cost`]?.[0] ?? ''}/>
                                        </td>

                                        {/* product_price "+++++calc" in sales */}
                                        <td className={type === "purchase" ? "d-none" : ""}>
                                            <NormalInput type={'number'}
                                                         onChangeHandler={event => ChangeProductInvoice(event, product, 'product_price')}
                                                         min={0}
                                                         name={`${type}_items[${index}][product_price]`}
                                                         value={product?.product_price}
                                                         className={'input-table-sm'}
                                                         placeholder="0.00"
                                                         error={errors?.[`${type}_items.${index}.product_price`]?.[0] ?? ''}/>
                                        </td>


                                        {/* quantity "+++++calc" */}
                                        <td>
                                            <div className="flex-select-input">
                                                <NormalInput
                                                    onChangeHandler={event => ChangeProductInvoice(event, product, 'quantity')}
                                                    type={'number'}
                                                    min={1}
                                                    name={`${type}_items[${index}][quantity]`}
                                                    className={'input-table-sm'}
                                                    placeholder="0"
                                                    value={product?.quantity}
                                                    step={1}
                                                    error={errors?.[`${type}_items.${index}.quantity`]?.[0] ?? ''}/>

                                                <p className='purchase-unit'>
                                                    {/* product unit */}
                                                    <input type="hidden"
                                                           name={`${type}_items[${index}][${type}_unit_id]`}
                                                           value={type === "purchase" ? product?.purchase_unit?.id : product?.sale_unit?.id}/>
                                                    {type === "purchase" ? product?.purchase_unit?.name : product?.sale_unit?.name}
                                                </p>
                                            </div>
                                        </td>

                                        {/* tax_value "++++++calc" */}
                                        <td>
                                            <NormalInput
                                                onChangeHandler={event => ChangeProductInvoice(event, product, 'tax_value')}
                                                type={'number'}
                                                min={0}
                                                value={product?.tax_value}
                                                name={`${type}_items[${index}][tax_value]`}
                                                className={'input-table-sm'}
                                                placeholder="0.00"
                                                error={errors?.[`${type}_items.${index}.tax_value`]?.[0] ?? ''}/>
                                        </td>

                                        {/* discount "-----calc"  */}
                                        <td>
                                            <div className="flex-select-input">
                                                {/* discount type */}
                                                <NormalSelectInput name={`${type}_items[${index}][discount_type]`}
                                                                   onChangeHandler={event => ChangeProductInvoice(event, product, 'discount_type')}
                                                                   className={'select-table'}>
                                                    <option disabled>نوع الخصم</option>
                                                    <option value={0} selected={product?.discount_type == 0}>
                                                        قيمة
                                                    </option>
                                                    <option value={1} selected={product?.discount_type == 1}>
                                                        نسبة
                                                    </option>
                                                </NormalSelectInput>

                                                {/* discount value */}
                                                <NormalInput
                                                    onChangeHandler={event => ChangeProductInvoice(event, product, 'discount_value')}
                                                    type={'number'}
                                                    min={0}
                                                    value={product?.discount_value}
                                                    name={`${type}_items[${index}][discount_value]`}
                                                    className={'input-table-sm pdr-custom'}
                                                    placeholder="0.00"
                                                    error={errors?.[`${type}_items.${index}.discount_value`]?.[0] ?? ''}/>
                                            </div>
                                        </td>

                                        {/* ==================================== */}


                                        {/* sub total ++++---- */}
                                        <td>
                                        <span
                                            className="all_total_product_cost price-red">{!isNaN(product?.sub_total) ? product?.sub_total : "---"}</span>
                                        </td>


                                        <td>
                                            <div className="flex-btn-table">
                                                <a onClick={event => deleteProductFromTableAction(product)} href="#!"
                                                   className="btn-danger">
                                                    <i className="bi bi-trash">
                                                    </i>
                                                </a>
                                            </div>
                                        </td>

                                    </tr>);
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        );
    }
;

export default InvoiceProductsListComponent;