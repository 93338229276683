import React from 'react';
import DefaultLayout from "../../../layouts/DefaultLayout";
import {prefixRouterPath} from "../../../config/helper";

import Settings from "../../../pages/system/settings/Settings";
import PosLayout from "../../../layouts/PosLayout";
import PosPage from "../../../pages/system/pos/PosPage";

import WorkShifts from "../../../pages/cruds/work-shifts/Index";
import CreateWorkShifts from "../../../pages/cruds/work-shifts/Create";
import EditWorkShifts from "../../../pages/cruds/work-shifts/Edit";


import Reports from '../../../pages/system/reports/Reports';
import PurchasesReport from "../../../pages/system/reports/reports/PurchasesReport";
import SalesReport from "../../../pages/system/reports/reports/SalesReport";
import Profile from '../../../pages/system/settings/Profile';
import ReturnPurchasesReport from "../../../pages/system/reports/reports/ReturnPurchasesReport";
import ReturnSalesReport from "../../../pages/system/reports/reports/ReturnSalesReport";
import TestPage from '../../../pages/TestPage';
import ShortageReport from "../../../pages/system/reports/reports/ShortageReport";
import AccountsBalancesForSuppliers from "../../../pages/system/accounts-balances/AccountsBalancesForSuppliers";
import AccountsBalancesForCustomers from "../../../pages/system/accounts-balances/AccountsBalancesForCustomers";

import AccountsBalancesDetailsForSuppliers
    from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForSuppliers";

import AccountBalancesDetailsForCustomers
    from "../../../pages/system/accounts-balances/AccountBalancesDetailsForCustomers";
import Page404 from "../../../pages/errors/Page404";
import AccountsBalancesDetailsForSafes from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForSafes";
import AccountsBalancesForSafes from "../../../pages/system/accounts-balances/AccountsBalancesForSafes";

import ExchangeBonds from "../../../pages/cruds/exchange_bonds/Index";
import CreateExchangeBonds from "../../../pages/cruds/exchange_bonds/Create";

import ReceiptVouchers from "../../../pages/cruds/receipt_vouchers/Index";
import CreateReceiptVouchers from "../../../pages/cruds/receipt_vouchers/Create";
import GetStoreInventoryReport from "../../../pages/system/reports/reports/GetStoreInventoryReport";
import CreateSafes from "../../../pages/cruds/safes/Create";
import EditSafes from "../../../pages/cruds/safes/Edit";

/* Statements */
import AccountsBalancesDetailsForPurchases
    from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForPurchases";
import AccountsBalancesDetailsForSales from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForSales";
import AccountsBalancesDetailsForReturnSales
    from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForReturnSales";
import AccountsBalancesDetailsForReturnPurchases
    from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForReturnPurchases";
import AccountsBalancesDetailsForExpenses
    from "../../../pages/system/accounts-balances/AccountsBalancesDetailsForExpenses";
import AccountsBalancesForPurchases from "../../../pages/system/accounts-balances/AccountsBalancesForPurchases";
import AccountsBalancesForSales from "../../../pages/system/accounts-balances/AccountsBalancesForSales";
import AccountsBalancesForReturnPurchases
    from "../../../pages/system/accounts-balances/AccountsBalancesForReturnPurchases";
import AccountsBalancesForReturnSales from "../../../pages/system/accounts-balances/AccountsBalancesForReturnSales";
import AccountsBalancesForExpenses from "../../../pages/system/accounts-balances/AccountsBalancesForExpenses";
import AccountingTrialBalance from "../../../pages/system/accounts-balances/AccountingTrialBalance";
import FinancialAccountsTree from "../../../pages/system/financial_accounts/FinancialAccountsTree";
import VoucherTransaction from "../../../pages/system/accounts-balances/VoucherTransaction";


export default [
    {
        path: prefixRouterPath('errors'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* settings */
            {
                path: "error404",
                element: <Page404/>,
            },
        ]
    },
    {
        path: prefixRouterPath('settings'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* settings */
            {
                path: 'general', /* replace "path" with "system/path" */
                element: <Settings/>, /* render instead of <Outlet/> */
            },

            /* profile */
            {
                path: 'profile', /* replace "path" with "system/path" */
                element: <Profile/>, /* render instead of <Outlet/> */
            },

            /* work shifts */
            {
                path: 'work-shifts', /* replace "path" with "system/path" */
                element: <WorkShifts/>, /* render instead of <Outlet/> */
            },
            {
                path: 'work-shifts/create', /* replace "path" with "system/path" */
                element: <CreateWorkShifts/>, /* render instead of <Outlet/> */
            }, {
                path: 'work-shifts/edit/:slug', /* replace "path" with "system/path" */
                element: <EditWorkShifts/>, /* render instead of <Outlet/> */
            },

            /* profile */
            {
                path: 'test-page', /* replace "path" with "system/path" */
                element: <TestPage/>, /* render instead of <Outlet/> */
            }
        ]
    },
    {
        path: prefixRouterPath('pos'),
        element: <PosLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* start-session */
            {
                path: 'start-session', /* replace "path" with "system/path" */
                element: <PosPage/>, /* render instead of <Outlet/> */
            }
        ]
    },
    {
        path: prefixRouterPath('reports'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* reports */
            {
                path: 'index', /* replace "path" with "system/path" */
                element: <Reports/>, /* render instead of <Outlet/> */
            },

            /* purchases-report */
            {
                path: 'purchases-report', /* replace "path" with "system/path" */
                element: <PurchasesReport/>, /* render instead of <Outlet/> */
            },

            /* purchases-return-report */
            {
                path: 'purchases-return-report', /* replace "path" with "system/path" */
                element: <ReturnPurchasesReport/>, /* render instead of <Outlet/> */
            },

            /* sales-report */
            {
                path: 'sales-report', /* replace "path" with "system/path" */
                element: <SalesReport/>, /* render instead of <Outlet/> */
            },

            /* sales-return-report */
            {
                path: 'sales-return-report', /* replace "path" with "system/path" */
                element: <ReturnSalesReport/>, /* render instead of <Outlet/> */
            },

            /* shortage-report */
            {
                path: 'shortage-report', /* replace "path" with "system/path" */
                element: <ShortageReport/>, /* render instead of <Outlet/> */
            },

            /* get-store-inventory */
            {
                path: 'get-store-inventory', /* replace "path" with "system/path" */
                element: <GetStoreInventoryReport/>, /* render instead of <Outlet/> */
            },
        ]
    },
    {
        path: prefixRouterPath('accounts-balances'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* reports */
            {
                path: 'accounts-balances-for-suppliers', /* replace "path" with "system/path" */
                element: <AccountsBalancesForSuppliers/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-customers', /* replace "path" with "system/path" */
                element: <AccountsBalancesForCustomers/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-safes', /* replace "path" with "system/path" */
                element: <AccountsBalancesForSafes/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-purchases', /* replace "path" with "system/path" */
                element: <AccountsBalancesForPurchases/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-sales', /* replace "path" with "system/path" */
                element: <AccountsBalancesForSales/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-return-purchases', /* replace "path" with "system/path" */
                element: <AccountsBalancesForReturnPurchases/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-return-sales', /* replace "path" with "system/path" */
                element: <AccountsBalancesForReturnSales/>, /* render instead of <Outlet/> */
            },
            {
                path: 'accounts-balances-for-expenses', /* replace "path" with "system/path" */
                element: <AccountsBalancesForExpenses/>, /* render instead of <Outlet/> */
            },


            /* statements */
            {
                path: 'show-supplier-account-details/:account_number', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForSuppliers/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-customer-account-details/:account_number', /* replace "path" with "system/path" */
                element: <AccountBalancesDetailsForCustomers/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-safe-account-details/:safe_account_number', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForSafes/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-expense-account-details/:expense_type', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForExpenses/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-purchase-account-details/:reference_code', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForPurchases/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-return-purchase-account-details/:reference_code', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForReturnPurchases/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-sale-account-details/:reference_code', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForSales/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-return-sale-account-details/:reference_code', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForReturnSales/>, /* render instead of <Outlet/> */
            },
            {
                path: 'show-return-sale-account-details/:reference_code', /* replace "path" with "system/path" */
                element: <AccountsBalancesDetailsForReturnSales/>, /* render instead of <Outlet/> */
            },

            /* statements */

            {
                path: 'voucher-transaction/:transaction_id', /* replace "path" with "system/path" */
                element: <VoucherTransaction/>, /* render instead of <Outlet/> */
            },

            {
                path: 'accounting-trial-balance', /* replace "path" with "system/path" */
                element: <AccountingTrialBalance/>, /* render instead of <Outlet/> */
            },

            /* exchange_bonds */
            {
                path: 'exchange_bonds', /* replace "path" with "system/path" */
                element: <ExchangeBonds/>, /* render instead of <Outlet/> */
            }
            ,
            {
                path: 'exchange_bonds/create', /* replace "path" with "system/path" */
                element: <CreateExchangeBonds/>, /* render instead of <Outlet/> */
            },

            /* receipt_vouchers */
            {
                path: 'receipt_vouchers', /* replace "path" with "system/path" */
                element: <ReceiptVouchers/>, /* render instead of <Outlet/> */
            },
            {
                path: 'receipt_vouchers/create', /* replace "path" with "system/path" */
                element: <CreateReceiptVouchers/>, /* render instead of <Outlet/> */
            },
        ]
    }
    ,
    {
        path: prefixRouterPath('financial-accounts'),
        element: <DefaultLayout/>, /* render "Components" inside "Layout" like home, about, etc... */
        children: [
            /* reports */
            {
                path: 'tree', /* replace "path" with "system/path" */
                element: <FinancialAccountsTree/>, /* render instead of <Outlet/> */
            },
        ]
    }
];
