import React, {useEffect, useState} from 'react';
import {useRtkState} from "../../../config/helper";

const InvoiceTotalPricingComponent = ({invoice, invoice_types, invoice_type, restAmount, totalPaidAmount}) => {

    const settings = useRtkState("Settings")?.settings;

    return (
        <>
            <ul className="total-bill">
                <li>
                    <h4>الاجمالي الفرعي</h4>
                    <h5>
                        <span> {invoice?.sub_grand_total} </span> {settings?.currency}
                    </h5>
                </li>
                <li className="discount">
                    <h4>الخصم </h4>
                    <h5>
                        {
                            invoice?.discount_type === 1 ?
                                (<span><small>%</small>{invoice?.discount_rate}</span>)
                                :
                                (<span>{invoice?.discount_amount} {settings?.currency}</span>)
                        }
                    </h5>
                </li>
                <li>
                    <h4>الضريبة %</h4>
                    <h5>
                        <span> {invoice?.tax_rate} </span> %
                    </h5>
                </li>
                <li>
                    <h4>الشحن</h4>
                    <h5>
                        <span> {invoice?.shipping} </span> {settings?.currency}
                    </h5>
                </li>

                <li>
                    <h4>اجمالي الفاتورة</h4>
                    <h5>
                        <span> {invoice?.grand_total} </span> {settings?.currency}
                    </h5>
                </li>

                <li>
                    <h4>اجمالي {invoice_types[invoice_type] ?? ""}</h4>
                    <h5>
                        <span> {invoice?.total_paid_payments} </span> {settings?.currency}
                    </h5>
                </li>

                <li className="total">
                    <h4>اجمالي المتبقي</h4>
                    <h5>
                        <span> {invoice?.total_rest_payments === 0 ? "تم السداد" : `${invoice?.total_rest_payments}  ${settings?.currency}`}</span>
                    </h5>
                </li>

            </ul>

        </>
    );
};

export default InvoiceTotalPricingComponent;