import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../config/constants";
import {trans, useRtkState} from "../config/helper";
import StatisticCount from "../components/HomePage/StatisticCount";
import SystemStatistic from "../components/HomePage/SystemStatistic";
import PermissionMiddleware from "../middlewares/PermissionMiddleware";


const HomePage = () => {

    const permissions = useRtkState('Settings')?.permissions;
    useEffect(() => {
        PermissionMiddleware(permissions, "home_page");
    }, [permissions]);


    return (
        <>
            {
                (permissions.length > 0) && permissions.includes("home_page") &&
                <>
                    <Helmet>
                        <meta name="description" content=""/>
                        <meta name="keywords" content=""/>
                        <title>
                            {" "}
                            {SYSTEM_TITLE} | {trans("Home_page")}{" "}
                        </title>
                    </Helmet>

                    {/* Home page Statistic Count */}
                    <StatisticCount/>

                    {/* system statistics */}
                    <SystemStatistic/>
                </>
            }

        </>
    );
};

export default HomePage;
