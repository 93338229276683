import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../config/constants";
import LoginComponent from "../../components/auth/LoginComponent";
import EmailWithImageInput from "../../shared/inputs/EmailWithImageInput";
import PasswordInput from "../../shared/inputs/PasswordInput";
import LoginButton from "../../shared/buttons/LoginButton";

import {useFormik} from 'formik';
import * as Yup from 'yup';
import AxiosInstance from "../../config/AxiosInstance";
import {Alert} from "@mui/material";
import {setCookie} from "../../hooks/global/useCookie";
import {useNavigate} from "react-router-dom";
import {prefixRouterPath, trans, warningSweetAlert} from "../../config/helper";
import CaptchaComponent from "../../components/global/CaptchaComponent";

const LoginPage = () => {

    const navigate = useNavigate();
    const [is_loading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [captcha, setCaptcha] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(trans('Email_is_invalid')).required(trans('Enter_email')),
            password: Yup.string().required(trans('Enter_password')),
        }),
        onSubmit: async (values) => {

            if (!captcha) {
                warningSweetAlert("من فضلك اكتب ناتج الجمع");
                return;
            }

            setIsLoading(true);
            await AxiosInstance.post('auth/login', values).then(async function ({data}) {
                setShowError(false);
                const user = data?.data;
                const token = user?.token;
                setCookie('token', token);

                let permissions = user?.permissions;

                if (permissions.length === 1 && permissions.includes("pos")) {
                    window.location.href = prefixRouterPath('pos/start-session');
                } else {
                    window.location.href = prefixRouterPath(''); /* to redirect main and routes redirect client side*/
                }

                // navigate(prefixRouterPath(''));
                setIsLoading(false);
            }).catch(function (response) {
                setIsLoading(false);
                warningSweetAlert();
            });
        },
        /* https://www.npmjs.com/package/yup */
    });

    const captchaCallback = (isCorrect) => {
        setCaptcha(isCorrect)
    }

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title> {SYSTEM_TITLE} | {trans('login')}</title>
            </Helmet>


            <LoginComponent>
                {!captcha ? <CaptchaComponent captchaCallback={captchaCallback}/> :
                    <form onSubmit={formik.handleSubmit}>
                        <EmailWithImageInput formik={formik} name={'email'}/>
                        <PasswordInput formik={formik} name={'password'}/>

                        <LoginButton isSubmitLoading={is_loading} isDisabled={!captcha}/>
                        {showError && <Alert severity="error">{trans('Error_Email_Or_Password')}</Alert>}
                    </form>
                }
            </LoginComponent>


        </>
    );
};

export default LoginPage;
