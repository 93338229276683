import React from "react";

const InfoPage = ({page_description}) => {
    return (
        <div className="info-box">
            <i className="bi bi-info-circle"></i>
            {
                page_description
                    ?
                    (<p>{page_description}</p>)
                    :
                    <p>
                        قم بالبحث عن الاصناف المراد اضافتها للفاتورة ثم قم باختيارها وإضافة
                        الكميات ، الخصومات والضرائب
                    </p>
            }
        </div>
    );
};

export default InfoPage;
