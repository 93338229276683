import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {clearOldState, selectedOrNo} from "../../../config/helper";
import {APP_URL} from "../../../config/constants";
import AxiosInstance from "../../../config/AxiosInstance";
import UsePrintHook from "../../../hooks/global/usePrintHook";


export const reRenderItems = createAsyncThunk('table/fetchItems', async (state, action) => {
    try {
        const {endpoint, params} = action.getState()?.table ?? {};
        const response = await AxiosInstance.get(`${APP_URL}${endpoint}`, {params: params});
        return response.data
    } catch (err) {
        // custom error
    }
});

const initialState = {
    old_endpoint: '',
    endpoint: '',
    isLoading: false,
    items: [],
    items_selected: [],
    export_headers: [],
    export_data: [],
    pagination: null,
    params: {}
};

const GenerateExportData = (state) => {
    // let export_headers = state.export_headers;
    // let items_selected = state.items_selected;
    // let data_keys = [];
    // let data = [];
    //
    // export_headers.forEach(function (export_header) {
    //     if (export_header.can_export_excel) {
    //         data_keys.push(export_header.key);
    //     }
    // });
    //
    // items_selected.forEach(function (item_selected) {
    //     data_keys.forEach(function (data_key) {
    //         data.push(item_selected);
    //     })
    // });
    //
    // state.export_data = data;
}

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        endpoint: (state, {type, payload}) => {
            state.old_endpoint = state.endpoint;
            state.endpoint = payload;
            return state;
        },
        itemsLoading: (state) => {
            state.isLoading = true;
            return state;
        },
        selectAll: (state) => {
            if (state.items_selected.length === state.items.length) {
                state.items_selected = [];
            } else {
                state.items_selected = state.items;
            }
            GenerateExportData(state);
            return state;
        },
        selectItem: (state, {type, payload}) => {
            const items_selected = state.items_selected;
            if (selectedOrNo(payload.id, items_selected)) {
                state.items_selected = items_selected.filter(item_selected => item_selected.id !== payload.id);
            } else {
                state.items_selected.push(payload);
            }
            GenerateExportData(state);
            return state;
        },
        exportHeaders: (state, {type, payload}) => {
            state.export_headers = payload;
            return state;
        },
        printTable: (state) => {
            UsePrintHook();
            return state;
        },

        /* filters */
        appendFilterParams: (state, {type, payload}) => {
            state.old_endpoint = state.endpoint;
            state.params = payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(reRenderItems.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(reRenderItems.fulfilled, (state, action) => {
            state.items = action?.payload?.data ?? [];
            state.pagination = action?.payload?.pagination ?? null;
            clearOldState(state);
            /** clear old state data */
            state.isLoading = false;
        })
    }
});

export const {
    endpoint,
    itemsLoading,
    selectAll,
    selectItem,
    exportHeaders,
    printTable,
    searchKey,
    pageNumber,
    appendFilterParams
} = tableSlice.actions

export default tableSlice.reducer