import React from "react";
import {Helmet} from "react-helmet";
import {SYSTEM_TITLE} from "../../../config/constants";

/* components & shared */
import PageHeader from "../../../components/layout/PageHeader";
import CardComponent from "../../../components/cards/CardComponent";
import TextInput from "../../../shared/inputs/TextInput";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import RestButton from "../../../shared/buttons/RestButton";
import SelectInput from "../../../shared/inputs/SelectInput";

/* create hook */
import CreateHook from "../../../hooks/cruds/transfer_stocks/CreateHook";

/* others */
import ProductListComponent from "../../../components/ProductList/ProductListComponent";
import SimpleButton from "../../../shared/buttons/SimpleButton";
import InfoPage from "../../../components/global/InfoPage";
import InvoiceSettingComponent from "../../../components/invoice/InvoiceSettingComponent";
import ShowInvoiceComponent from "../../../components/invoice/ShowInvoiceComponent";
import InvoiceProductsListComponent from "../../../components/invoice/InvoiceProductsListComponent";
import NormalInput from "../../../shared/inputs/NormalInput";
import {SetDateHandler} from "../../../store/invoices/InvoiceOperations";
import NormalSelectInput from "../../../shared/inputs/NormalSelectInput";
import TransferStocksProducts from "../../../components/cruds/transfer_stocks/TransferStocksProducts";


const Create = () => {
    const {
        module_name,
        slug,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        ChangeDateHandler,
        deleteProductFromTableAction,

        /* start product list operations */
        search_loading,
        searchProductsHandler,
        products_list_ref,
        InvoiceOperations,
        invoice_products,

        from_warehouses,
        to_warehouses,
        /* start product list operations */
    } = CreateHook();

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <title>
                    {SYSTEM_TITLE} | {module_name}
                </title>
            </Helmet>

            <PageHeader title={module_name} slug={slug}/>

            <InfoPage/>

            <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>

                <div className="row">
                    <div className="col-lg-5">
                        <CardComponent>

                            <div className="row">

                                <SelectInput
                                    label={"من المخزن"}
                                    name={"from_warehouse_id"}
                                    formik={formik}
                                    className={"col-lg-6 col-sm-6 col-12"}
                                    error={errors?.["from_warehouse_id"]?.[0] ?? ""}>
                                    {
                                        from_warehouses && from_warehouses.map(function (warehouse) {
                                            return (
                                                <option key={warehouse.id} value={warehouse.id}>
                                                    {warehouse.name}
                                                </option>
                                            );
                                        })
                                    }
                                </SelectInput>


                                <SelectInput
                                    label={"الى المخزن"}
                                    name={"to_warehouse_id"}
                                    formik={formik}
                                    className={"col-lg-6 col-sm-6 col-12"}
                                    error={errors?.["to_warehouse_id"]?.[0] ?? ""}>
                                    {
                                        to_warehouses && to_warehouses.map(function (warehouse) {
                                            return (
                                                <option key={warehouse.id} value={warehouse.id}>
                                                    {warehouse.name}
                                                </option>
                                            );
                                        })
                                    }
                                </SelectInput>


                                <NormalInput
                                    type="date"
                                    label={"التاريخ"}
                                    name={"date"}
                                    value={InvoiceOperations?.date ?? new Date().toJSON().slice(0, 10)}
                                    className={"col-lg-12 col-sm-6 col-12"}
                                    error={errors?.["date"]?.[0] ?? ""}
                                    onChangeHandler={event => ChangeDateHandler(event)}/>
                            </div>

                            <div className="card-footer custom-footer">
                                {/* search product input */}
                                <TextInput
                                    className={"col-12"}
                                    input_ref={products_list_ref}
                                    label={"ابحث عن الاصناف المراد اضافتها للفاتورة"}
                                    placeholder={"ابحث عن الصنف ..."}
                                    error={errors?.["purchase_items"]?.[0] ?? ""}/>

                                {/* button to search Action */}
                                <SimpleButton
                                    clickAction={searchProductsHandler}
                                    className={"btn btn-primary custom-btn-search"}
                                    btn_name={"بحث"}
                                    isLoading={search_loading}/>
                            </div>
                        </CardComponent>
                    </div>
                    <div className="col-lg-7">
                        {/* show products after search */}
                        <CardComponent custom_class="card-custom"
                                       card_title="اختر الاصناف المراد اضافتها"
                                       is_show={InvoiceOperations?.products?.length > 0}>
                            <ProductListComponent/>
                        </CardComponent>
                    </div>

                </div>


                {/* show products after select products */}
                <CardComponent custom_class="mt-3" is_show={invoice_products?.length > 0}>
                    <TransferStocksProducts products={invoice_products}
                                            errors={errors}
                                            deleteProductFromTableAction={deleteProductFromTableAction}/>
                    {
                        invoice_products?.length > 0
                            ? (
                                <div className="card-footer">
                                    <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                                </div>
                            ) : ""
                    }
                </CardComponent>

            </form>
        </>
    );
};

export default Create;
