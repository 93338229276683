import React from 'react';
import PopUp from "../../../../shared/material-ui/PopUp";
import TextInput from "../../../../shared/inputs/TextInput";
import TextAreaInput from "../../../../shared/inputs/TextAreaInput";
import SubmitButton from "../../../../shared/buttons/SubmitButton";
import AddNewCustomer from "../../../../hooks/system/pos/AddNewCustomer";
import RestButton from "../../../../shared/buttons/RestButton";

const AddClientComponent = () => {

    const {
        module_name,
        formik,
        submitForm,
        isSubmitLoading,
        errors,
        resetForm,
        isPopUpOpened,
        setIsPopUpOpened,
        handleClickOpen,
    } = AddNewCustomer();

    return (
        <div className='add-person'>

            <button type="button" onClick={handleClickOpen} className="add-customer position-relative">
                <i className="bi bi-plus">
                </i>
                أضافة عميل
            </button>

            <PopUp isOpen={isPopUpOpened} setIsOpen={setIsPopUpOpened} title={module_name}>
                <form ref={submitForm} onReset={resetForm} onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <TextInput
                            label={"الاسم"}
                            name={"name"}
                            formik={formik}
                            className={"col-lg-6 col-md-6 col-12"}
                            error={errors?.["name"]?.[0] ?? ""}/>

                        <TextInput
                            label={"البريد الالكتروني"}
                            name={"email"}
                            formik={formik}
                            className={"col-lg-6 col-md-6 col-12"}
                            error={errors?.["email"]?.[0] ?? ""}/>

                        <TextInput
                            label={"الهاتف"}
                            name={"phone"}
                            formik={formik}
                            className={"col-lg-6 col-md-6 col-12"}
                            error={errors?.["phone"]?.[0] ?? ""}/>

                        <TextInput
                            label={"الدولة"}
                            name={"country"}
                            formik={formik}
                            className={"col-lg-6 col-md-6 col-12"}
                            error={errors?.["country"]?.[0] ?? ""}/>

                        <TextInput
                            label={"المدينة"}
                            name={"city"}
                            formik={formik}
                            className={"col-lg-6 col-md-6 col-12"}
                            error={errors?.["city"]?.[0] ?? ""}/>

                        <TextAreaInput
                            label={"العنوان"}
                            name={"address"}
                            formik={formik}
                            className={"col-12"}
                            error={errors?.["address"]?.[0] ?? ""}/>
                    </div>
                    <div className="card-footer">
                        <SubmitButton isSubmitLoading={isSubmitLoading} className={"btn btn-primary"}/>
                    </div>
                </form>
            </PopUp>

        </div>
    );
};

export default AddClientComponent;