import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import AxiosInstance from "../../config/AxiosInstance";
import {preparing_invoice_product, trans, warningSweetAlert} from "../../config/helper";
import {setCookie} from "../../hooks/global/useCookie";
import PosReduxMethods from "../../hooks/system/pos/PosReduxMethods";
import AuthorizationFetch from "../../config/AuthorizationFetch";

export const fetchAllCategories = createAsyncThunk('pos/fetchCategories', async (paylaod, action) => {
    try {
        const {params} = action.getState()?.Pos ?? {};
        const response = await AxiosInstance.get(`product_categories`, {});
        return response.data
    } catch (err) {
        // custom error
    }
});

export const fetchAllCustomers = createAsyncThunk('pos/fetchCustomers', async (paylaod, action) => {
    try {
        const response = await AxiosInstance.get(`customers?sort_attribute=id&sort_type=asc`, {params: {}});
        return response.data
    } catch (err) {
        // custom error
    }
});

export const fetchAllWarehouses = createAsyncThunk('pos/fetchWarehouses', async (paylaod, action) => {
    try {
        const response = await AxiosInstance.get(`warehouses?sort_attribute=id&sort_type=asc`, {params: {}});
        return response.data
    } catch (err) {
        // custom error
    }
});

export const fetchAllProducts = createAsyncThunk('pos/fetchProducts', async (paylaod, action) => {
    try {
        let {params, warehouse_selected} = action.getState()?.Pos ?? {};
        params = {...params, warehouse_id: warehouse_selected?.id};

        if (params.warehouse_id) {
            return await (await AuthorizationFetch("products?sort_type=asc&sort_attribute=id", params)).json();
        }
        return null;
    } catch (err) {
        // custom error
    }
});


const {
    UpdateRepeatedProductQuantityAndReturnAllList,
    UpdateInvoiceGrandSubTotal,
    PlaySound,
    UpdateProductQtyAndReturnAllList,
    DeleteProductAndReturnAllList,
    RestInitialInvoiceStates,
} = PosReduxMethods();

const initialState = {
    /* pos settings */
    is_show_products_list: true,
    previous_is_show_ended_products_list: true,
    is_show_ended_products_list: true,
    is_allow_print_in_invoice: true,

    filtration_type: "categories", // "categories", "sub_categories" or "brands"
    filtration_params: {},
    filtration_list: [], // List of "categories", "sub_categories" or "brands"
    is_products_loading: false,
    products: [],
    pagination: null,
    warehouse_selected: null,
    warehouses: [],
    customers: [],
    customer_id_selected: null,
    params: {},

    /* pos invoice */
    invoice_products: [],
    invoice_grand_sub_total: 0,
    invoice_tax: 0,
    invoice_discount: 0,
    invoice_grand_total: 0,
    shipping: 0,
    notes: '',
    payment_type: 0, // 0 => cash, 1 => payment, 2 => cheque

    payment_status: 0, // 0 => cash, 1 => doubted
    paid_amount: 0, // when payment_status is cash => (0)
    rest_amount: 0,

    invoice_paid: '',
    invoice_rest_of_paid: '',
    is_confirm_payment_popup_opened: false,
    is_pos_submit_loader: false,
    pos_submit_errors: {},
};

export const Pos = createSlice({
    name: 'pos',
    initialState,
    reducers: {
        ConvertFiltrationTypeAction: (state, {type, payload}) => {
            state.filtration_type = payload;
            return state;
        },

        UpdateFiltrationListAction: (state, {type, payload}) => {
            state.filtration_list = payload;
            return state;
        },

        UpdateProductsListAction: (state, {type, payload}) => {
            state.products = payload;
            return state;
        },

        AppendProductToInvoiceAction: (state, {type, payload}) => {
            state.invoice_products = UpdateRepeatedProductQuantityAndReturnAllList(state, payload);
            UpdateInvoiceGrandSubTotal(state);
            // PlaySound();
            return state;
        },

        AppendNewCustomerToCustomersAction: (state, {type, payload}) => {
            state.customers = [...state.customers, payload];
            return state;
        },

        OnChangeCustomerAction: (state, {type, payload}) => {
            state.customer_id_selected = payload;
            return state;
        },

        UpdateInvoiceProductQtyAction: (state, {type, payload}) => {
            state.invoice_products = UpdateProductQtyAndReturnAllList(state, payload)
            UpdateInvoiceGrandSubTotal(state);
            return state;
        },

        DeleteInvoiceProductAction: (state, {type, payload}) => {
            state.invoice_products = DeleteProductAndReturnAllList(state, payload)
            UpdateInvoiceGrandSubTotal(state);
            return state;
        },

        /* tax & shipping $ discount */
        ChangeInvoiceTaxAction: (state, {type, payload}) => {
            state.invoice_tax = parseFloat(payload ?? 0);
            UpdateInvoiceGrandSubTotal(state);
            return state;
        },

        SetPosShippingAction: (state, {type, payload}) => {
            state.shipping = parseFloat(payload ?? 0);
            UpdateInvoiceGrandSubTotal(state);
            return state;
        },

        ChangeInvoiceDiscountAction: (state, {type, payload}) => {
            state.invoice_discount = parseFloat(payload ?? 0);
            UpdateInvoiceGrandSubTotal(state);
            return state;
        },
        /* tax & shipping $ discount */

        ChangeInvoicePaidAction: (state, {type, payload}) => {
            state.invoice_paid = payload;
            let invoice_rest_of_paid = parseFloat(payload - state.invoice_grand_total).toFixed(2);
            state.invoice_rest_of_paid = invoice_rest_of_paid >= 0 || invoice_rest_of_paid == "" ? invoice_rest_of_paid : trans("The Paid is less than grand total");
            return state;
        },

        ChangeWarehouseAction: (state, {type, payload}) => {
            state.warehouse_selected = payload;
            state.params = {...state.params, 'warehouse_id': state?.warehouse_selected?.id};
            RestInitialInvoiceStates(state);
            return state;
        },

        SetWarehouseSelectedAction: (state, {type, payload}) => {
            state.warehouse_selected = state?.warehouses?.length > 0 ? state?.warehouses[0] : null;
            return state;
        },

        RestInitialInvoiceStatesAction: (state, {type, payload}) => {
            RestInitialInvoiceStates(state);
            return state;
        },

        appendFilterParams: (state, {type, payload}) => {
            state.params = payload;
            return state;
        },

        RestoreInvoiceHoldsAction: (state, {type, payload}) => {

            const old_item = payload;
            const invoice_items = old_item['sale_items'] ?? [];

            /* invoice products */
            let invoice_products = [];
            invoice_items.forEach(function (invoice_item) {
                invoice_products.push(
                    preparing_invoice_product(
                        invoice_item?.product?.id,
                        invoice_item?.product?.name,
                        invoice_item?.product?.image_url,
                        invoice_item?.product_price,
                        invoice_item?.product_cost,
                        invoice_item[`${state?.invoice_type}_unit`]?.id,
                        Math.abs(invoice_item?.quantity),
                        invoice_item?.tax_value,
                        invoice_item?.discount_type,
                        invoice_item?.discount_value,
                        Math.abs(invoice_item?.sub_total),
                        invoice_item?.product?.sale_unit,
                        invoice_item?.product?.purchase_unit
                    ));
            });

            /**
             invoice_products
             invoice_grand_sub_total
             invoice_tax
             invoice_discount
             invoice_grand_total
             */

            state.invoice_products = invoice_products;
            state.warehouse_selected = old_item?.warehouse;
            state.invoice_grand_sub_total = Math.abs(old_item?.sub_grand_total ?? "0");
            state.invoice_tax = old_item?.tax_rate ?? 0.00;
            state.invoice_discount = old_item?.discount_amount;
            state.invoice_grand_total = Math.abs(old_item?.grand_total ?? 0.00);

            return state;
        },

        SetPosNotesAction: (state, {type, payload}) => {
            state.notes = payload;
            return state;
        },

        SetPosPaymentTypeAction: (state, {type, payload}) => {
            state.payment_type = payload;
            return state;
        },

        SetPosPaymentStatusAction: (state, {type, payload}) => {
            state.payment_status = payload;
            return state;
        },

        SetPosPaidAmountAction: (state, {type, payload}) => {
            let paid_amount = parseFloat(payload ?? "0");
            state.paid_amount = parseFloat((paid_amount).toFixed(2));
            state.rest_amount = parseFloat((state.invoice_grand_total - paid_amount).toFixed(2));
            if (state.rest_amount < 0) {
                warningSweetAlert(trans("The amount paid is more than the total bill"));
                state.paid_amount = 0;
                state.rest_amount = 0;
            }
            return state;
        },

        ChangeConfirmPaymentPopUpAction: (state, {type, payload}) => {
            state.is_confirm_payment_popup_opened = payload;
            return state;
        },

        ChangePosSubmitLoaderAction: (state, {type, payload}) => {
            state.is_pos_submit_loader = payload;
            return state;
        },

        SetPosSubmitErrorsAction: (state, {type, payload}) => {
            state.pos_submit_errors = payload;
            return state;
        },

        ChangeIsShowProductsList: (state, {type, payload}) => {
            let isTrue = (payload === "true" || payload === true) ?? false; // convert to boolean
            state.is_show_products_list = isTrue;
            setCookie('is_show_products_list', isTrue);
            return state;
        },

        ChangeIsShowEndedProductsList: (state, {type, payload}) => {
            let isTrue = (payload === "true" || payload === true) ?? false; // convert to boolean
            state.previous_is_show_ended_products_list = state.is_show_ended_products_list;
            state.is_show_ended_products_list = isTrue;
            setCookie('is_show_ended_products_list', isTrue);
            return state;
        },

        ChangeIsAllowPrintInInvoice: (state, {type, payload}) => {
            let isTrue = (payload === "true" || payload === true) ?? false; // convert to boolean
            state.is_allow_print_in_invoice = isTrue;
            setCookie('is_allow_print_in_invoice', isTrue);
            return state;
        },

    },
    extraReducers: (builder) => {
        /** categories */
        builder.addCase(fetchAllCategories.pending, (state, action) => {
            // state.isLoading = true;
        });
        builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
            state.filtration_list = action?.payload?.data ?? [];
        });

        /** customers */
        builder.addCase(fetchAllCustomers.pending, (state, action) => {
            // state.isLoading = true;
        });
        builder.addCase(fetchAllCustomers.fulfilled, (state, action) => {
            state.customers = action?.payload?.data ?? [];
        });

        /** warehouses */
        builder.addCase(fetchAllWarehouses.pending, (state, action) => {
            // state.isLoading = true;
        });
        builder.addCase(fetchAllWarehouses.fulfilled, (state, action) => {
            state.warehouses = action?.payload?.data ?? [];
            state.warehouse_selected = state?.warehouses?.length > 0 ? state?.warehouses[0] : null;
            state.params = {...state.params, 'warehouse_id': state?.warehouse_selected?.id};
        });

        /** products */
        builder.addCase(fetchAllProducts.pending, (state, action) => {
            state.is_products_loading = true;
        });
        builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
            state.is_products_loading = false;
            state.products = action?.payload?.data ?? [];
            state.pagination = action?.payload?.pagination ?? null;
        });
    }
});


export const {
    appendFilterParams,
    ConvertFiltrationTypeAction,
    UpdateFiltrationListAction,
    UpdateProductsListAction,
    AppendProductToInvoiceAction,
    UpdateInvoiceProductQtyAction,
    ChangeInvoiceTaxAction,
    ChangeInvoiceDiscountAction,
    ChangeInvoicePaidAction,
    DeleteInvoiceProductAction,
    AppendNewCustomerToCustomersAction,
    OnChangeCustomerAction,
    ChangeWarehouseAction,
    SetWarehouseSelectedAction,
    RestInitialInvoiceStatesAction,
    RestoreInvoiceHoldsAction,
    SetPosShippingAction,
    SetPosNotesAction,
    SetPosPaymentTypeAction,
    SetPosPaymentStatusAction,
    SetPosPaidAmountAction,
    ChangeConfirmPaymentPopUpAction,
    ChangePosSubmitLoaderAction,
    SetPosSubmitErrorsAction,

    ChangeIsShowProductsList,
    ChangeIsShowEndedProductsList,
    ChangeIsAllowPrintInInvoice,
} = Pos.actions

export default Pos.reducer