import React from 'react';
import NormalInput from "../../../../shared/inputs/NormalInput";

const InvoiceDate = ({is_hidden}) => {
    return (
        <NormalInput
            readOnly={true}
            type="date"
            name={"date"}
            value={new Date().toJSON().slice(0, 10)}
            className={`col-lg-12 col-md-12 col-12 ${is_hidden ? "d-none" : ""}`}/>
    );
};

export default InvoiceDate;