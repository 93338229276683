import React from 'react';

const LoginButton = ({isSubmitLoading, isDisabled = false}) => {
    return (
        <div className="form-login">
            <button type="submit" className="btn btn-login" disabled={isDisabled}>
                <span>{isSubmitLoading && <i className="loader-btn"></i>}</span>
                <span>تسجيل الدخول</span>
            </button>
        </div>
    );
};

export default LoginButton;
