import {prefixRouterPath, preparingCanExportExcel, useRtkState} from "../../../../config/helper";
import {useDispatch} from "react-redux";
import {appendFilterParams, endpoint, exportHeaders, reRenderItems} from "../../../../store/table/slices/tableSlice";
import {useEffect, useState} from "react";
import AxiosInstance from "../../../../config/AxiosInstance";
import {SetInvoiceType} from "../../../../store/invoices/InvoiceOperations";

export default () => {
    /* table */
    const prefix = 'reports';
    const module_endpoint = 'purchases-return-report';
    const module_name = 'تقارير مرتجعات المشتريات';
    const create_redirect = "";

    const headers = [
        {
            label: 'الرقم المرجعي',
            key: 'reference_code',
            printable: true,
            sortable: false,
            can_export_excel: false,
        },
        {
            label: 'المخزن',
            key: 'warehouse',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'المورد',
            key: 'supplier',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'الاجمالي',
            key: 'grand_total',
            printable: true,
            sortable: false,
            can_export_excel: true,
        },
        {
            label: 'التاريخ',
            key: 'date',
            printable: true,
            sortable: false,
            can_export_excel: true,
        }
    ];

    /* table state */
    const table = useRtkState('table');
    const dispatch = useDispatch();
    const items = table.items;
    const items_selected = table.items_selected;
    const isLoading = table.isLoading;
    const currency = useRtkState('Settings').settings?.currency;
    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        dispatch(endpoint(module_endpoint, {}));
        dispatch(exportHeaders(preparingCanExportExcel(headers), {}));
        dispatch(SetInvoiceType('purchase', {}));
        dispatch(reRenderItems())

        AxiosInstance.get('warehouses?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setWarehouses(data.data);
        }).catch(function ({response}) {
            //
        });
        AxiosInstance.get('suppliers?sort_type=asc&sort_attribute=id', {}).then(async function ({data}) {
            await setSuppliers(data.data);
        }).catch(function ({response}) {
            //
        });
    }, []);

    const OnChangeWarehouse = (event) => {
        dispatch(appendFilterParams({...table.params, warehouse_id: event.target.value}, {}));
    }

    const OnChangeSupplier = (event) => {
        dispatch(appendFilterParams({...table.params, supplier_id: event.target.value}, {}));
    }

    const OnChangeDuration = (event) => {
        dispatch(appendFilterParams({...table.params, report_type: event.target.value}, {}));
    }

    return {
        prefix,
        module_endpoint,
        module_name,
        create_redirect,
        headers,
        items,
        currency,
        items_selected,
        isLoading,
        warehouses,
        suppliers,
        OnChangeWarehouse,
        OnChangeSupplier,
        OnChangeDuration,
    };
}