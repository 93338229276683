import React from 'react';
import LogoComponent from "../layout/header/LogoComponent";
import PosProfileComponent from "../layout/header/PosProfileComponent";
import CalculatorComponent from "./components/PosHeader/CalculatorComponent";
import ScanCodeComponent from "./components/PosHeader/ScanCodeComponent";
import HoldComponent from './components/PosHeader/HoldComponent';
import InstallmentsComponent from './components/PosHeader/InstallmentsComponent';
import SystemSetting from './components/PosHeader/SystemSetting';
import SearchComponent from './components/PosCore/SearchComponent';

const PosHeaderComponent = () => {
    return (
        <>
            <LogoComponent is_allow_redirect={true}/>
            <SearchComponent/>
            <ScanCodeComponent/>
            <HoldComponent/>
            {/*<InstallmentsComponent/>*/}
            <CalculatorComponent/>
            <SystemSetting/>
            <PosProfileComponent/>
        </>
    );
};

export default PosHeaderComponent;