import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import AxiosInstance from "../../../config/AxiosInstance";
import {prefixRouterPath, warningSweetAlert} from "../../../config/helper";
import PageHeader from '../../../components/layout/PageHeader';
import CardComponent from '../../../components/cards/CardComponent';
import {import_products} from '../../../config/images-path';


const ImportProducts = () => {

    const UPLOAD_PER_TIME = 100;

    const [progressBar, setProgressBar] = useState(0);
    const [currentUploaded, setCurrentUploaded] = useState(0);
    const [jsonExcelDataLength, setJsonExcelDataLength] = useState(0);
    const [fileName, setFileName] = useState('');
    /**
     * @param jsonExcelData
     * @returns {[]}
     * @constructor
     */
    const ConvertExcelRowsTORealArrayOfObject = (jsonExcelData) => {
        let excelData = [];

        console.log({jsonExcelData});

        for (let i = 1; i < jsonExcelData.length; i++) {

            let brand_name = jsonExcelData[i][2] ?? "";
            let category_name = jsonExcelData[i][3] ?? "";
            let sub_category_name = jsonExcelData[i][4] ?? "";
            let name = jsonExcelData[i][1] ?? "";
            let code = jsonExcelData[i][5] ?? "";
            let price = jsonExcelData[i][6] ?? "";
            let image = jsonExcelData[i][7] ?? "";

            let Data = {
                brand_name: brand_name,
                category_name: category_name,
                sub_category_name: sub_category_name,

                // product info
                name: name,
                code: code,
                // price: 100,
                price: parseFloat(price.match(/\d+\.\d+/)) || parseInt(price.match(/\d+/)) || 0,
                image: image,
            };
            excelData.push(Data);
        }
        return excelData;
    }

    /**
     * @param RealArrayOfObject
     * @returns {Promise<void>}
     * @constructor
     */
    const StoreDataToServer = async (RealArrayOfObject) => {
        await AxiosInstance.post("products/export-products", RealArrayOfObject).then(async function (res) {
            let data = res?.data?.data ?? [];
        }).catch(function (response) {
            //
        });
    }

    /**
     * @param RealArrayOfObject
     * @param currentUploadedCount
     * @param RealArrayOfObjectLength
     * @returns {Promise<boolean>}
     * @constructor
     */
    const UploadExcelRowsToServer = async (RealArrayOfObject, currentUploadedCount = 0, RealArrayOfObjectLength) => {
        if (currentUploadedCount >= RealArrayOfObjectLength) {
            setProgressBar(100);
            setJsonExcelDataLength(0);
            setCurrentUploaded(RealArrayOfObjectLength);
            return false;
        }
        let NewRealArrayOfObject = [];
        let loopForCount = (currentUploadedCount + UPLOAD_PER_TIME);
        if ((RealArrayOfObjectLength - currentUploadedCount) < UPLOAD_PER_TIME) {
            loopForCount = RealArrayOfObjectLength - currentUploadedCount;
        }
        for (let i = currentUploadedCount; i < loopForCount; i++) {
            NewRealArrayOfObject.push(RealArrayOfObject[i]);
        }
        await StoreDataToServer(NewRealArrayOfObject);
        setCurrentUploaded(currentUploadedCount);
        setProgressBar(parseFloat((currentUploadedCount / RealArrayOfObjectLength * 100).toFixed(0)));
        await UploadExcelRowsToServer(RealArrayOfObject, (currentUploadedCount + UPLOAD_PER_TIME), RealArrayOfObjectLength);
    }

    /**
     * @param file
     * @returns {Promise<void>}
     * @constructor
     */
    const UploadExcelFile = async (file) => {

        try {
        const reader = new FileReader();
        reader.onload = async (e) => {
            if (e?.target?.result) {
              setFileName(file.name);
                let data = new Uint8Array(e?.target?.result);
                let workbook = XLSX.read(data, {type: 'array'});
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonExcelData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                if (!jsonExcelData[0] || jsonExcelData[0].length > 8) {
                    warningSweetAlert("من فضلك قم بتحميل المثال وادخل البيانات كما موضح بالمثال بنفس الترتيب .");
                    return false;
                }

                let jsonExcelDataLength = jsonExcelData.length;

                setJsonExcelDataLength(jsonExcelDataLength - 1);

                /* Convert Excel Rows TO Real Array Of Object */
                let RealArrayOfObject = await ConvertExcelRowsTORealArrayOfObject(jsonExcelData);

                /* Upload Excel Rows To Server */
                await UploadExcelRowsToServer(RealArrayOfObject, 0, jsonExcelDataLength - 1);
            }
        };
        reader.readAsArrayBuffer(file);
        } catch (e) {
            // console.log(e);
        }
    }

    return (
        <>
            <PageHeader title="استيراد الاصناف"/>
            <CardComponent>
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <label className='label-upload' htmlFor='upload-xlsx'>
                            <i className='bi bi-cloud-arrow-up'></i>
                            <span>رجاء قم باختيار ملف بامتداد xlsx</span>
                            <input className="form-control" type="file" accept=".xlsx" id='upload-xlsx'
                                   onChange={(e) => UploadExcelFile(e.target.files[0])}/>
                        </label>

                        {jsonExcelDataLength > 0 || progressBar >= 100 ? <div className="file-status">
                            <i className="icon-excel bi bi-file-earmark-excel"></i>
                            <div className='file-content'>
                                <p>{fileName}</p>
                                {jsonExcelDataLength > 0 ? (
                                    <span>{`${jsonExcelDataLength} / ${currentUploaded}`}</span>) : (
                                    <span>{`${currentUploaded} / مكتمل`}</span>)}
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar"
                                         style={{width: `${progressBar}%`}} aria-valuenow={`${progressBar}`}
                                         aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <p className="progress-value">{progressBar}%</p>
                            <i className={progressBar >= 100 ? 'bi bi-check-circle-fill' : 'loader-btn '}></i>
                        </div> : ''}


                    </div>
                    <div className="col-lg-5 col-md-6">
                        <a className='download-example btn-primary' href={import_products} download>
                            <i className='bi bi-download'></i>
                            تحميل مثال
                        </a>
                    </div>
                </div>
            </CardComponent>
        </>
    );

}

export default ImportProducts;
